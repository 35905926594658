import { baseList } from '@/constants/base/webSite';
import ClientRequestMessageModal from '@/views/components/common/modal/ClientRequestMessageModal.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

const DEV_TIMEOUT_MILLISECOND = 1000;

function getPath(path, defaultPath) {
  return path && path.length ? path : defaultPath;
}

function combineUrl(url, path) {
  return `${url}${path || ''}`;
}

export const tncInfo = siteId => {
  // TODO : [ean] 이용 약관, 개인 정보처리 방침, 사이버 보안 생태계 정책 등 link 부분 보정 필요
  const list = {
    N8IN: { brandName: 'NATURAL8', tncPath: '/tnc', securityPath: '/security-ecology-agreement', bonusPath: '/bonus-policy', horusPath: '/house-rules ' },
    GGPOK: { securityPath: '/security-ecology-agreement/', brandName: 'POKEROK' },
    GGPBE: { age: 21 },
    TWOACE: { brandName: '2ACE' },
    WSOPON: { brandName: 'GGPoker.ca', age: 19 },
    GGPPH: { bonusPath: '/bonus-policy', age: 21 },
  };
  const defaultPaths = {
    tncPath: '/terms-conditions/',
    securityPath: '/security-ecology-policy/',
    bonusPath: '',
    horusPath: '',
  };

  const url = baseList[siteId]?.url ?? '';
  const selectedItem = list[siteId] || {};
  const {
    tncPath,
    securityPath,
    bonusPath,
    horusPath,
    brandName = 'GGPoker',
    age = 18,
  } = selectedItem;
  const urls = {
    tncPath: combineUrl(url, getPath(tncPath, defaultPaths.tncPath)),
    privacyPath: combineUrl(url, '/privacy-policy/'),
    securityPath: combineUrl(url, getPath(securityPath, defaultPaths.securityPath)),
    bonusPath: combineUrl(url, getPath(bonusPath, defaultPaths.bonusPath)),
    horusPath: combineUrl(url, getPath(horusPath, defaultPaths.horusPath)),
  };

  return { urls, brandName, age };
};

export const webSiteInfo = siteId => baseList[siteId] || baseList.GGPCOM;

/**
 * brand web modal close 요청
 */
export const requestBrandWebCloseModal = async (scope, reason = null) => {
  let setTimeoutMillisecond = 0;
  const message = {key: 'CLOSE_SIGNUP', data: {reason}};
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cwebSiteUtil requestBrandWebCloseModal', 'color:blue', JSON.stringify(message));
  window.parent.postMessage(message, '*');
};

/**
 * brand web modal close 요청
 */
export const requestBrandWebDownloadInstaller = async scope => {
  let setTimeoutMillisecond = 0;
  const message = {key: 'DOWNLOAD_INSTALLER'};
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cwebSiteUtil requestBrandWebDownloadInstaller', 'color:blue', JSON.stringify(message));
  window.parent.postMessage(message, '*');
};

/**
 * brand web signup 랜더링 완료 정보 전달
 */
export const sendSignupReadyToBrandWeb = async scope => {
  // let setTimeoutMillisecond = 0;
  const message = {key: 'READY_FOR_SIGNUP'};
  // if (process.env.VUE_APP_ENV !== 'production') {
  //   setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
  //   await scope?.$modal(ClientRequestMessageModal, { message });
  // }
  //
  // if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cwebSiteUtil requestBrandWebDownloadInstaller', 'color:blue', JSON.stringify(message));
  window.parent.postMessage(message, '*');
};