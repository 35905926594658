import { isExcludeGgpass, isExcludeTnc, isInfoCaptcha } from '@/utils/baseUtil';
import TncCheckList from '@/views/components/pages/sign-up/TncCheckList.vue';
import FooterBasic from '@/views/components/extra/FooterBasic.vue';
import SignupForm from '@/views/components/pages/sign-up/SignupForm.vue';
import { routeNames } from '@/constants/base/signup/signUpSiteMap';
import VerifyForm from '@/views/components/pages/sign-up/VerifyForm.vue';
import SignUpComplete from '@/views/pages/sign-up/SignUpComplete.vue';
import { siteIds } from '@/constants/base/siteMap';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import HighestPassword from '@/views/components/pages/sign-up/HighestPassword.vue';

/** @define
 * */
export default ({ site, npOnly }) => {
  const excludeGgPass = isExcludeGgpass(site);
  const list = [
    {
      route: routeNames.SignUpPersonalInfo,
      title: 'title.GGPassSignUp',
      template: SignupForm,
      captcha: true,
      footer: FooterBasic,
      buttonTag: {
        EMAIL: 'Step4_Email_Password_Button',
        MOBILE: 'Step4_Mobile_Password_Button', // TODO : [ean] GTM 정의되면 보정 필요
      },
      buttonClass: {
        EMAIL: 'submit-email-password-btn',
        MOBILE: 'submit-mobile-password-btn', // TODO : [ean] GTM 정의되면 보정 필요
      },
      keys: {
        EMAIL: ['Email', 'Password'],
        MOBILE: ['MobileNumber', 'MobileCountryCode', 'Password'],
      },
      passed: false,
      service: {
        default: 'ggpass',
        [siteIds.N8IN]: 'sign',
        [siteIds.NATURAL8]: 'sign',
        [siteIds.SEVENXL]: 'sign',
        [siteIds.TWOACE]: 'sign'
      },
      api: {
        default: 'checkCredentials',
        [siteIds.NATURAL8]: 'sendEmailVerificationCode',
        [siteIds.N8IN]: 'emailVerification',
        [siteIds.SEVENXL]: 'sendEmailVerificationCode',
        [siteIds.TWOACE]: 'emailVerification'
      },
      // action: excludeGgPass ? 'SignupSendEmailCode' : 'SIGN_UP'
      action: {
        default: 'SIGN_UP',
        [siteIds.NATURAL8]: 'SignupSendEmailCode',
        [siteIds.N8IN]: 'SignupCheckUserEmail',
        [siteIds.SEVENXL]: 'SignupSendEmailCode',
        [siteIds.TWOACE]: 'SignupSendEmailCode'
      },
    },
    {
      route: routeNames.SignUpTnc,
      title: npOnly ? 'title.CreateAccount' : 'title.GGPassSignUp',
      template: TncCheckList,
      captcha: false,
      footer: FooterBasic,
      buttonTag: 'Step6_Sign_Up_Consent_Button',
      buttonClass: 'signup-consent-items-btn',
      extra: {
        [siteIds.GGPRO]: { template: ProgressButton }
      },
      keys: npOnly && isExcludeTnc(site) ? null : ['Agree'],
      action: excludeGgPass ? 'SignupSendEmailCode' : 'SIGN_UP',
      service: {
        default: 'ggpass',
        [siteIds.N8IN]: 'sign',
        [siteIds.NATURAL8]: 'sign',
        [siteIds.SEVENXL]: 'sign',
        [siteIds.TWOACE]: 'sign'
      },
      api: {
        default: 'signup',
        [siteIds.NATURAL8]: 'sendEmailVerificationCode', // 사실상 활용 X, tnc가 없음
        [siteIds.N8IN]: 'sendEmailVerificationCode',
        [siteIds.SEVENXL]: 'sendEmailVerificationCode', // 사실상 활용 X, tnc가 없음
        [siteIds.TWOACE]: 'sendEmailVerificationCode'
      },
    },
    {
      route: routeNames.SignUpVerify,
      title: 'title.GGPassSignUp',
      template: VerifyForm,
      captcha: false,
      footer: FooterBasic,
      buttonTag: 'Step8A_Email_Verification_Submit_Button',
      buttonClass: 'signup-email-verify-btn',
      keys: ['VerificationCode'],
      passed: false,
      action: excludeGgPass ? 'SignupCreateAccount' : 'SIGN_UP',
      service: {
        default: 'ggpass',
        [siteIds.NATURAL8]: 'sign',
        [siteIds.N8IN]: 'sign',
        [siteIds.SEVENXL]: 'sign',
        [siteIds.TWOACE]: 'sign',
      },
      api: {
        default: 'signup',
        [siteIds.NATURAL8]: 'signup',
        [siteIds.N8IN]: 'signup',
        [siteIds.SEVENXL]: 'signup',
        [siteIds.TWOACE]: 'signup'
      },
    },
    {
      route: routeNames.SignUpHighestPassword,
      title: npOnly ? 'title.CreateAccount' : 'title.GGPassSignUp',
      template: HighestPassword,
      captcha: false,
      footer: FooterBasic,
      buttonTag: '', // TODO : [ean] GTM 정의되면 보정 필요
      buttonClass: '', // TODO : [ean] GTM 정의되면 보정 필요
      keys: npOnly ? ['Password'] : null,
      action: null,
      service: {
        default: 'ggpass',
      },
      api: {
        default: 'updateNewPassword',
      },
    },
    { route: routeNames.SignUpComplete, template: SignUpComplete },
  ];

  return {
    step: 0,
    progress: 0,
    progressGroup: [],
    limitTime: 0,
    model: {
      BonusCode: null,
      CountryCode: null,
      Email: null,
      MobileNumber: null,
      MobileCountryCode: null,
      Password: null,
      VerificationCode: null,
      Agree: null,
      AllAgree: null,
      SendVerificationType: null,
    },
    error: false,
    errorMsg: null,
    target: 'signUp',
    verificationType: 'email',
    list,
  };
};
