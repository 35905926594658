<template>
  <div jumio-description v-if="show">
    <div v-for="(desc, i) in list[step].message" :key="i">
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { jumioStep } from '@/constants/base/onboarding/kyc';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'JumioDescription',
  lexicon: 'onBoarding.jumio.description',
  props: {
    step: String,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    show() {
      return this.list?.[this.step]?.isShow;
    }
  },
  mounted() {
    this.list = {
      [jumioStep.None]: { isShow: [siteIds.GGPDE].includes(this.site), message: [this.$t('_.uploadRequestedDocuments'), this.$t('_.forFurtherAssistance', { csEmail: this.csEmail })] },
      [jumioStep.Identity]: { isShow: [siteIds.GGPDE].includes(this.site), message: [this.$t('_.pleaseUpload'), this.$t('_.recommendUploadPersonalausweis')] },
      [jumioStep.Reviewing]: { isShow: [siteIds.GGPDE].includes(this.site), message: [this.$t('_.uploadedSuccessfully'), this.$t('_.verifyAndNotifyToEmail'), this.$t('_.thankYou')] },
      [jumioStep.Document]: { isShow: [siteIds.GGPDE, siteIds.GGPUK].includes(this.site), message: [this.$t('_.eachDocumentHasExpiration'), this.$t('_.uploadValidDocument')] },
      [jumioStep.PepFailed]: { isShow: [siteIds.GGPDE].includes(this.site), message: [this.$t('_.failed')] },
      [jumioStep.Failed]: { isShow: [siteIds.GGPDE].includes(this.site), message: [this.$t('_.failed')] },
    };
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[jumio-description] { .mb(16);
  > div + div { .mt(16); }
}
</style>
