<template>
  <div reaffirm-de-information v-if="ready">
    <div class="guide-holder">
      <p v-html="$t('_.guide')" />
    </div>
    <ul class="contents-holder">
      <li>
        <div class="contents">
          <p>{{ $t('address') }}</p>

          <dl>
            <template v-for="(item, id) in contents">
              <div class="reaffirm-de-item">
                <dt :key="`dt-${id}`">{{ $t(item.label) }}:</dt>
                <dd :key="`dd-${id}`">{{ item.value }}</dd>
              </div>
            </template>
          </dl>

        </div>
      </li>
    </ul>
    <div class="button-holder">
      <ProgressButton class="nothing" button-name="onBoarding.reaffirm.de.nothing" @click="nothing" />
      <ProgressButton class="haveChange" button-name="onBoarding.reaffirm.de.haveChange" @click="haveChange" />
    </div>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ReaffirmDeComplete from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeComplete.vue';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';

export default {
  name: 'ReaffirmDeInformation',
  lexicon: 'onBoarding.reaffirm.de',
  components: { ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      contents: [],
      expireOrder: [],
      ready: false,
    };
  },
  methods: {
    nothing() {
      this.structure.customSteps = [ReaffirmDeComplete];
    },
    async haveChange() {
      this.structure.customSteps = this.expireOrder;
    },
    async stepInitialize() {
      this.expireOrder = await this.$onboarding.getExpireOrder();
      if (this.expireOrder && this.expireOrder.length > 0 && this.expireOrder?.[0] !== routeNames.verifyContactEmail) this.structure.customSteps = this.expireOrder;
      else {
        this.contents = [
          { label: 'street', value: this.modelInfo.StreetName ?? '' },
          { label: 'houseNumber', value: this.modelInfo.HouseNumber ?? '' },
          { label: 'personalDetails.additionalAddress', value: this.modelInfo.Address ?? '' },
          { label: 'personalDetails.city', value: this.modelInfo.City ?? '' },
          { label: 'personalDetails.stateProvince', value: this.modelInfo.StateName ?? '' },
          { label: 'personalDetails.postalCode', value: this.modelInfo.PostalCode ?? '' },
        ];
      }
    }
  },
  async mounted() {
    this.item.title = 'onBoarding.reaffirm.de.title';
    await this.stepInitialize();
    this.ready = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de-information] {
  p { .fs(15); }
  > .guide-holder { .pb(20); }
  > .contents-holder { .pt(20) !important; .-t(#434343);
    li { .flex();
      > .contents { flex: 1; }
      .reaffirm-de-item {.flex(); .c(#7f7f7f); .ph(16px); .w(100%);
        > dt {flex: 2;}
        > dd { flex: 1; white-space: nowrap;}
      }
    }
  }
  @media (@ms-down) {
    > .contents-holder { .ph(0) !important;
      li { .flex();
        > .contents { flex: 1; }
        .reaffirm-de-item {.flex(); .c(#7f7f7f); .ph(8px); .w(100%); .gap(5);
          > dt {flex: 1;}
          > dd { flex: 1;}
        }
      }
    }

  }
  > .button-holder {
    @media (@ml-down) {
      > [progress-button] {line-height: 16px;}
    }
    > [progress-button] { .fs(18);
      &.nothing { .bgc(#448f69);
        @{hover-press} { .bgc(#346d50); }
      }
      &.haveChange { .bgc(#515151);
        @{hover-press} { .bgc(#515151); }
      }
      & + [progress-button] { .mt(12); }
    }
  }
}
</style>
