import RgDepositLimit from '@/views/components/pages/responsible-gaming/template/RgDepositLimit.vue';
import RgSingleBetLimit from '@/views/components/pages/responsible-gaming/template/RgSingleBetLimit.vue';
import RgTotalBetLimit from '@/views/components/pages/responsible-gaming/template/RgTotalBetLimit.vue';
import RgBalanceLimit from '@/views/components/pages/responsible-gaming/template/RgBalanceLimit.vue';
import RgLoginTimeLimit from '@/views/components/pages/responsible-gaming/template/RgLoginTimeLimit.vue';
import RgReLoginTimeLimit from '@/views/components/pages/responsible-gaming/template/RgReLoginTimeLimit.vue';
import RgMonthlyLoginFrequency from '@/views/components/pages/responsible-gaming/template/RgMonthlyLoginFrequency.vue';
import RgGameLimit from '@/views/components/pages/responsible-gaming/template/RgGameLimit.vue';
import RgLossLimit from '@/views/components/pages/responsible-gaming/template/RgLossLimit.vue';
import RgRealityCheckNotification from '@/views/components/pages/responsible-gaming/template/RgRealityCheckNotification.vue';
import RgAutomaticReserveWin from '@/views/components/pages/responsible-gaming/template/RgAutomaticReserveWin.vue';
import RgSelfExclusion from '@/views/components/pages/responsible-gaming/template/RgSelfExclusion.vue';
import RgLimitHistory from '@/views/components/pages/responsible-gaming/template/RgLimitHistory.vue';
import { rootRoute } from '@/constants/base/responsible-gaming';
import { siteIds } from '@/constants/base/siteMap';
import RgTwoAceLossLimit from '@/views/components/pages/responsible-gaming/template/RgTwoAceLossLimit.vue';
import { STEP } from '@/constants/base/my-page';
import RgExternalMonthlyLimit from '@/views/components/pages/responsible-gaming/template/RgExternalMonthlyLimit.vue';
export const routeNames = {
  RgMyDepositLimit: 'RgMyDepositLimit',
  RgMyTotalBetAmountLimit: 'RgMyTotalBetAmountLimit',
  RgMyLossLimit: 'RgMyLossLimit',
  RgMySingleBetAmountLimit: 'RgMySingleBetAmountLimit',
  RgMyBalanceLimit: 'RgMyBalanceLimit',
  RgMyLoginTimeLimit: 'RgMyLoginTimeLimit',
  RgMyReLoginTimeLimit: 'RgMyReLoginTimeLimit',
  RgMyMonthlyLoginFrequency: 'RgMyMonthlyLoginFrequency',
  RgMyGameLimit: 'RgMyGameLimit',
  RgMySelfExclusion: 'RgMySelfExclusion',
  RgMyRealityCheckNotification: 'RgMyRealityCheckNotification',
  RgMyAutomaticReserveWin: 'RgMyAutomaticReserveWin',
  RgLimitHistory: 'RgLimitHistory',
  RgTwoAceLossLimit: 'RgTwoAceLossLimit',
  RgExternalMonthlyLimit: 'RgExternalMonthlyLimit'
};
const siteModel = () => ({
  npOnly: true,
  model: {
    newDailyDepositLimit: null,
    newWeeklyDepositLimit: null,
    newMonthlyDepositLimit: null,
    newDailyTotalBetLimit: null,
    newWeeklyTotalBetLimit: null,
    newMonthlyTotalBetLimit: null,
    newDailyLossLimit: null,
    newWeeklyLossLimit: null,
    newMonthlyLossLimit: null,
    newSingleBetLimit: null,
    newBalanceLimit: null,
    newDailyLoginTimeLimit: null,
    newWeeklyLoginTimeLimit: null,
    newMonthlyLoginTimeLimit: null,
    reLoginTimeLimit: null,
    monthlyLoginFrequency: null,
    casinoGameLimit: null,
    liveDealerGameLimit: null,
    pokerGameLimit: null,
    interval: null,
    selfExclusionPeriodType: null,
    oasisBanCauses: null,
    reserveWinLimit: null,
  },
  root: rootRoute,
  'RgInfo': {
    title: 'Responsible Gaming',
  },
  [routeNames.RgMyDepositLimit]: {
    title: { default: 'depositLimit', [siteIds.GGPDE]: 'localDepositLimit' },
    description: { default: 'description.depositLimit', verifyFreeze: 'verifyFreeze.depositLimit' },
    component: RgDepositLimit,
    enableKey: 'IsEnableDepositLimit',
    requestKey: 'HasPendingDepositLimit',
    verifyFreezeKey: 'DepositLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit', 'deposit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getDepositLimit', params: null, index: 2 },
      { key: 'deposit', service: 'account', fetch: 'getPaymentDepositLimit', params: null, index: 3 },
    ],
    service: 'account',
    api: 'setResponsibleDepositLimit',
  },
  [routeNames.RgMyTotalBetAmountLimit]: {
    title: { default: 'totalBetLimit' },
    description: { default: 'description.totalBetLimit' },
    component: RgTotalBetLimit,
    enableKey: 'IsEnableTotalBetLimit',
    requestKey: 'HasPendingTotalBetLimit',
    verifyFreezeKey: 'TotalBetLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getTotalBetLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleTotalBetLimit',
  },
  [routeNames.RgMyLossLimit]: {
    title: { default: 'lossLimit' },
    description: { default: 'description.lossLimit' },
    component: RgLossLimit,
    enableKey: 'IsEnableLossLimit',
    requestKey: 'HasPendingLossLimit',
    verifyFreezeKey: 'LossLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getLossLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleLossLimit',
  },
  [routeNames.RgMySingleBetAmountLimit]: {
    title: { default: 'singleBetLimit' },
    description: { default: 'description.singleBetLimit' },
    component: RgSingleBetLimit,
    enableKey: 'IsEnableSingleBetLimit',
    requestKey: 'HasPendingSingleBetLimit',
    verifyFreezeKey: 'SingleBetLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getSingleBetLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleSingleBetLimit',
  },
  [routeNames.RgMyBalanceLimit]: {
    title: { default: 'balanceLimit', [siteIds.GGPCZ]: 'maximumBalance', [siteIds.GGPNL]: 'maximumBalance' },
    description: { default: 'description.balanceLimit', },
    component: RgBalanceLimit,
    enableKey: 'IsEnableBalanceLimit',
    requestKey: 'HasPendingBalanceLimit',
    verifyFreezeKey: 'BalanceLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getBalanceLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleBalanceLimit',
  },
  [routeNames.RgMyLoginTimeLimit]: {
    title: { default: 'playTimeLimit' },
    description: { default: 'description.playTimeLimit', },
    component: RgLoginTimeLimit,
    enableKey: 'IsEnableLoginTimeLimit',
    requestKey: 'HasPendingLoginTimeLimit',
    verifyFreezeKey: 'LoginTimeLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getLoginTimeLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleLoginTimeLimit',
  },
  [routeNames.RgMyReLoginTimeLimit]: {
    title: { default: 'reLoginLimit' },
    description: { default: 'description.reLoginLimit', },
    component: RgReLoginTimeLimit,
    enableKey: 'IsEnableReLoginTimeLimit',
    requestKey: 'HasPendingReLoginTimeLimit',
    verifyFreezeKey: 'ReLoginTimeLimitVerifyFreeze',
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getRelogintimelimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleReLoginTimeLimit',
  },
  [routeNames.RgMyMonthlyLoginFrequency]: {
    title: { default: 'monthlyLogin' },
    description: { default: 'description.monthlyLogin', },
    component: RgMonthlyLoginFrequency,
    enableKey: 'IsEnableMonthlyLoginFrequency',
    requestKey: 'HasPendingMonthlyLoginFrequency',
    verifyFreezeKey: 'MonthlyLoginFrequencyVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getRgMonthlyLoginFrequency', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleMonthlyLoginFrequency',
  },
  [routeNames.RgMyGameLimit]: {
    title: { default: 'gameLimit' },
    description: { default: 'description.gameLimit', },
    component: RgGameLimit,
    enableKey: 'IsEnableGameLimit',
    requestKey: 'HasPendingGameLimit',
    verifyFreezeKey: 'GameLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getGameLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleGameLimit',
  },
  [routeNames.RgMySelfExclusion]: {
    title: { default: 'selfExclusion' },
    description: { default: 'description.selfExclusion', [siteIds.GGPUK]: 'description.selfExclusionUK', 'GGPOKERPA': 'description.selfExclusionPA', 'GGPOKERNJ': 'description.selfExclusionPA', 'GGPOKERNV': 'description.selfExclusionPA' },
    component: RgSelfExclusion,
    enableKey: 'IsEnableSelfExclusion',
    requestKey: '',
    verifyFreezeKey: '',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getSelfExclusionType', params: null, index: 1 },
    ],
    service: 'account',
    api: 'setResponsibleSelfExclusion',
  },
  [routeNames.RgMyRealityCheckNotification]: {
    title: { default: 'realityCheckNotification' },
    description: { default: 'description.realityCheckNotification', },
    component: RgRealityCheckNotification,
    enableKey: 'IsEnableRealityCheckNotification',
    requestKey: '',
    verifyFreezeKey: '',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getRealityCheckNotification', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleRealityCheckNotification',
  },


  [routeNames.RgMyAutomaticReserveWin]: {
    title: { default: 'automaticReserveWin' },
    description: { default: 'description.automaticReserveWin' },
    component: RgAutomaticReserveWin,
    enableKey: 'IsEnableAutomaticReserveWin',
    requestKey: 'HasPendingAutomaticReserveWin',
    verifyFreezeKey: 'AutomaticReserveWinVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getAutomaticReserveWin', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setResponsibleAutomaticReserveWin',
  },
  [routeNames.RgExternalMonthlyLimit]: {
    title: { default: 'externalMonthlyLimit' },
    description: { default: 'description.externalMonthlyLimit' },
    component: RgExternalMonthlyLimit,
    enableKey: 'IsEnableExternalMonthlyLimit',
    requestKey: 'HasPendingExternalMonthlyLimit',
    verifyFreezeKey: 'ExternalMonthlyLimitVerifyFreeze',
    step: [STEP.DEFAULT],
    fetchKeys: ['limit'],
    fetchModels: [
      { key: 'limit', service: 'account', fetch: 'getDepositLimit', params: null, index: 2 },
    ],
    service: 'account',
    api: 'setLugasCentralFileLimit',
  },
  [routeNames.RgTwoAceLossLimit]: {
    title: { default: 'twoAceLimit' },
    description: { default: 'description.twoAceLimit' },
    component: RgTwoAceLossLimit,
    step: [STEP.DEFAULT, STEP.INPUT],
    fetchKeys: ['rgInfo', 'limit'],
    fetchModels: [
      { key: 'rgInfo', service: 'account', fetch: 'getRgConfig', params: true, index: 1, npOnly: true },
      { key: 'limit', service: 'account', fetch: 'getLossLimit', params: null, index: 2, npOnly: true },
    ],
    service: 'account',
    api: 'setResponsibleLossLimit',
  },

  [routeNames.RgLimitHistory]: {
    title: { default: '' },
    description: { default: 'description.depositLimit' },
    component: RgLimitHistory,
    step: [STEP.DEFAULT],
    fetchKeys: ['rgInfo'],
    fetchModels: [
      { key: 'rgInfo', service: 'account', fetch: 'getRgConfig', params: true, index: 1, },
    ],
    action: null
  }
});

export function getSiteList(rgModel, skipDeposit = false) {
  const routeModel = siteModel();
  const enableList = Object.keys(routeNames).filter(routeName => {
    if (routeName === routeNames.RgMyDepositLimit && skipDeposit) return false;
    const { enableKey } = routeModel[routeName];
    return rgModel[enableKey];
  });

  // const allList = Object.keys(routeNames).filter(routeName => {
  //   return true;
  // });

  const children = enableList.map(site => ({
    label: routeModel[site].title,
    name: site,
    enableKey: routeModel[site].enableKey,
    requestKey: routeModel[site].requestKey,
    verifyFreezeKey: routeModel[site].verifyFreezeKey,
  }));

  return [{ children: children }];
}
export default siteModel;