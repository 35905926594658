import RouterProxy from '@shared/modules/RouterProxy.js';
import { Service } from '@/services/Service';
import { isExcludeGgpass, updateSignEnv } from '@/utils/baseUtil';
import { defaultSiteMap, ggpassSiteMap, getSiteId } from '@/constants/base/signup/signUpSiteMap';
import { getCurrencySymbol } from '@/constants/model/my-page';
import { apiErrorType } from '@/constants/base/apiErrorCode';
import { log } from '@shared/utils/logUtils.mjs';

/**
 * @implements {ProxyManager}
 */
class ServiceManager extends Service {
  constructor({ store = null, cookies = null } = {}) {
    super({ store, cookies });
  }

  async mounted(app, route) {
    this.toast.setApp(app);
    this.modal.setApp(app);
  }

  getLocalApp(url) {
    const appList = {
      'find-id': 'fi', 'myinfo': 'myinfo', 'responsible-gaming': 'rg',
      'forgot-password': 'fp', 'promotion': 'promotion', 'onboarding': 'onboarding', 'signup': 'signup',
    };
    const key = Object.keys(appList).find(o => {
      return url.includes(o);
    });
    return key?.length ? appList[key] : '';
  }

  #gwOnly(url, queries) {
    const app = queries.app || this.getLocalApp(url);
    return url === '/' || ['signup', 'fp', 'fi'].includes(app);
  }

  #npOnly(url, queries) {
    if (!queries.app) return url.includes('responsible-gaming') || url.includes('rg');
    return queries.app === 'rg';
  }

  #notAuth(url) {
    return url.includes('auth-error') || url.includes('not-found');
  }

  async init(url, host, queries, store) {
    try {
      queries.ReferenceKey = queries.ReferenceKey || queries.referenceKey;
      queries.FindKey = queries.FindKey || queries.findKey;

      if (queries.isFromClientApp) this.cookie.setIsFromClientApp(true);
      if (queries.isFromMobileApp) this.cookie.setIsFromMobileApp(true);
      if (queries.redirectUri) this.cookie.setRedirectUri(queries.redirectUri);

      await this.#setSiteInfo(url, host, queries);
      if (!this.#notAuth(url)) {
        await this.token.updateGwToken(queries);

        await this.#getChannelingSupportRegions(queries);

        if (!this.#gwOnly(url, queries)) {
          if (queries.token || this.cookie.getToken()) await this.#getPrivateInfo(url, host, queries, store);
        } else {
          this.cookie.removeToken();
        }

        if (!isExcludeGgpass(store.state.env.site) && !this.#npOnly(url, queries)) {
          const app = store.state.query.app;

          if (!queries.ReferenceKey) {
            if (queries.token || this.cookie.getToken()) {
              if (app === 'myinfo') {
                // MyInfo 에서만 사용
                await this.#setOnePassInfo(store.state.env.brand);
              }
            }
          }
        }
      }
    } catch (e) {
      log({ logLevel: 'ERROR', message: 'error: init service'}, {host, url, queries, e});
      if (e.errorTemplate?.[apiErrorType.AUTH]) {
        location.href = `/auth-error/${e.errorTemplate?.ci}/${e.errorTemplate?.cci}`;
      }
    }

    return true;
  }

  async #setMyInfo(url, host, queries, store) {
    const r = await this.onboarding.getMyInfo();
    if (r?.error) {
      throw r;
    }
    const replaceSiteId = getSiteId((r?.SiteId || ''));
    this.store.commit('user/setUserInfo', r);
    await this.#setSiteInfo(url, host, queries, replaceSiteId);
  }

  async #setCurrencyList() {
    const r = await this.base.getCurrencyList();
    this.store.commit('config/setCurrencies', r.Currencies);
  }

  async #setLocalCurrency() {
    const r = await this.account.getBalance();
    const localCurrency = r?.LocalCurrency;
    const currencySymbol = getCurrencySymbol(localCurrency, '');

    this.store.commit('user/setCurrencySymbol', currencySymbol);
  }

  async #setOnePassInfo(brandAlias) {
    await this.base.getOpTokenByNpToken();
    const r = await this.ggpass.getInfo({ brandId: brandAlias, hasAvatar: true, hasMultiFactors: true, hasOauth: true, hasAccountDetail: true, hasMfas : true });
    this.store.commit('user/setOnePassInfo', r);
  }

  async #setSiteInfo(url, host, queries, userSite) {
    const { VUE_APP_ENV, VUE_APP_SITE, VUE_APP_SENTRY_DSN, VUE_APP_VERSION } = process.env;

    const site = (userSite || queries.siteId || VUE_APP_SITE);
    const npOnly = Boolean(queries.ReferenceKey);
    const siteInfo = defaultSiteMap({ domain: host, site, useBlock: site?.length && site.toLowerCase() !== 'none', npOnly });
    if (queries.ggpass) updateSignEnv({ host, store: this.store, route: this.$route, siteInfo: ggpassSiteMap(), queries });
    else updateSignEnv({ host, store: this.store, route: null, siteInfo, queries, site });
    const app = queries.app ? queries.app : this.getLocalApp(url);
    console.log('queries',queries)

    this.store.commit('query/setApp', app);
    this.store.commit('query/setRefKey', queries.ReferenceKey || queries.referenceKey);
    this.store.commit('query/setFindKey', queries.FindKey);
    this.store.commit('query/setSelectedLang', queries.lang);
    this.store.commit('env/setHost', host);
    this.store.commit('env/setCustomIp', queries.customIp);
    this.store.commit('query/setAtag', queries.atag || queries.aTag || queries.ATAG);
    this.store.commit('env/setGgPass', isExcludeGgpass(siteInfo.site) && queries.ggpass);
    this.store.commit('env/setTitle', this.getTitle({ host, queries, siteInfo }));
    this.store.commit('env/setNpOnly', npOnly);
    this.store.commit('env/setQueries', queries);
    this.store.commit('env/setEnv', VUE_APP_ENV);
    this.store.commit('env/setCasino', host.includes('casino') || process.env.VUE_APP_CASINO);
    return true;
  }

  async #getPrivateInfo(url, host, queries, store) {
    const token = queries.token || this.cookie.getToken();
    if (token) {
      this.cookie.setToken(token);
      this.store.commit('user/setToken', token);
      log({ message: 'user npToken'}, token);
      if (url !== '/sign-in') {
        await this.#setMyInfo(url, host, queries, store);
      }
    }
  }

  async #getChannelingSupportRegions(queries){
    const { channelingName } = queries;
    if(!channelingName) return;

    const r = await this.sign.getChannelingBonusCodes(channelingName.toLowerCase());
    let excludedList = null;
    if(r?.excluded) excludedList = r?.excluded.map(v => v.trim());
    this.store.commit('query/setChannelingRegionList', excludedList);
  }

  getTitle({ host, queries, siteInfo }) {
    const warpMode = queries.isFromExternalUrl && queries.siteId;
    const channelingBrand = {
      eapt: 'EAPT',
      roadtovegas: 'Road To Vegas',
      'wsop-paradise': 'WSOP Paradise',
      ocean:'OCEAN',
    };

    let siteTitle = queries.channelingName && channelingBrand[queries.channelingName] ? `${channelingBrand[queries.channelingName]} X ${siteInfo.title}` : warpMode ? `Signup | ${siteInfo.brandName}` : siteInfo.title;
    if (host.includes('eapt')) siteTitle = `EAPT X ${siteInfo.title}`;
    else if (host.includes('roadtovegas')) siteTitle = `Road To Vegas X ${siteInfo.title}`;
    else if (host.includes('wsop-paradise')) siteTitle = `WSOP Paradise X ${siteInfo.title}`;
    else if (host.includes('ocean')) siteTitle = `OCEAN X ${siteInfo.title}`;
    return siteTitle;
  }
  setRouter(router) {
    this.router = new RouterProxy(router);
    this.router.setRouter(router);
  }
}

export const servicePlugin = {
  install(Vue, { services }) {
    Vue.prototype.$services = services;
  }
};

const createServiceManager = ({ store, cookies, router }) => new ServiceManager({ store, cookies });

export default createServiceManager;
