import { email } from '@shared/plugins/validate/rules';
import { siteIds } from '@/constants/base/siteMap';
import { countryWritingPatterns, apostrophePattern, hyphenPattern, specialCharactersPattern, spacePattern } from '@shared/utils/regexUtils.mjs';
import defaultRules from '@shared/plugins/validate/rules';
import moment from 'moment/moment';
import { toYMD } from '@/utils/dateTimeUtil';

const passwordRegex = {
  NORMAL: /^[a-z0-9~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]{8,20}$/ig,
  SPECIAL: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/])([A-Za-z\d~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]){8,}$/,
};


const passwordRules = {
  validatePassword: {
    params: ['isSpecial'],
    validate: (value, { isSpecial }) => {
      if (!value) return;
      const regex = isSpecial ? passwordRegex.SPECIAL : passwordRegex.NORMAL;

      return regex.test(value);
    },
    computesRequired: true,
  },
  containCheckMobilePassword: {
    params: ['mobileNumber'],
    validate: (value, { mobileNumber }) => {
      if (!value) return false;
      let passwordLower = value.toLowerCase();

      mobileNumber = mobileNumber.replace(new RegExp('[^0-9]', 'g'), '');

      if (mobileNumber && passwordLower.search(mobileNumber) >= 0)
        return false;
      return true;
    },
  },
  containCheckEmailPassword: {
    params: ['email'],
    validate: (value, { email }) => {
      if (!value) return false;
      let passwordLower = value.toLowerCase();

      if (passwordLower.search(email.substring(0, (email.search('@'))).toLowerCase()) >= 0)
        return false;

      return true;
    },
  },
  containCheckNamePassword: {
    params: ['name'],
    validate: (value, { name }) => {
      if (!value) return false;
      let passwordLower = value.toLowerCase();

      if (passwordLower.search(name.toLowerCase()) >= 0)
        return false;

      return true;
    },
  },
  gpComparePassword: {
    params: ['newPassword'],
    validate: (value, { newPassword }) => (value === newPassword),
  },
  sameLimit: {
    params: ['current'],
    validate: ((value, { current }) => parseInt(value) !== parseInt(current))
  }
};

const emailRules = {
  email: {
    validate: value => email(value),
    computesRequired: true,
  }
};

// 88agent 기준 모바일 regex 참고
function mobileNumberRegex(siteId) {
  let regex = /^[-0-9]{1,20}$/g;
  if (siteId === siteIds.SEVENXL) regex = /^[0-9-+\(\)\s]{1,20}/g;
  return regex;
};

const mobileRules = {
  validateMobileNumber: {
    params: ['siteId'],
    validate: (value, { siteId }) => {
      if (!value) return false;
      let regex = mobileNumberRegex(siteId);
      return regex.test(value);
    },
    computesRequired: true,
  },
};

const verifyRules = {
  validateVerifyCode: {
    validate: value => {
      if (!value) return;
      if (value.length !== 4) return false;
      return true;
    },
    computesRequired: true,
  },
};

const personalRules = {
  /**
   * GGPass My Info Personal Info Validate Name
   */
  validateName: {
    params: ['min', 'max', 'countryCode', 'fieldName'],
    validate: (name, { min, max, countryCode, firstNameRules }) => {
      const validRequired = defaultRules.required.validate(name)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryCode ? (countryWritingPatterns.find(p => p.key === Number(countryCode))?.value ?? '') : '';
      return validRequired && new RegExp(`^[A-Za-z${hyphenPattern}${spacePattern}${apostrophePattern}${countryPattern}]${minMaxPattern}$`).test(name);
    },
  },

  validateDateOfBirth: {
    validate: values => {
      if (!values) return;
      const { year, month, day } = values;
      if (!year && !month && !day) return true;
      if (String(year).length !== 4) return false;
      const date = new Date(year, month - 1, day);
      const yearIsValid = parseInt(year, 10) === date.getFullYear();
      const monthIsValid = parseInt(month, 10) === date.getMonth() + 1;
      const dayIsValid = parseInt(day, 10) === date.getDate();
      return yearIsValid && monthIsValid && dayIsValid;
    },
    computesRequired: true,
  },
  validateConfirmAge: {
    params: ['age'],
    validate: (value, { age }) => {
      try {
        /* month value 와 moment 에서 사용하는 month value 값이 다름. value.month = 1 이면 1월 moment = 1이면 2월 */
        const v = { ...value };
        const { year, month, day } = v;
        if (!year && !month && !day) return true;
        const birthDate = toYMD(year + month + day);
        const currentDate = moment();
        return age <= currentDate.diff(birthDate, 'years');
      } catch {
        console.log('error : validate confirmAge');
      }
    }
  },
  validateRequiredAddress: {
    params: ['min', 'max', 'countryCode', 'fieldName'],
    validate: (value, { min, max, countryCode }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryCode ? (countryWritingPatterns.find(p => p.key === Number(countryCode))?.value ?? '') : '';
      return validRequired && (new RegExp(`^[A-Za-z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  validateAddress: {
    params: ['min', 'max', 'countryCode', 'fieldName'],
    validate: (value, { min, max, countryCode }) => {
      if (!value) return;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryCode ? (countryWritingPatterns.find(p => p.key === Number(countryCode))?.value ?? '') : '';
      return (new RegExp(`^[A-Za-z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    // computesRequired: true,
  },
  validateHouseNumber: {
    validate: v => { return /^[1-9]\d{0,3}[a-zA-Z]?(( | ?[/-] ?| bis | und ){1}([1-9]\d{0,3})?[a-zA-Z]?){0,2}$/i.test(v);},
    computesRequired: true,
  }
};

const limitRules = {
  requiredLimit: {
    params: ['noLimit'],
    validate: (value, { noLimit }) => {
      if (!value) {
        if (noLimit === 'true') return true;
        return;
      }
      return true;
    },
    computesRequired: true,
  },
  rgLimitCheck: {
    params: ['comparison1', 'comparison2', 'hangTime1', 'hangTime2', 'day1', 'day2'],
    validate: (value, { comparison1, comparison2, hangTime1, hangTime2 }) => {
      if ((comparison1 > comparison2 && hangTime1 !== hangTime2))
        return false;

      return true;
    },
  },
  rgMoreLimitCheck: {
    params: ['comparison1', 'comparison2', 'hangTime1', 'hangTime2', 'day1', 'day2'],
    validate: (value, { comparison1, comparison2, hangTime1, hangTime2 }) => {
      if ((comparison1 > comparison2 && hangTime1 !== hangTime2))
        return false;

      return true;
    },
  },
  rgMoreLimitComparison: {
    params: ['n', 'm', 'day1', 'day2'],
    validate: (value, { n, m }) => {
      if (parseInt(n) > parseInt(m)) return false;
      return true;
    },
  },
  rgLimitComparison: {
    params: ['n', 'm', 'day1', 'day2'],
    validate: (value, { n, m }) => {
      if (parseInt(n) > parseInt(m)) return false;
      return true;
    },
  },
  rgMin: {
    params: ['min'],
    validate: (value, { min }) => {
      return parseInt(value, 10) >= parseInt(min, 10);
    }
  },
  rgMax: {
    params: ['max'],
    validate: (value, { max }) => {
      return parseInt(value, 10) <= parseInt(max, 10);
    }
  },
  includeBalanceCharacter: {
    validate: (value) => {
      if (value.toString().indexOf('.') >= 0) return false;
      if (value.toString().indexOf('e') >= 0) return false;
      return true;
    }
  },
  limitMinMax: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => {
      if (!value || value === null || value === '') return false;
      return parseInt(value, 10) >= parseInt(min, 10) && parseInt(value, 10) <= parseInt(max, 10);
    },
    computesRequired: true,
  },
  customExclusionMin: {
    params: ['min'],
    validate: (value, { min }) => {
      return parseInt(value, 10) >= parseInt(min, 10);
    }
  },
  crossProviderMax: {
    params: ['max', 'currency', 'csEmail', 'amount',],
    validate: (value, { max }) => {
      return parseInt(value, 10) <= parseInt(max, 10);
    }
  },
};

const transferRules = {
  balanceTransferMin: {
    params: ['minimumamount', 'currency'],
    validate: (value, { minimumamount }) => {
      return parseFloat(value) >= parseFloat(minimumamount);
    }
  },
  balanceTransferMax: {
    params: ['maximumamount', 'currency'],
    validate: (value, { maximumamount }) => {
      return parseFloat(value) <= parseFloat(maximumamount);
    }
  },
};

export default {
  includeId: {
    params: ['id'],
    validate: (value, { id }) => {
      try {
        return !(value || '').toLowerCase().includes((id || '').split('@')[0].toLowerCase());
      } catch (e) {
        console.log('error : validate containEmail');
        return false;
      }
    },
    computesRequired: true,
  },
  idMinMax: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value && value.length >= min && value.length <= max,
  },
  pwMinMax: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value && value.length >= min && value.length <= max,
  },
  includeAlphabet: value => new RegExp('[a-zA-Z]').test(value),
  includeBothUpper: value => new RegExp('(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]').test(value),
  includeNumber: value => new RegExp('[0-9]').test(value),
  includeSpecialChar: value => new RegExp('[-!@#$%^&*()_+|~=`{}\\[\\]:;<>,.?\\/"\']').test(value),
  mobileNumber: {
    params: ['countryLength'],
    validate: (value, { countryLength }) => {
      const valueLength = value === 'undefined' ? 0 : String(value).length;
      const length = countryLength === 'undefined' ? 14 : 14 - Number(countryLength);
      return valueLength >= 6 && valueLength <= length;
    },
    computesRequired: true,
  },
  ...passwordRules,
  ...emailRules,
  ...mobileRules,
  ...verifyRules,
  ...personalRules,
  ...limitRules,
  ...transferRules,
};
