<template>
  <SignicatContainer signicat-information>
    <template v-if="!isEidvVerification">
      <p v-if="isGGPNL" v-html="$t('_.poiRequestSignicatDescriptionNL', { csEmail })" />
      <p v-else v-html="$t('_.poiRequestSignicatDescription')" />
    </template>
    <template v-else>
      <div class="holder">
        <h5><FontIcon name="check"/> {{ $t('kyc.proofOfIdentity.label') }}</h5>
        <ul>
          <li>
            {{ $t('kyc.documentType.Passport') }}
          </li>
          <li>
            {{ $t('kyc.documentType.GovernmentIssuedIdentificationCard') }}
          </li>
          <li>
            {{ $t('kyc.documentType.DriversLicence') }}
          </li>
        </ul>
      </div>
    </template>
    <ProgressButton :button-name="buttonName" @click="$emit('click')" />
  </SignicatContainer>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { signicatStep } from '@/constants/base/onboarding/kyc';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import SignicatContainer from '@/views/components/pages/on-boarding/template/kyc/signicat/SignicatContainer.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'SignicatInformation',
  lexicon: 'onBoarding',
  components: { FontIcon, SignicatContainer, ProgressButton },
  props: {
    step: String,
    isMobileApp: { type: Boolean, default: false },
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    buttonName() {
      return !this.isEidvVerification && this.isMobileApp ? 'continue' : 'proceed';
    },
    isEidvVerification() {
      return this.step === signicatStep.EidvVerification;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[signicat-information] {
  a[href^="mailto:"] { .c(@c-link); }
  i { font-style: italic; }
}
</style>
<style lang="less" scoped>
@import '~@/less/proj.less';
[signicat-information] {
  > p { .fs(16); .c(@c-w07); }
  .holder { .p(16); .mt(15); .bgc(@c-w01);
    h5 { .mb(8); .medium();
      [font-icon] { .fs(10,16); .br(50); .bgc(@c-mint); .wh(16); .ib(); .tc(); .mr(4);}
    }
    > ul { .pl(20); .fs(14,20);
      li { .c(@c-w07);
        &:not(:nth-of-type(1)) { .mt(4); }
        &:before { content: '-'; .pr(10); }
        span { .block(); .pl(16); }
      }
    }
  }
}
</style>
