<template>
  <GpFormTemplate reality-check-noti>
    <template #header>
      <GpDescription v-if="description" :message="description" />
    </template>
    <template #content>
      <InputRealityCheckNotification v-if="model.RequestInterval" :model="model" :current="current" />
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputRealityCheckNotification from '@/views/components/pages/responsible-gaming/template/inputs/InputRealityCheckNotification.vue';
import ResponsibleConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleConfirmModal.vue';
import Specific from '@shared/types/Specific';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
export default {
  name: 'RgRealityCheckNotification',
  lexicon: 'responsibleGaming',
  components: { InputRealityCheckNotification, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      rolling: null,
      current: {
        CurrentInterval: null,
        DefaultIntervalType: null,
        AvailableIntervalTypes: [],
      },
      model: {
        RequestInterval: null,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    disabled() {
      const request = this.model.RequestInterval;
      return this.current.CurrentInterval === request;
    }
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.model.RequestInterval = this.current.CurrentInterval;
    },
    async submit(validate) {
      if (validate) await validate();
      const modalTitle = this.$t('_.modal.title');
      const modalDesc = this.$t('_.modal.description.selfLimit');
      this.$modal(ResponsibleConfirmModal, { title: modalTitle, request: () => this.$rg.request(this.model), desc: modalDesc, modalType: 'Message' });
    },
  },
  async mounted() {
    await this.initialize();
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reality-check-noti] {}
</style>