<template>
  <div upload-item v-if="info" :class="{'status': status, error}">
    <img :src="info.img" @error="setError" />
    <div class="type-holder">
      <label>{{ info.file.name }}</label>
      <p v-if="status">{{ getStatus(status) }}</p>
      <SearchDropSelect v-else-if="list && useSelectFileType" selectOnly v-model="model" :list="list" :placeholder="$t('select')" @input="updateValue" @focus="$emit('focus')"  @blur="$emit('blur')"/>
      <button v-if="!status" @click="$emit('remove')">
        <FontIcon name="close" />
      </button>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';
import { kycStatus } from '@/constants/base/onboarding/kyc';
import FontIcon from '@shared/components/common/FontIcon.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'UploadItem',
  components: { SearchDropSelect, FontIcon },
  props: {
    selectList: Specific,
    info: Specific,
    status: String,
    error: Boolean,
    type: { /** @typedef {poi | poa}*/type: String, default: kycStatus.Poi.toLowerCase() },
  },
  data() {
    return {
      kycStatus,
      list: null,
      model: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    useSelectFileType() {
      // EDD지만 GGPPH는 type 선택을 사용함
      return [kycStatus.Poi.toLowerCase(), kycStatus.Poa.toLowerCase()].includes(this.type) || (this.type === kycStatus.Edd.toLowerCase() && this.site === siteIds.GGPPH);
    },
    defaultImg() {
      const type = this.info.file.type.replace(/[^/]*\//, '').toLowerCase();
      switch (type) {
        case 'bmp':
          return `${cdnImgPath}/icon_file_bmp.png`;
        case 'pdf':
          return `${cdnImgPath}/icon_file_pdf.png`;
        case 'docx':
          return `${cdnImgPath}/icon_file_docx.png`;
        default:
          return `${cdnImgPath}/icon_file_unknown.png`;
      }
    }
  },
  methods: {
    updateValue(v) {
      if (!v) return;
      this.model = v;
      this.info.type = v.value;
    },
    getStatus(value) {
      return this.error ? value : this.list?.find(o => o.value === value).label;
    },
    setError(e) {
      e.target.src = this.defaultImg;
    },
  },
  mounted() {
    this.list = this.selectList?.map(item => ({ label: this.$t(item.label), value: item.value }));
    const item = this.selectList?.find(item => item.value === this.info.type);
    this.model = item ? { label: this.$t(item.label), value: item.value } : null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[upload-item] { .flex(); .flex-ai(); .rel();
  > img { .wh(80); .bgc(white); .-a(white); .br(4); }
  .type-holder { .ml(20); .w(calc(100% - 100px)); .items-start(); .rel();
    > label { .items-start(); .mb(12); .block(); .fs(14); .break-all(); }
    [drop-select] { .w(100%); .bgc(@c-w01); .-a(@c-w02);
      input { .fs(14); }
    }
    [search-drop-select] { .wh(100%, 34); .pointer; .br(4); .bgc(@c-w01); .-a(@c-w02);
      > a label { .fs(14); .c(@c-white); }
    }
    > button { .wh(20); .abs(); .rt(0, 0); .c(white); }
  }
  &.status {
    .type-holder {
      > p { .fs(14); .c(@c-mint);}
    }
  }
  &.error {
    .type-holder {
      > p { .c(@c-red); }
    }
  }
}
</style>
