<template>
  <div social-linked-button :class="[{'active' : this.isActive}]">
    <div class="label-group">
      <img :src="imgPath" width="24" height="24" />
      <label>{{ title }}</label>
    </div>
    <div class="linked-group">
      <label v-if="!active">{{ $t('myInfo.socialLinked.notLinked') }}</label>
    </div>
  </div>
</template>

<script>
import { isAuthenticated } from '@shared/utils/routerUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { socialType } from '@/constants/base/my-page';
export default {
  name: 'SocialLinkedButton',
  components: { FontIcon },
  props: {
    active: { type: Boolean, default: false, },
    email: { type: String, default: null },
    type: { type: String, default: null },
    img: { type: String, default: null },

    // todo : 6월 런칭 이후 스펙 대비
    socialLink: { type: String, default: null },
    unLinkPath: { type: String, default: null },
  },
  data() {
    return {
      cdnPath: 'https://static.ggtechdev.com/ux/game/platform-front/img/widget/',
      labels : {
        [socialType.GOOGLE]: 'myInfo.socialLinked.google',
        [socialType.FACEBOOK]: 'myInfo.socialLinked.facebook',
        [socialType.APPLE]: 'myInfo.socialLinked.apple',
      }
    };
  },
  computed: {
    isActive() {
      return this.active;
    },
    title() {
      if (!this.isActive) {
        const key = this.labels[this.type] || null;
        return key ? this.$t(key) : '';
      }
      else {
        return this.email;
      }
    },
    imgPath() {
      return `${this.cdnPath}${this.img}`;
    }
  },
  methods: {
    click() {

    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[social-linked-button] { .p(16, 20, 16, 20); .bgc(#fff); .br(16); .w(100%); .flex(); .rel();
  &.active {
    &::before { .br(16); .-a(#34A853); content: ""; .w(100%); .h(100%); .abs(); .t(-1); .l(-1); .z(1); }
  }
  .label-group { flex: 1; .flex(); .gap(10);
    > label {.fs(14); .c(#000);}
  }

  .linked-group {
    > label {.fs(12); .c(#A3A3A3);}
  }


}
</style>