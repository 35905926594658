<template>
  <div level-radio-item :class="[{'active' : this.isActive}]">
    <!--    <span :class="['radio-button', {'active': isActive}]"></span>-->
    <div class="radio-box">
      <div class="title-group">
        <div>
          <img v-if="hasIcon" :src="`/img/${icon}`" alt="icon" />
          <h3>{{ label }}</h3>
          <p v-if="isBadge" class="not-recommended">({{ $t('myInfo.notRecommended') }})</p>
        </div>

        <FontIcon v-if="isActive" class="active-mark" name="check-circle" />
      </div>
      <div class="content-group">
        <p>{{ content }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { isAuthenticated } from '@shared/utils/routerUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'SecurityLevelRadioItem',
  components: { FontIcon },
  props: {
    value: { type: String },
    label: { type: String },
    content: { type: String },
    current: { type: String, default: '' },
    icon: { type: String, default: null },
    isAuthenticated: { type: Boolean, default: false },
    isBadge: { type: Boolean, default: false, },
    active: { type: Boolean, default: false, }
  },
  computed: {
    isActive() {
      return this.active;
    },
    hasIcon() {
      return this.icon !== null;
    }

  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[level-radio-item] { .c(black); .p(20); .mt(-1); .pointer(); .rel(); .min-h(60); .bgc(#fff); .br(16); .w(100%);
  &.active { .bgc(#F8FBF9);
    &::before { .br(16); .-a(@gp-green); content: ""; .w(100%); .h(100%); .abs(); .t(-1); .l(-1); .z(1); }
  }
  .radio-box { .flex(); .flex-dc(); .p(0); .gap(5);
    .title-group { .flex(); .flex-dr(); .space-between(); .items-center();
      //@media (@ml-up) {
      //  .justify-start(); .gap(20);
      //}
      > div { .flex(); .flex-dr(); .items-center(); .gap(5);
        > img {.wh(18); object-fit: cover;}
        > h3 {.fs(14); font-weight: 500;}
        > .not-recommended {.fs(12); .c(#737373);}
      }
      > p { .fs(12); .c(red);}
      .active-mark {.c(#2AAB3E);}
    }
    .content-group { .flex(); .flex-dr(); .gap(10); .items-center();
      > p {.c(#999999); .fs(12); line-height: 16px; }
    }

  }
}
</style>