import { setGTM } from '@/plugins/tracking';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import { siteIds } from '@/constants/base/siteMap';

export class OnboardingServiceController {
  #services;
  #scope;
  #router;
  #store;
  #siteMap;
  #siteInfo;
  #site;
  #step;
  #expireSteps;
  #orderInfo;
  #model;
  #kycStep;
  #error;

  #structure;
  #modelProxy;
  #routeNames;

  setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#model = scope.$model;
    this.#scope = scope;

    this.#siteInfo = this.#store.state.env.siteInfo;
    this.#site = this.#store.state.env.site;
    this.#kycStep = this.#store.state.user?.userInfo?.KycStatus;
    this.#error = false;

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  async reset() {
    await this.#getOrder();
    await this.#setAutomation();
  }

  async checkCustomStep() {
    const { customStep, additional } = this.#store.state.query;
    const additionalStep = additional ? JSON.parse(additional) : false;

    if (!customStep) await this.reset();

    return this.#error ? null : (customStep || additionalStep) ? true : !!this.#routeNames?.length;
  }

  async updateStructure(structure, replace) {
    const init = !this.#structure;
    this.#structure = structure;
    if(replace) {
      await this.reset();
      this.#modelProxy = null;
      await this.injectionMyInfo();
    }
    if(init)await this.injectionMyInfo(this.#store.state.user.userInfo);
  }

  async injectionMyInfo(info) {
    const r = !info ? await this.apiRequest('onboarding', 'getMyInfo', true) : info;
    this.#store.commit('user/setUserInfo', r);
    this.#kycStep = r?.KycStatus;
    Object.keys(r).forEach(o => {
      if (Array.isArray(r[o])) this.#structure.model[o] = r[o];
      else if (typeof r[o] === 'object') Object.keys(r[o]).forEach(c => this.#structure.model[c] = this.#getValue(r[o][c]));
      else this.#structure.model[o] = this.#getValue(r[o]);
    });
    this.#structure.model.CountryOfBirth = this.#structure.model.Country;

    /** pep type injection*/
    if (this.#site === siteIds.GGPDE) {
      this.#structure.model.pepType = this.#structure.model.pepType || [];
      const screenResult = this.#structure.model.ScreeningResult || [];

      if (screenResult.includes('Pep')) this.#structure.model.pepType.push('PEP');
      if (screenResult.includes('Watch')) this.#structure.model.pepType.push('Sanktionen');
    }
  }

  async getModel(replace = false, params) {
    return await this.#model.getModel(this.#scope, {application: 'onboarding', orderRouteNames: this.#routeNames, siteId: this.#site, replaceModel: replace ? this.#structure.model : null, params});
  }

  async setRecaptcha(action) {
    return await new Promise(resolve => {
      window.grecaptcha.enterprise.ready(async () => {
        resolve(await this.#services.captcha.recaptchaEnterprise(action));
      });
    });
  }

  async apiRequest(service, api, model, config) {
    try {
      if (!this.#error) { // 에러 발생 후에 중복 호출 방지 처리
        const r = await this.#getApiResponse(service, api, model, config);
        if (r) {
          this.#error = r.error;
          if (this.#error) {
            const errorInfo = this.errorHandler(r);
            if (errorInfo) await this.#router.replace({ name: errorInfo.path, params: errorInfo.params, query: errorInfo.query });
          }
        }
        return r;
      }
    } catch (e) { throw e; }
  }

  async #getApiResponse(service, api, model, config) {
    try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.#services[service][api](model, config); }
    catch (e) { throw e; }
  }

  errorHandler(r, move) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    if(r.errorTemplate?.path) {
      const queries = r.errorTemplate.queries || {};
      const values = { path: r.errorTemplate.path, param: { desc, errorTemplate, ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined, ...queries, desc} };
      if(move) {
        this.#scope.replaceRouteName(values.path, values.param, values.query);
        return true;
      }
      return values;
    }
    return false;
  }

  async errorRouteReplace(r) {
    const info = this.errorHandler(r);
    if (info) return await this.#router.replace({ name: info.path, params: info.params, query: info.query });
    return false;
  }

  async #getOrder() {
    const restrict = this.#store.state.query.restrict;
    this.#routeNames = await this.apiRequest('onboarding', 'getOrder', restrict);
    this.#store.commit('env/setRouteNames', this.#routeNames);
  }

  async getExpireOrder() {
    const r = await this.apiRequest('kyc', 'getOrderForKycExpire');
    if (Array.isArray(r)) {
      this.#expireSteps = [];
      r.forEach((item, id) => {
        this.#expireSteps.push(item);
        if (item === routeNames.verifyContactEmail) this.#expireSteps.push(routeNames.verifyCode);
      });
    }

    return this.#expireSteps;
  }

  /**
   * 온보딩 자동화 스탭이 내려올 경우 그에 대한 대응 후 스탭 재조회
   * @returns {Promise<boolean>}
   */
  async #setAutomation() {
    if (this.#routeNames?.[0] === 'AutoOnboarding') {
      await this.apiRequest('kyc', 'verificationAutomation');
      await this.#getOrder();
    }
  }

  #getValue(value) {
    return ['Undefined', 'undefined', undefined, 'Null', 'null', null].includes(value) ? null : value;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$onboarding = new OnboardingServiceController();
  }
};