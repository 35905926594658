import { completeRemoveCookie } from '@/utils/baseUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import ClientRequestMessageModal from '@/views/components/common/modal/ClientRequestMessageModal.vue';
import emailList from '@/constants/base/email';

const DEV_TIMEOUT_MILLISECOND = 1000;

/**
 * 창 닫기
 */
export const close = async scope => {
  try {
    let setTimeoutMillisecond = 0;
    const message = { message: 'CLOSE_WEBVIEW' };
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
    console.log('%cclientUtil close', 'color:blue', JSON.stringify(message));
    window.postMessage(message);
    window.close();
  } catch (e) { /* empty */ }
};

export const openEmailApp = async (scope, email) => {
  try {
    const queries = parseQuery(location.search);
    const isFromClientApp = getCookie('isFromClientApp') || queries?.isFromClientApp;
    const isFromMobileApp = getCookie('isFromMobileApp') || queries?.isFromMobileApp;

    let url;
    const [id, domain] = email.split('@');
    const findDomain = emailList.find(email => email.label === domain);

    if (findDomain) url = findDomain.value;
    else url = domain;

    const message = isFromClientApp ? { message: 'NAVIGATE_TO_URL', url } : isFromMobileApp ? { message: 'NAVIGATE_MAIL_APP' } : '';

    let setTimeoutMillisecond = 0;
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);

    console.log('%cclientUtil openEmailApp', 'color:blue', JSON.stringify(message));
    if (!message) window.open(url, 'blank');
    else window.postMessage(message);
  } catch (e) { /* empty */ }
};

/**
 * auto login
 * @param siteId
 * @param token
 * @param doLogin
 * @param email
 * @param password
 * @param mobileNumber
 */
export const autoLogin = async (scope, { siteId, token, doLogin, email, password, mobileNumber }) => {
  completeRemoveCookie();
  const userAddress = email ? encodeURIComponent(email) : undefined;
  const userPassword = password ? encodeURIComponent(password) : undefined;
  const userMobileNumber = mobileNumber ? encodeURIComponent(mobileNumber) : undefined;

  let setTimeoutMillisecond = 0;
  const message = password ? { message: 'AUTO_LOGIN', siteId, doLogin, token, userAddress, userPassword, userMobileNumber } : { message: 'PLAYER_VERIFICATION', status: 'Complete', siteId, token };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil autoLogin', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * citizenship Verification 시 연동 호출
 */
export const citizenshipVerification = async scope => {
  let setTimeoutMillisecond = 0;
  const message = { message: 'CITIZENSHIP_VERIFICATION' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%ccitizenshipVerification', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 카메라 권한 유무 체크
 */
export const checkClientPermission = async scope => {
  let setTimeoutMillisecond = 0;
  const message = { message: 'HAS_PERMISSION_CAMERA' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil checkClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 카메라 권한 요청
 */
export const requestClientPermission = async scope => {
  let setTimeoutMillisecond = 0;
  const message = { message: 'REQUEST_PERMISSION_CAMERA' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil requestClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 설정 화면 이동 요청
 */
export const settingClientPermission = async scope => {
  let setTimeoutMillisecond = 0;
  const message = { message: 'OPEN_APP_SETTING' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil settingClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

export const isClient = () => {
  const queries = parseQuery(location.search);
  const isFromClientApp = getCookie('isFromClientApp');
  const isFromMobileApp = getCookie('isFromMobileApp');

  return queries?.isFromClientApp || queries?.isFromMobileApp || isFromClientApp || isFromMobileApp;
};