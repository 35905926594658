<template>
  <div verify-rg-login-time-setting>
    <LimitHourField :isCz="isGGPCZ" :label="dailyLabel" min="1" max="24" :max-length="2" name="_time" :rules="getRule('daily')" v-model="modelInfo.RequestDailyLoginTimeLimit" @input="updateValue"/>
    <LimitHourField v-if="!isGGPCZ" :label="weeklyLabel" min="1" max="168" :max-length="3" name="_time" :rules="getRule('weekly')" v-model="modelInfo.RequestWeeklyLoginTimeLimit" @input="updateValue"/>
    <LimitHourField v-if="!isGGPCZ" :label="monthlyLabel" min="1" max="720" :max-length="3" name="_time" :rules="getRule('monthly')" v-model="modelInfo.RequestMonthlyLoginTimeLimit" @input="updateValue"/>
    <p class="list-star">{{ $t('_.loginTime.description') }}</p>
    <p class="error-msg" v-if="errorMsg">{{ $t(errorMsg) }}</p>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import LimitHourField from '@/views/components/pages/on-boarding/template/extra/LimitHourField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'VerifyRgLoginTimeSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitHourField },
  mixins: [baseTemplate],
  data() {
    return {
      loginTimeType: null,
      dailyMinMax: { min: 1, max: 24 },
      weeklyMinMax: { min: 1, max: 168 },
      monthlyMinMax: { min: 1, max: 720 },
      notNullType: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('_.dailyTime');
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('_.weeklyTime');
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('_.monthlyTime');
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isRolling() {
      return this.loginTimeType === 'Rolling';
    },
  },
  methods: {
    updateValue() {
      const { RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit } = this.modelInfo;
      if(RequestMonthlyLoginTimeLimit > this.monthlyMinMax.max || RequestWeeklyLoginTimeLimit > this.weeklyMinMax.max || RequestDailyLoginTimeLimit > this.dailyMinMax.max) {
        this.structure.error = true;
      } else {
        this.structure.error = false;
      }
    },
    getRule(type) {
      const { RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit } = this.modelInfo;
      const isRequired = !RequestDailyLoginTimeLimit && !RequestWeeklyLoginTimeLimit && !RequestMonthlyLoginTimeLimit;
      switch (type) {
        case 'monthly':
          return `${isRequired ? 'requiredNone|' : ''}loginTimeMinMax:${this.monthlyMinMax.min},${this.monthlyMinMax.max}`;
        case 'weekly':
          return `${isRequired ? 'requiredNone|' : ''}loginTimeMinMax:${this.weeklyMinMax.min},${this.weeklyMinMax.max}`;
        default:
          return `${isRequired ? 'requiredNone|' : ''}loginTimeMinMax:${this.dailyMinMax.min},${this.dailyMinMax.max}`;
      }
    },
    confirm() {

    },
    async initialize() {
      this.loginTimeType = (await this.$services.account.getRgConfig()).LoginTimeLimitPeriodType;
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-login-time-setting] {
  [limit-hour-field] { .column-gap(8);
    label { flex-basis: 30%; .flex(); .min-w(98); .min-h(46); .items-center(); .fs(16); }
    label + div { flex-basis: 70%; }
  }
  .list-star { .mt(22); }
}
</style>
