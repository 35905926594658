<template>
  <div daily-free-card :class="{completed}">
    <h5>{{ $t('promotion.day', {day: info.dayNumber}) }}</h5>
    <div>
      <BonusTicket v-for="(ticket, id) in (/** @type {DailyBonusItem} */ info).tickets" :key="id" :info="ticket" :active="info.status === 'Completed'" />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import BonusTicket from '@/views/components/promotion/BonusTicket.vue';

export default {
  name: 'DailyFreeCard',
  components: { BonusTicket },
  props: {
    info: { /** @type {DailyBonusItem} */ type: Specific, required: true },
  },
  computed: {
    completed() {
      return this.info.status === 'Completed';
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[daily-free-card] { .tc();
  + [daily-free-card] { .-l(red); }
  h5 { .mb(16); .fs(16); .o(0.5); }
  &.completed {
    h5 { .o(1); }
  }
  [bonus-ticket] { .w(122); .mh-c() }
}
</style>
