<template>
  <div fi-email class="field-wrapper">
    <div class="header">
      <p class="desc" v-html="desc" />
    </div>
    <div class="field-holder">
      <EmailSearchSelect :model-info="modelInfo" :structure="structure" rules="requiredSignEmail|required" />
<!--      <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />-->
      <LimitTimer class="error-msg" v-if="resendTime > 0" :time="resendTime" @complete="timerComplete" />
    </div>
  </div>
</template>
<script>

import { isExcludeGgpass, } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';

export default {
  name: 'FiEmail',
  components: { EmailSearchSelect, LimitTimer },
  props: {
    structure: Specific,
    modelInfo: Specific,
    resendTime: 0,
  },

  computed: {
    brand: state('env', 'brand'),
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
    desc() {
      return this.$t(this.structure.desc, { method: this.$t('email'), brand: this.displayBrandName });
    },
  },
  watch: {
    'modelInfo.Email': function () {this.$emit('clear-error'); },
  },
  methods: {
    timerComplete() {
      this.$emit('timer-complete');
    },
  },
  async mounted() {
    this.modelInfo.RequestType = 'VerificationEmail';
    this.structure.methodType = 'Email';

  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-email] {.flex();.flex-dc();
  .field-holder {flex: 1;}
  .or { .fs(16, 20); .c(#7F7F7F); .mv(20); .rel();
    span { .ph(12); .bgc(#171717); .rel(); .z(1);}
    em { .wh(100%, 1); .bgc(#333); .block(); .abs(); .lt(50%, 50%); .t-xyc(); .max-w(440); }
  }
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
}
</style>