<template>
  <div footer-linkage :class="isOnBoarding ? 'on-boarding' : 'sign-up'">
    <button @click="openVerifyModal" name="Step8B_Email_Verification_Resend_Button" class="signup-resend-email-code-btn">{{ $t('signUp.signUpVerify.dontReceive') }}</button>
    <FontIcon name="chevron-right" />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import VerifyRequestModal from '@/views/components/pages/sign-up/modal/VerifyRequestModal.vue';
import ReceiveRequestModal from '@/views/components/pages/on-boarding/modal/ReceiveRequestModal.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'FooterLinkage',
  components: { FontIcon },
  props: {
    structure: Specific,
  },
  computed: {
    isOnBoarding() {
      return this.structure.target === 'onBoarding';
    }
  },
  methods: {
    async openVerifyModal() {
      await this.$modal(this.isOnBoarding ? ReceiveRequestModal : VerifyRequestModal, { info: this.structure });
    }
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
[footer-linkage] {
  &.on-boarding { .mt(40); .flex(); .items-center(); .justify-start();
    > button { .-c(#717171); }
  }
  &.sign-up { .flex-center();
    > button { .c(#ccc); .-b(#ccc); }
  }
  > button { .-b(#ccc); }
  [font-icon] { .fs(12); .ml(4); .c(@c-red); }
}
</style>