<template>
  <SectionContainer referral-status>
    <template #contents>
      <div class="status-card signups">
        <div class="card-container">
          <div class="icon">
            <FontIcon name="users" />
          </div>
          <div class="text">
            <p class="title">{{ $t('_.title') }}</p>
            <p class="value">{{ info.referredPlayerCount || '0' }}</p>
          </div>
        </div>
      </div>
      <div class="status-card successful">
        <div class="card-container">
          <div class="icon">
            <FontIcon name="handshake" />
          </div>
          <div class="text">
            <p class="title">{{ $t('_.successful') }}</p>
            <p class="value">{{ info.rewardedPlayerCount || '0' }}</p>
          </div>
        </div>
      </div>
      <div class="status-card earned">
        <div class="card-container">
          <div class="icon">
            <FontIcon name="sack-dollar" />
          </div>
          <div class="text">
            <p class="title">{{ $t('_.totalEarned') }}</p>
            <p class="value">{{ earned }}</p>
          </div>
        </div>
      </div>
    </template>
  </SectionContainer>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import SectionContainer from '@/views/components/pages/promotion/SectionContainer.vue';
import Specific from "@shared/types/Specific";

export default {
  name: 'ReferralStatus',
  lexicon: 'promotion.referral',
  components: { SectionContainer, FontIcon },
  props: {
    info: /** @type{ReferralPromotionStatus} */ Specific,
  },
  computed: {
    earned() {
      return this.$promotion.getCurrencyString('GCD', (this.info.rewardedAmount ?? 0));
    },
  },
};

</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[referral-status] { .ph(0);
  .status-card { .p(24); .text-end(); .fs(16);
    .card-container { .flex(); .items-center(); .gap(18); .f(); }
    .icon { .w(36); .fs(1.625em); .tc(); }
    .text { .flex(); flex: 1; .space-between(); .items-center();
      .title { .medium(); .c(@c-lightSmoke); }
      .value { .semi-bold(); .fs(22); .c(@c-whiteSmoke); }
    }
  }
  .signups { .bgc(@c-dimGrey); .c(@c-veryLightGrey); }
  .successful { .bgc(@c-darkGrey); .c(@c-lightGrey); }
  .earned { .bgc(@c-purpleBlue); .c(#9d99fb); }

  @media (@tp-up) { .flex(); .column-gap(16); .p(20);
    .status-card { .items-end(); .w(calc(100% / 3)); .min-h(112); .br(4);
      .card-container { .grid(); .grid-template-columns(auto 1fr); }
      .icon { .fs(2.5em); .align-end();
        [font-icon] { .vab(); }
      }
      .successful .icon { .fs(2.75em); }
      .text { .flex-dc(); .items-end(); .align-start(); .row-gap(6); .pb(6);
        .value { .fs(24); }
      }
    }
  }
}
</style>
