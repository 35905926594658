<template>
  <div notification v-if="show">
    <div class="message">
      <p>
        <FontIcon :name="svgIcon.name" />
        <span v-html="$t(`promotion.${info.message.key}`, info.message.options)" />
      </p>
      <button class="close-btn" @click="() => show = false">
        <FontIcon name="close-thin" />
      </button>
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'Notification',
  components: { FontIcon },
  props: {
    info: { type: Specific, default: null },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    svgIcon() {
      return ({ name: this.info.icon || 'bell' });
    },
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[notification] { .rel(); .mb(20); .bgc(white); .fs(16); .medium();
  &::before { .cnt(); .abs(); .t(0); .l(0); .w(7); .hf(); .bgc(@c-blurRed); }
  .message { .flex(); .space-between(); .p(1em, 1.25em, 1em, 1.875em);
    p { .flex(); .items-center(); .column-gap(0.625em); .fs(1em); .c(@c-nero); .medium();
      [font-icon] { flex: 0; .fs(1.25em); line-height: 1.2; }
      a { .ml(0.5em); .-b(@c-blurRed); .bgc(); .c(@c-blurRed); .fs(1em); .nowrap();
        &:hover { .-b(); }
        &[target="_blank"] { .ml(0); }
        &[href^="mailTo:"] { .c(@c-blue); .-b(); .ml(0);
          &:hover { .c(@c-darkBlue); .-b(@c-darkBlue); }
        }
      }
    }
    .close-btn { .bgc(); .fs(1em);
      [font-icon] {.fs(1.25em); .c(@c-grey67); }
    }
  }

  @media (@tp-up) {
    .message { .pl(2.5em); }
  }
}
</style>
