<template>
  <div proof-of-affordability>
    <p class="desc" v-html="$t('_.description')" />
    <p class="note" v-html="$t('_.descriptionNote')" />
    <DescriptionHolder :list="example" transparent />
    <FileSelector @update="fileUpdate" ref="selector">
      <p v-html="$t('kyc.dropFiles', { fileLength: maxFileLength, fileSize: maxFileSize })" />
    </FileSelector>
    <p class="add-document">{{ $t('kyc.addDocument') }}</p>
    <div class="list-holder" v-if="fileList && fileList.length">
      <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" :selectList="eddList" :type="kycStatus.Edd.toLowerCase()" @remove="remove(id)" />
    </div>
    <ProgressButton button-name="upload" :disabled="disabled || !verifyDocument" @click="upload" />
  </div>
</template>

<script>
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getKycDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import FileSelector from '@/views/components/pages/on-boarding/FileSelector.vue';
import UploadErrorModal from '@/views/components/pages/on-boarding/modal/UploadErrorModal.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { siteIds } from '@/constants/base/siteMap';
import DescriptionHolder from '@/views/components/common/DescriptionHolder.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'ProofOfAffordability',
  components: { DescriptionHolder, ProgressButton, UploadItem, FileSelector },
  mixins: [baseTemplate],
  lexicon: 'kyc.proofAffordability',
  props: {
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
  },
  data() {
    return {
      kycStatus,
      fileList: null,
      uid: 0,
      maxFileLength: 5,
      maxFileSize: 10,
      isFromWithdrawal: false, // @todo: withdrawal(출금) 화면에서 접근한건지 체크
    };
  },
  computed: {
    site: state('env', 'site'),
    eddDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const eddKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Edd);
      return this.convertKeyValue(eddKycDocumentGroup, 'Default');
    },
    eddMandatoryKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const eddKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Edd);
      return this.convertKeyValue(eddKycDocumentGroup, 'Mandatory');
    },
    eddList() {
      return [...(this.eddDefaultKycDocumentList ?? []), ...(this.eddMandatoryKycDocumentList ?? [])];
    },
    verifyDocument() {
      // const emptySelectFile = !_.every(this.fileList, f => f.type);
      // const existDefaultFile = _.some(this.eddDefaultKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));
      // const existMandatoryFile = _.every(this.eddMandatoryKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));
      // return !this.fileList?.length || emptySelectFile || !existDefaultFile || !existMandatoryFile;
      return this.fileList?.length;
    },
    example() {
      return [this.$t('kyc.documentType.RecentBankStatements'), this.$t('kyc.documentType.RecentEwalletStatements'), this.$t('kyc.documentType.InvestmentTradingScreenshot'), this.$t('kyc.documentType.WinningsScreenshot'), this.$t('kyc.documentType.OtherProveDocuments')];
    },
    isDocumentList() {
      /** GGPCZ 이거나 N8IN의 출금화면에서 접근한 POI example document 는 2개다. */
      return this.example.length === 2;
    },
  },
  methods: {
    /**
     * Kyc Document 정보를 Label, Value 형태로 변환
     * @param {KycDocumentGroup} kycDocumentGroup - Kyc Document
     * @param type - Default, Mandatory
     * @returns {{label: *, value: *}[]|*[]}
     */
    convertKeyValue(kycDocumentGroup, type) {
      if (!kycDocumentGroup) return [];
      const documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (!documents) return [];

      const kycDocuments = getKycDocuments(this.site);
      return documents.map(document => {
        const kycDocument = kycDocuments.find(type => type.value === document);
        return kycDocument ? { label: kycDocument?.label, value: kycDocument?.value, description: kycDocument?.description } : null;
      });
    },
    /**
     * 올려야 할 Kyc document 중 Type(Poi, Poa) 별 정보 가져오기
     * @param {KycDocumentGroup[]} kycDocumentGroups - 업로드 필요한 Kyc document group
     * @param {string} type - Kyc document Type(Poi, Poa)
     * @returns {*}
     */
    findKycDocumentGroup(kycDocumentGroups, type) {
      return kycDocumentGroups?.find(group => group.KycDocumentGroupType === type);
    },
    fileUpdate(list) {
      this.fileList = list;
      this.uid++;
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
    async upload() {
      const info = /** @type { ErrorResponse[] } */ await Promise.all(this.fileList.map(async o => this.$services.kyc.setAddKycDocument({ DocumentType: o.type, FileData: o.img, FileName: o.file.name, IsProofOfAffordability: true })));
      await this.$services.kyc.setDeclarateAffordability(this.structure.model);
      if (info.find(o => o.error)) {
        const r = await this.$modal(UploadErrorModal, { info: this.fileList.map((o, id) => ({ ...o, status: info[id] })) });
        if (!r) return;
      }
      this.$emit('update');
    }
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.proofAffordability';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[proof-of-affordability] {
  [description-holder] { .mt(12); .pl(16);}
  > p + p { .mt(8); }
  .add-document { .c(#ccc); .m(20, 0); }
  > .note { .c(@c-red); }
}
</style>
