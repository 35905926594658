<template>
  <Modal receive-request-modal theme="dark" @close="close">
    <template #title>
      {{ `${$t('_.dontReceive')}?` }}
    </template>
    <div class="body">
      {{ $t('_.dontReceiveDescription') }}
    </div>
    <RadioButtonList theme="dark" v-model="type" :list="[{label: 'sms', value:'SMS'}, {label: 'call', value:'Voice'}]"/>
    <div class="bts">
      <LimitTimer v-if="voiceLimit || apiLimit" :time="voiceLimit || apiLimit" @complete="complete"/>
      <ProgressButton :progress="processing" :disabled="disabled" button-name="reSend" @click="reSend" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import RadioButtonList from '@shared/components/common/RadioButtonList.vue';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'ReceiveRequestModal',
  components: { LimitTimer, ProgressButton, Modal, RadioButtonList },
  lexicon: 'onBoarding.verifyCode',
  props: {
    options: Specific,
  },
  data() {
    return {
      type: 'SMS',
      processing: false,
      voiceLimit: null,
      apiLimit: null,
    };
  },
  computed: {
    disabled() {
      return !!this.voiceLimit || !!this.apiLimit;
    }
  },
  methods: {
    complete() {
      this.processing = false;
      this.voiceLimit = null;
      this.apiLimit = null;
    },
    async reSend() {
      this.processing = true;

      const payload = this.options.info.model;
      payload.MessageType = this.type;

      let r;
      try { r = /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.sendMobileVerification(payload); }
      catch (e) { r = e; }
      if (r.key === apiErrorCode.PHONE_RESEND_LIMITED) {
        this.apiLimit = +r.desc;
      } else if (r.error) {
        this.options.info.error = true;
        this.options.info.errorMsg = r.comment;
      } else {
        this.apiLimit = null;
        if (this.type === 'Voice') {
          this.voiceLimit = 53;
        }
      }

      this.processing = false;
    },
    close() {
      this.$modalCloseAll();
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[receive-request-modal] {
  [radio-button-list]{ .mt(20); .tc();}
  .bts { .block() !important;
    [limit-timer] { .c(#FF3B3B); .tc(); .mv(5); }
  }
}
</style>