<template>
  <GpFormTemplate rg-deposit-limit>
    <template v-if="title && description" #header>
      <GpDescription v-if="description" :message="description" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="verifyFreezeDescription" />
    </template>
    <template #content>
      <InputDepositLimitTable :rolling="rolling" :currency-symbol="currencySymbol" :model="model" :current="current" />
      <ValidationErrors v-if="limitErrorMsg" class="support-error-msg" :error-msg="limitErrorMsg" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="openModal" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import InputDepositLimitTable from '@/views/components/pages/responsible-gaming/template/inputs/InputDepositLimitTable.vue';
import { useExceedMaximumDepositLimit } from '@/constants/base/responsible-gaming';
import _ from 'lodash';
import { siteIds } from '@/constants/base/siteMap';
import ResponsibleHangTimeConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleHangTimeConfirmModal.vue';
import Specific from '@shared/types/Specific';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';

export default {
  name: 'RgDepositLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputDepositLimitTable, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      rolling: null,
      currencySymbol: null,
      info: null,
      current: {
        Currency: null,
        CurrentDailyDepositLimit: null,
        CurrentWeeklyDepositLimit: null,
        CurrentMonthlyDepositLimit: null,
        CurrentExternalMonthlyDepositLimit: null,

        DailyDepositLimitApplyAt: null,
        WeeklyDepositLimitApplyAt: null,
        MonthlyDepositLimitApplyAt: null,
        ExternalMonthlyDepositLimitApplyAt: null,

        Day1Limit: null,
        Day7Limit: null,
        Day30Limit: null,
      },
      model: {
        RequestDailyDepositLimit: null,
        RequestWeeklyDepositLimit: null,
        RequestMonthlyDepositLimit: null,

        DailyNoLimit: false,
        WeeklyNoLimit: false,
        MonthlyNoLimit: false,
      },
      limitErrorMsg: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    token: state('user', 'token'),
    csEmail: state('env', 'csEmail'),
    calculateList() {
      return [
        { type: 'daily', current: this.current.CurrentDailyDepositLimit, request: this.model.RequestDailyDepositLimit, noLimit: this.model.DailyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'weekly', current: this.current.CurrentWeeklyDepositLimit, request: this.model.RequestWeeklyDepositLimit, noLimit: this.model.WeeklyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'monthly', current: this.current.CurrentMonthlyDepositLimit, request: this.model.RequestMonthlyDepositLimit, noLimit: this.model.MonthlyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
      ];
    },
    exceedList() {
      return [
        { type: 'daily', current: this.current.CurrentDailyDepositLimit, limit: this.current.Day1Limit, request: this.model.RequestDailyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'weekly', current: this.current.CurrentWeeklyDepositLimit, limit: this.current.Day7Limit, request: this.model.RequestWeeklyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'monthly', current: this.current.CurrentMonthlyDepositLimit, limit: this.current.Day30Limit, request: this.model.RequestMonthlyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
      ];
    },
    subDescription() {
      if (this.isGGPBE) {
        if (this.isRolling) return this.$t('_.subDescription.weeklyDepositLimit', { utcInfo: this.structure.config.timeZoneType });
        else return this.$t('_.subDescription.weeklyCalendar', { utcInfo: this.structure.config.timeZoneType });
      }
      if (this.isRolling) return this.$t('_.subDescription.depositLimit', { utcInfo: this.structure.config.timeZoneType });
      else return this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
    },
    verifyFreezeDescription() {
      return this.$t('_.verifyFreeze.depositVerifyFreeze');
    },
    isExceedMaximum() {
      return useExceedMaximumDepositLimit.includes(this.site);
    },
    maxLimit() {
      return 1000000000;
    },
    isRolling() {
      return this.rolling === 'Rolling';
    },
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    isCashierLimitSite() {
      return [siteIds.GGPUK].includes(this.site);
    },
    disabled() {
      if (this.limitErrorMsg) return true;
      const {
        CurrentDailyDepositLimit,
        CurrentWeeklyDepositLimit,
        CurrentMonthlyDepositLimit,
      } = this.current;
      const {
        RequestDailyDepositLimit,
        RequestWeeklyDepositLimit,
        RequestMonthlyDepositLimit,
        DailyNoLimit,
        WeeklyNoLimit,
        MonthlyNoLimit
      } = this.model;

      let requestDaily = (RequestDailyDepositLimit || parseInt(RequestDailyDepositLimit) === 0) ? parseInt(RequestDailyDepositLimit) : null;
      let requestWeekly = (RequestWeeklyDepositLimit || parseInt(RequestWeeklyDepositLimit) === 0) ? parseInt(RequestWeeklyDepositLimit) : null;
      let requestMonthly = (RequestMonthlyDepositLimit || parseInt(RequestMonthlyDepositLimit) === 0) ? parseInt(RequestMonthlyDepositLimit) : null;

      requestDaily = DailyNoLimit ? null : requestDaily;
      requestWeekly = WeeklyNoLimit ? null : requestWeekly;
      requestMonthly = MonthlyNoLimit ? null : requestMonthly;

      const sameValue = CurrentDailyDepositLimit === requestDaily && CurrentWeeklyDepositLimit === requestWeekly && CurrentMonthlyDepositLimit === requestMonthly;
      const nullCheck = !requestDaily && requestDaily !== 0 && !requestWeekly && requestWeekly !== 0 && !requestMonthly && requestMonthly !== 0;

      return sameValue || nullCheck;
    },
    checkMaximumLimit() {
      const {
        Day1Limit,
        Day7Limit,
        Day30Limit
      } = this.current;
      const {
        RequestDailyDepositLimit,
        RequestWeeklyDepositLimit,
        RequestMonthlyDepositLimit
      } = this.model;

      return (Day1Limit < RequestDailyDepositLimit || Day7Limit < RequestWeeklyDepositLimit || Day30Limit < RequestMonthlyDepositLimit);
    },
  },
  watch: {
    'model.RequestDailyDepositLimit': 'update',
    'model.RequestWeeklyDepositLimit': 'update',
    'model.RequestMonthlyDepositLimit': 'update',

    'model.DailyNoLimit': 'update',
    'model.WeeklyNoLimit': 'update',
    'model.MonthlyNoLimit': 'update',
  },
  methods: {
    async initialize() {
      const { limit, deposit } = await this.structure.current;

      this.info = this.rgInfo;
      this.current = {
        ...this.current,
        ...limit,
      };
      this.currencySymbol = getCurrencySymbol(this.current.Currency);
      this.rolling = this.structure.config.DepositLimitPeriodType;

      this.current.Day1Limit = _.isNumber(deposit.Day1Limit) ? deposit.Day1Limit : this.maxLimit;
      this.current.Day7Limit = _.isNumber(deposit.Day7Limit) ? deposit.Day7Limit : this.maxLimit;
      this.current.Day30Limit = _.isNumber(deposit.Day30Limit) ? deposit.Day30Limit : this.maxLimit;

      let cashier1Limit = _.isNumber(deposit.Day1Limit) ? deposit.Day1Limit : null;
      let cashier7Limit = _.isNumber(deposit.Day7Limit) ? deposit.Day7Limit : null;
      let cashier30Limit = _.isNumber(deposit.Day30Limit) ? deposit.Day30Limit : null;

      if (!this.isCashierLimitSite) {
        cashier1Limit = null;
        cashier7Limit = null;
        cashier30Limit = null;
      }

      this.current.CurrentDailyDepositLimit = _.isNumber(limit.CurrentDailyDepositLimit) ? limit.CurrentDailyDepositLimit : cashier1Limit;
      this.current.CurrentWeeklyDepositLimit = _.isNumber(limit.CurrentWeeklyDepositLimit) ? limit.CurrentWeeklyDepositLimit : cashier7Limit;
      this.current.CurrentMonthlyDepositLimit = _.isNumber(limit.CurrentMonthlyDepositLimit) ? limit.CurrentMonthlyDepositLimit : cashier30Limit;
    },
    update() {
      if (this.limitErrorMsg) this.limitErrorMsg = null;
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';

      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
        exceed: 'responsibleGaming.modal.description.exceedDescription',
      };

      if (this.isExceedMaximum && this.checkMaximumLimit) {
        return {
          title: this.$t('_.modal.exceedTitle'),
          subTitle: '',
          isExceed: true,
          modalType: 'HangTime',
          calculateList: this.exceedList,
          descModel: descModel
        };
      } else {
        if (this.checkMaximumLimit) {
          this.limitErrorMsg = this.$t('responsibleGaming.error.increaseLimit', { csEmail: this.csEmail, support: this.$t('responsibleGaming.error.customerSupport') });
          return null;
        } else {
          return {
            title: this.$t('_.modal.title'),
            subTitle: this.$t(`_.modal.subTitle.${this.structure.config.title}`),
            modalType: 'HangTime',
            request: () => this.$rg.request(this.createPayload()),
            calculateList: this.calculateList,
            descModel: descModel,
            isVerifyFreeze: this.structure.config.verifyFreeze
          };
        }
      }
    },

    createPayload() {
      const payload = { ...this.model };
      if (!this.model.DailyNoLimit && this.current.CurrentDailyDepositLimit && !this.model.RequestDailyDepositLimit) {
        payload.RequestDailyDepositLimit = this.current.CurrentDailyDepositLimit;
      }
      if (!this.model.WeeklyNoLimit && this.current.CurrentWeeklyDepositLimit && !this.model.RequestWeeklyDepositLimit) {
        payload.RequestWeeklyDepositLimit = this.current.CurrentWeeklyDepositLimit;
      }
      if (!this.model.MonthlyNoLimit && this.current.CurrentMonthlyDepositLimit && !this.model.RequestMonthlyDepositLimit) {
        payload.RequestMonthlyDepositLimit = this.current.CurrentMonthlyDepositLimit;
      }
      return payload;
    },

    openModal() {
      const options = this.createModalOptions();
      if (!options) return;
      this.$modal(ResponsibleHangTimeConfirmModal, options);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-deposit-limit] {
  .sub-description {.c(#737373); .fs(14);
    > ul {list-style: square;}
    > ul > li {list-style: square}
  }
  .support-error-msg {.mt(20);
    > li { .fs(16);
      > a {.c(@c-link); font-weight: 500;}
    }
  }
}

</style>