<template>
  <div input-loss-limit-table>
    <LimitTable>
      <template #body>
        <LimitTableRow :class="['hidden-error', {'show-error' : showDailyError}]" no-limit-name="DailyNoLimit" :label="dailyLabel" :rules="dailyRules" :no-limit="model.DailyNoLimit" current-name="currentDailyLimit" v-model="model.RequestDailyLossLimit" :current-value="dailyCurrentAmount" new-name="dailyLimit" :preset="preset" @checked="checked" />
        <LimitTableRow :class="['hidden-error', {'show-error' : showWeeklyError}]" no-limit-name="WeeklyNoLimit" :label="weeklyLabel" :rules="weeklyRules" :no-limit="model.WeeklyNoLimit" current-name="currentWeeklyLimit" v-model="model.RequestWeeklyLossLimit" :current-value="weeklyCurrentAmount" new-name="weeklyLimit" :preset="preset" @checked="checked" />
        <LimitTableRow :class="['hidden-error', {'show-error' : showMonthlyError}]" no-limit-name="MonthlyNoLimit" :label="monthlyLabel" :rules="monthlyRules" :no-limit="model.MonthlyNoLimit" current-name="currentMonthlyLimit" v-model="model.RequestMonthlyLossLimit" :current-value="monthlyCurrentAmount" new-name="monthlyLimit" :preset="preset" @checked="checked" />
      </template>
    </LimitTable>
  </div>
</template>

<script>
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
import LimitTable from '@/views/components/pages/responsible-gaming/template/common/LimitTable.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'InputLossLimitTable',
  lexicon: 'onBoarding.setting',
  components: { LimitTable, LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
    errors: Specific,
    hangTime: Specific,
    rolling: { type: String, default: false },
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      showDailyError: false,
      showWeeklyError: false,
      showMonthlyError: false,
      tableHeader: ['', 'currentLimit', 'newLimit'],
      maxLimit: 1000000000,
    };
  },
  computed: {
    isRolling() {
      return this.rolling === 'Rolling';
    },
    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('daily');
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('weekly');
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('monthly');
    },
    dailyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentDailyLossLimit);
    },
    weeklyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentWeeklyLossLimit);
    },
    monthlyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentMonthlyLossLimit);
    },
    //tableHeader() {return ['', this.$t('currentLimit'), this.$t('newLimit')]},

    preset() {
      return { placeholder: this.$t('amount'), textAlign: 'right', currencySymbol: this.currencySymbol, maxLength: 9 };
    },
    currentModelKey() {
      return {
        daily: 'CurrentDailyLossLimit',
        weekly: 'CurrentWeeklyLossLimit',
        monthly: 'CurrentMonthlyLossLimit',
      };
    },
    requestModelKey() {
      return {
        daily: 'RequestDailyLossLimit',
        weekly: 'RequestWeeklyLossLimit',
        monthly: 'RequestMonthlyLossLimit',
      };
    },

    // Rules 관련
    currentDaily() {
      return this.getCurrentLimit('daily');
    },
    requestDaily() {
      return this.getRequestLimit('daily', this.model.DailyNoLimit);
    },
    dailyHangTime() {
      const [dailyLower, dailyUpper] = this.hangTime.daily;
      return this.currentDaily > this.requestDaily ? dailyLower : dailyUpper;
    },
    currentWeekly() {
      return this.getCurrentLimit('weekly');
    },
    requestWeekly() {
      return this.getRequestLimit('weekly', this.model.WeeklyNoLimit);
    },
    weeklyHangTime() {
      const [weeklyLower, weeklyUpper] = this.hangTime.weekly;
      return this.currentWeekly > this.requestWeekly ? weeklyLower : weeklyUpper;
    },
    currentMonthly() {
      return this.getCurrentLimit('monthly');
    },
    requestMonthly() {
      return this.getRequestLimit('monthly', this.model.MonthlyNoLimit);
    },
    monthlyHangTime() {
      const [monthlyLower, monthlyUpper] = this.hangTime.monthly;
      return this.currentMonthly > this.requestMonthly ? monthlyLower : monthlyUpper;
    },
    dailyRules() {
      const daily = Math.max(this.currentDaily, this.requestDaily);
      const weekly = Math.min(this.currentWeekly, this.requestWeekly);
      return `rgLimitComparison:${this.requestDaily},${this.requestWeekly},${this.$t('daily')},${this.$t('weekly')}|rgLimitCheck:${daily},${weekly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('daily')},${this.$t('weekly')}`;
      // return `dailyLimit:${this.requestWeekly},${this.$t('daily')},${this.$t('weekly')}|rgLimitCheck:${daily},${weekly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('daily')},${this.$t('weekly')}`;
    },
    weeklyRules() {
      const daily = Math.max(this.currentDaily, this.requestDaily);
      const minWeekly = Math.min(this.currentWeekly, this.requestWeekly);
      const maxWeekly = Math.max(this.currentWeekly, this.requestWeekly);
      const monthly = Math.min(this.currentMonthly, this.requestMonthly);

      const weeklyRules = `rgMoreLimitComparison:${this.requestDaily},${this.requestWeekly},${this.$t('weekly')},${this.$t('daily')}|rgMoreLimitCheck:${daily},${minWeekly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('weekly')},${this.$t('daily')}`;
      const monthlyRules = `rgLimitComparison:${this.requestWeekly},${this.requestMonthly},${this.$t('weekly')},${this.$t('monthly')}|rgLimitCheck:${maxWeekly},${monthly},${this.weeklyHangTime},${this.monthlyHangTime},${this.$t('weekly')},${this.$t('monthly')}`;

      // const dailyRules = `rgLimitCheck:${daily},${minWeekly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('daily')},${this.$t('weekly')}`;
      // const monthlyRules = `dailyLimit:${this.requestMonthly},Weekly,Monthly|rgLimitCheck:${maxWeekly},${monthly},${this.weeklyHangTime},${this.monthlyHangTime},${this.$t('weekly')},${this.$t('monthly')}`;

      return `${weeklyRules}|${monthlyRules}`;
    },
    monthlyRules() {
      const weekly = Math.max(this.currentWeekly, this.requestWeekly);
      const monthly = Math.min(this.currentMonthly, this.requestMonthly);
      return `rgMoreLimitComparison:${this.requestWeekly},${this.requestMonthly},${this.$t('monthly')},${this.$t('weekly')}|rgMoreLimitCheck:${weekly},${monthly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('monthly')},${this.$t('weekly')}`;
      // return `rgMoreLimitCheck:${this.requestWeekly},${this.requestMonthly},${this.$t('monthly')},${this.$t('weekly')}|rgLimitCheck:${weekly},${monthly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('weekly')},${this.$t('monthly')}`;
      // return `rgMoreLimitCheck:${weekly},${monthly},${this.dailyHangTime},${this.weeklyHangTime},${this.$t('weekly')},${this.$t('monthly')}`;
    },
  },
  watch: {
    'errors': 'updateError'
  },
  methods: {
    updateError() {
      if (this.errors.monthlyLimit && this.errors.monthlyLimit?.length > 0) {
        this.showDailyError = false;
        this.showWeeklyError = false;
        this.showMonthlyError = true;
        return;
      }
      if (this.errors.weeklyLimit && this.errors.weeklyLimit?.length > 0) {
        this.showDailyError = false;
        this.showWeeklyError = true;
        this.showMonthlyError = false;
        return;
      }
      if (this.errors.dailyLimit && this.errors.dailyLimit?.length > 0) {
        this.showDailyError = true;
        this.showWeeklyError = false;
        this.showMonthlyError = false;
        return;
      }
    },
    createCurrentDisplayAmount(value) {
      if (!value) return null;
      return `${this.currencySymbol}${value}`;
    },
    checked(name, v) {
      this.model[name] = v;
    },
    getCurrentLimit(type) {
      const key = this.currentModelKey[type];
      return this.current[key] === null ? this.maxLimit : this.current[key];
    },
    getRequestLimit(type, noLimit) {
      const key = this.requestModelKey[type];
      const value = this.model[key];
      const requestDaily = (value || parseInt(value) === 0) ? value : this.getCurrentLimit(type);
      return noLimit ? this.maxLimit : requestDaily;
    },

  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-loss-limit-table] {
  [limit-table-row] {
    &.hidden-error {
      [validation-errors] { display: none; }
    }
    &.show-error {
      [validation-errors] { display: block; }
    }

  }
}
</style>