<template>
  <div verify-jumio>
    <JumioDescription v-if="showDesc" :step="step" />
    <div class="view-container">
      <JumioDocumentSelect v-if="showSelect" :list="documentList" :value="document" @input="changeDocument" />
      <div class="view-holder" v-if="ready || component">
        <JumioManualInformation v-if="ready" :kycRequestMessage="kycRequestMessage" @confirm="ready = false" />
        <component v-else-if="component" :is="component" :step="step" :status="status" :item="item" @confirm="confirm" />
      </div>
      <VerifyIframe v-if="url" :url="url" />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { completeRedirectUrl } from '@/utils/baseUtil';
import { autoLogin, checkClientPermission, close, isClient } from '@/utils/clientUtil';
import JumioController from '@/plugins/jumioController';
import { jumioStep, jumioStatus, restrictType } from '@/constants/base/onboarding/kyc';
import baseTemplate from '@/mixins/baseTemplate';
import JumioManualInformation from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioManualInformation.vue';
import JumioProcessing from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioProcessing.vue';
import JumioDescription from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioDescription.vue';
import JumioReviewing from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioReviewing.vue';
import JumioVerificationFail from '@/views/components/pages/on-boarding/template/kyc/jumio/failed/JumioVerificationFail.vue';
import JumioDocumentExpired from '@/views/components/pages/on-boarding/template/kyc/jumio/failed/JumioDocumentExpired.vue';
import JumioDocumentInvalid from '@/views/components/pages/on-boarding/template/kyc/jumio/failed/JumioDocumentInvalid.vue';
import JumioOasisFailed from '@/views/components/pages/on-boarding/template/kyc/jumio/failed/JumioOasisFailed.vue';
import JumioPepMatch from '@/views/components/pages/on-boarding/template/kyc/jumio/failed/JumioPepMatch.vue';
import JumioDocumentSelect from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioDocumentSelect.vue';
import VerifyIframe from '@/views/components/pages/on-boarding/VerifyIframe.vue';
import { siteIds } from '@/constants/base/siteMap';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';

export default {
  name: 'VerifyJumio',
  components: { VerifyIframe, JumioDocumentSelect, JumioDescription, JumioManualInformation, JumioVerificationFail },
  mixins: [baseTemplate],
  data() {
    return {
      ready: null,
      jumio: null,
      step: null,
      status: null,
      requestType: null,
      isDe: null,
      url: null,
      kycStatus: null,
      kycRequestMessage: null,
      isManuallyRequested: null,
      customSteps: null,
      documentList: null,
      document: null,
      components: [
        { component: JumioReviewing, step: jumioStep.Reviewing, status: null, title: 'onBoarding.title.reviewingDocuments' },
        { component: JumioProcessing, step: null, status: jumioStatus.Pending, title: 'onBoarding.title.identityVerification' },
        { component: JumioOasisFailed, step: jumioStep.OasisFailed, status: null, title: 'onBoarding.title.pepMatch' },
        { component: JumioPepMatch, step: jumioStep.PepFailed, status: null, title: 'onBoarding.title.pepMatch' },
        { component: JumioDocumentExpired, step: jumioStep.Failed, status: jumioStatus.DocumentExpired, title: 'onBoarding.title.pepMatch' },
        { component: JumioDocumentInvalid, step: jumioStep.Failed, status: jumioStatus.DocumentInvalid, title: 'onBoarding.title.pepMatch' },
        { component: JumioVerificationFail, step: jumioStep.Failed, status: null, title: 'onBoarding.title.pepMatch' },
      ],
      JumioManualInformation,
      JumioProcessing,
    };
  },
  watch: {
    title: 'updateTitle',
  },
  computed: {
    site: state('env', 'site'),
    component() {
      return this.components.find(o => (!o.step || o.step === this.step) && (!o.status || o.status === this.status))?.component;
    },
    documentStep() {
      return this.step === jumioStep.Document;
    },
    showDesc() {
      return [siteIds.GGPDE, siteIds.GGPUK].includes(this.site);
    },
    showSelect() {
      return this.documentStep;
    },
    title() {
      return this.components.find(o => (!o.step || o.step === this.step) && (!o.status || o.status === this.status))?.title || 'onBoarding.title.kycVerification';
    },
  },
  methods: {
    callback({ step, status, requestType, isDe, url, kycRequestMessage, kycStatus, isManuallyRequested, customSteps, documentList, document }) {
      console.group('%cverifyJumio callback', 'color:yellow', `step: ${step}, status: ${status}`);
      console.log(`url: ${url}`);
      console.log(`requestType: ${requestType}`);
      console.log(`isDe: ${isDe}`);
      console.log(`isManuallyRequested: ${isManuallyRequested}`);
      console.log(`kycRequestMessage: ${kycRequestMessage}`);
      console.log(`kycStatus: ${JSON.stringify(kycStatus)}`);
      console.log(`customSteps: ${JSON.stringify(customSteps)}`);
      console.log(`documentList: ${JSON.stringify(documentList)}`);
      console.log(`document: ${JSON.stringify(document)}`);
      console.groupEnd();

      const isDocumentStep = this.step === jumioStep.Document;
      const isReviewingStep = this.step === jumioStep.Reviewing;
      const isSameStep = this.step === step;
      const isSameStatus = this.status === status;
      if ((!isDocumentStep && isSameStep && isSameStatus) || isReviewingStep) return;

      this.step = step;
      this.status = status;
      this.requestType = requestType;
      this.isDe = isDe;
      this.url = url;
      this.kycStatus = kycStatus;
      this.kycRequestMessage = kycRequestMessage;
      this.isManuallyRequested = isManuallyRequested;
      this.customSteps = customSteps;
      this.documentList = documentList;
      this.document = document;

      if (isDe) this.$emit('jumio', { step, status, kycRequestMessage });
      if (this.step === jumioStep.Reviewing) {
        this.status = null;
      } else {
        switch (status) {
          case jumioStatus.None:
            // isManuallyRequested 가 true 일 경우 ManualInformation 을 노출함
            this.ready = (!!kycRequestMessage && isManuallyRequested);
            break;
          case jumioStatus.Pending:
            break;
          case jumioStatus.Verified:
            this.modelInfo.replace = true;
            break;
          case jumioStatus.Done:
            if (this.step === jumioStep.Identity) this.jumio?.restart();

            else this.modelInfo.replace = true;
            break;
          case jumioStatus.Rejected: // 에러로 처리
          case jumioStatus.NotApplicable: // 에러로 처리
          case jumioStatus.DocumentExpired:
          case jumioStatus.DocumentInvalid:
          case jumioStatus.Failed:
          default:
            // this.jumio?.restart();
            break;
        }
      }
    },
    jumioMessageListener(e) {
      if (e?.data && e?.data.key === 'RECEIVER_JUMIO' && e.data.transactionId) {
        this.status = null;
        this.jumio.result(e.data);
      }
    },
    async confirm() {
      switch (this.step) {
        case jumioStep.PepFailed:
        case jumioStep.OasisFailed:
          this.jumio?.close();
          return;
        case jumioStep.Failed:
          this.jumio?.restart();
          return;
        case jumioStep.Reviewing:
          if (!completeRedirectUrl()) {
            if (isClient()) {
              const existRestrictLogin = this.kycStatus?.KycRequisition?.Restrics?.includes(restrictType.Login);
              if (existRestrictLogin) close(this);
              else autoLogin(this, { site: this.site, token: await this.$services.sign.getGcLoginToken() });
            } else close(this);
          }
          return;
      }
    },
    changeDocument(v) {
      if (!v && v === this.document) return;
      this.jumio.changeDocument(v);
    },
    clear() {
      this.$unblock();
      this.jumio = null;
      window.removeEventListener('message', this.jumioMessageListener);
    },
    updateTitle() {
      if (!this.isDe) return;
      if (this.item) this.item.title = this.title;
    },
  },
  async mounted() {
    if (this.structure.list.includes(routeNames.showReviewingKycMessage)) {
      this.step = jumioStep.Reviewing;
    } else {
      await checkClientPermission(this);
      const { $services: services, site, lang, callback, modelInfo } = this;
      this.jumio = new JumioController(this, { services, site, lang, callback, modelInfo, onboardingController: this.$onboarding });
      window.addEventListener('message', this.jumioMessageListener);
    }
  },
  beforeDestroy() {
    this.clear();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-jumio] { .rel();
  .view-container { .min-h(600); .rel(); .h(100%); .w(100%); .mh-c(); }
  .view-holder { .z(3); .abs; .min-h(100%); .f;  .bgc(@c-white); .c(@c-b07); }
}
</style>
