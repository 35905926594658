<template>
  <BaseModal :show-title="isEditStep" responsible-hangtime-confirm-modal :title="title" :class="{'result' : !isEditStep}">
    <template v-if="isLoaded" #content>
      <component :is="getStepComponent" :title="title" :sub-title="subTitle" :isExceed="isExceed" :description="description" :data="data" :type="resultType" :subMessage="subMessage" :message="message" :calculateList="calculateList" :descModel="descModel" :isVerifyFreeze="isVerifyFreeze" @input="input" @close="close" @submit="submit" @confirm="confirm" />
    </template>
    <template #floating>
      <div class="floating">
        <GpButton v-if="isEditStep" type="line-green" @click="close">{{ $t('cancel') }}</GpButton>
        <GpButton v-if="isEditStep" type="submit-green" :progress="progress" @click="submit">{{ $t(confirmText) }}</GpButton>
        <GpButton v-if="!isEditStep" type="submit-green" :progress="progress" @click="confirm">{{ $t('ok') }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import ConfirmResult from '@/views/components/pages/responsible-gaming/modal/components/ConfirmResult.vue';
import DisplayHangTimeModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayHangTimeModal.vue';
import DisplayMessageModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayMessageModal.vue';
import DisplayOasisBanModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayOasisBanModal.vue';
import DisplayExternalMonthlyModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayExternalMonthlyModal.vue';
import DisplayExceedLimitModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayExceedLimitModal.vue';
import rgErrorHandler from '@/mixins/responsible-gaming/rgErrorHandler';

export default {
  name: 'ResponsibleHangTimeConfirmModal',
  components: { GpDescription, RadioList, GpButton, BaseModal },
  mixins: [rgErrorHandler],
  props: {
    options: {
      title: { type: String, default: null },
      subTitle: { type: String, default: null },
      description: { type: String, default: null },
      data: Specific,
      calculateList: { type: Specific, default: null },
      isExceed: { type: Boolean, default: false, },
      request: { type: Function, default: () => {} },
      modalType: { type: String, default: null, },
      model: Specific,
      selfExclusion: false,
      descModel: Specific,
      isVerifyFreeze: { type: Boolean, default: false },
    }
  },
  data() {
    return {
      // Form Props
      title: null,
      data: null,
      description: null,
      subTitle: null,
      isExceed: false,
      step: 'EDIT',
      calculateList: null,
      descModel: null,
      isVerifyFreeze: false,

      // Result Props
      resultType: true,
      message: null,
      subMessage: null,

      // oasis 관련 처리용 변수
      oasisBanList: null,
      isLoaded: false,
      progress: false,

      DisplayHangTimeModal,
      DisplayExceedLimitModal,
      DisplayMessageModal,
      ConfirmResult,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    modalType() {
      return this.options.modalType;
    },
    getStepComponent() {
      return this.isEditStep ? this.getTypeComponent : ConfirmResult;
    },
    isEditStep() {
      return this.step === 'EDIT';
    },
    getTypeComponent() {
      switch (this.modalType) {
        case 'Message' :
          return DisplayMessageModal;
        case 'HangTime' :
          return this.isExceed ? DisplayExceedLimitModal : DisplayHangTimeModal;
        case 'Oasis' :
          return DisplayOasisBanModal;
        case 'External':
          return DisplayExternalMonthlyModal;
        default :
          return DisplayHangTimeModal;
      }
    },
    confirmText() {
      if (this.modalType === 'HangTime' && this.isExceed) return 'verify';
      return 'confirm';
    },
  },
  methods: {
    initialize() {
      this.data = this.options.data;
      this.title = this.options.title;
      this.subTitle = this.options.subTitle;
      this.description = this.options.desc;
      this.isExceed = this.options.isExceed;
      this.calculateList = this.options.calculateList;
      this.descModel = this.options.descModel;
      this.isVerifyFreeze = this.options.isVerifyFreeze;
      this.step = 'EDIT';

      this.isLoaded = true;
    },

    async submit() {
      this.progress = true;
      if (this.isExceed) {
        this.redirectKycUrl();
        return;
      }
      const { request } = this.options;
      const result = await request(this.oasisBanList);
      this.progress = false;
      if (result.error) {
        this.resultType = false;
        this.responseErrorHandler(result);
        this.step = 'RESULT';
        return;
      }

      if (this.options.selfExclusion) {
        // Logout 처리 ( Self Exclusion 처리 )
        await this.routeName('ResponsibleGaming');
        return;
      }

      this.resultType = true;
      this.message = this.$t('responsibleGaming.modal.successMessage');
      this.step = 'RESULT';
    },
    close() {
      this.$modalCloseAll();
    },
    confirm() {
      this.replaceRouteName('ResponsibleGaming');
      this.close();
    },
    input(array) {
      this.oasisBanList = array;
    },
    redirectKycUrl() {
      this.$rg.redirectKycUrl({ lang: this.$i18n('lang'), additional: true, redirectPath: 'rg-my-deposit-limit' });
    },

    responseErrorHandler(error) {
      const { message, subMessage } = this.apiErrorHandler(error);
      this.message = message;
      this.subMessage = subMessage;
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[responsible-hangtime-confirm-modal] {
  //> .panel { .rel(); background-color: #f7f7f8 !important; .max-h(70%); overflow-y: hidden;
  //  .scroll-area {.h(calc(100% - 120px)); .flex(); .flex-dc(); overflow-y: auto; .mb(40);}
  //  .floating {.abs(); .b(20); .w(100%); .ph(20); .flex(); .gap(10);}
  //}
  .scroll-area {.p(0); padding-left: 0 !important; padding-right: 0 !important;}
  .floating { .w(100%); .ph(20); .flex(); .gap(10); .pb(20);}
  &.result {
    > .panel {.h(auto);
      .scroll-area {margin-bottom: 30px;}
    }
  }
  [gp-description] {.pt(0); .pb(24); padding-right: 20px !important; padding-left: 20px !important;}

  .body { overflow-y: hidden; }
  .content {.mb(20); overflow-y: auto; .max-h(310); .ph(20); }

  .result-table {text-align: center; .c(black); .fs(12); .mv(20);
    > thead {.bold(); border-bottom: #d9d9d9 1px solid;
      > td { .pb(5); }
    }
    > tbody {
      > tr > td {.p(3);}
    }
  }
  .img-group { .flex(); .items-center(); .flex-dc(); .h(100%); .pt(0);
    > p {.c(black); font-size: 16px !important; .pt(10);}
  }

  .footer {.flex(); .gap(10);}

  @media (@ms-down) {
    [gp-description] {
      > p {.ph(15) !important;}
    }
  }

}
</style>