<template>
  <div temporarily-accepted>
    <span>
      <p v-html="$t('_.temporarilyAccepted.description1')" />
    </span>
    <span>
      <p v-html="$t('_.temporarilyAccepted.description2')" />
      <a @click="toggle">
        <FontIcon name="exclamation-circle-regular-sharp" />
      </a>
    </span>
    <DescriptionHolder v-if="isShow" :list="tip" transparent />
    <ProgressButton button-name="complete" @click="complete" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { completeRedirectUrl } from '@/utils/baseUtil';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import DescriptionHolder from '@/views/components/common/DescriptionHolder.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';

export default {
  name: 'TemporarilyAccepted',
  lexicon: 'onBoarding',
  components: { DescriptionHolder, FontIcon, ProgressButton },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    token: state('user', 'token'),
    tip() {
      return [this.$t('_.temporarilyAccepted.tooltipDescription1'), this.$t('_.temporarilyAccepted.tooltipDescription2'), this.$t('_.temporarilyAccepted.tooltipDescription3'), this.$t('_.temporarilyAccepted.tooltipDescription4')];
    }
  },
  methods: {
    async complete() {
      if (!completeRedirectUrl()) {
        if (isClient()) {
          const existRestrictLogin = this.kycStatus?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this, { site: this.site, token: await this.$services.sign.getGcLoginToken() });
        } else close(this);
      }
    },
    toggle() {
      this.isShow = !this.isShow;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[temporarily-accepted] {
  > span{ .flex(); .flex-ai();
    [font-icon] { .ml(4);}
    strong { .bold(); }
  }
  > span + span { .mt(32); .bold(); }
  > [description-holder] { .mt(20); box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#262626); .-a(#333);
    li { .c(#d8d8d8);
      &:before { .bgc(#d8d8d8); .t(8); }
    }
  }
}
</style>
