import { render, staticRenderFns } from "./OpenEmailApp.vue?vue&type=template&id=e09d6726&scoped=true"
import script from "./OpenEmailApp.vue?vue&type=script&lang=js"
export * from "./OpenEmailApp.vue?vue&type=script&lang=js"
import style0 from "./OpenEmailApp.vue?vue&type=style&index=0&id=e09d6726&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e09d6726",
  null
  
)

export default component.exports