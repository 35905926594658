<template>
  <div temp-account-guide-de v-if="modelInfo" :name="formName" class="kyc-personal-details-view">
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'TempAccountGuideDe',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      firstErrorMsg: null,
      lastErrorMsg: null,
      isCheckSameLastName: false,
      TrimTextInput,
    };
  },
  computed: {
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    extra() {
      return this.item?.extra && this.item.extra[this.site] ? this.item.extra[this.site] : {};
    },
    extraComponent() {
      return this.extra.template;
    },
    preset() {
      return { maxLength: 50 };
    },
    formName() {
      return this.site === siteIds.WSOPON ? 'WSOPca_Step11_Personal_Details_Name_View' : 'Personal_Details_Name_View';
    },
    site: state('env', 'site'),
  },
  watch: {
    errorMsg() {
      const { errorMsg } = this;
      this.firstErrorMsg = null;
      this.lastErrorMsg = null;

      if (errorMsg) {
        if (errorMsg === apiErrorCode.INVALID_FIRST_NAME) this.firstErrorMsg = this.$tv({ _rule_: 'required', _field_: '_firstName' });
        else if (errorMsg === apiErrorCode.INVALID_LAST_NAME) this.lastErrorMsg = this.$tv({ _rule_: 'required', _field_: '_lastName' });
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[temp-account-guide-de] {
  > p { .c(@c-text-desc); .mb(24); }
  > div {
    > input { .wh(0); .abs();}
  }
  .field-holder { .flex(); .space-between();
    > * {.w(calc(50% - 5px)); }
  }
  > *:nth-of-type(2) { .mt(8); }
}
</style>