<template>
  <ValidationProvider limit-unit-field v-slot="{errors}" :name="name" :rules="rules">
    <label v-if="label && label.length"> {{ label }}</label>
    <div>
      <s>{{ currencySymbol }}</s>
      <div>
        <TextInput v-if="!ready"/>
        <TextInput v-else v-model="model" theme="dark" digit :max-length="maxLength" :class="{'error': model && errors.length}" :disabled="noLimit" input-mode="numeric" pattern="[0-9]*" />
        <CheckboxItem v-if="hasNoLimit" v-model="noLimit" theme="dark" @change="updateCheckBox">{{ $t('noLimit') }}</CheckboxItem>
        <ValidationErrors v-if="model || errorMsg" :errors="errors" :error-msg="errorMsg" />
        <FontIcon v-if="!ready" class="progress" name="rotate-arrows"/>
      </div>
      <em v-if="ready && unitLabelAble">{{ unitLabelValue }}</em>
    </div>
  </ValidationProvider>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'LimitUnitField',
  components: { TextInput, CheckboxItem, FontIcon },
  props: {
    value: { type: [String, Number], default: null },
    limit: { type: Number, default: 0 },
    label: { type: String, default: null },
    unitLabel: { type: String, default: null },
    name: { type: String },
    rules: { type: String },
    maxLength: { type: Number, default: 999 },
    hasNoLimit: { type: Boolean, default: false },
    errorMsg: { type: String, default: null },
    ready: { type: Boolean, default: true },
  },
  data() {
    return {
      model: '',
      noLimit: false,
      disable: false,
      isEnabledLugas: false,
      TextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    currencySymbol: state('user', 'currencySymbol'),
    unitLabelAble() {
      return !this.model;
    },
    unitLabelValue() {
      if (this.isEnabledLugas || this.hasNoLimit) return '';
      return this.unitLabel || (this.limit > 0 ? `${this.$t('maximum')} : ${this.currencySymbol}${this.limit}` : this.$t('onBoarding.unLimited'));
    },
  },
  watch: {
    value: 'update',
    model() {
      if (this.model) this.noLimit = false;
      this.$emit('input', this.model ? +this.model : undefined);
    },
  },
  methods: {
    updateCheckBox() {
      if (this.noLimit) this.model = null;
      this.$emit('update', this.noLimit);
    },
    update() {
      if (this.value) this.model = this.value;
      this.isEnabledLugas = [siteIds.GGPDE].includes(this.site);
    },
  },
  async mounted() {
    await this.update();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-unit-field] { .flex(); .space-between(); .items-start(); .column-gap(8);
  &:not(:nth-of-type(1)) { .mt(12); }
  > label { flex-basis: 20%; .regular(); .c(@c-w07); .mt(16); .nowrap();
    &.hide { .o(0); }
  }
  > div { flex-basis: 80%; .rel();
    > s { .abs(); .lt(10, 16); }
    > em { .abs(); .fs(16); .rt(10, 16); .c(@c-w05); pointer-events: none;}
    [text-input] { .h(56); .pl(20);
      input { .tr();}
      &.error { .-a(@c-red, 2); }
    }
    [text-input].disabled { .bgc(@c-w01); }
    [checkbox-item] { .m(20, 0); }
    .progress { animation: rotate-circle 2s linear infinite; .fs(16); .block(); .abs(); .rt(20,50%); .mt(-10);}
  }
}
</style>
