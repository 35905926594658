import { values } from '@shared/utils/commonUtils.mjs';
// import LoginRequiredModal from '@shared/components/account/LoginRequiredModal.vue';
import axios from 'axios';
import { getSessionStorage } from '@shared/modules/ObjectStorage';
import { isNumber } from 'lodash';

export const NoToast = {};

export default (Vue, app, services) => {
  const errorHandler = error => {
    console.log('error ::::::::', error);
    if (!error || error === NoToast || error.constructor === axios.Cancel) return;

    if (error instanceof DOMException || error?.name === 'ChunkLoadError') {
      window.location.reload();
      return;
    }

    const des = error?.desc;
    const code = error?.code || error?.body?.code;

    let message;
    let translate = true;
    if (typeof error === 'string') {
      message = error;
    } else if (Array.isArray(error)) {
      if (!app.$te(error)) error[error.length - 1] = 'DEFAULT';
      message = error;
    } else if (error._rule_) {
      message = app.$tv(error);
      translate = false;
    } else if (code in values('401', '403')) {
      // 에러 발생 시 무시
      try { services?.sign?.logout({isSessionExpired: true}); } catch(e) { /* empty */ }
      return;
    } else if(code || des) {
      message = code || des;
      translate = false;
    } else if (error.code) {
      message = error.code || 'unhandledApiError';
      translate = false;
    } else {
      translate = !error.body;
      message = error?.body?.code || 'unhandledError';
      console.log('unhandledError :', error);
    }

    if (process.env.VUE_APP_ENV !== 'production') {
      app.$toast(message, { translate, type: 'fail' });
    }
  };

  Vue.config.errorHandler = errorHandler;
  window.addEventListener('unhandledrejection', event => event.promise.catch(errorHandler));
};
