<template>
  <GpFormTemplate np-balacne-transfer>
    <template #header>
      <GpDescription :message="$t('myInfo.balanceTransferDesc')" />
    </template>
    <template #content>
      <div class="link-account">
        <InputBalanceTransfer v-if="model.CurrentAccount && model.Accounts" :model="model" :list="list" @currency="getCurrencyRate" />
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" :disabled="!!invalid" @click="confirm(validate)">{{ $t('confirm') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import InputBalanceTransfer from '@/views/components/pages/my-page/template/inputs/InputBalanceTransfer.vue';
import TransferResultModal from '@/views/components/pages/my-page/modal/TransferResultModal.vue';
import { getDisplayAmount } from '@/utils/amountUtil';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import { routeNames } from '@/constants/model/my-page';
import Specific from '@shared/types/Specific';
export default {
  name: 'NpBalanceTransfer',
  components: { InputBalanceTransfer, GpButton, GpFormTemplate, GpDescription },
  mixins: [npErrorHandler],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      errorMsg: null,
      list: null,
      CurrencyFrom: null,
      CurrencyTo: null,
    };
  },

  computed: {
    routeNames: state('env', 'routeNames'),
    site: state('env', 'site'),
  },
  methods: {
    initialize() {
      if (!this.model.CurrentAccount && !this.model.Accounts) {
        this.routeName(routeNames.NpMyAccounts);
      }
    },
    async confirm(validate) {
      const result = await validate();
      const debit = { countryCode: this.model.DebitCountryCode, amount: this.model.DebitAmount, siteId: this.model.DebitSiteId, currency: this.model.FromCurrencyId, logoUrl: this.model.DebitLogoUrl };
      const credit = { countryCode: this.model.CreditCountryCode, amount: this.model.CreditAmount, siteId: this.model.CreditSiteId, currency: this.model.ToCurrencyId, logoUrl: this.model.CreditLogoUrl };

      if (this.model.FromCurrencyId === this.model.ToCurrencyId) {
        this.model.ExchangeAmount = this.model.FromAmount;
      }

      this.$modal(TransferResultModal, { debit, credit, fromAmount: this.model.FromAmount, exchangeAmount: this.model.ExchangeAmount, model: this.model, request: this.modalSubmit });
    },

    async modalSubmit() {
      const fromAmount = parseFloat(this.model.FromAmount).toFixed(2);
      const payload = { ...this.model, FromAmount: fromAmount };
      const result = await this.$myinfo.request(payload);

      if (result.error) {
        this.structure.showHistory = true;
        this.structure.buttonName = 'tryAgain';
        this.structure.backRoute = 'NpBalanceTransfer';
        this.$modalCloseAll();
        this.errorMessage = this.apiErrorHandler(result);
        this.$emit('error', this.errorMessage);
        return;
      }

      this.success();
    },

    success() {
      const fromAmountDisplay = getDisplayAmount(this.model.FromCurrencyId, this.model.FromAmount);
      const exchangeAmountDisplay = getDisplayAmount(this.model.ToCurrencyId, this.model.ExchangeAmount);

      this.$modalCloseAll();
      this.structure.showHistory = true;
      this.structure.buttonName = 'myInfo.viewAccounts';
      this.structure.backRoute = 'NpMyAccounts';

      this.$emit('success', 'myInfo.transferSuccess', { fromAmount: fromAmountDisplay, exchangeAmount: exchangeAmountDisplay });
    },

    async getCurrencyRate() {
      if (this.CurrencyFrom === this.model.FromCurrencyId && this.CurrencyTo === this.model.ToCurrencyId) return;
      if (!this.model.FromCurrencyId || !this.model.ToCurrencyId) return;
      if (this.model.FromCurrencyId === this.model.ToCurrencyId) return;

      const payload = {
        CurrencyFrom: this.model.FromCurrencyId,
        CurrencyTo: this.model.ToCurrencyId,
      };

      this.CurrencyFrom = this.model.FromCurrencyId;
      this.CurrencyTo = this.model.ToCurrencyId;

      const result = await this.$services.player.getExchangeRate(payload);

      if (result.error) {
        this.model.ExchangeLate = 1;
        return;
      }

      this.model.ExchangeLate = result.ExchangeRate;
      if (this.model.FromAmount)
        this.model.ExchangeAmount = this.model.FromAmount * result.ExchangeRate;
    },
  },
  mounted() {
    this.structure.title = this.$myinfo.title;
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-balacne-transfer] {
  .current-account { .bgc(#fff); .p(20);
    .footer {.flex(); .flex-dr(); .gap(10);}
  }
}
</style>