<template>
  <div verify-sumsub>
    <div class="view-holder">
      <div v-if="!hideSdk" id="sumsub-websdk-container"/>
      <component v-if="statusView" :is="statusView" :data="callbackData" :modelInfo="modelInfo" :item="item" @confirm="confirm" />
      <ProgressButton v-if="isVerificationFailed" button-name="close" @click="confirm('close')" />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { checkClientPermission } from '@/utils/clientUtil';
import SumsubController from '@/plugins/sumsubController';
import baseTemplate from '@/mixins/baseTemplate';
import SumsubStart from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubStart.vue';
import SumsubPending from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubPending.vue';
import SumsubReviewing from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubReviewing.vue';
import SumsubReCheck from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubRecheck.vue';
import SumsubTryCountOver from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubTryCountOver.vue';
import SumsubServiceAbort from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubServiceAbort.vue';
import SumsubDocumentInvalid from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubDocumentInvalid.vue';
import SumsubCertificationFailed from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubCertificationFailed.vue';
import SumsubFailed from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubFailed.vue';
import { sumsubStatus } from '@/constants/base/onboarding/kyc';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'VerifySumsub',
  components: { ProgressButton, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      sumsub: null,
      info: null,
      status: null,
      reviewAnswer: null,
      reviewRejectType: null,
      errorCode: null,
      viewList: [
        { template: SumsubStart, status: sumsubStatus.None },
        { template: SumsubPending, status: sumsubStatus.Pending },
        { template: SumsubReviewing, status: sumsubStatus.Reviewing },
        { template: SumsubServiceAbort, status: sumsubStatus.ServiceAbort },
        { template: SumsubFailed, status: sumsubStatus.Failed },
        { template: SumsubReCheck, status: sumsubStatus.ReCheck }, // 차후 기획 추가 시 활용 예정
        { template: SumsubTryCountOver, status: sumsubStatus.TryCountOver }, // 차후 기획 추가 시 활용 예정
        { template: SumsubCertificationFailed, status: sumsubStatus.CertificationFailed }, // 차후 기획 추가 시 활용 예정
        { template: SumsubDocumentInvalid, status: sumsubStatus.DocumentInvalid },
      ],
    };
  },
  computed: {
    site: state('env', 'site'),
    /**
     * status에 따른 component 반환
     * @returns {*}
     */
    statusView() {
      return this.viewList.find(o => o.status === this.status)?.template;
    },
    /**
     * Sumsub SDK 랜더링 대기 상태 여부
     * @returns {boolean}
     */
    isReady() {
      return this.status === sumsubStatus.Ready;
    },
    /**
     * Sumsub 최종 성공 여부
     * @returns {boolean}
     */
    isVerified() {
      return this.status === sumsubStatus.Verified;
    },

    isReviewing() {
      return this.status === sumsubStatus.Reviewing;
    },
    /**
     * Sumsub SDK 내에서의 최종 성공 여부
     * @returns {boolean}
     */
    isVerificationSuccessful() {
      return this.reviewAnswer === 'GREEN';
    },
    /**
     * Sumsub SDK 내에서의 최종 실패 여부
     * @returns {boolean}
     */
    isVerificationFailed() {
      return this.reviewAnswer === 'RED' && this.reviewRejectType === 'FINAL';
    },
    hideSdk() {
      return [sumsubStatus.Pending, sumsubStatus.Reviewing, sumsubStatus.Failed].includes(this.status);
    },
    callbackData() {
      return this.info?.data;
    }
  },
  watch: {
    hideSdk() {
      if(this.hideSdk) this.modelInfo.progress = true;
    },
  },
  methods: {
    /**
     * header에 Sumsub script 추가
     * https://developers.sumsub.com/web-sdk/#standalone-working-example
     */
    async appendStandaloneSumsubScript() {
      const script = document.createElement("script");
      script.src = process.env.VUE_APP_SUMSUB_WEB_SDK;
      script.async = false;
      document.head.appendChild(script);
    },
    /**
     * init Sumsub SDK
     * @returns {Promise<void>}
     */
    initSumsub() {
      if(typeof window === 'undefined' || !window.snsWebSdk) {
        console.log('sumsub webSdk not found.', window, !window.snsWebSdk);
        return;
      }
      const snsWebSdkInstance = window.snsWebSdk
        .init(this.info.accessToken, () => this.sumsub.getAccessToken())
        .withConf({ lang: this.lang?.substring(0, 2) }) // ISO 639-1 format을 맞추기위해 앞에 2글자로 자름(https://developers.sumsub.com/faq/#what-languages-does-sdk-support)
        .withOptions({ addViewportTag: true, adaptIframeHeight: true })
        .on('idCheck.onReady', payload => {})
        .on('idCheck.onInitialized', payload => {})
        .on('idCheck.onStepInitiated', payload => {})
        .on('idCheck.onApplicantLoaded', payload => this.sumsub.setApplicantId(payload?.applicantId))
        .on('idCheck.onApplicantSubmitted', payload => {})
        .on('idCheck.onApplicantResubmitted', payload => {})
        .on('idCheck.stepCompleted', payload => {})
        .on('idCheck.onUploadWarning', warning => console.log('::::::::::::::::initSumsub onUploadWarning', warning))
        .on('idCheck.onUploadError', uploadError => console.log('::::::::::::::::initSumsub onUploadError', uploadError))
        .on('idCheck.onError', error => {
          console.log('::::::::::::::::initSumsub onError', error);
          this.errorCode = error.code;
          if (this.errorCode === 'camera-error') this.sumsub.updateStatus(sumsubStatus.CameraFailed);
          else this.sumsub.updateStatus(sumsubStatus.Failed);
        })
        .on('idCheck.onResize', payload => {})
        .on('idCheck.onApplicantStatusChanged', payload => {
          console.log('::::::::::::::::initSumsub onApplicantStatusChanged', payload);
          if (!payload || payload?.reviewStatus !== 'completed') return;
          this.reviewRejectType = payload.reviewResult?.reviewRejectType;
          // reviewRejectType이 없으면 성공, reviewRejectType이 있는데 FINAL로 넘어올 경우만 실패 값 저장
          if (this.reviewRejectType !== 'RETRY') this.reviewAnswer = payload.reviewResult?.reviewAnswer;
          if (this.isVerificationSuccessful) setTimeout(this.sumsub.updateStatus(sumsubStatus.Pending), 3000);
        })
        .onMessage((type, payload) => {})
        .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    },
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%csumsub callback', 'color:yellow', info);
      this.status = info.status;
      this.info = info;

      if(this.isReady) {
        if (this.info?.accessToken) this.initSumsub();
        else this.status = sumsubStatus.Failed; // unKnownError 로 간주
      }
      else if (this.isVerified) {
        if(!this.statusView) {
          this.modelInfo.step = 1;
        } else {
          this.modelInfo.progress = false;
          this.modelInfo.replace = true;
        }
      }
      // else if (this.isVerified || this.isReviewing) this.modelInfo.replace = true;
    },
    /**
     * 버튼 클릭 시 동작에 대한 정의
     * Begin, ReCheck, ReCheckLimit, Failed 외에 버튼 활성화 X
     * @returns {Promise<void>}
     */
    async confirm(action) {
      !action ? this.sumsub.updateStatus(sumsubStatus.Ready) : this.sumsub.updateStatus(this.status, action);
    },
    /**
     * 페이지 이탈 시 초기화
     */
    destroy() {
      if (this.sumsub) this.sumsub.initialize();
      this.sumsub = null;
    },
  },
  async mounted() {
    await checkClientPermission(this);
    if (this.item) this.item.title = 'onBoarding.title.identityVerification';
    const { $services: services, site, lang, callback, modelInfo } = this;
    this.sumsub = new SumsubController(this, { services, site, lang, callback, modelInfo });
    this.appendStandaloneSumsubScript();
  },
  beforeDestroy() {
    this.destroy();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-sumsub] {
  .view-holder { .rel();
  }
}
</style>