<template>
  <div verify-surepay>
    <p v-html="$t('_.surepayStepDescription', {csEmail})"></p>
    <ValidationComponent v-model=modelInfo.IbanNumber name="_iban" :component="TextInput" rules="required" label="IBAN" :preset="{ maxLength:18 }"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerifySurePay',
  lexicon: 'onBoarding',
  mixins: [baseTemplate],
  components: { ValidationComponent },
    data() {
    return {
      TextInput,
      IbanCountryConfigs: [],
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-surepay]{
  > * { .mb(20); .c(#d8d8d8);
    a { .c(@c-link); }
  }
}
</style>
