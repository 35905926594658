<template>
  <div input-deposit-limit-table>
    <LimitTable>
      <template #body>
        <LimitTableRow v-if="!isGGPBE" :hidden-limit="isDailyCashierLimit" no-limit-name="DailyNoLimit" :rules="day1Rules" :no-limit="model.DailyNoLimit" :label="dailyLabel" current-name="currentDailyLimit" v-model="model.RequestDailyDepositLimit" :current-value="dailyCurrentAmount" new-name="dailyDepositLimit" :preset="preset" @checked="checked" />
        <LimitTableRow :hidden-limit="isWeeklyCashierLimit" no-limit-name="WeeklyNoLimit" :rules="day7Rules" :no-limit="model.WeeklyNoLimit" :label="weeklyLabel" current-name="currentWeeklyLimit" v-model="model.RequestWeeklyDepositLimit" :current-value="weeklyCurrentAmount" new-name="weeklyDepositLimit" :preset="preset" @checked="checked" />
        <LimitTableRow v-if="!isGGPBE" :hidden-limit="isMonthlyCashierLimit" no-limit-name="MonthlyNoLimit" :rules="day30Rules" :no-limit="model.MonthlyNoLimit" :label="monthlyLabel" current-name="currentMonthlyLimit" v-model="model.RequestMonthlyDepositLimit" :current-value="monthlyCurrentAmount" new-name="monthlyDepositLimit" :preset="preset" @checked="checked" />
      </template>
    </LimitTable>
  </div>
</template>

<script>
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
import LimitTable from '@/views/components/pages/responsible-gaming/template/common/LimitTable.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { makePrice } from '@shared/utils/currencyUtils.mjs';
import { useLugasSiteIds } from '@/constants/base/responsible-gaming';
export default {
  name: 'InputDepositLimitTable',
  lexicon: 'onBoarding.setting',
  components: { LimitTable, LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
    rolling: { type: String, default: false },
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      tableHeader: ['', 'currentLimit', 'newLimit'],
      maxLimit: 1000000000,
      externalMonthlyMax: 1000,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    site: state('env', 'site'),

    isRolling() {
      return this.rolling === 'Rolling';
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGGPUK() {
      return this.site === siteIds.GGPUK;
    },
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    isEnableLugas() {
      return useLugasSiteIds.includes(this.site);
    },

    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('daily');
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('weekly');
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('monthly');
    },
    dailyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentDailyDepositLimit);
    },
    weeklyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentWeeklyDepositLimit);
    },
    monthlyCurrentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentMonthlyDepositLimit);
    },

    day1Limit() {
      return this.current.Day1Limit;
    },
    day7Limit() {
      return this.current.Day7Limit;
    },
    day30Limit() {
      return this.current.Day30Limit;
    },

    day1Rules() {
      let rules = [`rgMin:0`, `sameLimit:${this.current.CurrentDailyDepositLimit}`];
      if (this.isGGPDE && !this.model.DailyNoLimit) {
        // rules.push('requiredNone');
        rules.push(`depositLimitDE:${this.currencySymbol},${this.csEmail},${this.externalMonthlyMax},${makePrice(this.externalMonthlyMax)}`);
      }
      return rules.join('|');
    },
    day7Rules() {
      let rules = [`rgMin:0`, `sameLimit:${this.current.CurrentWeeklyDepositLimit}`];
      if (this.isGGPDE && !this.model.WeeklyNoLimit) {
        // rules.push('requiredNone');
        rules.push(`depositLimitDE:${this.currencySymbol},${this.csEmail},${this.externalMonthlyMax},${makePrice(this.externalMonthlyMax)}`);
      }
      return rules.join('|');
    },
    day30Rules() {
      let rules = [`rgMin:0`, `sameLimit:${this.current.CurrentMonthlyDepositLimit}`];
      if (this.isGGPDE && !this.model.MonthlyNoLimit) {
        // rules.push('requiredNone');
        rules.push(`depositLimitDE:${this.currencySymbol},${this.csEmail},${this.externalMonthlyMax},${makePrice(this.externalMonthlyMax)}`);
      }
      return rules.join('|');
    },

    preset() {
      return { placeholder: this.$t('amount'), textAlign: 'right', currencySymbol: this.currencySymbol, type: 'number', maxLength: 9 };
    },
    isDailyCashierLimit() {
      if (this.isGGPUK) return true;
      return this.current.CurrentDailyDepositLimit !== null && this.current.CurrentDailyDepositLimit <= this.day1Limit && this.day1Limit < this.maxLimit;
    },
    isWeeklyCashierLimit() {
      if (this.isGGPUK) return true;
      return this.current.CurrentWeeklyDepositLimit !== null && this.current.CurrentWeeklyDepositLimit <= this.day7Limit && this.day1Limit < this.maxLimit;
    },
    isMonthlyCashierLimit() {
      if (this.isGGPUK) return true;
      return this.current.CurrentMonthlyDepositLimit !== null && this.current.CurrentMonthlyDepositLimit <= this.day30Limit && this.day1Limit < this.maxLimit;
    },
  },
  methods: {
    createCurrentDisplayAmount(value) {
      if (!value) return null;
      return `${this.currencySymbol}${value}`;
    },
    checked(name, v) {
      this.model[name] = v;
    },
  },



};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>