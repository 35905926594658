<template>
  <ValidationProvider ref="refObserver" :class="[theme]" :immediate="immediate" validation-password :name="name" :rules="rules" v-slot="{errors}" :slim="true">
    <p v-if="theme==='white'" class="label">
      <label :class="{'mandatory': isMandatory, 'label-color': labelColorGray}">{{ label }}</label>
    </p>
    <div :class="['holder','input-field',{'error': value && errors.length, },{'focus': isFocus, 'disabled': preset.disable }]">
      <p v-if="theme !=='white'" class="label">
        <label :class="{'mandatory': isMandatory, 'label-color': labelColorGray}">{{ label }}</label>
      </p>
      <div class="field" @click="click">
        <PasswordInput ref="component" class="component" :theme="theme"
                       :props="() => preset" :max-length="preset.maxLength" :disabled="preset.disable" :placeholder="preset.placeholder" :auto="preset.auto" :my-info="preset.myinfo"
                       v-model="model" @input="updateValue" @update="v => $emit('update',v)" @updateSearch="v => $emit('updateSearch',v)" @focus="setFocus" @blur="focusOut" @delete="$emit('delete')" />
      </div>
    </div>
    <ValidationErrors class="error-msg" v-if="value && isValid && (pwErrorMsg || (rules && !['requiredNone', 'minMaxNone', 'requiredSelectNone'].some(rule => rules.includes(rule))) && errors && errors.length)" :errors="errorInterceptors(errors)" :errorMsg="pwErrorMsg" />
  </ValidationProvider>
</template>

<script>
import Specific from '@shared/types/Specific';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'ValidationPassword',
  components: { PasswordInput },
  props: {
    value: Specific,
    config: Specific,
    name: { type: String, required: true },
    rules: { type: String, default: null },
    preset: { type: Specific, default: () => ({}) },
    labelColorGray: { type: Boolean, default: true },
    label: { type: String, default: null },
    errorMsg: { type: String, default: null },
    theme: { type: String, default: 'dark transparent' },
    visibleMandatory: { type: Boolean, default: true },
    immediate: { type: Boolean, default: false },
  },
  data() {
    return {
      model: null,
      isFocus: false,
      validateErrorMsg: null,
      configErrorMsg: '',
    };
  },
  computed: {
    isValid() {
      if (this.model === null || this.model === undefined) return false;
      if (Array.isArray(this.model) && this.model.length === 0) return false;
      if (this.model === false) return false;
      return !!String(this.model).trim().length;
    },
    isMandatory() {
      return this.label && this.rules && (this.rules.includes('required') || this.rules.includes('length:8,20')) && this.visibleMandatory;
    },
    hasErrorMsg() {
      return Boolean(this.errorMsg?.length);
    },
    passwordValidateModel() {
      return {
        passwordIncludeBothUpperLowerCase: `<li>- ${this.$t('validation.rules.passwordIncludeBothUpperLowerCase')}</li>`,
        passwordIncludeAlphabet: `<li>- ${this.$t('validation.rules.passwordIncludeAlphabet')}</li>`,
        passwordIncludeNumber: `<li>- ${this.$t('validation.rules.passwordIncludeNumber')}</li>`,
        passwordIncludeSpecialCharacter: `<li>- ${this.$t('validation.rules.passwordIncludeSpecialCharacter')}</li>`,
      };
    },
    passwordRuleModel() {
      return {
        includeBothUpper: 'passwordIncludeBothUpperLowerCase',
        includeAlphabet: 'passwordIncludeAlphabet',
        includeNumber: 'passwordIncludeAlphabet',
        includeSpecialChar: 'passwordIncludeSpecialCharacter'
      };
    },
    pwErrorMsg() {
      if (this.errorMsg) return this.errorMsg;
      return this.validateErrorMsg;
    },

    configErrorMessage() {
      if (!this.config) return '';

      const messageList = Object.keys(this.config).filter(key => this.config[key] && this.passwordValidateModel.hasOwnProperty(key)).map(key => {
        return this.passwordValidateModel[key];
      });

      let errorMessage = `
        <div class="error-exp">
          <h5>${this.$t('validation.rules.passwordTitle')}</h5>
          <ul>
            ${messageList.join('')}
          </ul>
        </div>`;

      return errorMessage;
    },
  },
  watch: {
    value: 'updateModel',
    errorMsg() {
      if (this.autoFocus && this.hasErrorMsg) this.$el.querySelector('input').focus();
    },
  },
  methods: {
    clear() {
      this.$refs.refObserver.reset();
    },
    updateModel() {
      this.model = this.value;
    },
    updateValue(v) {
      this.model = v;
      this.$emit('input', v);
    },
    setFocus() {
      if(this.isFocus) return;
      this.isFocus = true;
      this.$emit('focus');
    },
    focusOut() {
      if(!this.isFocus) return;
      this.isFocus = false;
      this.$emit('blur');
      this.$emit('input', this.model);
    },
    createConfigErrorMessage() {
      if (!this.config) return '';

      const messageList = Object.keys(this.config).filter(key => this.config[key] && this.passwordValidateModel.hasOwnProperty(key)).map(key => {
        return this.passwordValidateModel[key];
      });

      let errorMessage = `
        <div class="error-exp">
          <h5>${this.$t('validation.rules.passwordTitle')}</h5>
          <ul>
            ${messageList.join('')}
          </ul>
        </div>`;

      return errorMessage;
    },
    errorInterceptors(errors) {
      if (errors.length <= 0) {
        this.validateErrorMsg = null;
        return errors;
      }
      const error = errors[0];
      if (this.passwordRuleModel.hasOwnProperty(error._rule_)) {
        this.validateErrorMsg = this.configErrorMessage;
        return null;
      } else {
        this.validateErrorMsg = null;
        return errors;
      }
    },

    click(e) {
      this.isFocus = true;
      if(e.target.children?.length) Array.prototype.forEach.call(e.target.children, o => {
        const name = o.attributes?.[0]?.name;
        if(name === 'search-drop-select') o.click();
        else if(name === 'text-input') o.querySelector('input').focus();
      });
    },
  },
  mounted() {
    // this.configErrorMsg = this.createConfigErrorMessage();
    this.updateModel();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[validation-password] { .pointer();
  &.dark {
    .holder { .wh(100%, 68); .rel();
      > .label { .abs(); .lt(0, 4);
        > label { pointer-events: none; .pl(12); .fs(13); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat();
          &.mandatory:after { content: '*'; .c(@c-red); .ml(4);}
          &.label-color { .c(#AFAFAF); }
        }
      }
      &.error { .-a(@c-red, 2);
        > label { .c(@c-red); }
      }
      &.focus, &:focus { .-a(@c-yellow, 2); .bgc(rgba(39, 39, 39, 0.2)) !important;}
      > label { .pl(12); .fs(13); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat();
        &.mandatory:after { content: '*'; .c(@c-red); .ml(4);}
        &.label-color { .c(#AFAFAF); }
      }
      &.disabled { .bgc(@c-w01); box-shadow: none; cursor: not-allowed; }
      > .field { .h(100%);
        > * { .h(100%); .pt(28); }
      }
    }
  }
  &.white {
    .label { .fs(14); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat(); .pl(3);.mb(8);
      .mandatory:after { content: '*'; .c(@gp-green); .ml(1);}
      .label-color { .c(#737373); }
    }
    .input-field {.min-h(40); box-shadow: none; border: none; background-color: white; border-radius: 8px;}

    .error-msg {.ml(3);}
    .holder { .wh(100%, 40);
      &.error {
        > label { .c(@c-red); }
      }
      &.focus, &:focus {}
      > label { .pl(12); .fs(13); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat();
        &.mandatory:after { content: '*'; .c(@c-red); .ml(4);}
        &.label-color { .c(#AFAFAF); }
      }
      &.disabled { .bgc(#d4d4d8); box-shadow: none; cursor: not-allowed; .c(#a1a1aa) }

      [password-input] {.h(40);
        > span {.h(100%); .br(8); border: none;
          > input{
            &::placeholder{color:@gp-placeholder-color !important;}
          }
        }
        > span::after {content: none}

      }
    }
  }
}
</style>