export default class PlayerService {
  #services;
  #api;
  #rgConfig;
  #deStateList;
  constructor(services) {
    this.#services = services;
    this.#api = services.userApi;
  }

  /**
   * @id EditPlayerMyInfoRequest
   * @param FirstName {string}
   * @param LastName {string}
   * @param MaidenName {string}
   * @param MiddleName {string}
   * @param Alias {string}
   * @param PlaceOfBirth {string}
   * @param DateOfBirth {string}
   * @param Address {string}
   * @param Building {string}
   * @param StreetName {string}
   * @param StreetNumber {string}
   * @param HouseNumber {string}
   * @param City {string}
   * @param PostalCode {string}
   * @param State {string}
   * @param Nationality {string}
   * @param Gender {string}
   * @param EmploymentType {string}
   * @param IndustryType {string}
   * @param OccupationType {string}
   * @param IsMarketingAgreement {boolean}
   * @param EmployerName {string}
   * @param EmployerAddress {string}
   * @param BusinessPhoneNumber {string}
   */
  editPlayerMyInfo({ FirstName, LastName, MaidenName, MiddleName, Alias, PlaceOfBirth, DateOfBirth, Address, Building, StreetName, StreetNumber, HouseNumber, State, City, PostalCode, Nationality, Gender, EmployerAddress, EmploymentType, EmployerName, IndustryType, OccupationType, IsMarketingAgreement, BusinessPhoneNumber }) {
    try {
      return this.#api.patch('/player/myinfo', { FirstName, LastName, MaidenName, MiddleName, Alias, PlaceOfBirth, DateOfBirth, Address, Building, StreetName, StreetNumber, HouseNumber, State, City, PostalCode, Nationality, Gender, EmployerAddress, EmploymentType, EmployerName, IndustryType, OccupationType, IsMarketingAgreement, BusinessPhoneNumber });
    } catch (e) {
      console.log('error : editPlayerMyInfo');
      throw e;
    }
  }

  /**
   * @id EditMobileNumberRequest
   * @returns {*}
   */
  async editMobileNumber({ CountryCode, MobileNumber, VerificationCode, Kind }) {
    try {
      const r = await this.#api.patch('/player/mobile', { CountryCode, MobileNumber, VerificationCode, Kind });
      return r;
    } catch (e) {
      console.log('error : editMobileNumber');
      throw e;
    }
  }

  /**
   * @id ChangePasswordRequest
   * @returns {*}
   */
  async changePassword({ Password, OldPassword }) {
    try {
      const r = await this.#api.post('/player/password', { Password, OldPassword });
      return r;
    } catch (e) {
      console.log('error : changePassword');
      throw e;
    }
  }
  /**
   * @id ChangeFundPasswordRequest
   * @returns {*}
   */
  async changeFundPassword({ FundPassword, CurrentFundPassword, IsEnableFundPassword }) {
    try {
      const r = await this.#api.post('/player/fundpassword', { FundPassword, CurrentFundPassword, IsEnableFundPassword });
      return r;
    } catch (e) {
      console.log('error : changeFundPassword');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingDepositLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleDepositLimit({ RequestDailyDepositLimit, RequestWeeklyDepositLimit, RequestMonthlyDepositLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/depositlimit', { RequestDailyDepositLimit, RequestWeeklyDepositLimit, RequestMonthlyDepositLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleDepositLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingTotalBetLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleTotalBetLimit({ RequestDailyTotalBetLimit, RequestWeeklyTotalBetLimit, RequestMonthlyTotalBetLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/totalbetlimit', { RequestDailyTotalBetLimit, RequestWeeklyTotalBetLimit, RequestMonthlyTotalBetLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleTotalBetLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingLossLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleLossLimit({ RequestDailyLossLimit, RequestWeeklyLossLimit, RequestMonthlyLossLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/losslimit', { RequestDailyLossLimit, RequestWeeklyLossLimit, RequestMonthlyLossLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleLossLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingSingleBetLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleSingleBetLimit({ RequestSingleBetLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/singlebetlimit', { RequestSingleBetLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleSingleBetLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingBalanceLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleBalanceLimit({ RequestBalanceLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/balancelimit', { RequestBalanceLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleBalanceLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingLoginTimeLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleLoginTimeLimit({ RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit, RequestType }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/logintimelimit', { RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit, RequestType });
      return r;
    } catch (e) {
      console.log('error : setResponsibleLoginTimeLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingReLoginTimeLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleReLoginTimeLimit({ RequestReLoginTimeLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/relogintimelimit', { RequestReLoginTimeLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleReLoginTimeLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingMonthlyLoginFrequencyConfigRequest
   * @returns {*}
   */
  async setResponsibleMonthlyLoginFrequency({ RequestMonthlyLoginFrequency }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/monthlyloginfrequency', { RequestMonthlyLoginFrequency });
      return r;
    } catch (e) {
      console.log('error : setResponsibleMonthlyLoginFrequency');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingGameLimitConfigRequest
   * @returns {*}
   */
  async setResponsibleGameLimit({ RequestPokerGameLimit, RequestCasinoGameLimit, RequestLiveDealerGameLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/gamelimit', { RequestPokerGameLimit, RequestCasinoGameLimit, RequestLiveDealerGameLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleGameLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingRealityCheckNotificationConfigRequest
   * @returns {*}
   */
  async setResponsibleRealityCheckNotification({ RequestInterval }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/realitychecknotification', { RequestInterval });
      return r;
    } catch (e) {
      console.log('error : setResponsibleRealityCheckNotification');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingAutomaticReserveWinConfigRequest
   * @returns {*}
   */
  async setResponsibleAutomaticReserveWin({ RequestReserveWinLimit }) {
    try {
      const r = await this.#api.put('/player/rg/configuration/automaticreservewin', { RequestReserveWinLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleAutomaticReserveWin');
      throw e;
    }
  }
  /**
   * @id setResponsibleGamingSelfExclusionConfigRequest
   * @returns {*}
   */
  async setResponsibleSelfExclusion({ RequestSelfExclusionPeriodType, OasisBanCauses, CustomSelfExclusionPeriodDay }) {
    try {
      const r = await this.#api.post('/player/rg/configuration/selfexclusion', { RequestSelfExclusionPeriodType, OasisBanCauses, CustomSelfExclusionPeriodDay });
      return r;
    } catch (e) {
      console.log('error : etResponsibleSelfExclusion');
      throw e;
    }
  }
  /**
   * @id GetPlayerKycDocumentsRequest
   * */
  async getKycDocuments() {
    try {
      const r = await this.#api.get('/player/kyc/documents');
      return r;
    } catch (e) {
      console.log('error : getKycDocuments', e);
      throw e;
    }
  }
  /**
   * @id GetPaymentAccountVerifyStatusRequest
   * */
  async getPaymentAccountVerifyStatus() {
    try {
      const r = await this.#api.get('/payment/account/verify');
      return r;
    } catch (e) {
      console.log('error : getKycDocuments', e);
      throw e;
    }
  }


  /**
   * @id GetDEStateListInfoRequest
   * */
  async getDeState() {
    try {
      if (!this.#deStateList) this.#deStateList = await this.#api.get('/player/login/destatelist');
      return this.#deStateList;
    } catch (e) {
      console.log('error : getDeState', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerRGTransactionHistoryRequest
   * */
  async getPlayerRGTransactionHistoryRequest(payload) {
    try {
      const result = await this.#api.get('/player/rg_history', payload);
      return result;
    } catch (e) {
      console.log('error : getPlayerRGTransactionHistoryRequest', e);
      throw e;
    }
  }
  /**
   * @id GetRGLossLimitHistoryRequest
   * */
  async getRGLossLimitHistoryRequest({ AgentName, UserId }) {
    try {
      const result = await this.#api.get('/player/rg/configuration/losslimithistory', { AgentName, UserId });
      return result;
    } catch (e) {
      console.log('error : GetRGLossLimitHistoryRequest', e);
      throw e;
    }
  }
  /**
   * @id GetDepositLimitRequest
   * */
  async getPaymentDepositLimit() {
    try {
      const result = await this.#api.get('/payments/deposits/limits');
      return result;
    } catch (e) {
      console.log('error : GetRGLossLimitHistoryRequest', e);
      throw e;
    }
  }
  /**
   * @id GetResponsibleGamingSelfExclusionConfigRequest
   * */
  async getSelfExclusionType() {
    try {
      const result = await this.#api.get('/player/rg/configuration/selfexclusion', null, { silent: true });
      return result;
    } catch (e) {
      console.log('error : getSelfExclusionType', e);
      throw e;
    }
  }

  /**
   * @id SendMobileVerificationCodeRequest

   * */
  async sendEmailVerification({ VerificationType, Email, UserId, Language = 'en', Kind, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, }) {
    try {
      const r = await this.#api.post('/player/email/verification', { VerificationType, Email, UserId, Language, Kind, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, });
      const verificationCode = r?.VerificationCode;
      if (verificationCode && ['local', 'test'].includes(this.#services.store.state.env.env)) alert(verificationCode);
      return r;
    } catch (e) {
      if (e?.code === apiErrorCode.PHONE_RESEND_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @id VerifyAndEditEmailRequest

   * */
  async editEmail({ Email, Kind, VerificationCode, IsContactUpdate }) {
    try {
      const r = await this.#api.patch('/player/email', { Email, Kind, VerificationCode, IsContactUpdate });
      // const verificationCode = r?.VerificationCode;
      // if (verificationCode && ['local', 'test'].includes(this.#services.store.state.env.env)) alert(verificationCode);
      return r;
    } catch (e) {
      if (e?.code === apiErrorCode.PHONE_RESEND_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @id GetExchangeRateCurrencyToCurrencyRequest

   * */
  async getExchangeRate({ CurrencyFrom, CurrencyTo }) {
    try {
      const r = await this.#api.get(`/payments/exchangerates/${CurrencyFrom}/${CurrencyTo}`);
      return r;
    } catch (e) {
      console.log('error : getExchangeRate', e);
      throw e;
    }
  }

  async playerTokenValidate() {
    try {
      const r = await this.#api.get('/player/tokenvalidate');
    } catch (e) {
      console.log('error : playerTokenValidate', e);
      return e;
    }
  }
  /**
   * @id UpdateContactWithLoginIdRequest
   * @description GGPass A type일때 Update Contact Email / Mobile 전용
   * */
  async updateContactWithLoginId(IdType) {
    try {
      const r = await this.#api.patch('/player/contact/update-by-id', { IdType });
    } catch (e) {
      console.log('error : updateContactWithLoginId', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerKycUploadStatusInfoRequest
   * @param {boolean} IsConsiderKycStatusWhenKycNotRequested
   * @returns {Promise<PlayerKycStatus>}
   * */
  async getUploadDocuments(IsConsiderKycStatusWhenKycNotRequested = true) {
    try {
      return await this.#api.get('/player/kyc/upload-statusinfo', { IsConsiderKycStatusWhenKycNotRequested }, { silent: true });
    } catch (e) {
      console.log('error : getPlayerKycUploadStatus', e);
      throw e;
    }
  }
}