<template>
  <div deposit-bonus>
    <p v-if="noActiveBonus" class="no-active-bonus">{{$t('promotion.noActiveBonus')}}</p>
    <template v-else>
    <div v-if="notifications && notifications.length > 0">
      <Notification v-for="notification in notifications" :info="notification" :key="notification.name" />
    </div>
    <div class="contents">
      <div class="section-holder">
        <div>
          <NoPromotionData :status-info="depositInfo" v-if="!item" />
          <template v-else>
            <BonusStatus :status-info="depositInfo" @reload="updateInfo" />
            <div>
              <MatchBonus :status-info="depositInfo" v-if="matchBonus" />
              <template v-else>
                <FeatureBonus :status-info="depositInfo" />
                <DailyFreeChallenge :status-info="depositInfo" />
              </template>
            </div>
          </template>
        </div>
        <BonusHistory :playerPromotionId="playerPromotionId" />
      </div>
    </div>
    </template>
  </div>
</template>

<script>
import FeatureBonus from '@/views/components/pages/promotion/depositBonus/FeatureBonus.vue';
import BonusStatus from '@/views/components/promotion/BonusStatus.vue';
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import DailyFreeChallenge from '@/views/components/pages/promotion/depositBonus/DailyFreeChallenge.vue';
import MatchBonus from '@/views/components/pages/promotion/depositBonus/MatchBonus.vue';
import NoPromotionData from '@/views/components/promotion/NoPromotionData.vue';
import Notification from '@/views/components/promotion/Notification.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'DepositBonus',
  components: { Notification, NoPromotionData, DailyFreeChallenge, BonusHistory, BonusStatus, FeatureBonus, MatchBonus },
  data() {
    return {
      depositInfo: null,
      noActiveBonus: false,
    };
  },
  computed: {
    notifications() { return this.$promotion.getNotifications('deposit', { info: this.depositInfo }); },
    item() { return this.depositInfo?.item; },
    matchBonus() { return !!this.item?.matchBonuses?.[0]; },
    playerPromotionId() {
      return this.depositInfo?.playerPromotionId;
    },
    brand: state('env', 'brand'),
  },
  methods: {
    async updateInfo() {
      const r = await this.$services.promotion.depositPromotion();
      if (r == null) return this.noActiveBonus = true;
      if (r?.error) {
        await this.replaceRouteName(r?.path);
      } else {
        this.depositInfo = r;
      }
    },
  },
  async mounted() {
    await this.updateInfo();
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[deposit-bonus] { .wf();
  .no-active-bonus { .tc(); .mt(60); }
  .contents {
    .info-btn { .w(28); .h(28); .p(4); .mh(8); .bgc(); }
  }
}
</style>
