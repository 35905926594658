<template>
  <GpFormTemplate login-time-limit>
    <template #header>
      <GpDescription v-if="description" :message="pageDescription" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
      <CautionMessage :message="cautionMessage" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('_.verifyFreeze.message')" />
    </template>
    <template v-if="isLoaded" #content>
      <InputLoginTimeLimit :rolling="rolling" :model="model" :current="current" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputLoginTimeLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputLoginTimeLimit.vue';
import ResponsibleHangTimeConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleHangTimeConfirmModal.vue';
import Specific from '@shared/types/Specific';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';
import CautionMessage from '@/views/components/pages/responsible-gaming/template/common/CautionMessage.vue';
export default {
  name: 'RgLoginTimeLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputLoginTimeLimit, GpFormTemplate, GpDescription, GpButton, CautionMessage },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      countryCode: null,
      errorMsg: null,
      rolling: null,
      current: {
        CurrentDailyLoginTimeLimit: null,
        CurrentWeeklyLoginTimeLimit: null,
        CurrentMonthlyLoginTimeLimit: null,
        DailyLoginTimeLimitApplyAt: null,
        WeeklyLoginTimeLimitApplyAt: null,
        MonthlyLoginTimeLimitApplyAt: null,
      },
      model: {
        RequestDailyLoginTimeLimit: null,
        RequestWeeklyLoginTimeLimit: null,
        RequestMonthlyLoginTimeLimit: null,
        RequestType: 1,
      },
      isLoaded: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'daily', current: this.current.CurrentDailyLoginTimeLimit, request: this.model.RequestDailyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
        { type: 'weekly', current: this.current.CurrentWeeklyLoginTimeLimit, request: this.model.RequestWeeklyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
        { type: 'monthly', current: this.current.CurrentMonthlyLoginTimeLimit, request: this.model.RequestMonthlyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
      ];
    },
    pageDescription() {
      // dailyPlayLimit
      let desc = null;
      // if (!['CZ'].includes(this.countryCode)) {
      //   if (this.rolling === 'Rolling') {
      //     desc = this.$t('_.description.dailyPlayLimit');
      //   }
      //   if (this.rolling === 'Calendar' && this.structure.config.verifyFreeze) {
      //     desc = this.$t('_.description.dailyPlayLimit');
      //   } else if (this.rolling === 'Calendar' && !this.structure.config.verifyFreeze) {
      //     desc = this.$t('_.subDescription.hangtimePlayLimit');
      //   }
      // } else {
      //   desc = this.$t('_.description.dailyLoginLimit');
      // }

      if (this.rolling === 'Rolling') {
        desc = this.$t('_.description.dailyPlayLimit');
      }
      if (this.rolling === 'Calendar' && this.structure.config.verifyFreeze) {
        desc = this.$t('_.description.dailyPlayLimit');
      } else if (this.rolling === 'Calendar' && !this.structure.config.verifyFreeze) {
        desc = this.$t('_.description.dailyPlayLimit');
        // desc = this.$t('_.subDescription.hangtimePlayLimit');
      }

      return desc;
    },
    subDescription() {
      // dailyPlayLimit
      let desc = null;
      // if (!['CZ'].includes(this.countryCode)) {
      //   if (this.rolling === 'Rolling') {
      //     desc = this.$t('_.subDescription.dailyPlayLimit', { utcInfo: this.structure.config.timeZoneType });
      //   }
      //   if (this.rolling === 'Calendar') {
      //     desc = this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
      //   }
      // } else {
      //   if (this.rolling === 'Rolling') {
      //     desc = this.$t('_.subDescription.dailyLoginLimit', { utcInfo: this.structure.config.timeZoneType });
      //   }
      //   if (this.rolling === 'Calendar') {
      //     desc = this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
      //   }
      // }

      if (this.rolling === 'Rolling') {
        desc = this.$t('_.subDescription.dailyPlayLimit', { utcInfo: this.structure.config.timeZoneType });
      }
      if (this.rolling === 'Calendar') {
        desc = this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
      }

      return desc;
    },
    cautionMessage() {
      return this.$t('_.caution.dailyLoginLimit');
    },
    disabled() {
      const {
        CurrentDailyLoginTimeLimit,
        CurrentWeeklyLoginTimeLimit,
        CurrentMonthlyLoginTimeLimit,
      } = this.current;
      const {
        RequestDailyLoginTimeLimit,
        RequestWeeklyLoginTimeLimit,
        RequestMonthlyLoginTimeLimit,
      } = this.model;

      let requestDaily = (RequestDailyLoginTimeLimit || parseInt(RequestDailyLoginTimeLimit) === 0) ? parseInt(RequestDailyLoginTimeLimit) : 'custom';
      let requestWeekly = (RequestWeeklyLoginTimeLimit || parseInt(RequestWeeklyLoginTimeLimit) === 0) ? parseInt(RequestWeeklyLoginTimeLimit) : 'custom';
      let requestMonthly = (RequestMonthlyLoginTimeLimit || parseInt(RequestMonthlyLoginTimeLimit) === 0) ? parseInt(RequestMonthlyLoginTimeLimit) : 'custom';

      const sameValue = CurrentDailyLoginTimeLimit === requestDaily && CurrentWeeklyLoginTimeLimit === requestWeekly && CurrentMonthlyLoginTimeLimit === requestMonthly;
      const nullCheck = !requestDaily && requestDaily !== 0 && !requestWeekly && requestWeekly !== 0 && !requestMonthly && requestMonthly !== 0;

      return sameValue || nullCheck;
    },
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        CurrentDailyLoginTimeLimit: this.structure.current.CurrentDailyLoginTimeLimit || null,
        CurrentWeeklyLoginTimeLimit: this.structure.current.CurrentWeeklyLoginTimeLimit || null,
        CurrentMonthlyLoginTimeLimit: this.structure.current.CurrentMonthlyLoginTimeLimit || null
      };
      this.model = {
        ...this.model,
        RequestDailyLoginTimeLimit: this.current.CurrentDailyLoginTimeLimit ? this.current.CurrentDailyLoginTimeLimit.toString() : null,
        RequestWeeklyLoginTimeLimit: this.current.CurrentWeeklyLoginTimeLimit ? this.current.CurrentWeeklyLoginTimeLimit.toString() : null,
        RequestMonthlyLoginTimeLimit: this.current.CurrentMonthlyLoginTimeLimit ? this.current.CurrentMonthlyLoginTimeLimit.toString() : null
      };

      this.rolling = this.structure.config.LoginTimeLimitPeriodType;
      this.countryCode = this.structure.config.CountryCode;

      this.isLoaded = true;
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      return {
        title: this.$t('_.modal.title'),
        subTitle: this.$t(`_.modal.subTitle.${this.structure.config.title}`),
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel: descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze
      };
    },

    openModal() {
      this.$modal(ResponsibleHangTimeConfirmModal, this.createModalOptions());
    },

    async submit(validate) {
      if (validate) {
        const invalid = await validate();
        if (!invalid) return;
      }
      this.openModal();
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';

</style>