<template>
  <div verify-resend :class="[theme]">
    <span v-if="currentTime">{{ currentTime }}s</span>
    <button @click="reSend" :disabled="!!currentTime || progress" :name="name" :class="className">{{ ` ${$t('reSend')} ${isEmail ? $t('email') : $t('sms')}` }}</button>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap';

const DEFAULT_TIME = 59;

export default {
  name: 'VerifyResendButton',
  props: {
    intervalTime: { type: Number, default: 1000 },
    structure: { type: Specific, default: null },
    modelInfo: { type: Specific, default: null, },
    theme: { type: String, default: 'dark' },
    apiType: { type: String, default: 'SIGN_UP' },
    action: { type: String, default: null, },
    name: String,
    className: String,
  },
  watch: {
    currentTime(v) {
      this.structure.limitTime = v;
    }
  },
  data() {
    return {
      initTime: 0,
      currentTime: null,
      intervalId: null,
      testCaptcha: true,
      captcha: null,
      progress: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    isEmail() { return this.modelInfo.SendVerificationType === 'EMAIL'; },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.action);
    },
    startInterval(limitTime) {
      this.intervalId = setInterval(this.loop, this.intervalTime);
      this.currentTime = limitTime;
    },
    clearInterval() {
      clearInterval(this.intervalId);
      this.currentTime = 0;
    },
    loop() {
      const time = this.currentTime - 1;
      this.currentTime = time;
      if (this.currentTime <= 0) {
        this.clearInterval();
      }
    },
    async reSend() {
      this.progress = true;
      try {
        if (this.action) await this.loadCaptcha();
        const payload = { ...this.modelInfo, ...this.captcha };
        const r = await this.$services.verification.resend({ ...payload }, this.apiType);
        this.progress = false;

        this.clearInterval();
        if (r.error) {
          if (r?.key === 'limit') {
            const limitTime = +r.value;
            this.startInterval(limitTime);
            // limit time으로 실패했을 경우에도 팝업 노출
            this.$emit('complete', { isSend: false });
          } else {
            this.structure.error = true;
            this.structure.errorMsg = this.$t(r?.key, { csEmail: this.csEmail, method: this.isEmail ? this.$t('emailAddress') : this.$t('mobileNumber') });
            this.structure.errorDesc = r?.desc;
            this.$emit('complete', { isSend: false, error: r });
          }
        } else {
          this.startInterval(DEFAULT_TIME);
          // Forgot Password / My Info 는 Token 을 다시 세팅 하여야 하여 result 다시 보냄
          this.$emit('complete', { isSend: true, token: r.token, VerificationMethods: r.VerificationMethods });
        }
      } catch (e) {
        this.progress = false;
      }
    },
  },
  mounted() {
    this.clearInterval();
    this.structure.limitTime = this.structure.limitTime ? this.structure.limitTime : this.$route.params?.limitTime;
    this.structure.limitTime && this.startInterval(this.structure.limitTime);
  },
  beforeDestroy() {
    this.clearInterval();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-resend] {
  &.dark {
    > span { .c(#7F7F7F); }
    > button { .ml(16); .c(#CCCCCC);.fs(16);
      &:hover { .c(white); }
      &:disabled { .c(#434343); cursor: not-allowed; }
    }
  }
  &.green {
    > span { .c(#249135); .fs(14); }
    > button { .ml(8); .c(@gp-green); .fs(14); font-weight: 500;
      &:hover { .c(@gp-green); }
      &:disabled { .c(#D4D4D8); cursor: not-allowed; }
    }
  }
}
</style>