<template>
  <div gp-sub-header>
    <BackButton v-if="isBackButton" @click="back" />
    <p class="route-name">
      {{ title }}
    </p>
  </div>
</template>

<script>
import BackButton from '@/views/components/gg-pass/BackButton.vue';
export default {
  name: 'SubHeader',
  components: { BackButton },
  props: {
    isBackButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    back() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-sub-header] { .rel(); .flex(); .h(56); .justify-center(); .items-center(); .pv(17); .bgc(@gp-bgc-light-gray);
  > p {.fs(16); font-weight: 500; .c(#000);}
  [gp-btn-back] { .abs(); .l(15); .c(#000); .mt(0); }
}
</style>