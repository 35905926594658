<template>
  <SearchDropSelect size="md" :type="type" :placeholder="placeholder" :maxLength="maxLength" :list="filterList" :sub-label="subLabel" :template="template" :footer-template="footerTemplate" :auto-open="autoOpen" @input="update" @focus="focus" @blur="blur" @updateSearch="search" />
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'AddressSearchSelect',
  components: { SearchDropSelect },
  props: {
    placeholder: { type: String, default: '' },
    maxLength: { type: Number, default: 999 },
    value: { type: Specific, default: null },
    type: { /** @types { google-address | loqate-address | loqate-sub-address } */ type: String },
  },
  data() {
    return {
      searchValue: null,
      selectedItem: null,
      originList: null,
      filterList: null,
      sessionToken: null,
      delayId: false,
      autoOpen: false,
      timer: null,
    };
  },
  watch: {
    value: 'updateValue',
    searchValue: 'search',
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    isGoogleType() {
      const arrType = this.type.split('-');
      return arrType.includes('google');
    },
    isAddressType() {
      const arrType = this.type.split('-');
      return !arrType.includes('sub');
    },
    subLabel() {
      const label = this.isAddressType ? 'onBoarding.matchedAddress' : 'onBoarding.includedAddress';
      return this.$t(label, {length: this.filterList?.length || 0});
    },
    footerTemplate() {
      return this.isGoogleType ? '<img src="/img/powered_by_google_on_non_white.png" />' : '';
      // return '';
    },
  },
  methods: {
    /**
     * 검색 결과 노출 목록 아이템의 템플릿
     * 결과 값에 Type 여부와 값에 따라 우측 끝에 >를 표기해 줘야해서 해당 처리 해둠
     * @param {object} v - 노출 목록 아이템
     * @returns {string} - 템플릿 html
     */
    template(v) {
      return `${v.Text} ${v.Description}${v.Type && v.Type !== 'Address' ? '<i class="icn-chevron-right" />' : ''}`;
    },
    async blur() {
      if (this.selectedItem && this.searchValue !== this.selectedItem.label) this.searchValue = this.selectedItem.label;
      this.$emit('blur');
    },
    focus() {
      this.$emit('focus');
    },
    updateValue() {
      if(this.value) this.searchValue = this.value.selectedItem?.label;
    },
    reset() {
      this.searchValue = '';
      this.selectedItem = null;
      this.$emit('input', null);
    },
    async update(item) {
      if (item?.value === this.selectedItem?.value) return;
      this.selectedItem = item;
      this.$emit('input', { ...this.value, selectedItem: this.selectedItem, sessionToken: this.sessionToken });
    },
    async apiRequest(v) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        const r = await this.getApiResponse(v);
        // TODO : [ean] 에러 발생 시 여기에서는 structure에 error 정보 전달 X?
        if (r.error) return;
        this.sessionToken = r?.SessionToken;
        this.filterList = r?.List?.map(o => ({ ...o, label: `${o.Text} ${o.Description}`, value: o.Id }));
        this.originList = this.filterList;
      },100);
    },
    /**
     *
     * @param api
     * @param params
     * @param config
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(v) {
      const params = { SiteId: this.site, SearchText: v, Country: this.userInfo.Country, AddressId: this.value?.AddressId, SessionToken: this.value?.SessionToken };
      const config = { silent: true };
      try {
        const v = /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.searchAddress(params, config);
        this.$unblock();
        return v;
      }
      catch (e) {
        this.$unblock();
        return e;
      }
    },
    search(v) {
      if (this.isAddressType) {
        if (!v?.length) {
          this.filterList = [];
          return;
        }

        const time = !this.delayId ? 10 : 100;
        clearTimeout(this.delayId);
        this.delayId = setTimeout(async () => {
          await this.apiRequest(v);
          this.delayId = null;
        }, time);
      } else {
        this.filterList = v ? this.originList?.filter(item => item.label.toLowerCase().includes(v.toLowerCase())) : this.originList;
      }
    }
  },
  async mounted() {
    if (this.isAddressType) {
      this.filterList = [];
    } else {
      this.autoOpen = true;
      await this.apiRequest('');
    }
  }
};
</script>

<style scoped>

</style>