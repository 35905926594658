<template>
  <div limit-hour-field>
    <label v-if="label"> {{ label }}</label>
    <div>
      <SearchDropSelect theme="dark" class="input-field" v-if="selectList" :list="selectList" :translate="false" v-model="selected" auto select-only />
      <ValidationProvider v-if="selected && selected.value === 'custom'" v-slot="{errors}" :name="name" :rules="rules">
        <div class="hour-holder" :class="{'error': hasValue && errors.length}">
          <TextInput digit theme="dark" class="input-field" :placeholder="placeholder" :max-length="maxLength" v-model="model" input-mode="numeric" pattern="[0-9]*" />
          <em>{{ $t('hour') }}</em>
        </div>
        <ValidationErrors v-if="hasValue" :errors="errors" />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { isNull } from 'lodash';

export default {
  name: 'LimitHourField',
  components: { SearchDropSelect, TextInput },
  props: {
    value: { type: [String, Number] },
    label: String,
    min: { type: [String, Number], required: true },
    max: { type: [String, Number], required: true },
    name: { type: String },
    rules: { type: String, default: '' },
    maxLength: { type: Number, default: 999 },
    isCz: { type: Boolean, default: false }
  },
  data() {
    return {
      model: null,
      placeholder: null,
      selected: null,
      selectList: null,
    };
  },
  computed: {
    hasValue() {
      return !isNull(this.model) && +this.model.length > 0;
    },
  },
  watch: {
    selected: 'updateSelected',
    model: 'updateModel',
  },
  methods: {
    updateSelected() {
      this.model = this.selected?.value === 'custom' ? '' : this.selected?.value;
    },
    updateModel() {
      this.$emit('input', !this.model ? this.model : Number(this.model));
    },
    initialize() {
      this.placeholder = `${this.min} ~ ${this.max}`;

      const list = [];
      switch (this.max) {
        case '24':
          [24, 12, 6, 3].forEach(i => list.push({ label: `${i} ${this.$t('hr')}`, value: i }));
          break;
        case '168':
          [168, 120, 72, 24].forEach(i => list.push({ label: `${i} ${this.$t('hr')}`, value: i }));
          break;
        case '720':
          [720, 360, 240, 120].forEach(i => list.push({ label: `${i} ${this.$t('hr')}`, value: i }));
          break;
      }
      list.unshift({ label: 'custom', value: 'custom' });
      this.selectList = list;

      const defaultValue = list?.find(v => v.value === this.value) || ({ label: 'custom', value: 'custom' });
      this.selected = this.isCz ? ({ label: `24 ${this.$t('hr')}`, value: 24 }) : defaultValue;
    }
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-hour-field] { .flex(); .items-start();
  &:not(:nth-of-type(1)) { .mt(16); }
  > label { .fs(14); .c(#ccc); .mt(6); .min-w(120); }
  > div { .flex-grow(2);
    [search-drop-select] .opener { .h(46); }
    .hour-holder { .flex(); .items-center(); .mt(8);
      [text-input] { .flex-grow(2); }
      > em { .ml(12); .ib(); .fs(14); .c(@c-w05); }
    }
  }
}
</style>
