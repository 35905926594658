<template>
  <div verify-rg-totalbet-setting>
    <LimitUnitField :label="isRolling ? $t('_.24hours') : $t('_.dailyLimit')" :max-length="9" :rules="`${setRequired}|${dailyRule}`" v-model="modelInfo.RequestDailyTotalBetLimit" :hasNoLimit="hasNoLimit" :name="'_amount'" @update="checkNoLimit" />
    <!-- <LimitUnitField :label="isRolling ? $t('_.7Days') : $t('_.weeklyLimit')" :max-length="9" :rules="weeklyRule" v-model="modelInfo.RequestWeeklyTotalBetLimit"/> -->
    <LimitUnitField :label="isRolling ? $t('_.30Days') : $t('_.monthlyLimit')" :max-length="9" v-model="modelInfo.RequestMonthlyTotalBetLimit" :hasNoLimit="hasNoLimit" :name="'_amount'" />
    <p class="list-star">{{ $t('_.totalBetLimit.description') }}</p>
  </div>
</template>

<script>
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'VerifyRgTotalBetSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitUnitField },
  mixins: [baseTemplate],
  data() {
    return {
      totalBetLimitType: null,
      hasNoLimit: true,
      isNoLimit: false,
    };
  },
  computed: {
    currencySymbol: state('user', 'currencySymbol'),
    // TODO [kate] 이전 소스들 우선 주석처리 -> 추후 필요 없으면 제거 예정
    // dailyRule() {
    //   if (!this.modelInfo.RequestDailyTotalBetLimit && !this.modelInfo.RequestWeeklyTotalBetLimit && !this.modelInfo.RequestMonthlyTotalBetLimit) return '';
    //   return `weeklyLimit:${this.modelInfo.RequestWeeklyTotalBetLimit},${this.$t('daily')},${this.$t('weekly')}|monthlyLimit:${this.modelInfo.RequestMonthlyTotalBetLimit},${this.$t('daily')},${this.$t('monthly')}`;
    // },
    // weeklyRule() {
    //   if (!this.modelInfo.RequestDailyLossLimit && !this.modelInfo.RequestWeeklyTotalBetLimit && !this.modelInfo.RequestMonthlyTotalBetLimit) return '';
    //   return `monthlyLimit:${this.modelInfo.RequestMonthlyTotalBetLimit},${this.$t('weekly')},${this.$t('monthly')}`;
    // },
    setRequired() {
      if (this.isNoLimit) return '';
      return 'required';
    },
    dailyRule() {
      if (!this.modelInfo.RequestDailyTotalBetLimit && !this.modelInfo.RequestMonthlyTotalBetLimit) return '';
      return `dailyLimit:${this.modelInfo.RequestMonthlyTotalBetLimit},${this.$t('daily')},${this.$t('monthly')}`;
    },
    isRolling() {
      return this.totalBetLimitType === 'Rolling';
    },
  },
  methods: {
    checkNoLimit(v) {
      this.isNoLimit = v;
    },
    async initialize() {
      this.totalBetLimitType = (await this.$services.account.getRgConfig()).TotalBetLimitPeriodType;
      const { CurrentDailyTotalBetLimit, CurrentWeeklyTotalBetLimit, CurrentMonthlyTotalBetLimit } = await this.$services.account.getTotalBetLimit();
      this.modelInfo.RequestDailyTotalBetLimit = CurrentDailyTotalBetLimit;
      // this.modelInfo.RequestWeeklyTotalBetLimit = CurrentWeeklyTotalBetLimit;
      this.modelInfo.RequestMonthlyTotalBetLimit = CurrentMonthlyTotalBetLimit;
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-totalbet-setting] {
  > h4 { .fs(18); .medium(); .c(#ffc64d);}
  b { .c(#ffc64d); .medium(); }
  > h5 { .c(#ababab); .medium(); .fs(16, 24); .ls(-.2); .mb(16); .pt(20); .-t(#8b8b8b); }
  [limit-unit-field] {
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
}
</style>
