import { siteIds } from '@/constants/base/siteMap';

const includeUserName = (config, value) => ((!config.passwordAllowedUsername || !config.PasswordAllowedUsername) ? `|includeId:${value}` : '');

const includeAlphabet = config => ((config.passwordIncludeAlphabet || config.PasswordIncludeAlphabet) ? '|includeAlphabet' : '');

const includeBothUpper = config => ((config.passwordIncludeBothUpperLowerCase || config.PasswordIncludeBothUpperLowerCase) ? '|includeBothUpper' : '');

const includeNumber = config => ((config.passwordIncludeNumber || config.PasswordIncludeNumber) ? '|includeNumber' : '');

const includeSpecialChar = config => ((config.passwordIncludeSpecialCharacter || config.passwordIncludeSpecialCharacter) ? '|includeSpecialChar' : '');

const minMax = config => ((config.passwordMaxLength || config.PasswordMaxLength) ? `|pwMinMax:${config.passwordMinLength || config.PasswordMinLength},${config.passwordMaxLength || config.PasswordMaxLength}` : '');

export const emailPasswordValidation = (config, email) => `required${includeUserName(config, email)}${includeAlphabet(config)}${includeBothUpper(config)}${includeNumber(config)}${includeSpecialChar(config)}${minMax(config)}`;

export const mobilePasswordValidation = (config, mobile) => `required${includeUserName(config, mobile)}${includeAlphabet(config)}${includeBothUpper(config)}${includeNumber(config)}${includeSpecialChar(config)}${minMax(config)}`;

const allowedUserName = { default: false, };
const usedIncludeAlphabet = { default: true, };
const usedIncludedNumber = { default: true, };
const usedIncludeBothUpperLowerCase = { default: false, [siteIds.GGPDE]: true };
const usedIncludeSpecialCharacter = { default: false, [siteIds.GGPDE]: true };
const minLength = { default: 8, [siteIds.GGPDE]: 8, };
const maxLength = { default: 20, [siteIds.GGPDE]: 20, };

export const npPasswordConfigs = site => ({
  passwordAllowedUsername: allowedUserName[site] || allowedUserName.default,
  passwordIncludeAlphabet: usedIncludeAlphabet[site] || usedIncludeAlphabet.default,
  passwordIncludeNumber: usedIncludedNumber[site] || usedIncludedNumber.default,
  passwordIncludeBothUpperLowerCase: usedIncludeBothUpperLowerCase[site] || usedIncludeBothUpperLowerCase.default,
  passwordIncludeSpecialCharacter: usedIncludeSpecialCharacter[site] || usedIncludeSpecialCharacter.default,
  passwordMinLength: minLength[site] || minLength.default,
  passwordMaxLength: maxLength[site] || maxLength.default,
});