<template>
  <div np-display-social-list>
    <SocialLinkedButton v-if="usedList" v-for="(item, index) in usedList" :key="index" :img="item.img" :active="item.isLinked" :type="item.type" :email="item.email" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import siteModel, { routeNames } from '@/constants/model/my-page';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';
import { socialType } from '@/constants/base/my-page';
import SocialLinkedButton from '@/views/components/pages/my-page/template/social-login/SocialLinkedButton.vue';

export default {
  name: 'DisplaySocialList',
  components: { SocialLinkedButton },
  props: {
    model: Specific
  },
  data() {
    return {
      socialList: [
        { type: socialType.GOOGLE, img: 'Google.png', link: 'googleLink', email: null, isLinked: false },
        { type: socialType.FACEBOOK, img: 'Facebook.png', link: 'googleLink', email: null, isLinked: false },
        { type: socialType.APPLE, img: 'Apple.png', link: 'googleLink', email: null, isLinked: false }
      ],
      usedList :null,
      labels : {
        [socialType.GOOGLE]: 'myInfo.socialLinked.google',
        [socialType.FACEBOOK]: 'myInfo.socialLinked.facebook',
        [socialType.APPLE]: 'myInfo.socialLinked.apple',
      }
    };
  },
  methods: {
    initialize() {
      let list = [];
      this.socialList.forEach(social => {
        const oauths = this.model.filter(auth => auth.oauthType === social.type);
        if(oauths.length > 0) {
          const customAuthList = oauths.map(auth => ({
            ...social,
            // Mig 대상으로 인하여, Email 이 Null일 경우 처리
            email: auth?.oauthEmail || this.$t(this.labels[auth.oauthType]),
            isLinked: auth.emailVerified,
          }));
          list = [...list, ...customAuthList];
        }else {
          list = [...list, social];
        }
      });
      if(list.length > 0){
        this.usedList = list;
      }
      else {
        this.usedList = this.socialList;
      }
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-social-list] {.flex(); .flex-dc(); .gap(8);
}
</style>