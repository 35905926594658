<template>
  <div footer-basic :class="{'basic': isBasic, 'connect': isConnect}">
    <template v-if="isBasic">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAA4BJREFUSEu9V11u00AQ/sYiKvDS9gQNXKAtQkK1JXtTeObnAlDgnYJ4pz8HgPQAQMsFaN+BjCPFERKC9gT0BjQvgABl0Kx2i+vErUOg+xTH3v1mvpn5ZpZQcWXG3ASQAJgTYIqAOd0qwC4BBwB2hYijVmunypF03EefjJn6Diz3gYcETFU5UICDAGieBTbmmdWgoasUuGvMUh945gEF2AOwHQAMYH+BeV9P7BpTB1DvAwbATQJmHRMHBNwNmbeHIQ8FzoxpAlh2G1ICVheYFfDE1TXGCLDqwqLfN0PmR8WNA8CZMZsA7uiHavECsz6PvJQxAV467zcj5rv5Q44A5zztCWAi5t2REXMbnPdK9SSAjZD5oX99CFywcH5cUA/gwFvu+ZaPuQXW7P0KfNZEGofeMna8U5rx54ELmu0WuGPMKgErANKQWbPzn6/MGE3ORIC1iFnxgMwYrbdJAhpVs3dUyzzl6nXEPE1OkV5rnUbMVo3+1+oYoyqndX5LgW3Nego8aCeOYxDdUIlUOZyo1dYvv3nT8+8/XLs2+ePnz2WI7Ift9qsqxuZCuqHAlvs8zQpKQZDaw0Q+gugSRD6HaXpR/3rfaMz+6vdbRDQtIu+iNL2aB+4sLs6cEZm60mqp2h2uXIanCqzSN0PABS+DNuGS5MFErbalXmZJ8hxE96Tf1yYBNUoBiWhxGHCWJE8ESAYMMmaOgE+2sWRW4YCQuVS3O0ny1oIEQX0iCA6U4jBN13XvKMAukS3eicBZHN9GEGyJyNMoTR/nqSsCewOL8c475R0tpdpa50Ah8iJM0/vFA4vA9nuiutKsoQNgdV7ZseH7Q/Xe0OSqAuppG4XqYnINlFM3jq9LEOyIyBcC9L1LcOGo3W7759IYx/FtIZothqZYTjrSqIDsRszz1pMkeQKitYHaFFnxtB3ncVlNHxEQd8CpSSaAXsiszehIk+CQuVFm8Tj/D20S2ha/ASok2ij+euooMyzX63vngPphW3R021jrbwFOZxDIZanPcI15Y9wpRMunD7x2k+rw0ScH/s+HPQBbIfNSPhRVxlsV8bWqA4ITCZ1m7CRTbLcevLQxuAFBvdcJ0V5VAmBbryki0vNhsDJINEkiSqsO9H6Y0N69NNJA761yQ6BeX3QstQZUWDoaN88Dzb+6whQBHANKn7+0+avKnr+0AVAdGHplKZ73Gy2uEdSqary5AAAAAElFTkSuQmCC" v-if="isOverTwentyOne"/>
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIBAMAAACnw650AAAAElBMVEVHcEzGQEDGQEDGQEDGQEDGQEBKIwbaAAAABXRSTlMA6Th1sNpNghQAAAIFSURBVEjHlVa5ksIwDM3h7RMgfRhIH3ZIzw5xvwn4/3+FWJYPOfaO1g0T8yw9PR12UZB1mVul2vm7yK/Lj8J1ysLuKljPNOamyDoyMEnUWe3WIcYIR3meHf0HxVQd7C7PHr7u+NknnJ3cZmWsrSGm3jM1cYzBzqQ3XpRBvAeG3n1EU1JTQ2zZH11JaIe0dC7Ach+tP9zgh0wbMqbeeCBjCE31ztuaLozB+ZtSofkAjVSdc7xfG93FotccaEAvZd6bsdAYSku+NzpDSu5SG6X5bVRq4IQJttpq5OhVK0GpGsoUqrdxufcVIYCxAOhgQeeoByqwUQJvaUGmuINQOr0/oJQVgGrtSrvsAzlXTf/lQQL6SATKwf8S9XagEew9QO6xMJ420O9foC8N6qw+lpN3ByAIrMNurmx0QHwpLEhokKIgr9O57dTSjlrHGOR7cjAtnAIJN0/yIJwuWt/rLLc51AOIEhfg6hZIIDC6QIIBmmuztxIJqJiTydGE1K2YNC3SgnTSr3OPaaEJlqEln2BaKpPl9CKlUmKFWeL67ynoRAhM4ExwEigyLE351qgmKi6dmGEjVLZNzE9NDWFL2ea0Ca51OGPUnLw2Zw0M1uhhDTHeOGQNVtaIZg173rXBuoBYVxnvUmRdr7yLOnflH/7/eGA9Q3gPGt7TiPfI4j3XeA+/7BPyA+/dL6bfHKVoAAAAAElFTkSuQmCC" v-else/>
      <a href="https://www.begambleaware.org/" target="_blank" v-if="!isGGPPH">
        <img :src="`${cdnImgPath}/gambleAware_gray.png`" alt="gamble aware">
      </a>
      <span v-else>{{ $t('_.responsible') }}</span>
    </template>
    <template v-else-if="isConnect">
      <div v-html="$t('_.ontario')"/>
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'FooterBasic',
  lexicon: 'signUp.footer',
  data() {
    return {
      basicType: [siteIds.GGPCOM, siteIds.GGPDE, siteIds.GGPUK, siteIds.GGPEU, siteIds.GGPFI, siteIds.GGPPL, siteIds.GGPBE, siteIds.GGPHU, siteIds.GGPNL, siteIds.GGPRO, siteIds.GGPBR, siteIds.GGPUA, siteIds.GGPCW, siteIds.GGPPH, siteIds.GGPJP, siteIds.GGPNG],
      connectType: [siteIds.WSOPON]
    };
  },
  computed: {
    isBasic() {
      return this.basicType.includes(this.site);
    },
    isConnect() {
      return this.connectType.includes(this.site);
    },
    isOverTwentyOne() {
      return [siteIds.GGPBE, siteIds.GGPPH].includes(this.site);
    },
    isGGPPH() {
      return this.site === siteIds.GGPPH;
    },
    site: state('env', 'site'),
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[footer-basic] {
  &.basic { .items-center(); .flex-center(); .pb(40);
    > img { .wh(28); .mr(10); }
    > a {
      img { .h(22); }
    };
    > span { .c(@c-text-desc); .fs(16); }
  }
  &.connect { .tc();
    > div {
      > p { .c(@c-text-desc); .fs(14);
        .number { .c(#ccc); .fs(14); .medium(); }
        .link { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }
      }
    }
  }
}
</style>
