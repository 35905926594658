<template>
  <Modal verify-request-message-modal theme="dark" @close="close">
    <template #title>
      {{ title }}
    </template>
    <div class="body" v-html="body" />
    <div class="bts">
      <ProgressButton button-name="close" @click="close" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'VerifyRequestMessageModal',
  components: { ProgressButton, Modal },
  lexicon: 'signUp.resend',
  props: {
    // options: {
    //   type: { type: String, default: 'email' },
    //   isSend: Boolean,
    // }
    options: Specific,
  },
  computed: {
    title() { return this.$t('_.title', { method: this.$t(this.type) }); },
    body() { return this.isSend ? this.$t('_.successDescription') : this.$t('_.failedDescription', { method: this.$t(this.type) }); },
    type() { return this.options?.type?.toLowerCase(); },
    isSend() { return this.options?.isSend; }

  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-request-message-modal] {
  .bts { .flex(); .flex-dc(); .items-center(); }
  > .panel.large { .max-w(460); .mh(16); }
}
</style>
