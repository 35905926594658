import createApp from '@shared/ssr/createApp';
import clickOutside from '@shared/directive/clickOutside';
import ClientOnly from '@shared/components/general/ClientOnly.vue';
import App from '@/App.vue';
import createStore from '@/store';
import createRouter from '@/router';
import createServiceManager, { servicePlugin } from '@/services';
import i18n, { i18nLoad } from '@/plugins/i18n';
import validate from '@/plugins/validate';
import modelController from '@/plugins/modelController';
import globalMixin from '@shared/mixins/global';
import sentry from '@shared/plugins/sentry';
import { siteIds } from '@/constants/base/siteMap';
export default createApp({
  App,
  createStore,
  createRouter,
  createServiceManager,
  beforeCreate: (Vue, services, router, store) => {
    //
    Vue.use(i18n);
    Vue.use(servicePlugin, { services });
    Vue.use(validate, { services });
    Vue.directive('clickOutside', clickOutside);
    Vue.component('ClientOnly', ClientOnly);
    Vue.use(modelController, { services, router, store });
    Vue.mixin(globalMixin);
    //
    const env = process.env.VUE_APP_ENV;
    if(env === 'production' && [siteIds.N8IN, siteIds.NATURAL8].includes(store.state.env?.site))Vue.use(sentry, { router, dsn: process.env.VUE_APP_SENTRY_DSN });

    Vue.directive('clickOutside', clickOutside);
    router.beforeEach((to, from, next) => i18nLoad(to).then(next));
  },
});
