<template>
  <Modal verify-request-modal theme="dark" @close="close">
    <template #title>
      {{ $t('_.dontReceive') }}
    </template>
    <div class="body">
      {{ $t('_.dontReceiveDescription') }}
    </div>
    <div class="bts">
      <LimitTimer v-if="buttonDisabled" :time="limitTime" />
      <ProgressButton button-name="reSend" @click="reSend" :disabled="buttonDisabled" />
    </div>
  </Modal>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { getTime } from '@shared/utils/timeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import Modal from '@shared/components/common/Modal.vue';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import { siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'FpVerifyRequestModal',
  components: { LimitTimer, ProgressButton, Modal },
  lexicon: 'signUp.signUpVerify',
  props: {
    options: Specific,
  },
  data() {
    return {
      limitTime: null,
      buttonDisabled: false,
      captcha: null,
      action: 'SignupResendEmailCode',
      testCaptcha: true,
    };
  },
  computed: {
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isLocalOrTest() { return ['local', 'test'].includes(this.env); },
    countryCode() { return this.$route.query.country || state('user', 'countryCode'); },
    site: state('env', 'site'),
    env: state('env', 'env'),
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      if (!this.isLocalOrTest || this.testCaptcha) this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.action);
    },
    async reSend() {
      await this.loadCaptcha();
      const excludeGgpass = isExcludeGgpass(this.site);
      const r = excludeGgpass ? await this.$services.sign.sendEmailVerificationCode({ ...this.options.info.model, IsResend: true, CountryCode: this.countryCode, SiteId: this.site, ...this.captcha }) : await this.$services.ggpass.verification(this.options.info.model);

      if (r.key === 'limit' || r.key === apiErrorCode.RESEND_TERM_LIMITED) {
        this.limitTime = +r?.value || JSON.parse(r?.desc2).remainingTime;
        this.setDisabled();
      } else if (r.key || r.desc) {
        this.options.info.error = true;
        if (r.key === apiErrorCode.RESEND_LIMITED) {
          if (process.env.VUE_APP_ENV !== 'production') {
            console.log(`Api Request Error : sign.sendEmailVerificationCode`, r);
            this.$toast(r.key, { type: 'fail' });
          }

          this.$emit('reject');
        }
      } else {
        this.$store.commit('setResendCloseSecond', 0);
        this.$store.commit('setResendSumSecond', 0);
        this.$emit('resolve');
      }
    },
    setDisabled() {
      this.buttonDisabled = true;
      this.timeCount();
    },
    timeCount() {
      setTimeout(() => this.buttonDisabled = false, this.limitTime * 1000);
    },
    close() {
      this.$store.commit('setResendCloseSecond', getTime());
      this.$emit('close');
    }
  },
  mounted() {
    const closeTime = this.$store.state.resendCloseSecond;
    const sum = this.$store.state.resendSumSecond * 1000;
    const gap = (getTime() - closeTime);
    if (gap < sum) {
      this.limitTime = Math.round((sum - gap) / 1000);
      this.setDisabled();
    }
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-request-modal] {
  .bts { .flex(); .flex-dc(); .items-center();
    [limit-timer] { .mb(10); }
  }
}

</style>