import { siteIds } from "@/constants/base/siteMap";
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';

const defaultImage = `${cdnImgPath}/banner_site_all_in.jpg`;
const defaultWelcomeUrl = 'https://www.contents.ggnetwork-contents.com/aof-sit-go';
const welcomeBonusImage = `${cdnImgPath}/Sign-up-Success-Welcome-Banner`;
const czImage = '';
const psps = [`${cdnImgPath}/visa.png`, `${cdnImgPath}/mastercard.png`, `${cdnImgPath}/paypal.png`, `${cdnImgPath}/luxonpay.png`, `${cdnImgPath}/interac.png`, `${cdnImgPath}/skrill.png`, `${cdnImgPath}/astropay.png`, `${cdnImgPath}/neteller.png`];
const pspsCZ = [`${cdnImgPath}/visa.png`, `${cdnImgPath}/mastercard.png`, `${cdnImgPath}/luxonpay.png`];

const info = {
    /** GGPUKE */ [siteIds.GGPUKE]: { successImage: `${cdnImgPath}/banner_mbp_sign_up.png`, welcomeUrl: 'http://www.ggpk2.com/Event' },
    /** NATURAL8 */ [siteIds.NATURAL8]: { psp: true },
    /** N8IN */ [siteIds.N8IN]: { psp: true },
    /** GGPOK */ [siteIds.GGPOK]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://pokerok166.com/promotions/welcome-bonus/' },
    /** GGPCOM */ [siteIds.GGPCOM]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** GGPCOM */ [siteIds.GGPJP]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** GGPCOM */ [siteIds.GGPNG]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** GGPUK */ [siteIds.GGPUK]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.co.uk/promotions/boosted-welcome-bonus/' },
    /** GGPEU */ [siteIds.GGPEU]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.eu/promotions/welcome-bonus/' },
    /** GGPFI */ [siteIds.GGPFI]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://fi.ggpoker.eu/promotions/welcome-bonus/' },
    /** GGPPL */ [siteIds.GGPPL]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://pl1.ggpoker.com/promotions/welcome-bonus/' },
    /** GGPDE */ [siteIds.GGPDE]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.de/promotions/welcome-bonus/' },
    /** GGPBE */ [siteIds.GGPBE]: { psp: true },
    /** GGPHU */ [siteIds.GGPHU]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://hun.ggpoker.eu/promotions/welcome-bonus/' },
    /** GGPNL */ [siteIds.GGPNL]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.nl/promotions/welcome-bonus/' },
    /** GGPRO */ [siteIds.GGPRO]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://playgg.ro/promotions/welcome-bonus/' },
    /** GGPBR */ [siteIds.GGPBR]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://br.ggpoker.com/promotions/welcome-bonus/' },
    /** GGPUA */ [siteIds.GGPUA]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ua.ggpoker.com/promotions/welcome-bonus/' },
    /** GGPCW */ [siteIds.GGPCW]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** DAVAOPOKER */ [siteIds.DAVAOPOKER]: {},
    /** EVPUKE */ [siteIds.EVPUKE]: { successImage: `${cdnImgPath}/banner_mbp_all_in_old.jpg` },
    /** TWOACE */ [siteIds.TWOACE]: {},
    /** WSOPON */ [siteIds.WSOPON]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.ca/promotions/welcome-bonus/' },
    /** SEVENXL */ [siteIds.SEVENXL]: { welcomeUrl: '' },
    /** GGPCZ */ [siteIds.GGPCZ]: { successImage: `${cdnImgPath}/cz-banner.png`, psp: true },
    /** GGPPH */ [siteIds.GGPPH]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** GGPDK */ [siteIds.GGPDK]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** OCNP */ [siteIds.OCNP]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** POKERARABIA */ [siteIds.POKERARABIA]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
    /** GGVEGAS */ [siteIds.GGVEGAS]: { successImage: welcomeBonusImage, psp: true, welcomeUrl: 'https://ggpoker.com/promotions/welcome-bonus/' },
};

export default (site, lang) => {
    const data = info[site];
    const invalidLang = ['CS', 'DA', 'DE', 'EN', 'ES', 'ET', 'FI', 'FR', 'HU', 'ID', 'JA', 'KO', 'LV', 'PL', 'RO', 'RU', 'SV', 'TH', 'TR', 'UK-UA', 'VI', 'ZH-CN', 'PT'];
    lang = invalidLang.includes(lang) ? lang : 'EN';
    data.successImage = data.successImage || defaultImage;
    data.welcomeUrl = data.welcomeUrl || defaultWelcomeUrl;
    if (data.successImage === welcomeBonusImage) data.successImage = `${data.successImage}_${lang}.png`;
    if (data.psp) data.psp = site === 'GGPCZ' ? pspsCZ : psps;
    return data;
};