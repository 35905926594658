<template>
  <div sign-up-bonus v-if="!isEmpty(signUpInfo)">
    <div v-if="notifications && notifications.length > 0">
      <Notification v-for="notification in notifications" :info="notification" :key="notification.name" />
    </div>
    <div class="contents">
      <div class="section-holder">
        <BonusStatus :status-info="signUpInfo" @reload="updateInfo" />
        <FeatureBonus :status-info="signUpInfo" />
        <DailyFreeChallenge :status-info="signUpInfo" />
        <SectionContainer class="claim-reward">
          <template #contents>
            <PromotionButton v-if="item.playerPromotionStatus === 'ToDo'" @click="optIn" :disabled="!disabled">{{ $t('promotion.claimYourFreeReward') }}</PromotionButton>
          </template>
        </SectionContainer>
        <BonusHistory :playerPromotionId="signUpInfo.playerPromotionId" />
      </div>
    </div>
  </div>
</template>

<script>
import FeatureBonus from '@/views/components/pages/promotion/depositBonus/FeatureBonus.vue';
import BonusStatus from '@/views/components/promotion/BonusStatus.vue';
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import DailyFreeChallenge from '@/views/components/pages/promotion/depositBonus/DailyFreeChallenge.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import SectionContainer from '@/views/components/pages/promotion/SectionContainer.vue';
import PromotionButton from '@/views/components/promotion/PromotionButton.vue';
import Notification from '@/views/components/promotion/Notification.vue';
import {isEmpty} from 'lodash';

export default {
  name: 'SignUpBonus',
  components: { DailyFreeChallenge, BonusHistory, BonusStatus, FeatureBonus, SectionContainer, PromotionButton, Notification },
  data() {
    return {
      signUpInfo: null,
      history: null,
      customerErrorCode: null,
      claimedRewarded: false,
    };
  },
  computed: {
    promotionList: state('promotion', 'promotionList'),
    item() { return this.signUpInfo?.item; },
    disabled() {
      if (isEmpty(this.signUpInfo)) return false;
      const { isOnBoardingCompleted, item, promotionRestriction } = this.signUpInfo;
      const toDo = item.playerPromotionStatus === 'ToDo';
      return toDo && isOnBoardingCompleted && !promotionRestriction;
    },
    notifications() {
      return this.$promotion.getNotifications('signUp', { info: this.signUpInfo, customerErrorCode: this.customerErrorCode, claimedRewarded: this.claimedRewarded });
    },
  },
  methods: {
    async optIn() {
      const r = await this.$services.promotion.optInSignUp(true);
      if (r.code === 204) this.claimedRewarded = true;
      this.customerErrorCode = r.key;
      await this.updateInfo();
    },
    async updateInfo() {
      const r = await this.$services.promotion.signUpPromotion();
      if (r?.error) {
        await this.replaceRouteName(r?.path);
      } else {
        this.signUpInfo = r;
      }
    },
  },
  async mounted() {
    await this.updateInfo();
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[sign-up-bonus] {
  .contents {
    .info-btn { .w(28); .h(28); .p(4); .mh(8); .bgc(); }
    .claim-reward { .tc();
      &::before { .cnt(); .wf(); .h(1); .mb(20); .bgc(@c-dimGrey); }
    }
  }
}
</style>
