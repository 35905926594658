<template>
  <div sign-up-verify-form name="Step7_Email_Verification_View" class="signup-email-verify-view">
    <h2>{{ $t('_.title', { method: email ? $t('emailAddress') : $t('mobileNumber') }) }}</h2>
    <p v-html="$t('_.description1', {personalInfo})" />
    <p>{{ $t('_.description2') }}</p>
    <VerifyResendButton :structure="structure" :model-info="modelInfo" :type="sendVerificationType" :action="action" @complete="resendComplete" name="Step8B_Email_Verification_Resend_Button" className="signup-resend-email-code-btn" />
    <VerifyCode v-model="modelInfo.VerificationCode" :structure="structure" @input="updateCode" :class="{'error': structure.errorMsg}" />
    <ValidationErrors v-if="structure.errorMsg" :error-msg="structure.errorMsg" />

    <OpenEmailApp v-if="email" :email="modelInfo.Email" />
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import baseTemplate from '@/mixins/baseTemplate';
import VerifyCode from '@/views/components/common/VerifyPinCode.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import OpenEmailApp from '@/views/components/common/OpenEmailApp.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import VerifyRequestMessageModal from '@/views/components/common/modal/VerifyRequestMessageModal.vue';

export default {
  name: 'VerifyForm',
  lexicon: 'signUp.signUpVerify',
  components: { VerifyResendButton, OpenEmailApp, VerifyCode },
  mixins: [baseTemplate],
  data() {
    return {
      intervalId: null,
      currentSec: null,
      limitSec: null,
      captcha: null,
      TextInput,
      PasswordInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    isFromMobileApp: state('query', 'isFromMobileApp'),
    isFromClientApp: state('query', 'isFromClientApp'),
    personalInfo() {
      return this.modelInfo.Email ? this.modelInfo.Email : this.modelInfo.MobileNumber;
    },
    email() {
      return this.sendVerificationType === 'EMAIL';
    },
    sendVerificationType() {
      return this.modelInfo.SendVerificationType;
    },
    action() {
      return isExcludeGgpass(this.site) ? 'SignupSendEmailCode' : 'SIGN_UP';
    }
  },
  methods: {
    updateCode(v) {
      this.modelInfo.VerificationCode = v;
    },
    openEmailApp() {
      const userEmail = this.email;
      if (!userEmail) return;

      let url;
      const [id, domain] = userEmail.split('@');
      const findDomain = this.emailDomainList.find(email => email.label === domain);

      if (findDomain) url = findDomain.value;
      else url = domain;

      if (this.isFromMobileApp) {
        window.postMessage({ message: 'NAVIGATE_MAIL_APP' });
      } else if (this.isFromClientApp) {
        window.postMessage({ message: 'NAVIGATE_TO_URL', url });
      } else {
        window.open(url, 'blank');
      }
    },
    async resendComplete(v) {
      await this.$modal(VerifyRequestMessageModal, { isSend: v?.isSend, type: this.modelInfo.SendVerificationType === 'EMAIL' ? 'email' : 'sms' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sign-up-verify-form] { .rel();
  h2 { .fs(24); .c(#ccc); .mb(12); .medium();}
  > p { .c(@c-text-desc); word-break: break-word;
    em { .c(white); }
  }
  [validation-errors] { .tc(); .mt(20); }
  > [verify-resend] { .mt(20); .tr(); }
  //.error { .c(@c-red); .fs(14); .medium(); .tc(); .abs(); .lb(50%, -30); .t-xc(); }
  > [open-email-app] { .mt(100); .flex-center(); }
}
</style>