<template>
  <div gp-country-drop-down>
    <SearchDropSelect v-model="model" @input="selected" :template="getView" type="country" :list="list" :size="size" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled" />
    <!--    <p class="link" v-if="blockItem">Please sign up at <a :href="blockItem.link" target="_blank">{{ blockItem.name }}</a></p>-->
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { sleep } from '@shared/utils/commonUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'CzCityDropDown',
  components: { SearchDropSelect },
  props: {
    country: { type: Specific, default: null },
    size: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false, }
  },
  data() {
    return {
      list: null,
      model: null,
      blockItem: null,
    };
  },
  computed: {
    isExternal() {
      return (this.site !== siteIds.GGPCW && this.landingMode === 'warp') || this.channelingName;
    },
    isNullSite() {
      return [siteIds.TWOACE, siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.SEVENXL].includes(this.site);
    },
    isNoneSite() {
      return !this.$route.query?.siteId || this.$route.query?.siteId?.toLowerCase() === 'none' || (/** GGPPH GGPDK GGPCZ Stage 까지만 배포 */ this.env === 'production' && [siteIds.GGPPH, siteIds.GGPDK, siteIds.GGPCZ].includes(this.$route.query?.siteId));
    },
    channelingName: state('query', 'channelingName'),
    blockCountries: state('env', 'blockCountries'),
    userInfo: state('user', 'userInfo'),
    defaultSite: state('env', 'defaultSite'),
    site: state('env', 'site'),
  },
  methods: {
    getView(item) {
      const code = item.value.toLowerCase();
      const img = this.size === 'lg' ? `<img class="img-country" src="https://static.ggtechdev.com/ux/game/platform-front/img/${code}.svg" alt="country">` : '';
      return `${img}<em>${item.label}</em>`;
    },
    selected(v) {
      if (!v) return;
      this.blockItem = this.blockCountries?.find(o => o.country === v.CountryCode);
      if (this.blockItem) {
        this.$emit('block', { ...v, ...this.blockItem });
      } else {
        this.$emit('input', v.value);
      }
    },
    async getList() {
      const request = this.isNullSite ? null : this.isNoneSite ? 'None' : (this.defaultSite || this.site);
      this.list = (await this.$services.onboarding.getCzechState()).List.map(o => ({ label: o.Name, value: o.Name, o }));
      if (this.isExternal && this.blockCountries.length) this.originList = this.originList.filter(country => !this.blockCountries.some(b => b.country === country.CountryCode));

      await sleep(60);
      this.model = this.list.find(o => o.value === (this.country)) || this.list[0];

      this.$emit('input', this.model.CountryCode);
    }
  },
  async mounted() {

    await this.getList();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-drop-down] { .h(40); .rel();
  &.disabled {.h(20);}
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .-b(@c-red);}
  }
}
</style>