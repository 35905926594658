<template>
  <div gp-description>
    <p v-html="message">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'GpDescription',
  props: {
    message: { type: String, default: null, }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-description] { .p(0, 0, 20, 0); white-space: pre-line;
  > p { .c(#737373); .fs(14); line-height: 16px; }
}

</style>