''// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from 'axios';
import qs from 'qs';
import ApiErrorController from '@/modules/ApiErrorController';
import * as Sentry from '@sentry/vue';

/**
 * @typedef { store: StoreProxy, router: RouterProxy, modal: ModalProxy } ProxyManager
 */

export default class ApiConnection {
  /** @type{AxiosInstance} */
  #axios;
  #getSync = {};
  /** @type {ServiceManager} */
  #services;
  #configDecorator;
  baseUrl;

  /**
   * @param {string} baseURL
   * @param {ServiceManager?} services
   * @param {function(config: {}): {}} configDecorator
   */
  constructor(baseURL, services, configDecorator = v => v) {
    this.baseUrl = baseURL;
    this.#services = services;
    this.#axios = axios.create({ baseURL });
    this.#configDecorator = configDecorator;
    this.#axios.interceptors.request.use(config => {
      /*
      * sign-up 시 국가 인도 선택 시 RestrictLocation 을 확인하기 위해 특정 IP header 추가
      * https://ggnetwork.atlassian.net/wiki/spaces/AG/pages/116555796/2.+Sign+up#Restrict-Location-Modal
      * // config.headers['X-Forwarded-For-Custom'] = '223.27.122.111'; // 223.196.169.4, 15.230.246.235
      * */
      config.headers['Content-Type'] = 'application/json';
      config.headers.Pragma = 'no-cache';
      config.headers.Expires = -1;
      config.paramsSerializer = qs.stringify;
      return config;
    });
  }

  get base() {
    return this.baseUrl;
  }

  #errorHandler = (code, customCode) => {
    if (!code) return;
    /*
    * https://ggnetwork.atlassian.net/wiki/spaces/NPF/pages/1791131773/04.+error+code
    * 전역 에러로 발생 시 에러 처리되야하며 로그아웃까지 진행되야 할 코드 목록
    * ACCOUNT_LOCKED : ACCOUNT_LOGIN_LIMIT_ACCESS, GAMSTOP_SELF_EXCLUDED, CRUKS_SELF_EXCLUDED, AISG_SELF_EXCLUDED, ACCOUNT_LOCKED, OASIS_BAN_EXIST
    * ACCESS_DENIED :
    * ACCOUNT_SUSPENDED : ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED, ACCOUNT_LOGIN_REMAIN_LIMIT, ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED, COMPLIANCE_CHECK_PERIOD, CLOSED, COMPLIANCE_CHECK, SECURITY, MULTIACC_CHECK, EXCLUSION_CHECK, BLOCKEDLISTACC_CHECK
    * */
    return ApiErrorController.proxy({ site:  this.#services.store.state.env.site , code, customCode });
  };

  #responseHandler = (promise, silent) => new Promise((resolve, reject) => {
    if (!silent) this.#services?.modal.block();
    promise.then(response => {
      if (!silent) this.#services?.modal.unblock();
      const body = (response?.data?.body && response?.data?.error ? response?.data : response?.data?.body) || response?.data || response;
      if (body.error) {
        const errorTemplate = this.#errorHandler(body.body, body.CustomerCode ? body.CustomerCode : body.body);
        resolve({ error: true, code: body.error, desc: body.comment, key: body.body, errorTemplate });
      } else resolve(body);
    }).catch(e => {
      const {/** @type {{ status: number, data: { Description: string, CustomerErrorCode : string, Code: string, CustomerErrorParameters: string[] }}} */ response: r } = e;
      const status = r?.status;
      const code = r?.data?.Code || r?.data?.code;
      const customerErrorCode = r?.data?.CustomerErrorCode || r?.data?.customerErrorCode || r?.data?.customer_error_code;
      const errorTemplate = this.#errorHandler(code, customerErrorCode);

      if ((r?.data?.Code || r?.data?.code) === 'ACCESS_DENIED' || r?.data?.error === 'Unauthorized' || (status === 401 && typeof r?.data === 'string')) {
        reject({ error: true, code: 401 });
      } else if (status === 401 || status === 404 || status === 500) {
        resolve({ error: true, code: status, desc: r?.data?.Description || r?.data?.message, desc2: r?.data?.description, key: customerErrorCode || code, errorTemplate });
      } else {
        resolve({ error: true, code: status, desc: r?.data?.Description || r?.data?.message, desc2: r?.data?.description, key: customerErrorCode || code, CustomerErrorParameters: r?.data?.CustomerErrorParameters, errorTemplate });
      }

      if (!silent) this.#services?.modal.unblock();
    });
  });

  // noinspection JSCheckFunctionSignatures
  /**
   * @param {string} path
   * @param {object?} params
   * @param {AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  get(path, params, config) {
    if (!config?.silent) this.#services?.modal.block();
    return this.#responseHandler(this._get(path, params, this.#configDecorator(config)), config?.silent);
  }

  _get(path, params, config) {
    const uri = `${path}?${qs.stringify(params)}`;
    // noinspection JSUnresolvedVariable
    return this.#getSync[uri] || (this.#getSync[uri] = this.#axios.get(path, { ...config, params, timeout: 100000 }).then(response => {
      delete this.#getSync[uri];
      return response;
    }).catch(e => {
      delete this.#getSync[uri];
      throw e;
    }));
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  post(path, data, config) {
    return this.#responseHandler(this.#axios.post(path, data, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  put(path, data, config) {
    return this.#responseHandler(this.#axios.put(path, data, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  delete(path, config) {
    return this.#responseHandler(this.#axios.delete(path, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  patch(path, data, config) {
    return this.#responseHandler(this.#axios.patch(path, data, this.#configDecorator(config)), config?.silent);
  }
}
