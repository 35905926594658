<template>
  <div time-limit-row>
    <div class="label">{{ label }}</div>
    <div v-if="load" class="content">
      <div class="current">
        <InputComponent :label="$t('responsibleGaming.label.setTime')" name="drop-down" :component="SearchDropSelect" :value="selectValue" :preset="selectedPreset" @input="select" />
      </div>
      <div v-if="!isPossibleInput" class="new">
        <InputComponent :label="$t('hour')" :name="newName" :component="TextInputBox" :rules="rules" :value="inputValue" @input="input" :preset="preset" />
      </div>
    </div>

  </div>
</template>

<script>
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import Specific from '@shared/types/Specific';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'TimeLimitRow',
  components: { InputComponent },
  props: {
    value: { type: Specific },
    currentValue: { type: Specific },
    label: { type: String, default: '' },
    list: { type: Array, default: null },
    currentName: { type: String },
    newName: { type: String },
    preset: { type: Specific },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      selectValue: null,
      inputValue: null,
      load: false,
      TextInputBox,
      SearchDropSelect,
    };
  },
  computed: {
    selectedPreset() {return { list: this.list, translate: false, auto: true, selectOnly: true, };},
    isPossibleInput() {
      return this.selectValue !== 'custom';
    }
  },
  watch: {
    selectValue: 'updateSelectValue',
    inputValue: 'updateInputValue'
  },
  methods: {
    select(v) {
      if (!v) return;
      this.selectValue = v?.value;
    },
    input(v) {
      if (!v) return;
      this.inputValue = v;
    },
    updateSelectValue() {
      if (!this.selectValue || this.selectValue === '') return;
      if (this.selectValue !== 'custom') {
        this.inputValue = this.selectValue;
      } else {
        this.inputValue = this.currentValue;
      }
    },
    updateInputValue() {
      this.$emit('input', this.inputValue);
    }
  },
  mounted() {
    this.inputValue = this.value;
    this.$nextTick(() => {
      this.selectValue = this.list.map(v => v.value).indexOf(this.value) >= 0 ? this.value : 'custom';
      this.load = true;
    });

  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[time-limit-row] {.w(100%);.flex(); .flex-dc(); .gap(10);
  .label {.c(#000); .fs(16); font-weight: 600; .pl(5);}
  .content {.flex(); .flex-dc(); .gap(10); .bgc(#EBEBEF); .br(16); .p(20);}
}
</style>
