<template>
  <div temp-account-expired v-if="modelInfo">
    <p v-html="$t('onBoarding.tempAccountExpired.description', { duration })" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'TempAccountExpired',
  components: {},
  mixins: [baseTemplate],
  computed: {
    site: state('env', 'site'),
    duration() {
      return this.site === siteIds.GGPDE ? '72 hours' : '30 Days';
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[temp-account-expired] {
  > p { .c(@c-text-desc); .mb(24); }
  > *:nth-of-type(2) { .mt(8); }
}
</style>