<template>
  <div fi-mobile class="field-wrapper">
    <div class="header">
      <p class="desc" v-html="desc" />
    </div>
    <div class="field-holder">
      <div class="mobile-holder">
        <ValidationComponent :label="$t('mobileNumber')" name="_countryNumber" :component="CountryNumberDropSelect" rules="requiredSelect" v-model="countryInfo" @input="updateCountry" @focus="$emit('focus')" @blue="$emit('blue')" />
        <ValidationComponent class="number-field" name="_mobileNumber" :component="TrimTextInput" rules="required" v-model="modelInfo.MobileNumber" :preset="{phone: true, type: 'tel'}" />
      </div>
      <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />
      <LimitTimer class="error-msg" v-if="resendTime > 0" :time="resendTime" @complete="timerComplete" />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CountryNumberDropSelect from '@/views/components/common/CountryNumberDropSelect.vue';
import { brandIds, displayBrandNames } from '@/constants/base/siteMap';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';

export default {
  name: 'FiMobile',
  components: { LimitTimer, ValidationComponent },
  props: {
    structure: Specific,
    modelInfo: Specific,
    resendTime: 0,
  },
  data() {
    return {
      countryInfo: null,
    };
  },
  computed: {
    brandName : state('env', 'brandName'),
    CountryNumberDropSelect() {
      return CountryNumberDropSelect;
    },
    addressPreset() {
      return { placeholder: this.placeholder, maxLength: 100, type: 'loqate-address' };
    },
    TrimTextInput() {
      return TrimTextInput;
    },
    brand: state('env', 'brand'),
    desc() {
      return this.$t(this.structure.desc, { method: this.$t('mobile'), brand: this.displayBrandName });
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
  },
  watch: {
    'modelInfo.MobileNumber': function () {this.$emit('clear-error'); },
    'modelInfo.CountryCode': function () {this.$emit('clear-error'); }
  },
  methods: {
    updateCountry(v) {
      if (!v) return;
      this.countryInfo = v;
      if (this.countryInfo) {
        this.modelInfo.PhoneCountryCode = this.countryInfo.PhoneCountryCode;
        if (!this.isGgpass) this.modelInfo.CountryCode = this.countryInfo.CountryCode;
        else this.modelInfo.CountryCode = `+${this.countryInfo.PhoneCountryCode}`;
      }
    },
    timerComplete() {
      this.$emit('timer-complete');
    },
  },
  async mounted() {
    this.modelInfo.RequestType = 'VerificationMobile';
    this.structure.methodType = 'Mobile';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-mobile] {.flex();.flex-dc();
  .field-holder {flex: 1;}
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
  .mobile-holder { .flex(); .flex-dc();
    > div + div { .mt(4); }
    @media (@ml-up) {
      .flex(); .flex-dr(); .space-between();
      > div + div { .mt(0); }
      > * {
        &:nth-of-type(1) { .w(35%); }
        &:nth-of-type(2) { .w(calc(65% - 8px)); }
      }
    }
  }
}
</style>