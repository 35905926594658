import { jumioType } from '@/constants/base/onboarding/kyc';
import { siteIds } from '@/constants/base/siteMap';

export class JumioDocs {
  #site;
  #docs;
  constructor(site) {
    this.#site = site;
    this.#docs = this.getDocuments();
  }

  getDocuments() {
    const getDeDocuments = () => [
      { label: 'kyc.documentType.BankOpeningLetter', value: 'BankOpeningLetter', selectable: true, description: null, jumioType: jumioType.BS, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.UtilityBill', value: 'UtilityBill', selectable: true, description: null, isPoi: false, isPoa: true, jumioType: jumioType.UB },
      { label: 'kyc.documentType.CreditCardStatement', value: 'CreditCardStatement', selectable: true, description: null, jumioType: jumioType.CCS, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.TaxAssessment', value: 'TaxAssessment', selectable: true, description: null, jumioType: jumioType.TR, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last12Months' },
      { label: 'kyc.documentType.SignedLeaseAgreement', value: 'SignedLease', selectable: true, description: null, jumioType: jumioType.LAG, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.currentYear' },
    ];
    const getUkDocuments = () => [
      { label: 'kyc.documentType.UtilityBillUk', value: 'UtilityBill', selectable: true, description: null, isPoi: false, isPoa: true, jumioType: jumioType.UB, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.BankStatementUk', value: 'BankStatement', selectable: true, description: null, jumioType: jumioType.BS, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.BuildingSocietyStatement', value: 'BankStatement', selectable: true, description: null, jumioType: jumioType.BS, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.CreditCardStatement', value: 'CreditCardStatement', selectable: true, description: null, jumioType: jumioType.CCS, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last3Months' },
      { label: 'kyc.documentType.CouncilTaxBill', value: 'ProofAddress', selectable: true, description: null, jumioType: jumioType.CB, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last12Months' },
      { label: 'kyc.documentType.P45P60', value: 'ProofAddress', selectable: true, description: null, jumioType: jumioType.TR, isPoi: false, isPoa: true, period: 'onBoarding.jumio.documentsSelect.last12Months' },
    ];
    const getDefaultDocuments = () => [
      { label: 'kyc.documentType.BankOpeningLetter', value: 'BankOpeningLetter', selectable: true, description: null, isPoi: false, isPoa: true, jumioType: jumioType.BS },
      { label: 'kyc.documentType.UtilityBill', value: 'UtilityBill', selectable: true, description: null, isPoi: false, isPoa: true, jumioType: jumioType.UB },
    ];

    let kycDocuments;
    switch (this.#site) {
      case siteIds.GGPDE:
        kycDocuments = getDeDocuments();
        break;
      case siteIds.GGPUK:
        kycDocuments = getUkDocuments();
        break;
      default:
        kycDocuments = getDefaultDocuments();
        break;
    }
    return kycDocuments;
  }

  getDocumentsByIncludesValues(values) {
    if (!values) return;
    return this.#docs?.filter(doc => values.includes(doc.value));
  }

  getDocumentsByIncludesTypes(types) {
    if (!types) return;
    return this.#docs?.filter(doc => types.includes(doc.jumioType));
  }
}
