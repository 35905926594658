import { apiErrorCode } from '@/constants/base/apiErrorCode';
export default class FindIdService {
  #services;
  #api;
  #store;
  #ggpass;
  #siteId;
  #findKey;
  constructor(services) {
    this.#services = services;
    this.#api = services.api;
    this.#store = services.store;
    this.#ggpass = this.#store.state.env.ggpass;
    this.#siteId = this.#store.state.env.site;
  }

  async requestEmailCert({ Email, IsResend, Challenge, SecCode, Validate, IsOffline = false, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction }) {
    try {
      const r = await this.#api.post('/findid/email/send', { Email, IsResend, IsOffline, SiteId: this.#siteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate });
      if (r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) {
        return { ...r, key: 'limit', value: (r.desc || r.desc2).replace(/[^0-9]/g, '') };
      }
      return r;
    } catch (e) {
      console.log('error : requestEmailCert ');
      throw e;
    }
  }

  async requestGgpassEmailCert({ Email, IsResend, Challenge, SecCode, Validate, IsOffline = false, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction }) {
    try {
      const r = await this.#api.post('/findid/email/onepass/send', { FindKey: this.#store.state.query.findKey, Email, IsResend, IsOffline, SiteId: this.#siteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate });
      if (r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) {
        return { ...r, key: 'limit', value: (r.desc || r.desc2).replace(/[^0-9]/g, '') };
      }
      return r;
    } catch (e) {
      console.log('error : requestGgpassEmailCert ');
      throw e;
    }
  }

  async requestMobileCert({ CountryCode, MobileNumber, MessageType = 'SMS', IsResend, Challenge, SecCode, Validate, IsOffline = false, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction }) {
    try {
      const r = await this.#api.post('/findid/mobile/send', { CountryCode, MobileNumber, MessageType, SiteId: this.#siteId, IsResend, Challenge, SecCode, Validate, IsOffline, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction });
      if (r?.key === apiErrorCode.PHONE_RESEND_TERM_LIMITED) {
        return { ...r, key: 'limit', value: (r.desc || r.desc2).replace(/[^0-9]/g, '') };
      }
      return r;
    } catch (e) {
      console.log('error : requestMobileCert ');
      throw e;
    }
  }

  /**
   * @returns { Promise<FindIdVerify>}
   * */
  async verify({ RequestType, Email, CountryCode, MobileNumber, Username, Password, VerificationCode, Challenge, SecCode, Validate, IsOffline = false, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction }) {
    try {
      const r = await this.#api.post('/findid/verify', { RequestType, Email, CountryCode, MobileNumber, Username, Password, VerificationCode, SiteId: this.#siteId, Challenge, SecCode, Validate, IsOffline, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction });
      return r;
    } catch (e) {
      console.log('error : verify ');
      throw e;
    }
  }

  async ggpassVerify({ Email, VerificationCode }) {
    try {
      const r = await this.#api.post('/findid/email/onepass/verify', { FindKey: this.#store.state.query.findKey, Email, VerificationCode, SiteId: this.#siteId });
      return r;
    } catch (e) {
      console.log('error : ggpassVerify ');
      throw e;
    }
  }

  async confirmPassword({ FindKey, Password }) {
    try {
      const r = await this.#api.post('/findid/password', { FindKey: this.#store.state.query.findKey, Password, SiteId: this.#siteId,  });
      return r;
    } catch (e) {
      console.log('error : confirmPassword ');
      throw e;
    }
  }

  async getPwRule() {
    try {
      const r = await this.#api.get('/findid/password-config', { FindKey: this.#store.state.query.findKey });
      return r;
    } catch (e) {
      console.log('error : getPwRule ');
      throw e;
    }
  }
}