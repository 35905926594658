import bt from '@/constants/base/t';
import { customDateFormat, getTime, getUtcTime } from '@shared/utils/timeUtils.mjs';
import * as Sentry from "@sentry/vue";
export default class TokenService {
  #api;
  #userApi;
  #npDevApi;
  #services;
  #gwToken;
  #isLocal;
  constructor(services) {
    this.#services = services;
    this.#api = services.passApi;
    this.#userApi = services.userApi;
    this.#npDevApi = services.npDevApi;
  }

  /**
   * @ returns {Promise{GatewayToken}}
   * */
  async updateGwToken(queries, reset) {
    try {
      if(queries.gwToken) {
        this.#saveGwToken(queries.gwToken, queries.gwExpired);
      } else {
        this.#isLocal = true;
        if (!this.#gwToken || reset) await this.#getGwToken();
      }
      // setInterval(() => {
      //   const expired = this.#services.state.user.gwExpired;
      //   if(getUtcTime() > expired) this.#isLocal ? this.#getGwToken() : '';
      // },3000);
      return true;
    } catch (e) {
      console.log('error : updateGwToken', e);
    }
  }

  async #getGwToken() {
    this.#gwToken = await this.#api.post('/auth/token?grant_type=client_credentials', null, { headers: { 'Authorization': `Basic ${btoa(`${atob(bt.i)}:${atob(bt.s)}`)}` }, silent: true });
    // this.#services.store.commit('env/deliveryGap', `token request: ${customDateFormat(st, 'HH:mm:ss')} / token get : ${customDateFormat(et, 'HH:mm:ss')} / token get time : ${et-st} millisecond`);
    this.#saveGwToken(this.#gwToken.access_token, getUtcTime() + this.#gwToken.expires_in);
  }

  #saveGwToken(token, expired) {
    this.#services.store.commit('user/setGwToken', token);
    this.#services.store.commit('user/setGwExpired', expired);
  }

  async npAccessTokenForDev({token}) {
    try {
      return await this.#npDevApi.get('/player/access-tokens', {token});
    } catch (e) {
      console.log('error : npAccessTokenForDev', e);
      throw e;
    }
  }
}
