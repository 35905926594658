<template>
  <div v-if="model.CountryCode" input-mobile-number>
    <div  class="input-mobile-holder">
      <InputComponent v-if="currentMobileNumber" name="_currentMobileNumber" :component="TextInputBox" :label="$t('_.currentMobileNumber')" v-model="currentMobileNumber" :preset="{disable: true}" />
      <ValidationProvider ref="refObserver" rules="required" name="_mobileNumber" v-slot="{errors, dirty, touched, valid}" :slim="true">
        <label class="label">{{ $t('myInfo.newMobileNumber') }}<span>*</span></label>
        <input type="hidden" v-model="model.MobileNumber">
        <div class="mobile-input" ref="box">
          <CountryNumberDropDown :disabled="disabled" name="_countryCode" v-model="model.CountryCode" @input="updateCountry" :innerWidth="innerWidth" @focus="$emit('focus')" @blue="$emit('blue')" :placeholder="$t('personalDetails.country')" />
          <div>
            <TextInputBox class="text-box" v-model="model.MobileNumber" phone type="tel" :placeholder="$t('mobileNumber')" @input="input" :disabled="disabled" />
          </div>
        </div>
        <ValidationErrors class="error-msg" :error-msg="error.MobileNumber" />
      </ValidationProvider>
    </div>

  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
export default {
  name: 'InputMobileNumber',
  components: { DropSelect, LimitTimer, InputComponent, CountryNumberDropDown, TextInputBox },
  lexicon: 'myInfo.mobileNumber',
  props: {
    model: Specific,
    error: Specific,
    disabled: { type: Boolean, default: false, },
    isVerified: { type: Boolean, default: false, },
    invalid: Specific,
    isGpApi: { type: Boolean, default: false, },
    currentMobileNumber: { type: String, default: null },
  },
  data() {
    return {
      innerWidth: 0,
      countryCode : null,
      TextInputBox,
      CountryNumberDropDown
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
  },
  methods: {
    // updateCountryCode(){
    //   this.model.CountryCode = this.countryCode
    // },
    updateCountry(item) {
      if (!item) return;
      this.countryCode = item.CountryCode;
      this.MobileCountryCode = item.CountryCode;
      this.CountryCode = item.CountryCode;
      this.PhoneCountryCode = item.PhoneCountryCode;

      this.model.CountryCode = item.CountryCode;
      this.model.CountryNumber = item.PhoneCountryCode;
    },
    input(v) {
      this.model.MobileNumber = v;
    }
  },
  mounted() {
    if (!this.model.CountryCode) {
      this.model.CountryCode = this.userInfo.CountryCode;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-mobile-number] {
  .input-mobile-holder { .flex(); .flex-dc(); .bgc(@gp-bgc-gray); .p(20, 15, 20, 15); .br(16); .rel(); .gap(10);
    .label { .fs(14); .c(#737373); .pl(3);
      > span {.c(@gp-green); .pl(3);}
    }
    .mobile-input { .flex(); .flex-dc(); .gap(5); .pt(5);
      > div + div { .mt(4); }
      [gp-text-input-box] {
        >input {&::placeholder {.c(#A1A1AA);}}
        &::after {content: none}

      }
    }
    > div > [validation-errors] {.pl(0); .ml(4); .mt(5);}
  }
  @media (@mm-up) {
    .input-mobile-holder {
      .mobile-input {.flex-dr();.space-between();
        > div + div { .mt(0); }
        > * {
          &:nth-of-type(1) { .w(35%); }
          &:nth-of-type(2) { .w(calc(65% - 8px)); }
        }
      }
    }
  }

  [gp-text-input-box] {
    > input {
      &::placeholder {.c(@c-b05);}
    }
  }

}
</style>