import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { siteIds } from '@/constants/base/siteMap';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    pendingUrlInfo() {
      if (this.isGGPBE) {
        return {
          url: 'https://www.gamingcommission.be',
          text: 'gamingcommission.be',
        };
      }
      if (this.isGGPNL) {
        return {
          url: 'https://ggpoker.nl/en/responsible-gaming/',
          text: this.$t('responsibleGaming.responsibleGaming').toLowerCase(),
        };
      }
      return {
        url: 'https://responsiblegambling.org',
        text: 'responsiblegambling.org',
      };
    }
  },
  methods: {
    apiErrorHandler(error) {
      const { key, desc } = error;
      let message = null;
      let subMessage = null;
      switch (key) {
        case apiErrorCode.ALREADY_HAVE_RG_PENDING_LIMIT: {
          const { url, text } = this.pendingUrlInfo;
          message = this.$t('RG_ALREADY_HAVE_RG_PENDING_LIMIT', { url: url, text: text });
          break;
        }
        case apiErrorCode.REJECT_FROM_BGC:
          message = this.$t(key);
          break;
        case apiErrorCode.INVALID_PLAYER:
          message = this.$t('RG_INVALID_PLAYER');
          break;
        case apiErrorCode.INVALID_DAY1_DEPOSIT_LIMIT:
          message = this.$t('INVALID_DAY_DEPOSIT_LIMIT', { day1: this.$t('daily'), day2: this.$t('weekly') });
          break;
        case apiErrorCode.INVALID_DAY7_DEPOSIT_LIMIT:
          message = this.$t('INVALID_DAY_DEPOSIT_LIMIT', { day1: this.$t('weekly'), day2: this.$t('monthly') });
          break;
        case 'OASIS_ERROR':
        case apiErrorCode.OASIS_CREATE_BAN_FAILED: {
          message = this.$t('OASIS_CREATE_BAN_FAILED');
          break;
        }
        case apiErrorCode.NEGATIVE_TIME_DURATION_NOT_ALLOWED:
          message = this.$t(key);
          break;
        case 'NEGATIVE_FREQUENCY_NOT_ALLOWED':
          message = this.$t(key);
          break;
        case apiErrorCode.NEGATIVE_TIME_INTERVAL_NOT_ALLOWED:
          message = this.$t(key);
          break;
        case apiErrorCode.NEGATIVE_AMOUNT_NOT_ALLOWED:
          message = this.$t(key);
          break;
        case apiErrorCode.LUGAS_INTERNAL_ERROR:
          const lugasCode = error.CustomerErrorParameters[0] ? error.CustomerErrorParameters[0].trim() : null;
          const lugasErrorPrefix = 'onBoarding.lugasStep.';
          if (lugasCode) {
            message = this.$t(lugasErrorPrefix + lugasCode, { email: this.csEmail });
          } else {
            message = this.$t('RG_UNKNOWN_ERROR', { email: this.csEmail });
          }
          // message = this.$t('RG_LUGAS_INTERNAL_ERROR');
          break;
        case 'LOSS_LIMIT_NUMBER_OF_CHANGES_EXCEEDED' : {
          message = this.$t(key);
          subMessage = `(*${this.$t('responsibleGaming.modal.subTitle.onceMonthly')})`;
          break;
        }
        case apiErrorCode.INVALID_REQUEST:
          message = this.$t(key, { email: this.csEmail, csEmail: this.csEmail });
          break;
        default:
          message = this.$t('COMMON_EXCLUDE', { email: this.csEmail });
          break;
      }

      return { message, subMessage };
    }
  },
};