<template>
  <div daily-freebie>
    <p v-if="noActiveBonus" class="no-active-bonus">{{$t('promotion.noActiveBonus')}}</p>
    <template v-else>
      <div v-if="notifications && notifications.length > 0">
        <Notification v-for="notification in notifications" :info="notification" :key="notification.name" />
      </div>
      <div class="contents">
        <h2><span>{{ $t('_.title') }}</span></h2>
        <TermsAndConditionsLink :status-info="dailyInfo" />
        <div class="freebie">
          <div class="today" v-if="isEnablePromotion">
            <p class="title">{{ $t('_.label') }}</p>
            <p class="day" v-if="hasDayOfWeek">{{ $t(`promotion.dayOfTheWeek.${todayItem.dayOfWeek.slice(0, 3).toLowerCase()}`) }}</p>
            <BonusTicket :info="todayTicket" :active="true" />
            <p class="expiry">{{ $t('_.description') }}</p>
          </div>
          <div class="next-reward" v-else>
            <p class="title">{{ $t('_.nextReward') }}</p>
            <PromotionTimer :time="remainTime" />
          </div>
          <Slider slides-per-view="auto" :info="itemList">
            <template v-slot:slide="{ info }">
              <p v-if="confirmHasDayOfWeek(info)">{{ $t(`promotion.dayOfTheWeek.${(/** @type {DailyFreebieItem} */ info).dayOfWeek.slice(0, 3).toLowerCase()}`) }}</p>
              <BonusTicket :info="(/** @type {DailyFreebieItem} */ info).tickets[0]" />
            </template>
          </Slider>
        </div>
        <div class="term-and-conditions" v-if="enabledTnC">
          <CheckboxItem theme="dark" :disabled="isRequiredKyc || isError" v-model="agreedNLTnC" @change="changeStatusOfTnC">
            <span v-html="$t('_.acceptPromotionTnC', { url: dailyFreebieTermsAndConditionUrl })" />
          </CheckboxItem>
          <p v-if="showErrorOfTnC">{{ $t('_.requiredPromotionTnC') }}</p>
        </div>
        <div class="btn-box">
          <PromotionButton v-if="isEnablePromotion" @click="optIn" :disabled="claimDisabled">{{ $t('promotion.claimYourFreeReward') }}</PromotionButton>
          <PromotionButton disabled-style="grey" disabled v-else>{{ $t('promotion.freeRewardReleased') }}</PromotionButton>
          <ul v-if="isEnablePromotion && (env === 'local' || env === 'test')">
            <li v-for="(day, i) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="day">
              <PromotionButton @click="optIn(i)" :disabled="claimDisabled">{{ day }}</PromotionButton>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Slider from '@shared/components/general/Slider.vue';
import BonusTicket from '@/views/components/promotion/BonusTicket.vue';
import TermsAndConditionsLink from '@/views/components/promotion/TermsAndConditionsLink.vue';
import PromotionTimer from '@/views/components/promotion/PromotionTimer.vue';
import PromotionButton from '@/views/components/promotion/PromotionButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Notification from '@/views/components/promotion/Notification.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { isObjectEmpty } from '@shared/utils/objectUtils.mjs';
import { toMilliseconds } from '@/utils/dateTimeUtil';

export default {
  name: 'DailyFreebie',
  lexicon: 'promotion.daily',
  components: { Notification, PromotionButton, PromotionTimer, TermsAndConditionsLink, BonusTicket, Slider, CheckboxItem },
  data() {
    return {
      dailyInfo: null,
      errorCode: {
        code: null,
        customerErrorCode: null,
      },
      claimedRewarded: false,
      agreedNLTnC: false,
      showErrorOfTnC: false,
      noActiveBonus: false,
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    isRequiredKyc() {
      return this.dailyInfo?.isRequiredKyc;
    },
    dailyFreebieTermsAndConditionUrl() {
      return this.dailyInfo?.dailyFreebieTermsAndConditionUrl;
    },
    isEnablePromotion() {
      return this.dailyInfo?.isEnablePromotion;
    },
    itemList() {
      return (this.dailyInfo?.items) || [];
    },
    todayItem() {
      return this.dailyInfo?.todayItem || {};
    },
    todayTicket() {
      return this.todayItem?.tickets?.[0] || {};
    },
    buttonType() {
      return this.isEnablePromotion ? 'light-red' : 'medium-gray';
    },
    remainTime() {
      return this.suspended ? 0 : toMilliseconds(this.dailyInfo?.nextReward);
    },
    hasDayOfWeek() {
      return this.confirmHasDayOfWeek(this.todayItem);
    },
    isError() {
      const { code, customerErrorCode } = this.errorCode;
      const isPromotionError = ['ACCESS_DENIED', 'INVALID_PLAYER'].includes(code);
      const isCustomerError = ['KYC_UNVERIFIED', 'KYC_DOCUMENT_NOT_UPLOADED', 'KYC_TRIGGER_NOT_NONE', 'MAC_ADDRESS_DUPLICATE'].includes(customerErrorCode);

      return isPromotionError || isCustomerError;
    },
    enabledTnC() {
      if (!this.dailyInfo) return false;
      const { country, isFirstOptIn } = this.dailyInfo;
      const isFirstOptInToNL = country === 'NL' && isFirstOptIn;

      this.confirmUseTnC(isFirstOptInToNL);
      return this.isEnablePromotion && isFirstOptInToNL;
    },
    suspended() {
      return !isObjectEmpty(this.dailyInfo?.suspendPromotionData);
    },
    claimDisabled() {
      if (!this.dailyInfo) return false;
      const { isEnablePromotion, isRequiredKyc, promotionRestriction } = this.dailyInfo;
      const restrictionStatus = promotionRestriction?.status;
      const unconfirmedTnC = this.enabledTnC && !this.agreedNLTnC;
      const restricted = ['Restriction', 'Edit'].includes(restrictionStatus);

      return !isEnablePromotion || isRequiredKyc || unconfirmedTnC || restricted || this.isError;
    },
    notifications() {
      return this.$promotion.getNotifications('daily', { info: this.dailyInfo, customerErrorCode: this.errorCode.customerErrorCode, claimedRewarded: this.claimedRewarded });
    },
  },
  methods: {
    confirmHasDayOfWeek(item) {
      return item?.dayOfWeek && item.dayOfWeek.length > 0;
    },
    confirmUseTnC(isFirstOptInToNL) {
      this.agreedNLTnC = isFirstOptInToNL ? this.agreedNLTnC : true;
    },
    async optIn(day) {
      if (!this.agreedNLTnC) return this.showErrorOfTnC = true;

      const r = await this.$services.promotion.optInFreebie({ reset: true, DayOfWeek: day });
      if (r?.code >= 200 && r?.code < 300) {
        this.claimedRewarded = true;
      } else {
        this.errorCode = { code: r?.errorTemplate?.queries?.code, customerErrorCode: r?.errorTemplate?.queries?.customCode };
      }
      await this.updateInfo();
    },
    async updateInfo() {
      const r = await this.$services.promotion.dailyPromotion();
      if (r == null) return this.noActiveBonus = true;
      if (r?.error) {
        await this.replaceRouteName(r?.path);
      } else {
        this.dailyInfo = r;
      }
    },
    changeStatusOfTnC() {
      return this.showMessageOfTnC = this.agreedNLTnC ? true : this.showMessageOfTnC;
    }
  },
  async mounted() {
    await this.updateInfo();
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
.TWOACE [daily-freebie] [slider] .swiper-slide { .ph(4); }
[daily-freebie] {
  [slider] { .-t(@c-darkGrey);
    .swiper-wrapper { .grid(); .grid-template-columns(repeat(6, 1fr)); .min-w(820); .tc(); .o(0.5); }
    .swiper-slide { .w(); .h(); .p(16, 10);
      + .swiper-slide { .-l(@c-darkGrey); }
      [bonus-ticket] { .wf(); .mt(10); }
    }
  }
  [checkbox-item] { .inline-flex(); .justify-center(); .items-center(); .fs(inherit); .c(@c-whiteSmoke);
    &.disabled { .o(0.5); .default();
      i { .bgc(@c-whiteSmoke); }
      a { .default(); }
    }
    a { .ib(); .c(@c-blue); .-b(transparent); }
    &:not(.disabled) {
      a:hover { .-c(@c-blue); }
    }
    i { .wh(1.125em); .-c(@c-whiteSmoke); .br(4px); .mt(0);
      svg { .wh(1em); }
      &.checked { .bgc(@c-whiteSmoke);
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
.TWOACE [daily-freebie] .contents .freebie .today [bonus-ticket] { .w(180); }
[daily-freebie] {
  .no-active-bonus { .tc(); .mt(60); }
  .contents { .wf(); .max-w(962); .mh-c(); .p(32, 16); .bgc(@c-nero); .tc();
    h2 { .medium(); .mb(30); .fs(24); }
    .link { .tr(); .fs(14); .c(@c-blue);
      a { .-b(@c-blue); }
    }
    .freebie { .mt(12); .bgc(@c-lightBlack);
      .title { .p(4, 10); .bgc(@c-darkGrey); .lh(1.4em); }
      [promotion-timer] { .pv(14); .fs(43); }
      .today { .pb(16);
        .title { .mb(20); }
        [bonus-ticket] { .w(160); .m(10, auto); .fs(20); }
        .expiry { .fs(12); .c(@c-grey55); }
      }
    }
    .term-and-conditions { .mt(30); .fs(1em);
      + .btn-box { .mt(20);}
      p { .mt(2); .fs(0.875em); .c(@c-blurRed); }
    }
    .btn-box { .mt(30);
      [promotion-button] { .wf(); .max-w(436); }
      ul { .max-w(436); .flex(); .gap(6px); .m(0, auto);
        li { .w(calc(100% / 7)); }
        [promotion-button] {
          .min-w(auto); .mt(10); .p(10, 0); .br(10); .fs(14);
        }
      }
    }
  }

  @media (@ml-up) {
    .contents {
      .btn-box ul li [promotoin-button] { .fs(16); }
    }
  }
  @media (@tp-up) {
    .contents { .ph(70);
      .freebie .title { .pv(8); }
    }
  }
}
</style>
