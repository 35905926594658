export default class NoticeService {
  #services;
  #api;

  constructor(services) {
    this.#services = services;
    this.#api = services.userApi;
  }
  /**
   * @id GetNotificationBoardRequest
   * @returns {Promise<NoticeType>}
   * */
  async getNotification() {
    try {
      const r = await this.#api.get('/notification/status', {}, { silent: true });
      return r;
    } catch (e) {
      console.log('error : getNotification', e);
      throw e;
    }
  }

}