<template>
  <div email-search-select :class="{'has-list': filterList && filterList.length}">
    <ValidationComponent key="Email" name="_email" v-model="email" :component="SearchDropSelect" :rules="rules" :label="emailLabel" :preset="emailPreset" :error-msg="error" :label-color-gray="false" @updateSearch="updateSearch" />
    <ValidationProvider :rules="rules" name="_email" v-slot="{errors}">
      <input :value="searchValue" type="text" class="ghost" ref="ghostInput" tabindex="-1" />
<!--      <ValidationErrors v-if="(emailValue.length && errors.length) || error" class="error-msg" :errors="errors" :error-msg="error" />-->
    </ValidationProvider>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import emailList from '@/constants/base/email';
import Specific from '@shared/types/Specific';
import TextInput from '@shared/components/common/input/TextInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'EmailSearchSelect',
  components: { ValidationComponent },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: { type: Specific, default: null },
    rules: { type: String, default: 'requiredSignEmail' },
    errorMsg: { type: String, default: null },
    label: {type: String, default: null}
  },
  data() {
    return {
      searchValue: null,
      quartile: '*_*',
      TextInput,
      SearchDropSelect,
      email: null,
    };
  },
  computed: {
    emailValue() {
      return this.email || '';
    },
    emailPreset() {
      return { size: 'md', type: 'email', list: this.filterList, searchHandler: this.emailSearchHandler, showNoResult: false, searchValue: this.searchValue };
    },
    filterList() {
      const email = this.searchValue?.toLowerCase()?.split('@');
      const label = email?.[0] ? `${email[0]}@` : '';
      if (['local', 'development'].includes(process.env.VUE_APP_ENV) && !emailList.find(email => email.label === 'mailinator.com')) emailList.push({ label: 'mailinator.com', value: 'https://www.mailinator.com' });
      return email?.[0]?.length > 0 && email[1]?.length > 0 ? emailList.filter(o => o.label.startsWith(email[1]))?.map(i => ({ label: `${label}${i.label}`, value: `${i.label}${this.quartile}${i.value}` })).slice(0, 5) : null;
    },
    error() {
      return /** @type {string} */ this.errorMsg || this.structure.errorMsg || this.$route.params.errorMsg;
    },
    emailLabel() {
      return this.label ? this.label : this.$t('emailAddress');
    },
  },
  watch: {
    email() {
      this.modelInfo.Email = this.email?.label || this.email;
    },
  },
  methods: {
    updateSearch(v) {
      this.searchValue = v;
      this.email = v;
      this.$emit('input', v);
    },
    emailSearchHandler(originList, searchText) {
      return originList;
    },
  },
  mounted() {
    this.searchValue = this.modelInfo.Email?.label || this.modelInfo.Email;
    this.email = this.searchValue;
    // this.$refs.ghostInput.focus();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[email-search-select] {
  .ghost { .fix(); .z(-9999); }
  [validation-component] {
    //[validation-errors] { .hide(); }
  }
  [search-drop-select] {
    .list-container { .o(0) !important; }
  }
  &.has-list {
    [search-drop-select] {
      .list-container { .o(1) !important; }
    }
  }
}
</style>