<template>
  <GpFormTemplate np-verifi-code>
    <template #header>
      <GpDescription :message="description" />
      <GpDescription v-if="!isMobile" :message="$t('_.verifyCode.emailDescription')" />
    </template>
    <template #content="{invalid}">
      <div>
        <VerifyResendButton :model-info="model" :structure="structure" theme="green" :api-type="model.Type" :type="model.SendVerificationType" @complete="resendComplete" />
        <InputVerificationCode :model="model" :length="verifyLength" :error="error" />
        <!--        <VerifyPolling v-if="isPolling" :structure="structure" :model="model" @verified="verified" @error="pollingError" :reset="pollingReset" :interval-delay="5000" :max-interval-time="600000" />-->
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <OpenEmailApp v-if="isEmail" :email="model.Email" />
      <GpButton type="submit-green" @click.prevent="verification" :disabled="!!invalid || disableButton">{{ $t('_.verify') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { routeNames } from '@/constants/model/my-page';
import Specific from '@shared/types/Specific';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import InputVerificationCode from '@/views/components/pages/my-page/template/inputs/InputVerificationCode.vue';
import ResendModal from '@/views/components/pages/my-page/modal/ResendModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import OpenEmailApp from '@/views/components/common/OpenEmailApp.vue';

export default {
  name: 'NpVerifyCode',
  components: { VerifyResendButton, InputVerificationCode, GpButton, GpFormTemplate, GpDescription, OpenEmailApp },
  lexicon: 'myInfo',
  mixins: [npErrorHandler],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
    isModal: { type: Boolean, default: false, }
  },
  data() {
    return {
      isPageLoad: false,
      VerificationMethod: null,
      SecurityLevel: null,
      pollingReset: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    onePassInfo: state('user', 'onePassInfo'),
    userInfo: state('user', 'userInfo'),
    verifyLength() {
      if (!this.isGgpass) return 4;
      if (this.isContactInfo) return 4;
      return 6;
    },
    verifyCheck() {
      if (!this.model.VerificationCode) return true;
      return this.verifyLength > this.model.VerificationCode.length;
    },
    disableButton() {
      return this.verifyCheck || this.error.VerificationCode !== null;
    },
    description() {
      let method = '';
      if(this.isMobile){
        const countryNumber = this.model?.CountryNumber ? `+${this.model?.CountryNumber}` : ''
        method = this.isGgpass ? `${countryNumber} ${this.model.MobileNumber}` : `${countryNumber} ${this.model.MobileNumber}`
      }else {
        method =  this.model.Email;
      }
      return this.$t('_.verifyCode.description', { length: this.verifyLength, method: method });
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    // isPolling() {
    //   return this.isGgpass && !this.isContactInfo && this.isEmail;
    // },
    isMfa() {
      return this.model.Type === 'MFA_TYPE_ADD';
    },
    isChangeLoginId() {
      return this.model.Type === 'CHANGE_LOGIN_ID';
    },
    isContactInfo() {
      return this.model.Type === 'CONTACT_INFO';
    },
    isEmail() {
      return this.model.LoginMethod === 'Email';
    },
    isMobile() {
      return this.model.LoginMethod === 'Mobile';
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },

  },
  methods: {
    async verification(token) {
      this.model.ApiType = this.model.Type;
      let payload = { ...this.model };
      if (!token) payload.Token = null;
      const isRefetch = !this.isContactInfo;
      const result = await this.$myinfo.request(payload, null, isRefetch);

      if (result.error) {
        if(result.key === 'contact' || result.key === 'contact-error') {
          await this.$myinfo.setMyInfo();
          this.$emit('success', 'myInfo.contactInfoSuccess', { contact: result.key });
          return;
        }
        this.apiErrorHandler(result, this.isEmail);
        return;
      }
      if (this.isMfa) {
        this.structure.backRoute = routeNames.NpLoginSecurity;
        this.$emit('success', 'myInfo.mfaSuccess');
        return;
      }

      if(this.isContactInfo){
        this.$emit('success', 'myInfo.contactInfoSuccess');
        return;
      }
      this.$emit('success', 'myInfo.loginIdChangeSuccess', { method: this.isEmail ? this.$t('emailAddress') : this.$t('mobileNumber') });
    },

    async resendComplete(result) {
      const { isSend } = result;
      if (!isSend) {
        if (result.error) {
          this.apiErrorHandler(result, this.isEmail);
          return;
        }
        this.$modal(ResendModal, { message: this.$t('_.verifyCode.resendFailed'), success: false, });
        return;
      }

      this.$modal(ResendModal, { message: this.$t('_.verifyCode.resendSuccess'), success: true, });
      this.model.Token = result?.token;

      // Polling 제거
      // if (this.isPolling) await this.restartPolling();
    },

    // Polling 제거
    // async restartPolling() {
    //   this.pollingReset = true;
    //   await sleep(60);
    //   this.pollingReset = false;
    // },

    // async verified() {
    //   await this.verification(true);
    // },

    // pollingError(error) {
    //   this.apiErrorHandler(error, this.isEmail);
    // },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-verifi-code] {
  [open-email-app] {.c(@gp-green); font-weight: 600;}
  [fp-resend-button] {
    .timer {.fs(#737373); .fs(12); }
    .resend {.fs(14); .pb(20); text-decoration-line: none; font-weight: 600;
      &.end {.c(@gp-green);}
    }
  }
  [verify-resend] {.flex(); .justify-end(); .pb(10);}
  [gp-description] {
    > p {line-height: 18px;
      > em {.c(black)}
    }
  }
}

</style>