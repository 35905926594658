<template>
  <div link-amount-item @click="click">
    <div class="content-group">
      <div class="label-group">
        <RadioButton v-if="isRadio" :active="active" />
        <div :class="['label', {'disable' : disable}]">
          <img :class="['img-country', {'disable' : disable}]" :src="logoUrl" :alt="label" width="12" height="8">
          <p class="label-wrapper">
            <span>{{ label }}</span>
            <TooltipItem v-if="disable || toolTipMessage" :text="toolTipMessage || '-'">
              <FontIcon name="exclamation-circle-regular-sharp" />
            </TooltipItem>
          </p>
        </div>
      </div>
      <div :class="['amount','button-group', {'show-icon' : showIcon || isDropDown}, {'disable' : disable}]">
        <p>{{ currencySymbol }}{{ amount }}</p>
      </div>
      <FontIcon v-if="showIcon && !disable" name="caret-down" :class="['icon',{'on' : toggle}]" />
    </div>
    <div v-if="showButton" class="transfer-button">
      <GpButton class="type-button" type="line-green" size="sm" :disabled="disable" @click="transferClick">{{ $t('transfer') }}</GpButton>
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import TooltipItem from '@/views/components/gg-pass/TooltipItem.vue';

export default {
  name: 'LinkAmountItem',
  components: { TooltipItem, RadioButton, GpButton, CheckboxItem, FontIcon },
  props: {
    showButton: { type: Boolean, default: false, },
    showIcon: { type: Boolean, default: false, },
    isRadio: { type: Boolean, default: false, },
    label: { type: String, default: '' },
    countryName: { type: String, default: '' },
    value: { type: String, default: null, },
    currentValue: { type: String, default: '', },
    countryCode: { type: String, default: null, },
    amount: { type: String, default: null, },
    isDropDown: { type: Boolean, default: false, },
    toggle: { type: Boolean, default: false, },
    active: { type: Boolean, default: false, },
    isCheckBox: { type: Boolean, default: false, },
    disable: { type: Boolean, default: false, },
    currencySymbol: { type: String, default: null },
    toolTipMessage: { type: String, default: null, },
    logoUrl: { type: String, default: null }
  },
  data() {
    return {
      checkedValue: false,
    };

  },
  computed: {
    isActive() {
      return this.value === this.currentValue;
    }
  },
  methods: {
    getImgSrc(code) {
      return `https://static.ggtechdev.com/ux/game/platform-front/img/${code}.svg`;
    },
    click() {
      if (this.disable) return;
      this.$emit('click', this.value, this.active);
    },
    transferClick(v) {
      if (this.disable) return;
      this.$emit('transferClick', this.value);
    }
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[link-amount-item] { .w(100%); .bgc(#fff); .br(16); .flex(); .flex-dc(); .p(20); .rel(); .gap(10);
  .check-box-group { .flex(); .pt(5); .h(16); }
  .content-group { .flex(); flex: 1; .space-between(); .rel();
    .label-group { .flex(); .items-center();
      .img-country {.wh(28); object-fit: fill;}
      .label-wrapper {.flex(); .gap(5); .items-center();
        > [tooltip-item] {.p(0); .flex(); .justify-end(); .items-end();
          [font-icon] {.rel(); .pt(2);}
          > button {.p(0); .c(#737373); .flex();}
        }
      }
      [gp-radio-button] {.pt(0);}
      .label { .flex(); .flex-dr(); .items-center(); .gap(10); .rel();
        > p {.fs(14); .c(black); }
        &.disable {
          > p {.c(#ccc);}
        }
      }
      [font-icon] {.ib(); .fs(16); .abs(); .r(0);
        &.on { .t-r(180deg); .z(2); .t(7) }
      }
    }
    .button-group { .flex(); .flex-dc(); .gap(10); .items-center();
      &.show-icon {
        > p { .pr(25); }
      }
      > p {
        .c(black); font-weight: 500;
      }
      &.disable {
        > p {.c(#ccc);}
      }
      .type-button {.w(80)}
    }
  }
  .transfer-button { .flex(); .justify-end();
    [my-info-button] {.br(100); width: 90px !important;}
  }
  &.on { .bgc(#F8FBF9); }
  .icon {.abs(); right: 0; top: 0; .c(black);
    &.on {transform: rotate(180deg); top: 5px;}
  }

  @media (@ml-up) {
    .flex-dr(); .items-center();
  }
}
</style>
