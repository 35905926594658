<template>
  <div highest-password>
    <h2> {{ $t('_.title') }}
      <span v-html="$t('_.description')" />
    </h2>
    <ValidationPassword :config="config" :label="$t('_.labelNewPassword',{min, max})" name="newPassword" :rules="pwRules" v-model="modelInfo.Password" :preset="newPasswordPreset" :label-color-gray="false" />
    <ValidationComponent :component="PasswordInput" :label="$t('_.labelConfirmPassword')" name="confirmPassword" :rules="confirmRules" :label-color-gray="false" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { emailPasswordValidation, mobilePasswordValidation } from '@/utils/pwUtil';
import baseTemplate from '@/mixins/baseTemplate';
import ValidationPassword from '@/views/components/common/ValidationPassword.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';

export default {
  name: 'HighestPassword',
  lexicon: 'signUp.newPassword',
  components: { ValidationComponent, ValidationPassword },
  mixins: [baseTemplate],
  data() {
    return {
      config: null,
      password: null,
      confirmPassword: null,
      PasswordInput
    };
  },
  computed: {
    site: state('env', 'site'),
    min() {
      return this.config?.passwordMinLength || 8;
    },
    max() {
      return this.config?.passwordMaxLength || 20;
    },
    pwRules() {
      if (!this.config) return '';
      return this.isEmailForm ? emailPasswordValidation(this.config, this.modelInfo.Email) : mobilePasswordValidation(this.config, this.modelInfo.MobileNumber);
    },
    confirmRules() {
      return `required|confirmPassword:${this.modelInfo.Password}`;
    },
    newPasswordPreset() {
      return { placeholder: this.$t('_.placeHolderNewPassword'), maxLength: this.max };
    },
    confirmPasswordPreset() {
      return { placeholder: this.$t('_.placeHolderConfirmPassword'), maxLength: this.max };
    },
  },
  methods: {
    async initialize() {
      this.config = await this.$services.ggpass.getHighestConfig();
    },
    confirm() {
      if (this.password === this.confirmPassword) {
        this.modelInfo.Password = this.password;
      }
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[highest-password] {
  > h2 { .fs(22, 30); .c(#CCCCCC); .regular(); .mb(16);
    span { .block(); .regular(); .fs(16); .c(@c-text-desc); }
  }
  [validation-password] { .mb(8); }
}
</style>
