import _ from 'lodash';

const view = path => () => import(/* webpackChunkName: "signUp" */ `../views/pages/sign-up/${path}.vue`);

export default ({ local, ggpass, npOnly }) => {
  const path = local ? 'signup/' : 'app/';
  const countryAlias = {
    path: ':country',
    alias: [':country/:regionSite(dtd)', ':country/:regionSite(eapt)', ':country/:regionSite(roadtovegas)', ':country/:regionSite(wsop-paradise)', ':country/:regionSite(ocean)'],
    redirect: `/`
  };
  const country = {
    path: 'country',
    name: 'SignUpCountry',
    component: view('SignUpCountry'),
  };
  const personalInfo = {
    path: 'personalInfo',
    name: 'SignUpPersonalInfo',
    component: view('SignUpProgress'),
  };
  const tnc = {
    path: 'tnc',
    name: 'SignUpTnc',
    component: view('SignUpProgress'),
  };
  const verify = {
    path: 'verify',
    name: 'SignUpVerify',
    component: view('SignUpProgress'),
  };
  const highestPassword = {
    path: 'highest-password',
    name: 'SignUpHighestPassword',
    component: view('SignUpProgress'),
  };
  const complete = {
    path: 'complete',
    name: 'SignUpComplete',
    component: view('SignUpProgress'),
  };
  const restrictLocation = {
    path: 'restrict-location',
    name: 'RestrictLocation',
    component: view('RestrictLocation'),
  };
  const restrictEmail = {
    path: 'restrict-email',
    name: 'RestrictedEmail',
    component: view('RestrictedEmail'),
  };
  const loginRestriction = {
    path: 'login-restriction',
    name: 'LoginRestriction',
    component: view('LoginRestriction'),
  };
  const list = [
    /** redirect */
    {
      path: `client/signup`,
      redirect: npOnly ? `${path}tnc` : ggpass ? `${path}personalInfo` : `${path}country`,
    },
    /** redirect */
    {
      path: `client/signup/country`,
      redirect: npOnly ? `${path}tnc` : ggpass ? `${path}personalInfo` : `${path}country`,
    },
    /** root */
    {
      path: `${path}`,
      alias: ['/tracking', '/eapt', '/roadtovegas', '/wsop-paradise', '/ocean'],
      name: 'SignUp',
      component: view('SignUp'),
      redirect: npOnly ? `${path}tnc` : ggpass ? `${path}personalInfo` : `${path}country`,
    },
    countryAlias,
  ];

  if(!npOnly && !ggpass) list[2].children = [country, personalInfo, tnc, verify, complete, restrictLocation, restrictEmail, loginRestriction];
  else if (npOnly) list[2].children = [tnc, highestPassword, restrictLocation, restrictEmail, loginRestriction];
  else if(ggpass) list[2].children = [personalInfo, verify, restrictLocation, restrictEmail, loginRestriction, complete];
  list[2].children.push({ path: '*', redirect: '/not-found' });
  return list;
};