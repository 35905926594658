<template>
  <div fi-email-guide class="field-wrapper">
    <div class="content">
      <FontIcon name="check-circle-regular" />
      <p v-html="guideText" />
      <p v-html="emailGuide" />
    </div>
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { brandIds, displayBrandNames } from '@/constants/base/siteMap';

export default {
  name: 'FiEmailGuide',
  components: { ProgressButton, FontIcon },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: Specific,
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
    guideText(){
      return this.$t('findId.guide.guideText', {brand : this.displayBrandName})
    },
    emailGuide(){
      return this.$t('findId.guide.emailGuide')
    }
  },
  methods: {
    async click(){
      await this.routeName('FiCreate');
    }
  },
  async mounted() {
    this.structure.title = null;
    this.structure.desc = null;
    this.structure.back = false;
    this.modelInfo.RequestType = '';
    this.structure.methodType = '';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-email-guide] { .flex(); .flex-dc(); .justify-center(); .align-center(); .w(100%); .gap(10); .pt(30);
  .content{.flex(); flex : 1;  .tc(); .flex-dc(); .fs(16); .c(#7F7F7F);
    [font-icon] { .block(); .fs(60); .c(#1DB77D); .mb(24); }
    >em{.c(white);}
  }
  .footer{.mb(80);.w(100%);
    [progress-button] {.max-w(100%);}
  }
}
</style>