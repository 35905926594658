<template>
  <BaseModal :show-title="isEditStep" responsible-confirm-modal :title="title" :class="{'result' : !isEditStep}" @close="close">
    <template v-if="isLoaded" #content>
      <component :is="getStepComponent" :title="title" :sub-title="subTitle" :isExceed="isExceed" :description="description" :data="data" :type="resultType" :subMessage="subMessage" :message="message" @input="input" @close="close" @submit="submit" @confirm="confirm" />
    </template>
    <template v-if="showFloating" #floating>
      <div class="floating">
        <GpButton v-if="isEditStep" type="line-green" @click="close">{{ $t('cancel') }}</GpButton>
        <GpButton v-if="isEditStep" type="submit-green" @click="submit">{{ $t('confirm') }}</GpButton>
        <GpButton v-if="!isEditStep" type="submit-green" @click="confirm">{{ $t('ok') }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';

import DisplayHangTimeModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayHangTimeModal.vue';
import ConfirmResult from '@/views/components/pages/responsible-gaming/modal/components/ConfirmResult.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import DisplayMessageModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayMessageModal.vue';
import DisplayOasisBanModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayOasisBanModal.vue';
import Specific from '@shared/types/Specific';
import DisplayExternalMonthlyModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayExternalMonthlyModal.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import rgErrorHandler from '@/mixins/responsible-gaming/rgErrorHandler';

export default {
  name: 'ResponsibleConfirmModal',
  components: { GpDescription, RadioList, GpButton, BaseModal },
  mixins: [rgErrorHandler],
  props: {
    options: {
      title: { type: String, default: null },
      subTitle: { type: String, default: null },
      description: { type: String, default: null },
      data: Specific,
      isExceed: { type: Boolean, default: false, },
      request: { type: Function, default: () => {} },
      modalType: { type: String, default: null, },
      model: Specific,
      selfExclusion: false,
      showFloating: { type: Boolean, default: true, },
      closeReturn: { type: Boolean, default: false, }
    }
  },
  data() {
    return {
      // Form Props
      title: null,
      data: null,
      description: null,
      subTitle: null,
      isExceed: false,
      step: 'EDIT',
      showFloating: true,

      // Result Props
      resultType: true,
      message: null,
      subMessage: null,
      closeReturn: false,

      // oasis 관련 처리용 변수
      oasisBanList: null,
      isLoaded: false,

      DisplayHangTimeModal,
      DisplayMessageModal,
      ConfirmResult,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    modalType() {
      return this.options.modalType;
    },
    getStepComponent() {
      return this.isEditStep ? this.getTypeComponent : ConfirmResult;
    },
    isEditStep() {
      return this.step === 'EDIT';
    },
    getTypeComponent() {
      switch (this.modalType) {
        case 'Message' :
          return DisplayMessageModal;
        case 'HangTime' :
          return DisplayHangTimeModal;
        case 'Oasis' :
          return DisplayOasisBanModal;
        case 'External':
          return DisplayExternalMonthlyModal;
        default :
          return DisplayHangTimeModal;
      }
    }
  },
  methods: {
    initialize() {
      this.data = this.options.data;
      this.title = this.options.title;
      this.subTitle = this.options.subTitle;
      this.description = this.options.desc;
      this.isExceed = this.options.isExceed;
      this.closeReturn = this.options.closeReturn;
      this.showFloating = typeof this.options.showFloating === 'undefined' ? true : this.options.showFloating;
      this.isLoaded = true;
    },

    async submit() {
      const { request } = this.options;
      const result = await request(this.oasisBanList);

      if (result.error) {
        this.resultType = false;
        this.responseErrorHandler(result);
        this.step = 'RESULT';
        return;
      }

      if (this.options.selfExclusion) {
        // Logout 처리 ( Self Exclusion 처리 )
        this.replaceRouteName('ResponsibleGaming');
        return;
      }

      this.resultType = true;
      this.message = this.$t('responsibleGaming.modal.successMessage');
      this.step = 'RESULT';
    },
    close() {
      this.$modalCloseAll();
      if (this.closeReturn) {
        this.replaceRouteName('ResponsibleGaming');
        return;
      }
    },
    confirm() {
      // this.$router.back();
      this.replaceRouteName('ResponsibleGaming');
      this.close();
    },

    input(array) {
      this.oasisBanList = array;
    },

    responseErrorHandler(error) {
      const { message, subMessage } = this.apiErrorHandler(error);
      this.message = message;
      this.subMessage = subMessage;
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[responsible-confirm-modal] {
  > .panel { .rel(); overflow: visible; background-color: #f7f7f8 !important;
    .scroll-area {.h(calc(100% - 120px)); .flex(); .flex-dc(); overflow-y: visible;}
    .floating {.abs(); .b(20); .w(100%); .ph(20); .flex(); .gap(10);}
  }
  &.result {
    > .panel {.h(30%);}
  }
  .content {
    [gp-description] {.pt(0); .pb(20);}
    .body {
      > p {.c(black);}
    }
  }
  .result-table {text-align: center; .c(black); .fs(12); .mv(20);
    > thead {.bold(); border-bottom: #d9d9d9 1px solid;
      > td { .pb(5); }
    }
    > tbody {
      > tr > td {.p(3);}
    }
  }
  .img-group { .flex(); .items-center(); .flex-dc(); .h(100%); .pt(0);
    > p {.c(black); font-size: 16px !important; .pt(10);}
  }

  .footer {.flex(); .gap(10);}


}
</style>