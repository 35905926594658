import { siteIds } from '@/constants/base/siteMap';
import axios from 'axios';
import { appendQuery } from '@shared/utils/urlUtils.mjs';

export default class BaseService {
  #services;
  #api;
  #userApi;
  #store;
  #countrySignupList;
  #countryOnboardingList;
  #stateList;

  #userLocation;
  #useLoqate;
  #gpToken;
  constructor(services) {
    this.#services = services;
    this.#api = services.api;
    this.#userApi = services.userApi;
    this.#store = services.store;

    const loqateSite = [siteIds.GGPUK, siteIds.GGPDE];
    this.#useLoqate = loqateSite.includes(this.#store.state.env.site);
  }

  async getUserCountry() {
    if (!this.#userLocation) {
      this.#userLocation = await this.#api.get('/currentcountry');
      if (this.#userLocation.CountryCode === 'XX' && this.#store.state.user.userInfo) {
        const { Country, CountryName } = this.#store.state.user.userInfo;
        this.#userLocation.CountryCode = Country;
        this.#userLocation.CountryName = CountryName;
      }
    }
    return this.#userLocation;
  }

  #googleToken;
  /**
   * @description GET 회원가입 가능 국가
   * @id GetSignupCountriesRequest
   * @param {string} SiteId
   * @return {Promise<SupportedCountries>} GetSupportedCountriesResponse
   * */
  async getCountryList(SiteId) {
    try {
      if (!this.#countrySignupList) this.#countrySignupList = (await this.#api.get('/countries/signup', { SiteId })).List;
      return this.#countrySignupList;
    } catch (e) {
      console.log('error : getCountryList', e);
      throw e;
    }
  }

  /**
   * @id GetStateListByCountryCodeRequest
   * @param {string} SiteId
   * @param {string} CountryCode
   * @return {Promise<SupportedStates>}
   * */
  async getStateList({ CountryCode = this.#services.store.state.user.userInfo.Country, SiteId }) {
    try {
      if (!this.#stateList) this.#stateList = (await this.#api.get('/country/statelistbycountrycode', { SiteId, CountryCode })).StateList;
      return this.#stateList;
    } catch (e) {
      console.log('error : getStateList', e);
      throw e;
    }
  }

  /**
   * @param { string } SearchText
   * @param { string } Id
   * @param { string } Country
   * @param { boolean } silent
   * @returns { Promise<LoqateCitiInfo | GoogleCityInfo>}
   * */
  async getSearchPlace({ SearchText, Id, Country = this.#services.store.state.user.userInfo.Country, silent = true }) {
    try {
      const path = this.#useLoqate ? '/address/loqate' : `/address/google/${Country}`;
      const r = (await this.#api.get(path, { SearchText, SearchText1: SearchText, SearchText2: SearchText, Id, Country, SessionToken: this.#googleToken }, { silent }));
      if (r.SessionToken) this.#googleToken = r.SessionToken;
      return (r.List || r.Predictions).map(o => ({ Id: o.Id || o.PlaceId, Text: o.Text || o.Description }));
    } catch (e) {
      console.log('error : getSearchPlace', e);
    }
  }

  /**
   * @param { string } SearchText
   * @param { string } AddressId
   * @param { string } Country
   * @param { boolean } silent
   * @returns { Promise<GoogleCity>}
   * */
  async getSearchAddress({ AddressId, Country = this.#services.store.state.user.userInfo.Country, silent = true }) {
    try {
      const path = this.#useLoqate ? `/address/loqate/${AddressId}` : `/address/google/${Country}/${AddressId}`;
      // const path = `/address/loqate/${AddressId}`;
      let r = await this.#api.get(path, { AddressId, Country, SessionToken: this.#googleToken }, { silent });
      r = r[0] || r;
      return { Building: r.BuildingNumber || r.Building, Address: r.Street || r.Address, City: r.City, PostalCode: r.PostalCode, };
    } catch (e) {
      console.log('error : getSearchAddress', e);
      throw e;
    }
  }

  /**
   * @description 선택한 국가가 가입 가능한지 체크
   * @id CheckAvailableLocationRequest
   * @param {string} CountryCode
   * @return {boolean} IsAllowed
   */
  getAvailableLocation(CountryCode) {
    try {
      return this.#api.get('/CheckAvailableLocationRequest', { CountryCode });
    } catch (e) {
      console.log('error : getAvailableLocation');
      throw e;
    }
  }

  /**
   * @description 230개국에 관한 CountryCode, CountryName, PhoneCountryCode 가져오는 함수 (Sign-up 과정에서 호출이 불필요)
   * @id GetSupportedPhoneCodeCountriesRequest
   * @return {SupportedPhoneCodeCountries} GetSupportedPhoneCodeCountriesResponse
   */
  async getMobileCountries() {
    try {
      if (!this.#countryOnboardingList) this.#countryOnboardingList = await this.#api.get(`/phonecode/countries`);
      return this.#countryOnboardingList;
    } catch (e) {
      console.log('error : getMobileCountries');
      throw e;
    }
  }

  /**
   * @description GET 현재 국가코드
   * @id GetCurrentCountryCodeRequest
   * @return {string} CountryCode, {string} CountryName - GetCurrentCountryCodeResponse
   * @refer 없으면(local 인 경우) "XX"
   */
  async getCurrentCountryCode() {
    try {
      return this.#api.get('/GetCurrentCountryCodeRequest');
    } catch (e) {
      console.log('error : getCurrentCountryCode');
      throw e;
    }
  }

  /**
   * @description 용도 불명
   * @id GetDownloadUrlRequest
   * @param {number} SiteId
   * @return  {Promise<DownloadUrl>} GetDownloadUrlResponse
   */
  getDownloadUrl(SiteId) {
    try {
      return this.#api.get('/client/url', { SiteId });
    } catch (e) {
      console.log('error : getDownloadUrl');
      throw e;
    }
  }

  /**
   * @description 3rd party - Google reCaptcha
   * @id GetGoogleReCaptchaSiteKeyRequest
   * @return {string} SiteKey - GetGoogleReCaptchaSiteKeyResponse
   */
  getGoogleReCaptcha() {
    try {
      return this.#api.get('/GetGoogleReCaptchaSiteKeyRequest');
    } catch (e) {
      console.log('error : getGoogleReCaptcha');
      throw e;
    }
  }

  /**
   * @description 3rd party - GeeTestCaptcha (Use EVPuke, GGPuke)
   * @id GetGeeTestCaptchaInitialRequest
   * @return {Promise<GeeTestInitialData>} GetGeeTestCaptchaInitialResponse
   */
  getGeeTestCaptcha() {
    try {
      return this.#api.get('/GetGeeTestCaptchaInitialRequest');
    } catch (e) {
      console.log('error : getGeeTestCaptcha');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingConfigReadyRequest
   *
   * */
  getGamingConfigReady() {
    try {
      return this.#userApi.get('/player/rg/configuration/ready', null, { silent: true });
    } catch (e) {
      console.log('error : getGamingConfigReady');
      throw e;
    }
  }

  getAllCountries() {
    try {
      return this.#api.get('/countries/all', null, { silent: true });
    } catch (e) {
      console.log('error : getAllCountries', e);
      throw e;
    }
  }

  /**
   * @description ReferenceKey 기반 가입 가능 국가 목록 조회
   * @id GetSignupCountriesByReferenceKeyRequest
   * @param {string} ReferenceKey
   * @returns {Promise<ArrayLike<T>|Array<AddressInfo>|SupportedCountry[]|PhoneCodeCountry[]|CityItem[]>}
   */
  async getRefCountryList(ReferenceKey) {
    try {
      const r = await this.#api.get(`/v1/region/countries/sign-up/${ReferenceKey}`, null, { silent: true });
      return r.List;
    } catch (e) {
      console.log('error : getRefCountryList', e);
      throw (e);
    }
  }

  /**
   * @description direct Tnc
   * */
  async getOpTokenByNpToken(reset) {
    try {
      if (!this.#gpToken || reset) {
        this.#gpToken = await this.#userApi.get('/v1/auth/tokens/onepass', {}, { silent: true });
        this.#services.store.commit('user/setGpToken', this.#gpToken.AccessToken);
        this.#services.cookie.setGpToken(this.#gpToken.AccessToken);
      }
      return true;
    } catch (e) {
      console.log('error : getOpTokenByNpToken', e);
      throw e;
    }
  }

  /**
   * @description 통화 정보 리스트
   * @id GetCurrenciesRequest
   * @returns {Promise<CurrencyItem[]>}
   */
  async getCurrencyList() {
    try {
      return await this.#api.get(`/currencies`, null, { silent: true });
    } catch (e) {
      console.log('error : getCurrencyList', e);
      throw e;
    }
  }

  reset() {
    this.#countrySignupList = null;
    this.#countryOnboardingList = null;
    this.#stateList = null;
  }
}
