<template>
  <div channeling-code-input>
    <ValidationComponent :component="TextInput" v-model="model" :rules="rules" :immediate="true" name="_channelingCode" :preset="{maxLength: 20}" :label="isGGPBE ? $t('signUp.personalInfo.partnerCodeOptional') : $t('signUp.personalInfo.bonusCodeOptional')"/>
  </div>
</template>

<script>

import TextInput from '@shared/components/common/input/TextInput.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'ChannelingCodeInput',
  components: { ValidationComponent },
  props: {
    value: { type: Specific, default: null },
  },
  computed: {
    site: state('env', 'site'),
    channelingName: state('query', 'channelingName'),
    regionKey: state('query', 'regionKey'),
    channelingRegionList : state('query', 'channelingRegionList'),
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    rules(){
      return this.isGGPBE ? `channelingPartnerSameValue:${this.channelingName}|partnerCode` : `channelingBonusSameValue:${this.channelingName}|bonusCode`
    }
  },
  data() {
    return {
      model: '',
      TextInput,
    };
  },
  watch: {
    value: 'updateModel',
    model() {
      this.$emit('input', this.model);
    },
  },
  methods: {
    updateModel() {
      this.model = this.value;
    }
  },
  mounted() {
    this.updateModel();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[channeling-code-input] {
  > button { .ml(4); .mt(8); .c(@c-text-desc); .-b(@c-text-desc); }
}

</style>