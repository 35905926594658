import { completeRedirectUrl } from '@/utils/baseUtil';
import { close } from '@/utils/clientUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { sumsubStatus } from '@/constants/base/onboarding/kyc';

export default class SumsubController {
  #scope;
  #site;
  #lang;
  #services;
  #callback;
  #modelInfo;
  #status;
  #data;
  #applicantId;
  #existApplicant;
  #accessToken;
  #intervalId;
  #intervalMilliSecond;
  #currentMilliSeconds;
  #limitMilliSecond;

  constructor(scope, { services, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#limitMilliSecond = 5 * 60 * 1000;
    this.#intervalMilliSecond = 5 * 1000;
    this.#restart();
  }

  /**
   * 초기 설정 처리 후 AccessToken을 반환
   * @returns {Promise<SumsubAccessToken>}
   */
  async #getInitialize() {
    const r = await this.#services.kyc.initializeSumsub();
    // ACCESS_DENIED, INVALID_PLAYER, INTERNAL_ERROR, SUMSUB_ERROR error 존재
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * 갱신된 AccessToken을 반환
   * @returns {Promise<SumsubAccessToken>}
   */
  async #getAccessToken() {
    const r = await this.#services.kyc.getSumsubAccessToken();
    // ACCESS_DENIED, INVALID_PLAYER, INTERNAL_ERROR, SUMSUB_ERROR error 존재
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * Sumsub 진행 상태 조회
   * @returns {Promise<SumsubStatus>}
   */
  async #getStatus() {
    const r = await this.#services.kyc.verifySumsubStatus();
    // ACCESS_DENIED, INVALID_PLAYER, INTERNAL_ERROR, SUMSUB_REJECTED error 존재
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * KYC 레벨과 트리거 정보를 반환
   * @returns {Promise<PlayerKycStatus>}
   */
  async #getPlayerKycStatus() {
    const r = await this.#services.kyc.getPlayerKycStatus();
    // ACCESS_DENIED, INVALID_PLAYER 존재
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * Reviewing 중인 문서 목록 조회
   * @returns {Promise<PlayerKycDocument>}
   */
  async #getPlayerKycDocuments() {
    const r = await this.#services.kyc.getPlayerKycDocuments();
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    this.#clearPollingInfo();
    switch (r?.key) {
      case apiErrorCode.INVALID_DOCUMENT:
        this.#callbackInfo(sumsubStatus.DocumentInvalid);
        break;
      // 로직 변경으로 현재는 사용하지 않으나 차후 기획 추가 예정으로 유지
      case apiErrorCode.SUMSUB_REJECTED:
        this.#callbackInfo(sumsubStatus.CertificationFailed);
        break;
      // 로직 변경으로 현재는 사용하지 않으나 차후 기획 추가 예정으로 유지
      case apiErrorCode.SUMSUB_REQUIRED_PERSONAL_DATA_RECHECK:
        this.#callbackInfo(sumsubStatus.ReCheck);
        break;
      // 로직 변경으로 현재는 사용하지 않으나 차후 기획 추가 예정으로 유지
      case apiErrorCode.SUMSUB_TRY_COUNT_OVER:
        this.#callbackInfo(sumsubStatus.TryCountOver);
        break;
      default:
        // SUMSUB_ERROR 를 포함 나머지 에러는 다 failed
        this.#callbackInfo(sumsubStatus.Failed);
        break;
    }
  }

  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#clearPollingInfo();
    this.#status = null;
    this.#data = null;
    this.#accessToken = null;
    this.#applicantId = null;
    this.#existApplicant = false;
  }
  /**
   * 진행중인 sumsub 현황 정보 반환
   * @returns {{statusInfo, lang, accessToken, applicantId, status}}
   */
  #getInfo() {
    return { lang: this.#lang, status: this.#status, data: this.#data, accessToken: this.#accessToken, applicantId: this.#applicantId, existApplicant: this.#existApplicant };
  }

  /**
   * Polling 관련 정보 초기화
   */
  #clearPollingInfo() {
    this.#currentMilliSeconds = 0;
    this.#intervalId && clearInterval(this.#intervalId);
    this.#intervalId = null;
  }
  /**
   * sumsub 진행 상태 반복 조회
   * 5초 마다 반복 호출을 하여 상태 Polling
   * 최대 5분을 넘어갈 경우 ServiceAbort 화면 노출
   * @returns {Promise<void>}
   */
  #pollingStatus() {
    this.#intervalId = setInterval(async () => {
      // currentMilliSecond이 최대 시간보다 같거나 넘어갈 경우 Inverval을 초기화 시키고 ServiceAbort로 이동
      if (this.#limitMilliSecond < this.#currentMilliSeconds) this.#callbackInfo(sumsubStatus.ServiceAbort);
      else await this.#callbackInfo();

      // currentMilliSecond에 intervalMilliSecond를 추가
      this.#currentMilliSeconds += this.#intervalMilliSecond;
    }, this.#intervalMilliSecond);
  }

  /**
   * sumsub status 및 정보 저장
   * @param {string} status - 설정할 sumsub status
   * @param {any} data - 설정할 sumsub status의 정보
   */
  #setInfo(status, data) {
    if(status !== this.#status) this.#status = status;
    this.#data = data;
  }
  /**
   * Sumsub 상태 값에 따른 화면 전환 처리
   * @returns {Promise<void>}
   */
  async #callbackInfo(status = null) {
    console.log('%csumsub ccontroller #callbackInfo', 'color:yellow', status);
    let r;
    let tempStatus = status;
    if (!tempStatus) {
      r = await this.#getStatus();
      if (r?.error) return;
      // #intervalId가 존재할 경우 polling 중이며 polling 진행 시 ready, pending이 내려올 경우 pending으로 간주, 그 외는 가져온 상태에 맞게 처리
      if (this.#intervalId) tempStatus = [sumsubStatus.Ready, sumsubStatus.Pending].includes(r.StatusType) ? sumsubStatus.Pending : r.StatusType;
      else tempStatus = r.StatusType;
    }

    let info;
    if (this.#status !== tempStatus) {
      this.#clearPollingInfo();
      switch (tempStatus) {
        case sumsubStatus.None:
          r = await this.#getPlayerKycStatus();
          if (r?.error) return;
          info = r.KycRequisition;
          break;
        case sumsubStatus.Ready:
          if (!this.#status) {
            /*
              this.#status가 아무 값이 없다는건 초기 진입 상태으로 간주한다.
              status값이 Ready일 경우 중도 진입한 신청자이므로 this.#existApplicant를 True로 설정 및 강제로 None으로 변경하여 Start화면으로 진입하게 한다
            */
            this.#existApplicant = true;

            r = await this.#getPlayerKycStatus();
            if (r?.error) return;
            info = r.KycRequisition;
            // Ready로 왔지만 초기 welcome 페이지 노출을 위해 None으로 강제 저장
            tempStatus = sumsubStatus.None;
          } else {
            /*
              중도 진입 신청자(this.#existApplicant === true)의 경우 getAccessToken,
              신규 신청자의 경우 getInitialize로 AccessToken을 가져온다.
            */
            r = !this.#existApplicant ? await this.#getInitialize() : await this.#getAccessToken();
            if (r?.error) return;
            this.#accessToken = r.AccessToken;
          }
          break;
        case sumsubStatus.Pending:
          this.#pollingStatus();
          break;
        case sumsubStatus.Reviewing:
          break;
        default:
          break;
      }
    }

    this.#setInfo(tempStatus, info);
    this.#callback(this.#getInfo());
  }
  /**
   * logout 처리를 하고 창을 닫아줌
   * @returns {Promise<void>}
   */
  async #close() {
    // SumsubReviewing, SumsubFailed, SumsubServiceAbort, SumsubCertificationFailed(차후 추가 예정), SumsubTryCountOver(차후 추가 예정) 일때만 호출되며 호출 시 단순 웹뷰 닫힘
    if (!completeRedirectUrl()) close(this.#scope);
  }
  /**
   * Personal Detail 초기 단계로 돌아가기 위해 Route 이동
   * (step은 이미 초기화 된 상태이므로 해당 상태의 스텝으로 이동)
   */
  #reset() {
    const { router } = this.#services;
    router.replace({ name: 'Deposit', params: { locale: router.currentRoute?.params?.locale }});
  }
  /**
   * Sumsub 단계 초기화
   */
  #restart() {
    this.#initialize().then(() => this.#callbackInfo());
  }
  /**
   * 화면에서 버튼이나 특정 이벤트를 핸들링 할때 활용
   * @param {string} status - 전환될 상태
   * @param {string} action - 전환 후 액션
   */
  updateStatus(status, action = '') {
    if (!status) return;
    if (action) {
      switch (action) {
        case 'reset':
          this.#reset();
          break;
        case 'close':
          this.#close();
          break;
        case 'restart':
          this.#restart();
          break;
        default:
          break;
      }
    } else {
      this.#callbackInfo(status);
    }
  }

  /**
   * 전달받은 applicantId 설정
   * @param id
   */
  async setApplicantId(id) {
    this.#applicantId = id;
  }

  /**
   * Sumsub Access Token 조회
   * @returns {Promise<string>}
   */
  async getAccessToken() {
    const r = await this.#getAccessToken();
    this.#accessToken = r?.AccessToken;
    return this.#accessToken;
  }
  /**
   * 초기화
   */
  initialize() {
    this.#initialize();
  }
}
