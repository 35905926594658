<template>
  <div input-fund-password>
    <InputComponent v-if="enableComponent.currentFundPassword" :label="$t('_.currentFundPassword')" name="_currentPassword" :component="PasswordInput" rules="required|length:6,20" v-model="model.CurrentFundPassword" :preset="currentPasswordPreset" :error-msg="error.CurrentFundPassword" />
    <InputComponent v-if="enableComponent.fundPassword" :label="fundPasswordText" name="_password" :component="PasswordInput" rules="required|length:6,20" v-model="model.FundPassword" :preset="fundPasswordPreset" :error-msg="error.FundPassword" />
    <InputComponent v-if="enableComponent.confirmPassword" :label="$t('_.confirmFundPassword')" :rules="`required|gpComparePassword:${model.FundPassword}`" name="_confirmNewPassword" :component="PasswordInput" v-model="ConfirmPassword" :preset="confirmFundPasswordPreset" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { FUND_MODE } from '@/constants/base/my-page';

export default {
  name: 'InputFundPassword',
  lexicon: 'myInfo',
  components: { InputComponent },
  props: {
    model: Specific,
    isEnableFundPassword: { type: Boolean, default: false },
    mode: { type: String, default: null, },
    error: Specific,
    disabled: { type: Boolean, default: false, }
  },
  data() {
    return {
      enableComponent: {
        currentFundPassword: false,
        fundPassword: false,
        confirmPassword: false,
      },
      ConfirmPassword: null,
      PasswordInput
    };
  },
  computed: {
    currentPasswordPreset() { return { placeholder: this.currentFundPasswordText, myinfo: true, }; },
    fundPasswordPreset() { return { placeholder: this.fundPasswordPlaceHolder, myinfo: true, }; },
    confirmFundPasswordPreset() { return { placeholder: this.$t('_.fundPassword.confirmFundPasswordPlaceHolder'), myinfo: true, }; },
    currentFundPasswordText() {
      return this.$t('_.fundPassword.currentFundPasswordPlaceHolder')
    },
    fundPasswordPlaceHolder() {
      return this.$t('_.fundPassword.newFundPasswordPlaceHolder')
    },
    fundPasswordText() {
      return this.$t('_.newFundPassword')
    },
    isFundMode() {return this.mode === FUND_MODE.FUND;},
    isChangeMode() {return this.mode === FUND_MODE.CHANGE;},
    isDisableMode() {return this.mode === FUND_MODE.DISABLE;},

    showCurrentFundPassword() {
      return (this.isChangeMode || this.isDisableMode) && this.isEnableFundPassword;
    },
    showFundPassword() {
      return this.isChangeMode || this.isFundMode;
    },
    showConfirmPassword() {
      return !this.isDisableMode;
    }
  },
  mounted() {
    this.enableComponent = {
      ...this.enableComponent,
      currentFundPassword: this.showCurrentFundPassword,
      fundPassword: this.showFundPassword,
      confirmPassword: this.showConfirmPassword,
    };
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-fund-password] { .br(16); .flex(); .flex-dc(); .gap(15); .w(100%);}
</style>