import { getDomain } from '@/utils/baseUtil';

const {
  VUE_APP_ENV, VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY, VUE_APP_COOKIE_USER_KEY, VUE_APP_COOKIE_DOMAIN, VUE_APP_COOKIE_LANG_KEY, VUE_APP_COOKIE_TOKEN_KEY,
} = process.env;

const DEFAULT_EXPIRES_DAY = parseFloat(VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY);
const SESSION_ONLY = 0;
const IS_FROM_MOBILE_APP = 'isFromMobileApp';
const IS_FROM_CLIENT_APP = 'isFromClientApp';
const REDIRECT_URI = 'redirectUri';
const gwBasicToken = 'gwBasicToken';
const gwExpired = 'gwExpired';
const gpToken = 'gpToken';
let MAIN_DOMAIN;

export default class CookieService {
  /** @type {JsCookie} */
  #cookies;
  #domain = VUE_APP_COOKIE_DOMAIN;

  constructor(cookies) {
    this.#cookies = cookies;
  }

  setCookie(key, value, expires = DEFAULT_EXPIRES_DAY) {
    this.#cookies.set(key, value, { domain: this.#domain, path: '/', expires: expires === SESSION_ONLY ? undefined : expires, secure: true, sameSite: 'None' });
  }

  removeCookie(key) {
    this.#cookies.remove(key, { domain: this.#domain, path: '/' });
  }

  setGwExpired(key) {
    this.setCookie(gwExpired, key);
  }
  setHost(host) {
    if (VUE_APP_ENV === 'local') MAIN_DOMAIN = 'localHost';
    else MAIN_DOMAIN = `.${getDomain(host)}`;
  }

  setUserInfo(info) {
    this.#cookies.set(VUE_APP_COOKIE_USER_KEY, info);
  }

  getUserInfo() {
    const info = this.#cookies.get(VUE_APP_COOKIE_USER_KEY);
    return info ? JSON.parse(info) : null;
  }

  removeUserInfo() {
    this.removeCookie(VUE_APP_COOKIE_USER_KEY);
  }

  getLanguage() {
    return this.#cookies.get(VUE_APP_COOKIE_LANG_KEY);
  }

  setLanguage(lang) {
    this.setCookie(VUE_APP_COOKIE_LANG_KEY, lang);
  }

  setToken(value) {
    this.#cookies.set(VUE_APP_COOKIE_TOKEN_KEY, value);
  }
  getToken() {
    return this.#cookies.get(VUE_APP_COOKIE_TOKEN_KEY);
  }
  removeToken() {
    this.removeCookie(gwExpired);
    this.removeCookie(VUE_APP_COOKIE_TOKEN_KEY);
    this.removeGpToken();
  }
  setBonusTag(key, value) {
    const keyUpperCase = key.toUpperCase();
    if (!value || this.#cookies.get(keyUpperCase)) return;
    const date = new Date();
    this.setCookie(keyUpperCase, value, date.getDate() + 30);
  }

  getBonusTag() {
    const bonusTags = ['QTAG', 'QTAG1', 'BTAG', 'BTAG1'];
    for (const tag of bonusTags) {
      if (this.#cookies.get(tag)) return this.#cookies.get(tag) || '';
    }
  }

  setIsFromMobileApp(value) {
    return this.#cookies.set(IS_FROM_MOBILE_APP, value, { domain: MAIN_DOMAIN, path: '/' });
  }

  getIsFromMobileApp() {
    return this.#cookies.get(IS_FROM_MOBILE_APP);
  }

  removeIsFromMobileApp() {
    return this.removeCookie(IS_FROM_MOBILE_APP, { domain: MAIN_DOMAIN, path: '/' });
  }

  setIsFromClientApp(value) {
    return this.#cookies.set(IS_FROM_CLIENT_APP, value, { domain: MAIN_DOMAIN, path: '/' });
  }

  getIsFromClientApp() {
    return this.#cookies.get(IS_FROM_CLIENT_APP);
  }

  removeIsFromClientApp() {
    return this.removeCookie(IS_FROM_CLIENT_APP, { domain: MAIN_DOMAIN, path: '/' });
  }

  setRedirectUri(value) {
    return this.#cookies.set(REDIRECT_URI, value, { domain: MAIN_DOMAIN, path: '/' });
  }

  getRedirectUri() {
    return this.#cookies.get(REDIRECT_URI);
  }

  removeRedirectUri() {
    return this.removeCookie(REDIRECT_URI, { domain: MAIN_DOMAIN, path: '/' });
  }

  setGpToken(key) {
    this.setCookie(gpToken, key);
  }

  getGpToken() {
    return this.#cookies.get(gpToken);
  }

  removeGpToken() {
    return this.removeCookie(gpToken, { domain: MAIN_DOMAIN, path: '/' });
  }
}
