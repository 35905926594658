<template>
  <div auth-error>
    <div :class="className" v-if="template">
      <FontIcon v-if="topIcon" :name="topIcon"/>
      <h2 v-if="title" v-html="$t(title)" />
      <h3 v-if="dearCustomer" v-html="$t(dearCustomer)" />
      <p v-if="subTitle" v-html="$t(subTitle)" />
      <template v-if="code === apiErrorCode.ACCOUNT_SUSPENDED">
        <template v-if="customCode === apiErrorCode.ETC">
          <p v-if="type && type === 'PermanentSelfExclusion'" v-html="$t(template.permanentSelfExclusion)" />
          <p v-else v-html="$t(template.otherSelfExclusion, {date})" />
        </template>
        <template v-else-if="customCode === apiErrorCode.CLOSED">
          <p v-html="$t(template.content, {startDate: startDate})" />
        </template>
        <template v-else>
          <p v-html="$t(template.otherSelfExclusion, {date: excludeDate})" />
        </template>
      </template>
      <template v-else>
        <template v-if="contactInfo === 'CRUKS'">
          <p v-if="template.cruksContent" v-html="$t(template.cruksContent)" />
        </template>
        <template v-else>
          <!-- SuspendedOthers 처리 필요 -->
          <p v-if="template.content" v-html="$t(template.content, {limit, loginAvailable, contactInfo, webSiteUrl, startDate, endDate})" />
          <span v-if="url">(<a class="link" :href="`https://${url}`" target="_blank">{{ url }}</a>)</span>
        </template>
      </template>
      <p v-if="template.description" v-html="$t(template.description, {csEmail: siteEmail})" />
    </div>
    <FloatingAdminButton v-if="isLocal" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isLocal } from '@/utils/baseUtil';
import { getObjectPropertyByIndex } from '@/utils/valueUtil';
import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import FloatingAdminButton from '@/views/components/common/FloatingAdminButton.vue';
import ApiErrorController from '@/modules/ApiErrorController';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { baseList } from '@/constants/base/siteMap';

export default {
  name: 'AuthError',
  components: { FloatingAdminButton, FontIcon },
  props: {
    ci: { type: [Number, String] },
    cci: { type: [Number, String] },
  },
  data() {
    return {
      code: null,
      customCode: null,
      customParams: null,
      template: null,
      info: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    className() {
      return this.template?.className;
    },
    topIcon() {
      return this.template?.topIcon;
    },
    title() {
      return this.template?.title;
    },
    subTitle() {
      return this.template?.subTitle;
    },
    dearCustomer() {
      return this.template?.dearCustomer;
    },
    isLocal() {
      return isLocal();
    },
    apiErrorCode() {
      return apiErrorCode;
    },
    firstCustomParam() {
      if (!this.customParams) return null;
      const param = Array.isArray(this.customParams) ? this.customParams[0] : this.customParams;
      try { return JSON.parse(param); } catch (e) { return param; }
    },
    secondCustomParam() {
      if (!this.customParams) return null;
      if (!Array.isArray(this.customParams) || this.customParams.length < 2) return null;
      try { return JSON.parse(this.customParams[1]); } catch (e) { return this.customParams[1]; }
    },
    startDate() {
      return this.firstCustomParam?.SuspendStartDate?.replace('+', ' ') || '';
    },
    endDate() {
      return this.firstCustomParam?.SuspendEndDate?.replace('+', ' ') || '';
    },
    accountStatus() {
      return this.firstCustomParam?.Type || '';
    },
    webSiteUrl() {
      return baseList.find(o => o.site === this.site)?.webSiteUrl?.replace('//', '');
    },
    siteEmail() {
      return this.firstCustomParam?.SiteEmail || this.csEmail || 'help@ggpoker.com';
    },
    limit() {
      return this.firstCustomParam?.Limit || '';
    },
    loginAvailable() {
      return this.firstCustomParam?.LoginAvailable || '';
    },
    contactInfo() {
      return this.firstCustomParam?.ContactInfo || '';
    },
    url() {
      return this.firstCustomParam?.Url || '';
    },
    type() {
      return this.code === apiErrorCode.ACCOUNT_SUSPENDED && this.customCode === apiErrorCode.ETC ? this.firstCustomParam : '';
    },
    date() {
      return this.code === apiErrorCode.ACCOUNT_SUSPENDED && this.customCode === apiErrorCode.ETC ? this.secondCustomParam : '';
    },
    excludeDate() {
      return this.code === apiErrorCode.ACCOUNT_SUSPENDED && this.customCode === apiErrorCode.ACCOUNT_SUSPENDED ? this.firstCustomParam : '';
    },
  },
  methods: {
    initialize() {
      try {
        const { query } = this.$route;
        this.code = query.code || getObjectPropertyByIndex(apiErrorCode, Number(this.ci));
        this.customCode = query.customCode || getObjectPropertyByIndex(apiErrorCode, Number(this.cci));
        if (!this.code && !this.customCode) {
          this.template = ApiErrorController.proxy({ site: this.site ?? process.env.VUE_APP_SITE, code: apiErrorCode.ACCESS_DENIED, customCode: apiErrorCode.ACCESS_DENIED })?.[apiErrorType.AUTH];
        } else {
          this.template = ApiErrorController.proxy({ site: this.site ?? process.env.VUE_APP_SITE, code: this.code, customCode: this.customCode })?.[apiErrorType.AUTH];
          if (this.$route.query?.p) this.customParams = decodeURIComponent(decodeURIComponent(this.$route.query.p));
        }
      } catch (e) { console.log('auth error: ', e);/** empty */ }
      this.$services?.modal.unblock();
    },
  },
  mounted() {
    this.initialize();
    if (this.template?.logout) this.$services.sign.logout({ useRedirect: false });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[auth-error] {
  .no-repeat('@{cdn-path}/img/bg_session_expired.png'); .bg-xc(); .abs(); .wh(100vw, 100vh); .lt(0); .flex(); .items-center(); .justify-center();
  > div { .w(90%); .max-w(800);
    > h2 { .fs(22); .mb(65); .lh(1em); .tc(); }
    > h3 { .fs(18); .mb(15); .regular(); .lh(1em); }
    p { .fs(14); .c(#999999); .lh(1em); }
    a { .underline(); }
    @media (@tp-up) {
      > h2 { .fs(30); }
      > h3 { .fs(20); }
      p { .fs(16); }
    }
  }
  .oasis-ban { .tc();
    >[font-icon] { .block(); .mh-c(); .fs(72); .mb(24); .c(@c-red); .tc();}
  }
  a[href^="mailto:"] { .c(@c-link); }
}

</style>

<!--
  index, CustomerErrorParameters
    <h2>{{'Error.SessionExpired.Title.Main' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    ACCESS_DENIED : (logoutMobile)
    ACCOUNT_LOCKED : ETC (logoutMobile)

    <p>{{'Error.SessionExpired.Description' | translate}}</p>
-->
<!--
  oasisBanExist, CustomerErrorParameters
    <h2 class="text-danger">{{'Error.OasisBlock.Title' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    ACCOUNT_LOCKED : OASIS_BAN_EXIST (logoutMobile)

    <p class="description" translate="ResponsibleGaming.Fail.OasisWarning" translate-values="{SUPPORT_EMAIL: supportEmail}"></p>
-->
<!--
  loginLimit, CustomerErrorParameters({Limit, LoginAvailable})

    <h2>{{'Error.LoginLimit.Title.Main' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    if RESPONSIBLE_GAMING : LOGIN_TIME_LIMIT_REACHED
      <p class="description" translate="Error.LoginLimit.Description.LoginTimeLimit" translate-values="{LIMIT : '@ViewBag.Model.Limit', LOGINAVAILABLE: '@ViewBag.Model.LoginAvailable'}"></p>
    else if RESPONSIBLE_GAMING : RE_LOGIN_TIME_LIMIT_REACHED
      <p class="description" translate="Error.LoginLimit.Description.ReLoginTimeLimit" translate-values="{LIMIT : '@ViewBag.Model.Limit', LOGINAVAILABLE: '@ViewBag.Model.LoginAvailable'}"></p>
    else if RESPONSIBLE_GAMING : LOGIN_COUNT_LIMIT_REACHED
      <p class="description" translate="Error.LoginLimit.Description.MonthlyLoginFrequency" translate-values="{LIMIT : '@ViewBag.Model.Limit', LOGINAVAILABLE: '@ViewBag.Model.LoginAvailable'}"></p>
    ACCOUNT_LOCKED : ACCOUNT_LOGIN_LIMIT_ACCESS (logoutMobile)

    <p class="description" translate="Error.LoginLimit.Description2" translate-values="{EMAIL : LoginLimit.commonModel.supportEmail('@ViewBag.SiteId')}"></p>
-->
<!--
  accountLocked, CustomerErrorParameters

    <h2>{{'Error.AccountLocked.Title.Main' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    ACCOUNT_SUSPENDED : BLOCKEDLISTACC_CHECK (logoutMobile)
    ACCOUNT_LOCKED : ACCOUNT_LOCKED (logoutMobile)

    if errorCode (BLOCKEDLISTACC_CHECK)
      <p>{{'@ViewBag.ErrorCode' | translate}}</p>
    else (ACCOUNT_LOCKED)
      <p>{{'Error.AccountLocked.Description' | translate}}</p>

    <p class="description" translate="Error.AccountLocked.Description2" translate-values="{EMAIL : AccountLocked.commonModel.supportEmail('@ViewBag.SiteId')}"></p>
-->
<!--
  selfExcluded, CustomerErrorParameters({CONTACTINFO, Url})

    <h2 class="text-danger">{{'Error.SelfExcluded.Title.Main' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    ACCOUNT_LOCKED : GAMSTOP_SELF_EXCLUDED, CRUKS_SELF_EXCLUDED, AISG_SELF_EXCLUDED (logoutMobile)
    ACCOUNT_SUSPENDED : EXCLUSION_CHECK (logoutMobile)

    if ContactInfo == "CRUKS"
      <p class="description" translate="Error.SelfExcluded.SelfExclusion.Cruks"></p>
    else
      <span translate="Error.SelfExcluded.VisitUrl" translate-values="{CONTACTINFO : '@ViewBag.Model.ContactInfo'}"></span>
      if
        URL !== # (<a class="link" href="https://@ViewBag.Model.Url" target="_blank">@ViewBag.Model.Url</a>)

    <p class="description" translate="Error.SelfExcluded.ContactByEmail" translate-values="{EMAIL : Excluded.commonModel.supportEmail('@ViewBag.SiteId')}"></p>
-->
<!--
  accountSuspended : CustomerErrorParameters({SuspendStartDate, SuspendEndDate})

    <h2>{{'Error.AccountLocked.Title.Main' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>

    ACCOUNT_SUSPENDED : ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED, ACCOUNT_LOGIN_REMAIN_LIMIT, ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED, COMPLIANCE_CHECK_PERIOD, CLOSED, COMPLIANCE_CHECK, SECURITY, MULTIACC_CHECK (logoutMobile)

    // accountStatus api의 type으로 구분 가능함
    if Type === SuspendedOthers : Type 전달 확인 필요
      <p translate="Error.AccountLocked.SuspendedOthers" translate-values="{STARTDATE: '@ViewBag.Model.SuspendStartDate', ENDDATE: '@ViewBag.Model.SuspendEndDate'}"></p>

    <p class="description" translate="Error.AccountLocked.ContactSupport" translate-values="{EMAIL : AccountSuspended.commonModel.supportEmail('@ViewBag.SiteId')}"></p>
-->
<!--
  excluded : date(CustomerErrorParameters[0]), type(CustomerErrorParameters[1]), siteId

    <h2 class="text-danger">{{'Error.Self.Excluded' | translate}}</h2>
    <h3>{{'Error.DearCustomer.Title' | translate}},</h3>
    <p>{{'Error.AccountLocked.Description' | translate}}</p>

    ACCOUNT_SUSPENDED : ETC (logoutMobile)

    if type === PermanentSelfExclusion
      <p class="description" translate="ResponsibleGaming.Message.SelfExclusion.Permanent"></p>
    else
      <p class="description" translate="ResponsibleGaming.Message.SelfExclusion" translate-values="{DATE: '@ViewBag.Date'}"></p>

    <p class="description" translate="Error.AccountLocked.Description2" translate-values="{EMAIL : Excluded.commonModel.supportEmail('@ViewBag.SiteId')}"></p>
-->