import { isExcludeGgpass } from '@/utils/baseUtil';
import NpChangeLoginPassword from '@/views/components/pages/my-page/template/NpChangeLoginPassword.vue';
import NpLoginSecurity from '@/views/components/pages/my-page/template/NpLoginSecurity.vue';
import NpEmailAddress from '@/views/components/pages/my-page/template/NpEmailAddress.vue';
import NpFundPassword from '@/views/components/pages/my-page/template/NpFundPassword.vue';
import NpMobileNumber from '@/views/components/pages/my-page/template/NpMobileNumber.vue';
import NpPersonalInfo from '@/views/components/pages/my-page/template/NpPersonalInfo.vue';
import NpMyDocuments from '@/views/components/pages/my-page/template/NpMyDocuments.vue';
import { STEP, rootRoute } from '@/constants/base/my-page';
import { siteIds } from '@/constants/base/siteMap';
import NpMyAccounts from '@/views/components/pages/my-page/template/NpMyAccounts.vue';
import NpBalanceTransfer from '@/views/components/pages/my-page/template/NpBalanceTransfer.vue';
import NpTransferHistory from '@/views/components/pages/my-page/template/NpTransferHistory.vue';
import NpVerifyCode from '@/views/components/pages/my-page/template/NpVerifyCode.vue';
import NpSocialAccounts from '@/views/components/pages/my-page/template/NpSocialAccounts.vue';
import NpAutoLoginSetting from '@/views/components/pages/my-page/template/NpAutoLoginSetting.vue';

export const routeNames = {
  NpMobileNumber: 'NpMobileNumber',
  NpEmailAddress: 'NpEmailAddress',
  NpVerifyCode: 'NpVerifyCode',
  NpPersonalInfo: 'NpPersonalInfo',
  NpBankInfo: 'NpBankInfo',
  NpChangeLoginPassword: 'NpChangeLoginPassword',
  NpFundPassword: 'NpFundPassword',
  NpLoginSecurity: 'NpLoginSecurity',
  NpMyDocuments: 'NpMyDocuments',
  MpWithdrawal: 'MpWithdrawal',
  NpMyAccounts: 'NpMyAccounts',
  NpBalanceTransfer: 'NpBalanceTransfer',
  NpContactMobileNumber: 'NpContactMobileNumber',
  NpContactEmailAddress: 'NpContactEmailAddress',
  NpMfaMobileNumber: 'NpMfaMobileNumber',
  NpMfaEmailAddress: 'NpMfaEmailAddress',
  NpTransferHistory: 'NpTransferHistory',
  NpSocialAccounts: 'NpSocialAccounts',
  NpAutoLoginSetting: 'NpAutoLoginSetting',
  NpMyDevice : 'NpMyDevice'
};

const currencySymbols = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
  'CAD': '$',
  'CNY': '¥',
  'JPY': 'JP¥',
  'VND': '₫',
  'THB': '฿',
  'RUB': '₽',
  'UAH': '₴',
  'CZK': 'Kč',
  'DKK': 'Kr.',
  'NOK': 'kr',
  'CHF': 'CHf',
  'BRL': 'R$',
  'INR': '₹',
  'COP': 'COL$',
  'MXN': 'MEX$',
  'ARS': 'ARS$',
  'RON': 'lei',
  'PLN': 'zł',
  'RSD': 'din',
  'HUF': 'Ft',
  'GEL': 'ლ',
  'KRW': '₩',
  'KZT': '₸',
  'CLP': 'CL$',
  'AUD': 'AU$',
  'GCD': { 'GGPDE': 'C€', 'N8IN': 'C', default: 'C$', },
  'GTD': { 'GGPDE': 'T€', 'N8IN': 'T', default: 'T$', },
  'GCO': 'Coin',
};

export const getCurrencySymbol = (currency, siteId) => {
  const currencyMapping = currencySymbols[currency];
  if (typeof currencyMapping === 'object') return currencyMapping[siteId] || currencyMapping.default;
  return currencyMapping || currency;
};


// Onboarding SITE List ( 88 agent 코드 참조 )
export const useOnBoardingList = [siteIds.GGPNL, siteIds.N8IN, siteIds.GGPDE, siteIds.GGPPH];
// Self Exclusion 상태이지만, View 는 가능한 사이트 리스트
export const excludeSkipList = [siteIds.GGPNL];
export const excludeSkipRoutes = [routeNames.NpEmailAddress, routeNames.NpMobileNumber, routeNames.NpChangeLoginPassword, routeNames.NpFundPassword, routeNames.NpLoginSecurity];

const siteModel = () => ({
  model: {
    Email: null,
    IsEmailVerified: true,
    CountryCode: null,
    CountryNumber: null,
    MobileNumber: null,
    LoginMethod: null,
    MessageType: 0,
    VerificationType: null,
    VerificationCode: null,
    Kind: null,
    Type: null,
    IsMobileVerified: false,
    Token: null,
    IsContactUpdate: true,
    ApiType: null,
    SendVerificationType: 'EMAIL',

    OldPassword: null,
    Password: null,

    CurrentFundPassword: null,
    FundPassword: null,
    IsEnableFundPassword: false,

    SecurityLevel: null,
    VerificationMethod: null,
    UnusualLoginSecurity: null,
    UsualLoginSecurity: null,
    Level: null,
    MethodType: null,

    KycDocumentsGroup: null,
    KycUploadedDocuments: [],

    // Name Area
    FirstName: null,
    LastName: null,
    Gender: null,

    // Day of Birth
    DateOfBirth: null,
    Month: null,
    Day: null,
    Year: null,

    // Info
    Address: null,
    State: null,
    City: null,
    PostalCode: null,
    Nationality: null,
    Street: null,
    StreetNumber: null,
    LastNameAtBirth: null,
    PlaceOfBirth: null,
    HouseNumber: null,
    AdditionalAddress: null,
    BuildingAddress: null,
    Building: null,
    EmploymentStatus: null,
    MiddleName: null,
    Alias: null,
    EmployerName: null,
    EmployerAddress: null,
    EmploymentType: null,
    BusinessPhoneNumber: null,
    Country: null,
    CountryName: null,
    BankInfo: null,
    Occupation: null,
    OccupationType: null,
    WithDrawalAccount: null,
    MaidenName: null,
    StreetName: null,
    TPID: null,
    PlayerBankInfo: null,
    Withdrawal: null,
    IsMarketingAgreement: true,

    Accounts: null,
    CurrentAccount: null,
    SelectedAccount: null,
    CustomLinkedAccount: null,
    LinkType: null,


    FromOnepassAccountId: null,
    FromCurrencyId: null,
    FromAmount: null,

    ToOnepassAccountId: null,
    ToCurrencyId: null,

    TransferType: 'CASH',
    TransferMethod: 'WALLET',




    // Result Modal
    DebitCountryCode: null,
    DebitAmount: null,
    DebitSiteId: null,
    DebitLogoUrl: null,
    DebitErrorCode: null,

    CreditCountryCode: null,
    CreditAmount: null,
    CreditSiteId: null,
    CreditLogoUrl: null,
    CreditErrorCode: null,

    ExchangeLate: 1,
    ExchangeAmount: null,
  },
  'MyInfo': {
    title: 'My Info',
  },
  npOnly: false,
  root: rootRoute,
  // My Account - Mobile Number
  [routeNames.NpMobileNumber]: {
    title: { default: 'mobileNumber' },
    component: NpMobileNumber,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    service: 'verification',
    api: 'sendVerification',
    group: 'id'
  },
  // My Account - Email Address
  [routeNames.NpEmailAddress]: {
    title: { default: 'emailAddress' },
    component: NpEmailAddress,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: 'id',
    service: 'verification',
    api: 'sendVerification',
  },
  [routeNames.NpVerifyCode]: {
    title: { default: 'emailAddress' },
    component: NpVerifyCode,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    group: 'id',
    service: 'user',
    api: 'setLoginId',
  },
  // My Account - Personal Info
  [routeNames.NpPersonalInfo]: {
    title: { default: 'personalInformation' },
    component: NpPersonalInfo,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT, STEP.INPUT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      {key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: 'account',
    icon: 'clipboard-regular',
    disableSite: [siteIds.TWOACE],
    service: 'user',
    api: 'editPersonalInfo',
  },

  // Login Security - Change Password
  [routeNames.NpChangeLoginPassword]: {
    title: { default: 'loginPassword' },
    component: NpChangeLoginPassword,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: [],
    fetchModels: [
      // { key: 'myInfo', service: 'onboarding', fetch: 'getMyInfo', params: true, index: 0 },
    ],
    group: 'security',
    icon: 'key2',
    service: 'user',
    api: 'changePassword',
  },
  // Login Security - Fund Password
  [routeNames.NpFundPassword]: {
    title: { default: 'fundPassword' },
    component: NpFundPassword,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT, STEP.INPUT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: 'security',
    icon: 'lock-regular',
    disableSite: [siteIds.TWOACE],
    service: 'user',
    api: 'changeNpFundPassword',
  },
  // Login Security - Mfa Setting ( Two-Factor Auth )
  [routeNames.NpLoginSecurity]: {
    title: { default: 'security2Fa' },
    component: NpLoginSecurity,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo', 'mfaConfig'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0, },
      { key: 'mfaConfig', service: 'mfa', fetch: 'getMfa', params: null, index: 1 },
    ],
    group: 'security',
    icon: 'shield-regular',
    service: 'mfa',
    api: 'sendMfa',
  },
  // My Documents - Index
  [routeNames.NpMyDocuments]: {
    title: { default: 'myDocuments' },
    component: NpMyDocuments,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo', 'uploadedList', 'kycDocuments'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0, },
      { key: 'uploadedList', service: 'player', fetch: 'getKycDocuments', params: false, index: 2, },
      // { key: 'kycDocuments', service: 'kyc', fetch: 'getPlayerKycUploadStatus', params: true, index: 1, },
    ],
    group: 'account',
    icon: 'file-regular',
    disableSite: [siteIds.TWOACE],
    service: null,
    api: null,
  },

  // Wallet Transfer - Index
  [routeNames.NpMyAccounts]: {
    title: { default: 'myAccounts' },
    component: NpMyAccounts,
    fetch: 'onePassInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['onePassInfo', 'myInfo'],
    fetchModels: [
      /** 6월 제외 */
      // { key: 'onePassInfo', service: 'ggpass', fetch: 'getInfo', params: { hasTransferInfo: true, hasBalances: true, }, index: 0, },
      { key: 'onePassInfo', service: 'ggpass', fetch: 'getInfo', params: {}, index: 0, },
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    disableSite: [siteIds.TWOACE, siteIds.SEVENXL],
    group: 'transfer',
    icon: 'user-regular',
    service: null,
    api: null,
    ggpassOnly: true,
  },


  [routeNames.NpContactMobileNumber]: {
    title: { default: 'editContactInfo' },
    component: NpMobileNumber,
    step: [STEP.DEFAULT, STEP.INPUT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    service: 'verification',
    api: 'sendVerification',
    group: null,
    ggpassOnly: true,
  },

  [routeNames.NpContactEmailAddress]: {
    title: { default: 'editContactInfo' },
    component: NpEmailAddress,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      {key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: null,
    service: 'verification',
    api: 'sendVerification',
    ggpassOnly: true,
  },
  [routeNames.NpMfaMobileNumber]: {
    title: { default: 'mobileAuthentication' },
    component: NpMobileNumber,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    service: 'verification',
    api: 'sendVerification',
    group: null,
  },
  // My Account - Email Address
  [routeNames.NpMfaEmailAddress]: {
    title: { default: 'emailAuthentication' },
    component: NpEmailAddress,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['myInfo'],
    fetchModels: [
      { key: 'myInfo', service: 'account', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: null,
    service: 'verification',
    api: 'sendVerification',
  },
  [routeNames.NpBalanceTransfer]: {
    title: { default: 'balanceTransfer' },
    component: NpBalanceTransfer,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: [],
    fetchModels: [],
    group: null,
    service: 'ggpass',
    api: 'setTransfer',
    ggpassOnly: true,
  },
  [routeNames.NpTransferHistory]: {
    title: { default: 'transferHistory' },
    component: NpTransferHistory,
    fetch: 'getMyInfo',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: ['history'],
    fetchModels: [
      { key: 'history', service: 'ggpass', fetch: 'getTransferHistories', params: true, index: 0 },
    ],
    group: null,
    service: null,
    api: null,
    ggpassOnly: true,
  },
  [routeNames.NpSocialAccounts]: {
    title: { default: 'linkedSocialAccounts' },
    component: NpSocialAccounts,
    fetch: '',         // Get API Function Name,
    step: [STEP.DEFAULT],
    fetchKeys: [],
    fetchModels: [
      //  { key: 'info', service: 'ggpass', fetch: 'getInfo', params: true, index: 0 },
    ],
    group: null,
    service: null,
    api: null,
    ggpassOnly: true,
  },
});

function getChildren(siteName, routeList, group, onBoardingStepLength = 0) {
  let isOnBoardingLink = false;
  // 그룹별 분리
  const groups = routeList.filter(route => route.group === group);
  const enableList = groups.filter(route => {
    if (route.hasOwnProperty('enableSite')) {
      return route.enableSite.includes(siteName);
    }

    if (route.hasOwnProperty('disableSite')) {
      return !route.disableSite.includes(siteName);
    }
    return true;
  });

  // onBoarding 으로 이동되는 Site 를 체크.
  if (useOnBoardingList.includes(siteName) && (onBoardingStepLength > 0)) isOnBoardingLink = true;
  // if (useOnBoardingList.includes(siteName)) isOnBoardingLink = true;

  const result = enableList.map(route => {
    return { label: route.title, name: route.key, toOnboarding: isOnBoardingLink, icon: route.icon };
  });
  // if (group === 'security') {
  //   result.push({ label: { default: 'logoutAllDevices' }, name: routeNames.NpAutoLoginSetting, icon: 'log-out-regular' });
  // }

  return result;
}

export function getSiteList(siteName, onBoardingStepLength = 0) {
  const routeModel = siteModel();
  let routeList = Object.keys(routeModel).filter(name => Object.keys(routeNames).includes(name)).map(name => ({ ...routeModel[name], key: name }));

  // None GGPass 라우트 정리
  routeList = routeList.filter(x => {
    if (isExcludeGgpass(siteName)) {
      if (x.hasOwnProperty('ggpassOnly') && x.ggpassOnly) return false;
      else return true;
    } else {
      return true;
    }
  });

  const groups = [
    {
      title: 'accountInfo',
      children: getChildren(siteName, routeList, 'account', 1),
      //       children: getChildren(siteName, routeList, 'account', onBoardingStepLength),
    },
    {
      title: 'accountSecurity',
      // My Security 는 OnBoarding을 타지 않음.
      children: getChildren(siteName, routeList, 'security', 0),
    },
    {
      title: 'ggpassLinkedAccounts',
      children: getChildren(siteName, routeList, 'transfer', 0),
    }
  ];

  return groups.filter(x => x.children.length > 0);
}



export default siteModel;
