<template>
  <div referral-bonus :class="{disabled}" v-if="!isEmpty(referralInfo)">
    <div v-if="notifications && notifications.length > 0">
      <Notification v-for="notification in notifications" :info="notification" :key="notification.name" />
    </div>
    <div class="contents">
      <BonusStatus :status-info="referralInfo" @reload="updateInfo" />
      <div class="section-holder">
        <ReferralStatus :info="referralInfo" />
        <ReferralStep />
        <ReferralCode :info="referralInfo" />
        <BonusHistory :playerPromotionId="referralInfo.playerPromotionId" />
      </div>
    </div>
  </div>
</template>

<script>
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import BonusStatus from '@/views/components/promotion/BonusStatus.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Notification from '@/views/components/promotion/Notification.vue';
import ReferralStatus from '@/views/components/pages/promotion/referralBonus/ReferralStatus.vue';
import ReferralStep from '@/views/components/pages/promotion/referralBonus/ReferralStep.vue';
import ReferralCode from '@/views/components/pages/promotion/referralBonus/ReferralCode.vue';

export default {
  name: 'ReferralBonus',
  components: { ReferralCode, ReferralStep, ReferralStatus, Notification, BonusStatus, BonusHistory },
  lexicon: 'referral',
  data() {
    return {
      referralInfo: null,
    };
  },
  computed: {
    isMobile: state('browser', 'mobile'),
    token: state('user', 'token'),
    lang: state('query', 'selectedLang'),
    disabled() {
      return !this.referralInfo?.isOnBoardingCompleted;
    },
    notifications() {
      return this.$promotion.getNotifications('referral', { info: this.referralInfo });
    },
  },
  methods: {
    async updateInfo() {
      const r = await this.$services.promotion.referralPromotion();
      if (r?.error) {
        await this.replaceRouteName(r?.path);
      } else {
        this.referralInfo = r;
      }
    },
  },
  mounted() {
    this.updateInfo();
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[referral-bonus] {
  &.disabled .contents {
    [bonus-status] { .bgc(rgba(38, 38, 38, 0.5)); }
    section:not([bonus-history]) { .o(0.5);
      &[referral-code] { .o(0.15); }
    }
  }
  .contents {
    .section-holder { .c(@c-lightGrey); }
  }
}
</style>
