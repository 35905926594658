const view = path => () => import(/* webpackChunkName: "Onboarding" */ `../views/pages/on-boarding/${path}.vue`);

export default ({ local, token }) => {
  const path = local ? 'onboarding/' : 'app/';

  const list = [
    /** redirect */
    {
      path: `Client/ManualVerification`,
      redirect: `${path}verification`,
    },
    {
      path: `Deposit`,
      redirect: `${path}verification`,
    },
    /** root */
    {
      path: `${path}`,
      redirect: `${path}verification`,
    },
    /** 3rd */
    {
      path: `${path}receiver-jumio`,
      name: 'ReceiverJumio',
      component: view('ReceiverJumio'),
    },
    {
      path: `${path}receiver-signicat`,
      name: 'ReceiverSignicat',
      component: view('ReceiverSignicat'),
    },
    {
      path: `${path}receiver-signicat-idin`,
      name: 'ReceiverSignicatIdin',
      component: view('ReceiverSignicatIdin'),
    },
    {
      path: `${path}receiver-bank-id`,
      name: 'ReceiverBankId',
      component: view('ReceiverBankId'),
    },
    {
      path: `${path}receiver-payletter`,
      name: 'ReceiverPayletter',
      component: view('ReceiverPayletter'),
    },
    {
      path: `${path}receiver-sofort`,
      name: 'ReceiverSofort',
      component: view('ReceiverSofort'),
    },
    /** verification */
    {
      path: `${path}verification`,
      name: 'Onboarding',
      component: view('Onboarding'),
      redirect: token ? `${path}verification/personal-name` : '/auth-error',
      children:
        (!token ? [{ path: '*', redirect: '/auth-error' }] :
            [
              {
                path: 'personal-detail-reaffirm',
                name: 'PersonalDetailReaffirm',
                component: view('OnboardingProgress'),
              },
              {
                path: 're-check-personal-details',
                name: 'ReCheckPersonalDetails',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-citizenship',
                name: 'VerifyCitizenship',
                component: view('OnboardingProgress'),
              },
              {
                path: 'screening',
                name: 'Screening',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal',
                name: 'Personal',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-name',
                name: 'PersonalName',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-birth',
                name: 'PersonalBirth',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-address',
                name: 'PersonalAddress',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-extra-address',
                name: 'PersonalExtraAddress',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-occupation',
                name: 'PersonalOccupation',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-details',
                name: 'PersonalDetails',
                component: view('OnboardingProgress'),
              },
              {
                path: 'kyc-verification',
                name: 'KycVerification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'fund-protection-policy',
                name: 'FundProtectionPolicy',
                component: view('OnboardingProgress'),
              },
              {
                path: 'deposit-limit',
                name: 'DepositLimit',
                component: view('OnboardingProgress'),
              },
              {
                path: 'loss-limit',
                name: 'LossLimit',
                component: view('OnboardingProgress'),
              },
              {
                path: 'identifying',
                name: 'Identifying',
                component: view('OnboardingProgress'),
              },
              // {
              //   path: 'cross-provider-deposit-limit',
              //   name: 'CrossProviderDepositLimit',
              //   component: view('OnboardingProgress'),
              // },
              {
                path: 'temp-account-expired',
                name: 'TempAccountExpired',
                component: view('OnboardingProgress'),
              },
              {
                path: 'temp-account-login',
                name: 'TempAccountLogin',
                component: view('OnboardingProgress'),
              },
              {
                path: 'temporarily-accepted',
                name: 'TemporarilyAccepted',
                component: view('OnboardingProgress'),
              },
              {
                path: 'id-address-verification', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'IdAddressVerification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'identity-verification', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'IdentityVerification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verification-complete', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'VerificationComplete',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verification-request', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'VerificationRequest',
                component: view('OnboardingProgress'),
              },
              {
                path: 'self-identification', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'SelfIdentification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'proof-of-identity', // TODO : [ean] 사용 안하는걸로 보이고 차후 정리 대상
                name: 'ProofOfIdentity',
                component: view('OnboardingProgress'),
              },
              {
                path: 'reviewing-documents', // TODO : [ean] 화면에서 컴포넌트를 핸들링할 뿐 별도로 꽂는 스텝은 없는걸로 보이며 차후 정리 대상
                name: 'ReviewingDocuments',
                component: view('OnboardingProgress'),
              },
              {
                path: 'bank-details',
                name: 'BankDetails',
                component: view('OnboardingProgress'),
              },
              {
                path: 'total-bet-amount-limit',
                name: 'TotalBetAmountLimit',
                component: view('OnboardingProgress'),
              },
              {
                path: 'long-time-limit',
                name: 'LongTimeLimit',
                component: view('OnboardingProgress'),
              },
              {
                path: 're-login-timelimit',
                name: 'ReLoginTimeLimit',
                component: view('OnboardingProgress'),
              },
              {
                path: 'monthly-login-frequency',
                name: 'MonthlyLoginFrequency',
                component: view('OnboardingProgress'),
              },
              {
                path: 'start-onboarding',
                name: 'StartOnboarding',
                component: view('OnboardingProgress'),
              },
              {
                path: 'regulatory-transition',
                name: 'RegulatoryTransition',
                component: view('OnboardingProgress'),
              },
              {
                path: 'permanent-address',
                name: 'PermanentAddress',
                component: view('OnboardingProgress'),
              },
              {
                path: 'account-verification',
                name: 'AccountVerification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'account-verification-required',
                name: 'AccountVerificationRequired',
                component: view('OnboardingProgress'),
              },
              {
                path: 'complete',
                name: 'Complete',
                component: view('OnboardingComplete'),
              },
              {
                path: 'fail',
                name: 'Fail',
                component: view('OnboardingProgress'),
              },
              {
                path: 'payletter',
                name: 'Payletter',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-pep',
                name: 'PersonalPep',
                component: view('OnboardingProgress'),
              },
              {
                path: 'signicat',
                name: 'Signicat',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-crucks',
                name: 'VerifyCrucks',
                component: view('OnboardingProgress'),
              },
              {
                path: 'surePay',
                name: 'SurePay',
                component: view('OnboardingProgress'),
              },
              {
                path: 'kyc-document-manual',
                name: 'KycDocumentManual',
                component: view('OnboardingProgress'),
              },
              {
                path: 'trans-union',
                name: 'TransUnion',
                component: view('OnboardingProgress'),
              },
              {
                path: 'jumio',
                name: 'Jumio',
                component: view('OnboardingProgress'),
              },
              {
                path: 'show-reviewing-kyc-message',
                name: 'ShowReviewingKycMessage',
                redirect: 'jumio',
              },
              {
                path: 'sumsub',
                name: 'Sumsub',
                component: view('OnboardingProgress'),
              },
              {
                path: 'kyc-de',
                name: 'KycDE',
                component: view('OnboardingProgress'),
              },
              {
                path: 'lugas',
                name: 'Lugas',
                component: view('OnboardingProgress'),
              },
              {
                path: 'poi',
                name: 'Poi',
                component: view('OnboardingProgress'),
              },
              {
                path: 'poa',
                name: 'Poa',
                component: view('OnboardingProgress'),
              },
              {
                path: 'edd',
                name: 'Edd',
                component: view('OnboardingProgress'),
              },
              {
                path: 'age-verify-failed',
                name: 'AgeVerifyFailed',
                component: view('OnboardingProgress'),
              },
              {
                path: 'personal-self-declaration',
                name: 'PersonalSelfDeclaration',
                component: view('OnboardingProgress'),
              },
              {
                path: 'confirm-personal-info',
                name: 'ConfirmPersonalInfo',
                component: view('OnboardingProgress'),
              },
              /** verify*/
              {
                path: 'verify-age',
                name: 'VerifyAge',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-email',
                name: 'VerifyEmail',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-mobile-number',
                name: 'VerifyMobileNumber',
                component: view('OnboardingProgress'),
              },
              {
                path: 'contact-email',
                name: 'ContactEmail',
                component: view('OnboardingProgress'),
              },
              {
                path: 'contact-mobile-number',
                name: 'ContactMobileNumber',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-contact-email',
                name: 'VerifyContactEmail',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-contact-mobile-number',
                name: 'VerifyContactMobileNumber',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-code',
                name: 'VerifyCode',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-total-bet-setting',
                name: 'VerifyRgTotalBetSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-loss-setting',
                name: 'VerifyRgLossSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-loss-cz-setting',
                name: 'VerifyRgLossCzSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-loss-kr-setting',
                name: 'VerifyRgLossKrSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-login-time-setting',
                name: 'VerifyRgLoginTimeSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-re-login-time-setting',
                name: 'VerifyRgReLoginTimeSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-monthly-login-frequency-setting',
                name: 'VerifyRgMonthlyLoginFrequencySetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-deposit-setting',
                name: 'VerifyRgDepositSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-rg-balance-setting',
                name: 'VerifyRgBalanceSetting',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-fund-protection-acceptance',
                name: 'VerifyFundProtectionAcceptance',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verify-sure-pay',
                name: 'VerifySurePay',
                component: view('OnboardingProgress'),
              },
              {
                path: 'payment-account-verification',
                name: 'PaymentAccountVerification',
                component: view('OnboardingProgress'),
              },
              {
                path: 'home-address',
                name: 'HomeAddress',
                component: view('OnboardingProgress'),
              },
              {
                path: 'mailing-address',
                name: 'MailingAddress',
                component: view('OnboardingProgress'),
              },
              {
                path: 'data-box',
                name: 'DataBox',
                component: view('OnboardingProgress'),
              },
              {
                path: 'poi-selfie-ready',
                name: 'PoiSelfieReady',
                component: view('OnboardingProgress'),
              },
              {
                path: 'poi-selfie',
                name: 'PoiSelfie',
                component: view('OnboardingProgress'),
              },
              /** Etc*/
              {
                path: 'replace',
                name: 'Replace',
                component: view('OnboardingProgress'),
              },
              {
                path: 'verification-failed',
                name: 'VerificationFailed',
                props: true,
                component: view('VerificationFailed'),
              },
              {
                path: 'template-viewer',
                name: 'TemplateViewer',
                component: view('TemplateViewer'),
              },
              { path: '*', redirect: '/not-found' }
            ]
        ),
    },
  ];
  return list;
};