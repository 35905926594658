<template>
  <div fi-confirm-password>
    <div class="header">
      <p class="desc" v-html="$t('findId.description.confirmPw')" />
    </div>
    <div v-if="config" class="content">
<!--      <ValidationComponent :label="$t('myInfo.newPassword')" name="_password" :component="PasswordInput" :rules="rule" v-model="modelInfo.Password" :preset="newPasswordPreset" :error-msg="structure.errorMsg" />-->
      <ValidationPassword :config="pwConfig" :label="$t('signUp.personalInfo.passwordMinMax',{min, max})" name="newPassword" :rules="pwRules" v-model="modelInfo.Password" />
      <ValidationComponent :label="$t('myInfo.confirmPassword')" :rules="confirmRule" name="_confirmPassword" :component="PasswordInput" v-model="modelInfo.ConfirmPassword" :preset="confirmPasswordPreset" />
    </div>
  </div>
</template>

<script>
import { emailPasswordValidation, mobilePasswordValidation } from '@/utils/pwUtil';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import ValidationPassword from '@/views/components/common/ValidationPassword.vue';

export default {
  name: 'FiConfirmPassword',
  components: { ValidationPassword, ValidationComponent },
  props: {
    structure: Specific,
    modelInfo: { type: Specific, default: null },
  },
  data() {
    return {
      PasswordInput,
      config: null,
    }
  },
  computed: {
    site: state('env','site'),
    newPasswordPreset() { return { placeholder: this.$t('myInfo.newPassword')}; },
    confirmPasswordPreset() { return { placeholder: this.$t('myInfo.confirmPassword') }; },
    min() {
      return this.config?.passwordMinLength || 8;
    },
    max() {
      return this.config?.passwordMaxLength || 20;
    },
    emailValue() {
      return this.modelInfo.Email || '';
    },
    mobileValue() {
      return this.modelInfo.MobileNumber || '';
    },
    emailMode() {
      return this.modelInfo?.LoginMethod === 'Email';
    },
    pwConfig(){
      return this.config
    },
    pwRules() {
      if (!this.pwConfig) return '';
      return emailPasswordValidation(this.pwConfig, this.emailValue);
    },
    confirmRule() {
      return `required|confirmPassword:${this.modelInfo.Password}`;
    },
  },
  methods: {
    async getRules() {
      const result = await this.$services.findId.getPwRule();

      this.config = {
        ...result,
        passwordIncludeBothUpperLowerCase:  result?.PasswordIncludeBothUpperLowerCase,
        passwordIncludeAlphabet: result?.PasswordIncludeAlphabet,
        passwordIncludeNumber: result?.PasswordIncludeNumber,
        passwordIncludeSpecialCharacter: result?.PasswordIncludeSpecialCharacter,
        passwordMinLength :result?.PasswordMinLength,
        passwordMaxLength :result?.PasswordMaxLength,
        passwordAllowedUsername : result?.PasswordAllowedUsername
      }
    },
  },
  mounted() {
    this.modelInfo.Password = null;
    this.getRules();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-confirm-password] {
  .content {
    >* {
      &:nth-of-type(1) { .mb(8); }
    }
  }
}
</style>