<template>
  <div rg-display-oasis-modal>
    <div class="content">
      <GpDescription :message="description" />
      <div class="ban-wrapper">
        <p>{{ $t('responsibleGaming.label.causeOfBan') }}</p>
        <ul class="ban-list">
          <OasisCheckBox v-for="(ban,id) in banCauseList" :key="id" :value="ban.value" :disabled="disabled && ban.value !== 99" :label="$t(ban.label)" :active="hasSelectedValue(ban.value)" @change="change" />
        </ul>
      </div>
      <div class="footer">
        <!--        <GpButton type="line-green" @click="close">{{ $t('cancel') }}</GpButton>-->
        <!--        <GpButton type="submit-green" @click="submit">{{ $t('confirm') }}</GpButton>-->
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { oasisBanCauseList } from '@/constants/base/responsible-gaming';
import OasisCheckBox from '@/views/components/pages/responsible-gaming/template/common/OasisCheckBox.vue';
export default {
  name: 'DisplayOasisBanModal',
  components: { OasisCheckBox, CheckboxItem, GpDescription, GpButton },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
  },
  data() {
    return {
      selectedValues: [],
    };
  },
  computed: {
    banCauseList() {
      return oasisBanCauseList;
    },
    disabled() {
      return this.selectedValues.indexOf(99) >= 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    change(v, remove) {
      if (this.hasSelectedValue(v)) {
        this.selectedValues = this.selectedValues.filter(x => x !== v);
      } else {
        if (v === 99) {
          this.selectedValues = [v];
        } else this.selectedValues = [...this.selectedValues, v];
      }
      this.$emit('input', this.selectedValues);
    },
    hasSelectedValue(v) {
      return this.selectedValues.indexOf(v) >= 0;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-oasis-modal] {
  .content {
    [gp-description] {
      > p {.pt(0); .fs(14); text-align: left;}
    }
    .ban-wrapper {.c(#999999); .min-h(200);
      .ban-list {.pt(10); .ph(10); .flex(); .flex-dc(); .gap(5); }
    }
  }

  .body {
    > p {.c(black);}
  }
  .footer {.flex(); .gap(10);}
}
</style>