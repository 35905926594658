<template>
  <div input-login-time-limit>
    <TimeLimitRow :label="dailyLabel" :current-value="current.CurrentDailyLoginTimeLimit" current-name="currentDailyLimit" rules="limitMinMax:1,24" :list="dailyList" v-model="model.RequestDailyLoginTimeLimit" new-name="dailyLimit" :preset="dailyPreset" />
    <TimeLimitRow :label="weeklyLabel" :current-value="current.CurrentWeeklyLoginTimeLimit" current-name="CurrentWeeklyLoginTimeLimit" rules="limitMinMax:1,168" :list="weeklyList" v-model="model.RequestWeeklyLoginTimeLimit" new-name="weeklyLimit" :preset="weeklyPreset" />
    <TimeLimitRow :label="monthlyLabel" :current-value="current.CurrentMonthlyLoginTimeLimit" current-name="CurrentMonthlyLoginTimeLimit" rules="limitMinMax:1,720" :list="monthlyList" v-model="model.RequestMonthlyLoginTimeLimit" new-name="monthlyLimit" :preset="monthlyPreset" />
  </div>
</template>

<script>
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
import LimitTable from '@/views/components/pages/responsible-gaming/template/common/LimitTable.vue';
import Specific from '@shared/types/Specific';
import TimeLimitRow from '@/views/components/pages/responsible-gaming/template/common/TimeLimitRow.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'InputLoginTimeLimit',
  lexicon: 'onBoarding.setting',
  components: { TimeLimitRow, LimitTable, LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
    rolling: { type: String, default: false },
    currencySymbol: { type: String, default: null }
  },
  computed: {
    isRolling() {
      return this.rolling === 'Rolling';
    },
    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('daily');
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('weekly');
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('monthly');
    },
    timeList() {
      return {
        daily: [
          { label: 'custom', value: 'custom' },
          { label: '24', value: '24' },
          { label: '12', value: '12' },
          { label: '6', value: '6' },
          { label: '3', value: '3' },
        ],
        weekly: [
          { label: 'custom', value: 'custom' },
          { label: '24', value: '24' },
          { label: '72', value: '72' },
          { label: '120', value: '120' },
          { label: '168', value: '168' },
        ],
        monthly: [
          { label: 'custom', value: 'custom' },
          { label: '120', value: '120' },
          { label: '240', value: '240' },
          { label: '360', value: '360' },
          { label: '720', value: '720' },
        ],
      };
    },
    dailyList() {
      return this.timeList.daily.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },
    weeklyList() {
      return this.timeList.weekly.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },
    monthlyList() {
      return this.timeList.monthly.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },

    dailyPreset() { return { type: 'digit', required: true, placeholder: '1~24', maxLength: 2, digit: true, inputMode: 'numeric', pattern: '[0-9]*', }; },
    weeklyPreset() { return { type: 'digit', required: true, placeholder: '1~168', maxLength: 3, digit: true, inputMode: 'numeric', pattern: '[0-9]*', }; },
    monthlyPreset() { return { type: 'digit', required: true, placeholder: '1~720', maxLength: 3, digit: true, inputMode: 'numeric', pattern: '[0-9]*', }; },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-login-time-limit] {.flex(); .flex-dc(); .gap(30);}
</style>