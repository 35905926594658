import { isExcludeGgpass } from '@/utils/baseUtil';
import { ggpassLevel, ggpassMethod } from '@/constants/base/my-page';
import { gpSiteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default class MfaService {
  #services;
  #userApi;
  #ggpass;
  #store;
  constructor(services) {
    this.#services = services;
    this.#userApi = services.userApi;
    this.#ggpass = services.passApi;
    this.#store = services.store;
  }

  /**
   * @id GetMfaConfigRequest
   * @returns {*}
   */
  #getNpMfa() {
    try {
      return this.#userApi.get('/player/mfaconfig');
    } catch (e) {
      console.log('error : #getNpMfa');
      throw e;
    }
  }

  /**
   * @returns {Promise<*|{error}>}
   */
  async #getGpMfa() {
    try {
      const r = await this.#ggpass.get('/op/v1/onepass/mfas');
      return r;
    } catch (e) {
      console.log('error : #getGpMfa');
      throw e;
    }
  }

  /**
   * @id GetPlayerGoogleAuthenticationUrlRequest
   * @return {MfaGoogleAuthentication}
   */
  #getNpGoogleAuthentication() {
    try {
      return this.#userApi.get('/player/google/authentication/url');
    } catch (e) {
      console.log('error : #getNpGoogleAuthentication');
      throw e;
    }
  }

  /**
   * @id EditMfaConfigRequest
   * @param VerificationMethod
   * @param SecurityLevel
   * @param UsualLoginSecurity
   * @param UnusualLoginSecurity
   * @returns {Promise<*>}
   */
  async #editNpMfaConfig({ VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity }) {
    try {
      const r = await this.#userApi.patch('/player/mfaconfig', { VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity }, { silent: true });
      return r;
    } catch (e) {
      console.log('error : #editNpMfaConfig');
      throw e;
    }
  }

  /**
   * @id GetPlayerGoogleAuthenticationVerifyRequest
   * @param MfaCode
   * @returns {Promise<*>}
   */
  async #sendNpGoogleAuthenticationCode(MfaCode) {
    try {
      const r = await this.#userApi.post('/player/google/authentication/verify', { MfaCode }, { silent: true, });
      return r;
    } catch (e) {
      console.log('error : #sendNpGoogleAuthenticationCode');
      throw e;
    }
  }

  /**
   * @param totpCode { String }
   * */
  #sendGpGoogleAuthenticationCode(totpCode) {
    try {
      return this.#ggpass.put('/op/v1/onepass/mfa/google', { totpCode });
    } catch (e) {
      console.log('error : #sendGpGoogleAuthenticationCode', e);
    }
  }

  /**
   * @param methodType { String }
   * @param level { String }
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @param VerificationMethod { String }
   * */
  async #setGpMfa(mfa) {
    const isEmail = ggpassMethod[mfa.VerificationMethod] === ggpassMethod.Email;
    const methodType = ggpassMethod[mfa.VerificationMethod];
    const level = ggpassLevel[mfa.SecurityLevel];
    const email = isEmail ? mfa?.Email : null;
    const countryNumber = mfa?.CountryNumber ?  `+${mfa.CountryNumber}` : null
    const countryCode = isEmail ? null : countryNumber
    const mobileNumber = isEmail ? null : mfa.MobileNumber;
    const verificationCode = mfa.VerificationCode ? mfa.VerificationCode : null;
    const verificationToken = mfa.Token ? mfa.Token : null;
    const brandId = this.#store.state.env.gpBrand;
    // EVPUKE일 경우에만 siteID 발송
    const siteId = this.#store.state.env.gpSite === gpSiteIds.EVPUKE ? this.#store.state.env.gpSite : null;
    try {
      const result = await this.#ggpass.put('/op/v1/onepass/mfa', { methodType, level, email, countryCode, mobileNumber, verificationCode, verificationToken, brandId, siteId });
      if (result.error) {
        if (result.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) {
          return {
            ...result,
            error: false,
          };
        } else if (result.key === apiErrorCode.RESEND_TERM_LIMITED) {
          const json = JSON.parse(result.desc2);
          const value = parseInt(json.remainingTime);
          return { key: 'limit', value: value };
        }
      }
      return result;
    } catch (e) {
      console.log('error : getMfa', e);
    }
  }

  async sendMfa(payload) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#editNpMfaConfig(payload);
      } else {
        return await this.#setGpMfa(payload);
      }
    } catch (e) {
      console.log('error : sendMfa');
      throw e;
    }
  };

  async sendGoogleAuthentication(mfaCode) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#sendNpGoogleAuthenticationCode(mfaCode);
      } else {
        return await this.#sendGpGoogleAuthenticationCode(mfaCode);
      }
    } catch (e) {
      console.log('error : sendGoogleAuthentication');
      throw e;
    }
  }

  async getMfa() {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#getNpMfa();
      } else {
        return await this.#getGpMfa();
      }
    } catch (e) {
      console.log('error : getMfa');
      throw e;
    }
  }

  async getGoogleInfo(payload) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#getNpGoogleAuthentication();
      } else {
        const r = await this.#setGpMfa(payload);
        return {
          QRCodeImageUrl: r.qrCode,
          ManualEntryKey: r.key,
        };
      }
    } catch (e) {
      console.log('error : getGoogleInfo');
      throw e;
    }
  }
}