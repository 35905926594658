<template>
  <GpFormTemplate np-my-documents>
    <template #content>
      <div class="title-group">
        <GpTitle :name="$t('_.kyc')" />
        <GpBadge :type="kycStatsType">{{ kycStatsText }}</GpBadge>
        <GpBadge v-if="isKycExpired" type="EXPIRED">{{ $t('_.expired') }}</GpBadge>
      </div>
      <DisplayKycDocument v-if="documentGroup" :model="documentGroup" />
      <div class="reviewing" v-if="isReviewing">
        <GpDescription :message="$t('_.myDocumentVerified')" />
      </div>
      <div>
        <GpTitle :name="$t('_.uploadedDocuments')" />
        <DisplayUploadedKycDocuments :model="model.KycUploadedDocuments" />
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton v-if="isManageVisible" type="submit-green" :progress="progress || !isLoad" @click="manage(validate)">{{ $t('_.manage') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isLocal, isTest } from '@/utils/baseUtil';
import DisplayKycDocument from '@/views/components/pages/my-page/template/views/DisplayKycDocument.vue';
import DisplayUploadedKycDocuments from '@/views/components/pages/my-page/template/views/DisplayUploadedKycDocuments.vue';
import { KYC_REQUEST_TYPE, KYC_STEP, kycMorePoiArray, kycVerified } from '@/constants/base/my-page';
import { siteIds } from '@/constants/base/siteMap';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import Specific from '@shared/types/Specific';

const excludeSites = [siteIds.GGPDE, siteIds.GGPCZ];
export default {
  name: 'NpMyDocuments',
  lexicon: 'myInfo',
  components: { DisplayUploadedKycDocuments, DisplayKycDocument, GpBadge, GpTitle, GpButton, GpFormTemplate, GpDescription },
  mixins: [],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      onBoardingStepLength: null,
      kycStatus: null,
      kycRequestType: null,
      isKycExpired: false,
      isLoad: false,
      pendingList: [],
      progress: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    token: state('user', 'token'),
    isManageVisible() {
      if (excludeSites.includes(this.site)) return false;
      if (!kycVerified.includes(this.kycStatus)) return true;
      if (this.kycRequestType !== KYC_REQUEST_TYPE.NONE) return true;

      return false;
    },
    poiVerifiedSite() {
      return ['WSOPON', 'GGPCZ', 'GGPPH'];
    },
    kycVerifiedArray() {
      if (this.poiVerifiedSite.includes(this.site)) return kycMorePoiArray;
      else return kycVerified;
    },
    kycStatsText() {
      if (kycMorePoiArray.includes(this.kycStatus)) {
        return this.$t('verified');
      } else {
        return this.$t('unverified');
      }
    },
    kycStatsType() {
      if (kycMorePoiArray.includes(this.kycStatus)) {
        return 'SUCCESS';
      } else {
        return 'FAILED';
      }
    },
    isReviewing() {
      if (!this.pendingList) return false;
      return this.pendingList.length > 0;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isLocal() {
      return isLocal();
    },
    isTest() {
      return isTest();
    },
    documentGroup() {
      return this.model.KycDocumentsGroup;
    },
    showKycDocuments() {
      if (!this.model.KycUploadedDocuments) return false;
      if (typeof this.model.KycUploadedDocuments !== 'array') return false;
      return this.model.KycUploadedDocuments.length >= 0;
    },
    verifyGroupResponsibleGamingSteps() {
      return [
        KYC_STEP.VerifyRgDepositSetting,
        KYC_STEP.VerifyRgLoginTimeSetting,
        KYC_STEP.VerifyRgBalanceSetting,
        KYC_STEP.VerifyRgTotalBetSetting,
        KYC_STEP.VerifyRgLossSetting,
        KYC_STEP.VerifyRgLossKrSetting,
        KYC_STEP.VerifyRgMonthlyLoginFrequencySetting,
        KYC_STEP.VerifyRgReLoginTimeSetting,
        KYC_STEP.Lugas
      ];
    }
  },
  methods: {
    async initialize() {
      const { myInfo, uploadedList } = await this.$myinfo.fetch({payload : null, block : true});

      this.kycStatus = myInfo?.KycStatus;

      const kycDocuments = await this.getKycDocuments();
      this.model.KycDocumentsGroup = kycDocuments?.KycDocumentGroup;
      this.model.KycUploadedDocuments = uploadedList?.Documents || [];

      this.kycRequestType = kycDocuments?.KycRequestType;
      this.isKycExpired = myInfo.IsKycExpired;
      this.pendingList = uploadedList?.Documents.filter(document => document.DocumentStatus === 0);
      this.isLoad = true;
    },

    async getKycDocuments() {
      const isManual = this.kycVerifiedArray.includes(this.kycStatus) === false;
      return await this.$services.player.getUploadDocuments(isManual);
    },

    async manage(validate) {
      this.progress = true;
      const r = this.$myinfo.redirectKycUrl({ redirectPath: 'np-my-documents', additional: true, lang: this.$i18n('lang') });
      if (!r) this.progress = false;
    },
  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-my-documents] {
  .title-group {.flex(); .space-between(); .items-center();}
  .reviewing {
    [gp-description] > p {letter-spacing: 0.42px;
      &:before {content: ''}
    }
  }
}

</style>

