import StoreProxy from '@shared/modules/StoreProxy';
import ToastProxy from '@shared/modules/ToastProxy';
import ModalProxy from '@shared/modules/ModalProxy';
import CookieService from '@/services/CookieService';
import BaseService from '@/services/BaseService';
import SignService from '@/services/sign/SignService';
import CaptchaService from '@/services/CaptchaService';
import OnBoardingService from '@/services/on-boarding/OnboardingService';
import KycService from '@/services/on-boarding/KycService';
import PoiService from '@/services/on-boarding/PoiService';
import PoaService from '@/services/on-boarding/PoaService';
import VerifyService from '@/services/on-boarding/VerifyService';
import AccountService from '@/services/AccountService';
import GgPassService from '@/services/GgPassService';
import MfaService from '@/services/MfaService';
import PlayerService from '@/services/PlayerService';
import TokenService from '@/services/sign/TokenService';
import NoticeService from '@/services/NoticeService';
import FindIdService from '@/services/findIdService';
import ApiConnection from '@/modules/ApiConnection';
import PromotionService from '@/services/PromotionService';
import VerificationService from '@/services/VerificationService';
import UserService from '@/services/UserService';
import { brandIds, siteIds } from '@/constants/base/siteMap';

export class Service {
  static cnt = 0;

  id;
  /** @type {StoreProxy} */ store;
  /** @type {ToastProxy} */ toast;
  /** @type {ModalProxy} */ modal;
  /** @type {CookieService} */ cookie;

  /** API */
  #api;
  #userApi;
  #passApi;
  #promoApi;
  #npDevApi;
  #channelingApi;

  constructor({ store = null, cookies = null }) {
    this.id = Service.cnt++;
    this.store = new StoreProxy(store);
    this.toast = new ToastProxy();
    this.modal = new ModalProxy();
    this.cookie = new CookieService(cookies);
  }

  #constructors = {
    base: BaseService,
    sign: SignService,
    captcha: CaptchaService,
    onboarding: OnBoardingService,
    kyc: KycService,
    poi: PoiService,
    poa: PoaService,
    verify: VerifyService,
    account: AccountService,
    ggpass: GgPassService,
    mfa: MfaService,
    player: PlayerService,
    token: TokenService,
    notice: NoticeService,
    findId: FindIdService,
    promotion: PromotionService,
    verification: VerificationService,
    user: UserService,
  };

  #instances = {};

  #brandPath() {
    const brand = this.store.state.env.brand;
    const site = this.store.state.env.site;
    let brandPath = brand;
    switch (brand) {
      case brandIds.N8: {
        brandPath = 'Natural8';
        break;
      }
      case brandIds.GGPOKERUK: {
        brandPath = 'GGPOKER';
        break;
      }
      case brandIds.GGPUKE:
      case brandIds.EVPUKE: {
        brandPath = 'MBP';
        break;
      }
    }
    if (this.store.state.env.isCasino) {
      brandPath = site === siteIds.WSOPON ? brandIds.WSOPCASINO : brandIds.GGPCOMCASINO;
    }
    return brandPath;
  }
  #npEndPoint(type, brand = true) {
    let path = `${this.#gateway}/np-gw/${type}`;
    if (brand) path += `/${this.#brandPath()}`;
    return path;
  }
  get #npDevEndPoint() { return this.store.state.env.api; }
  get #gateway() {
    return this.store.state.env.site === siteIds.WSOPON ? process.env.VUE_APP_GATEWAY_WSOP_API : process.env.VUE_APP_GATEWAY_API;
  }
  get base() { return this.getService('base'); }
  get sign() { return this.getService('sign'); }
  get token() { return this.getService('token'); }
  get captcha() { return this.getService('captcha'); }

  get onboarding() { return this.getService('onboarding'); }
  get kyc() { return this.getService('kyc'); }
  get poi() { return this.getService('poi'); }
  get poa() { return this.getService('poa'); }
  get verify() { return this.getService('verify'); }
  get account() { return this.getService('account'); }

  get ggpass() { return this.getService('ggpass'); }
  get mfa() { return this.getService('mfa'); }
  get player() {return this.getService('player');}
  get notice() {return this.getService('notice');}
  get findId() {return this.getService('findId');}
  get verification() { return this.getService('verification');}
  get user() {return this.getService('user');}


  get promotion() {return this.getService('promotion');}
  get api() {
    if (!this.#api) this.#api = new ApiConnection(this.#npEndPoint('public'), this, this.applyBase.bind(this));
    return this.#api;
  }

  get userApi() {
    if (!this.#userApi) this.#userApi = new ApiConnection(this.#npEndPoint('user'), this, this.applyBase.bind(this));
    return this.#userApi;
  }

  get npDevApi() {
    if (!this.#npDevApi) this.#npDevApi = new ApiConnection(this.#npDevEndPoint, this, this.applyBase.bind(this));
    return this.#npDevApi;
  }

  get promoApi() {
    if (!this.#promoApi) this.#promoApi = new ApiConnection(this.#npEndPoint('promo'), this, this.applyBase.bind(this));
    return this.#promoApi;
  }

  get passApi() {
    if (!this.#passApi) this.#passApi = new ApiConnection(this.#gateway, this, this.applyGgpass.bind(this));
    return this.#passApi;
  }

  get channelingApi() {
    if (!this.#channelingApi) this.#channelingApi = new ApiConnection(this.#npEndPoint('channeling', false), this, this.applyBase.bind(this));
    return this.#channelingApi;
  }

  applyGgpass(config) {
    const c = config || {};
    if (!c.headers) c.headers = {};
    if (this.store.state.user.gwToken) c.headers['Authorization'] = `Bearer ${this.store.state.user.gwToken}`; // c.headers['Authorization'] || `Bearer ${this.store.state.user.gwToken}`;
    if (this.store.state.user.gpToken) c.headers['Z-Authorization'] = `Bearer ${this.store.state.user.gpToken}`;
    return c;
  }

  applyBase(config) {
    const c = config || {};
    if (!c.headers) c.headers = {};

    if (this.store.state.user.gwToken) c.headers['Authorization'] = `Bearer ${this.store.state.user.gwToken}`;
    if (this.store.state.user.token) c.headers['X-Authorization'] = `Bearer ${this.store.state.user.token}`;
    return c;
  }

  getService(name) {
    if (!this.#instances[name]) this.#instances[name] = new this.#constructors[name](this);
    return this.#instances[name];
  }
}