import DepositBonus from '@/views/pages/promotion/DepositBonus.vue';
import DailyFreebie from '@/views/pages/promotion/DailyFreebie.vue';
import SignUpBonus from '@/views/pages/promotion/SignUpBonus.vue';
import ReferralBonus from '@/views/pages/promotion/ReferralBonus.vue';
import InvitationBonus from '@/views/pages/promotion/InvitationBonus.vue';

const view = path => () => import(/* webpackChunkName: "promotion" */ `../views/pages/promotion/${path}.vue`);

export default ({ local, token }) => {
  const path = local ? 'promotion/' : 'app/';
  const list = [
    /** redirect */
    {
      path: `deposit`,
      redirect: token ? `${path}deposit` : '/auth-error',
    },
    {
      path: `daily`,
      redirect: token ? `${path}daily` : '/auth-error',
    },
    {
      path: `signup`,
      redirect: token ? `${path}signup` : '/auth-error',
    },
    {
      path: `referral`,
      redirect: token ? `${path}referral` : '/auth-error',
    },
    {
      path: `invitation`,
      redirect: token ? `${path}invitation` : '/auth-error',
    },
    /** root */
    {
      path: `${path}`,
      name: 'Promotion',
      component: view('PromotionHome'),
      redirect: token ? `${path}deposit` : '/auth-error',
      children: [
        {
          path: 'deposit',
          name: 'Deposit',
          component: DepositBonus
        },
        {
          path: 'daily',
          name: 'Freebie',
          component: DailyFreebie
        },
        {
          path: 'signup',
          name: 'Signup',
          component: SignUpBonus
        },
        {
          path: 'referral',
          name: 'Referral',
          component: ReferralBonus
        },
        {
          path: 'invitation',
          name: 'Invitation',
          component: InvitationBonus
        },
      ]
    },
  ];
  return list;
};
