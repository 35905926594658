import { baseList, langList, siteIds, } from '@/constants/base/siteMap';
import { checkPermissionList } from '@shared/utils/permissionUtils.mjs';

/**
 * @description title: X MEAN: 화면 없음
 */

const screening = 'Screening';
const personal = 'Personal';
const personalName = 'PersonalName'; // title: Personal Details
const personalBirth = 'PersonalBirth'; // Backend 에서는 PersonalBirth 로 내려오지만, RO인 경우만 사용되기에 해당 값으로 치환 시켜야 함. title: Personal Details
const personalAddress = 'PersonalAddress'; // title: Address
const personalExtraAddress = 'PersonalExtraAddress'; // title: Permanent Address
const personalSelfDeclaration = 'PersonalSelfDeclaration'; // title: Self-Identification Declaration
const personalOccupation = 'PersonalOccupation'; // title: Occupation
const personalDetailReaffirm = 'PersonalDetailReaffirm';
const personalDetailsRecheck = 'ReCheckPersonalDetails';
const verifyCitizenship = 'VerifyCitizenship';
const personalPep = 'PersonalPep'; // title: Self-identification declaration
const contactEmail = 'ContactEmail'; // title: Account Verification
const contactMobileNumber = 'ContactMobileNumber'; // title: Account Verification
const verifyContactEmail = 'VerifyContactEmail'; // title: Account Verification
const verifyContactMobileNumber = 'VerifyContactMobileNumber'; // title: Account Verification
const verifyEmail = 'VerifyEmail';
const verifyMobileNumber = 'VerifyMobileNumber'; // title: Account Verification
const verifyCode = 'VerifyCode';
const temporarilyAccepted = 'TemporarilyAccepted';
const temporaryAccountLogin = 'TemporaryAccountLogin';
const idAddressVerification = 'IdAddressVerification';
const identityVerification = 'IdentityVerification';
const verificationComplete = 'VerificationComplete'; // BackEnd API step 존재 x
const verificationRequest = 'VerificationRequest';
const reviewingDocuments = 'ReviewingDocuments';
const signicat = 'Signicat';
const payletter = 'Payletter'; // 본인 인증 (2ACE)
const jumio = 'Jumio'; // title: KYC Verification
const sumsub = 'Sumsub'; // title: Identity Certification
const verifyFundProtectionAcceptance = 'VerifyFundProtectionAcceptance'; // title: Fund Protection Policy (GGPUK)
const verifyRgLoginTimeSetting = 'VerifyRgLoginTimeSetting'; // title: Login Time Limit (GGPNL)
const verifyRgBalanceSetting = 'VerifyRgBalanceSetting'; // title: Balance Limit (GGPNL)
const verifyRgDepositSetting = 'VerifyRgDepositSetting'; // title: Deposit Limit
const verifyRgLossSetting = 'VerifyRgLossSetting'; // title: Loss Limit
const verifyRgLossCzSetting = 'VerifyRgLossCzSetting'; // title: Loss Limit (GGPCZ)
const verifyRgLossKrSetting = 'VerifyRgLossKrSetting'; // title: Loss Limit (2ACE)
const verifyRgTotalBetSetting = 'VerifyRgTotalBetSetting';
const verifyRgMonthlyLoginFrequencySetting = 'VerifyRgMonthlyLoginFrequencySetting';
const verifyRgReLoginTimeSetting = 'VerifyRgReLoginTimeSetting';
const lugas = 'Lugas'; // title: Cross-Provider Deposit Limit
const poi = 'Poi'; // title: Proof of Identity
const poa = 'Poa'; // title: Proof of Address
const poc = 'Poc'; // title: Proof of Citizenship
const edd = 'Edd';
const kycDE = 'KycDE'; // title: Identity & Address Verification
const confirmPersonalInfo = 'ConfirmPersonalInfo';
const tempAccountExpired = 'TempAccountExpired';
const tempAccountGuide = 'TempAccountGuide';
const tempAccountLogin = 'TempAccountLogin';
const paymentAccountVerification = 'PaymentAccountVerification';
const verifyAge = 'VerifyAge'; // GGPDE title: IDENTIFYING, Default: X
const transUnion = 'TransUnion';
const showReviewingKycMessage = 'ShowReviewingKycMessage'; /** review 상태에서 jumio 진입시 해당 스텝으로 내려옴. */
const startOnboarding = 'StartOnboarding'; //
const regulatoryTransition = 'RegulatoryTransition';
const replace = 'Replace';
const poiSelfieReady = 'PoiSelfieReady';
const poiSelfie = 'PoiSelfie';
const eidvVerification = 'EidvVerification'; // signicat step=
const verifyCrucks = 'VerifyCrucks'; // signicat step
const verifySurePay = 'VerifySurePay'; // signicat step
const kycDocumentManual = 'KycDocumentManual'; // signicat step
/** 3rd */

export const routeNames = {
  screening,
  personal,
  personalName,
  personalBirth,
  personalAddress,
  personalExtraAddress,
  personalSelfDeclaration,
  personalOccupation,
  personalDetailReaffirm,
  personalDetailsRecheck,
  verifyCitizenship,
  verifyAge,
  contactEmail,
  contactMobileNumber,
  verifyContactEmail,
  verifyContactMobileNumber,
  verifyEmail,
  verifyMobileNumber,
  verifyCode,
  temporarilyAccepted,
  temporaryAccountLogin,
  idAddressVerification,
  identityVerification,
  verificationComplete,
  verificationRequest,
  startOnboarding,
  regulatoryTransition,
  payletter,
  signicat,
  personalPep,
  reviewingDocuments,
  verifyRgLoginTimeSetting,
  verifyRgBalanceSetting,
  verifyRgDepositSetting,
  verifyRgLossSetting,
  verifyRgLossCzSetting,
  verifyRgLossKrSetting,
  eidvVerification,
  verifyCrucks,
  verifySurePay,
  kycDocumentManual,
  verifyFundProtectionAcceptance,
  showReviewingKycMessage,
  jumio,
  sumsub,
  lugas,
  poi,
  poa,
  poc,
  edd,
  kycDE,
  tempAccountExpired,
  tempAccountGuide,
  tempAccountLogin,
  confirmPersonalInfo,
  paymentAccountVerification,
  verifyRgTotalBetSetting,
  verifyRgMonthlyLoginFrequencySetting,
  verifyRgReLoginTimeSetting,
  transUnion,
  replace,
  poiSelfieReady,
  poiSelfie,
};

/**
 * on-boarding Step의 Grouping에 활용 될 타입 정보
 * @type {{PERSONAL_DETAIL: string, ETC: string, VERIFY_RESPONSIBLE_GAMING: string}}
 */
export const STEP_TYPES = {
  PERSONAL_DETAIL: 'PERSONAL_DETAIL',
  VERIFY_RESPONSIBLE_GAMING: 'VERIFY_RESPONSIBLE_GAMING',
  ETC: 'ETC',
};
/**
 * Personal Detail Step Group 정보
 * @type {(string)[]}
 */
export const personalDetailSteps = [personalName, personalBirth, personalAddress, personalExtraAddress, contactEmail, contactMobileNumber, verifyContactEmail, verifyContactMobileNumber, verifyEmail, verifyMobileNumber, personalOccupation, personalPep, personalSelfDeclaration];
/**
 * Kyc Step Group 정보
 * @type {(string)[]}
 */
export const kycSteps = [poi, poa, edd, jumio, sumsub, signicat];
/**
 * Responsible Gaming Step Group 정보
 * @type {(string)[]}
 */
export const verifyResponsibleGamingSteps = [verifyRgDepositSetting, verifyRgLoginTimeSetting, verifyRgBalanceSetting, verifyRgTotalBetSetting, verifyRgLossSetting, verifyRgLossCzSetting, verifyRgLossKrSetting, verifyRgMonthlyLoginFrequencySetting, verifyRgReLoginTimeSetting, lugas];
/**
 * Step 처리 후 Replace(정보 재조회) 처리가 필요한 Step Group 정보
 * @type {(string)[]}
 */
export const replaceSteps = [verifyAge, transUnion, sumsub, jumio, signicat];

export const verifyCodeSteps = [verifyEmail, verifyMobileNumber, verifyContactEmail, verifyContactMobileNumber];
const currencySymbols = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
  'CAD': '$',
  'CNY': '¥',
  'JPY': 'JP¥',
  'VND': '₫',
  'THB': '฿',
  'RUB': '₽',
  'UAH': '₴',
  'CZK': 'Kč',
  'DKK': 'Kr.',
  'NOK': 'kr',
  'CHF': 'CHf',
  'BRL': 'R$',
  'INR': '₹',
  'COP': 'COL$',
  'MXN': 'MEX$',
  'ARS': 'ARS$',
  'RON': 'lei',
  'PLN': 'zł',
  'RSD': 'din',
  'HUF': 'Ft',
  'GEL': 'ლ',
  'KRW': '₩',
  'KZT': '₸',
  'CLP': 'CL$',
  'AUD': 'AU$',
  'GCD': { 'GGPDE': 'C€', 'N8IN': 'C', default: 'C$', },
  'GTD': { 'GGPDE': 'T€', 'N8IN': 'T', default: 'T$', },
  'GCO': 'Coin',
};

const getCurrencySymbol = (currency, siteId) => {
  const currencyMapping = currencySymbols[currency];
  if (typeof currencyMapping === 'object') return currencyMapping[siteId] || currencyMapping.default;
  return currencyMapping || currency;
};

export const excludeStreet = [siteIds.GGPEU, siteIds.GGPNL];

export default (domain, env, site, defaultSite, localCurrency) => {
  const list = {
    [siteIds.GGPUKE]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.NATURAL8]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.GGPOK]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.SEVENXL]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.DAVAOPOKER]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.EVPUKE]: { /** 인증 X */
      routeNames: null
    },
    [siteIds.N8IN]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth]],
    },
    [siteIds.GGPJP]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPNG]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPPL]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPBR]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPUA]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPCW]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPDK]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPCOM]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge]]
    },
    [siteIds.GGPFI]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge], [poi], [verifyRgDepositSetting]]
    },
    [siteIds.GGPHU]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge], [poi], [verifyRgDepositSetting]]
    },
    [siteIds.GGPEU]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge], [poi], [verifyRgDepositSetting]]
    },
    [siteIds.GGPRO]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, verifyMobileNumber, verifyCode]]
    },
    [siteIds.GGPPH]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress, personalExtraAddress, personalSelfDeclaration], [poiSelfieReady, poiSelfie]]
    },
    /** GGPBE 경우 personalAddress 에서 verifyAge(BGC)를 실행함 */
    [siteIds.GGPBE]: { /** 3rd party X */
      routeNames: [[personalName, personalBirth, personalAddress]]
    },
    /** GGPUK verifyAge 실패시 jumio step 이 추가됨 */
    [siteIds.GGPUK]: { /** 3rd party iframe: Jumio */
      routeNames: [[verifyMobileNumber, verifyCode, personalName, personalBirth, personalAddress, personalOccupation, verifyAge], [jumio, showReviewingKycMessage], [verifyFundProtectionAcceptance, verifyRgDepositSetting]]
    },
    /** GGPDE 경우
     * verifyAge 실패 횟수에 따라 lugas, kycDE 등 추가됨
     * temporarilyAccepted 에서 화면이 닫히고, 다시 onBoarding 접근 or temp account 만료 시에 다음 step 호출
     * kycDE step 에서 Sofort or Jumio 선택 */
    [siteIds.GGPDE]: { /** 3rd party iframe: jumio */
      routeNames: [[personalName, personalBirth, personalAddress, verifyAge], [lugas, temporarilyAccepted, kycDE]]
    },
    [siteIds.GGPNL]: { /** 3rd party redirection: signicat */
      routeNames: [[poiRequestSignicat, eidvVerification], [personalName, personalBirth, personalAddress, verifyCrucks, verifySurePay], [verifyRgDepositSetting, verifyRgLoginTimeSetting, verifyRgBalanceSetting]]
    },
    /** 3rd party redirection: payletter */[siteIds.TWOACE]: { routeNames: [payletter, verifyRgLossKrSetting] },
    /** WSOP 경우 PersonalPep 후에 Country, 입력한 데이터에 따라 후 step 이 달라진다. */
    /** country === CA && test Data 일 경우 아래 route 에서 Jumio 제외 */
    /** 3rd party iframe: jumio */
    [siteIds.WSOPON]: {
      routeNames: [[personalName, personalBirth, personalAddress, personalOccupation, personalPep, verifyAge, transUnion], [sumsub], [verifyRgDepositSetting, verifyRgLossSetting]]
    },
    /** StartOnboarding 에서 BankID 선택 후 완료 시에 confirmPersonalInfo 로 이동 */
    [siteIds.GGPCZ]: { /** 3rd party redirection: bankID */
      routeNames: [[startOnboarding, personalName, personalBirth, personalAddress, confirmPersonalInfo, verifyAge], [poi], [paymentAccountVerification, verifyRgTotalBetSetting, verifyRgLossCzSetting, verifyRgLoginTimeSetting, verifyRgReLoginTimeSetting, verifyRgMonthlyLoginFrequencySetting]]
    },
  };

  const selected = baseList.find(o => [defaultSite, site].includes(o.site));

  selected.langList = checkPermissionList(langList, selected.site);
  return { ...list[defaultSite || site], ...selected, currencySymbol: getCurrencySymbol(localCurrency, site) };
};
