<template>
  <SumsubContainer sumsub-reviewing v-if="identityDocuments || selfieDocuments">
    <FontIcon name="reviewing-document" />
    <h3 v-html="$t('onBoarding.title.reviewingDocuments')" />
    <p v-html="$t('_.description', {webSiteUrl})" />
    <template>
      <h4 v-html="$t('kyc.identityDocuments')" />
      <ul>
        <li v-for="document in identityDocuments">
          <div>
            <span class="name">{{ document.FileName }}</span>
            <span class="date">{{ document.UploadDate && toFullDateTS(document.UploadDate, locale) }}</span>
          </div>
          <span :class="getStatus(document.DocumentStatus)">{{ $t(`kyc.${getStatus(document.DocumentStatus)}`) }}</span>
        </li>
      </ul>
    </template>
    <template>
      <h4 v-html="$t('kyc.documentType.Selfie')" />
      <ul>
        <li v-for="document in selfieDocuments">
          <div>
            <span class="name">{{ document.FileName }}</span>
            <span class="date">{{ document.UploadDate && toFullDateTS(document.UploadDate, locale) }}</span>
          </div>
          <span :class="getStatus(document.DocumentStatus)">{{ $t(`kyc.${getStatus(document.DocumentStatus)}`) }}</span>
        </li>
      </ul>
    </template>
    <ProgressButton button-name="close" @click="$emit('confirm', 'close')" />
  </SumsubContainer>
</template>

<script>
import { baseList } from '@/constants/base/siteMap';
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';
import { toFullDateTS } from 'client-user-ssr/src/utils/dateTimeUtil';

export default {
  name: 'SumsubReviewing',
  lexicon: 'onBoarding.sumsub.reviewing',
  components: { FontIcon, SumsubContainer, ProgressButton },
  data() {
    return {
      identityDocuments: /** @type {KycDocument[]} */ [],
      selfieDocuments: /** @type {KycDocument[]} */ [],
    };
  },
  props: {
    data: Specific,
    item: {type: Specific, default: null},
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    locale() { return this.$i18n('locale'); },
    webSiteUrl() { return baseList.find(o => o.site === this.site)?.webSiteUrl?.replace('//', ''); },
  },
  methods: {
    toFullDateTS,
    getStatus(status) {
      switch (status) {
        case 0:
          return 'reviewing';
        case 1:
          return 'approved';
        case 2:
          return 'rejected';
        case 3:
          return 'expired';
        default:
          return '';
      }
    }
  },
  async mounted() {
    const data = await this.$services.kyc.getPlayerKycDocuments();
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
    this.identityDocuments = data?.Documents?.filter(document => document.DocumentType !== 'Selfie');
    this.selfieDocuments = data?.Documents?.filter(document => document.DocumentType === 'Selfie');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-reviewing] { .tc();
  > [font-icon] { .block(); .mb(8); .fs(80); .mt(24); }
  > h3 { .fs(22, 27, normal); .regular(); .mb(24); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a { .c(#2680EB); .underline(); }
  }
  > h4 { .mt(24); .mb(14); .tl(); .fs(18, 22, normal); .regular(); .c(#ccc); }
  ul { .tl();
    li { .flex(); .flex-ai(); .space-between(); .gap(8);
      &:not(:last-child) { .-b(#7F7F7F); .pb(12); }
      > span { flex: 0; .block(); .fs(20, 24, normal); .medium();
        &.reviewing{.c(#FFC64D);}
        &.approved{.c(#CCC);}
        &.rejected{.c(#7F7F7F);}
        &.expired{.c(#FFC64D);}
      }
      div { flex: 1; .break-all();
        span { .block();
          &.name { .fs(16, 20, normal); .c(#7F7F7F); }
          &.date { .mt(4); .fs(16, 20, normal); .c(#7F7F7F); }
        }
      }
    }
    li + li { .mt(12); }
  }
}
</style>
