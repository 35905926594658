<template>
  <div sign-up-complete name="Step9_Registration_View" class="signup-success-view" :style="{paddingTop: `${paddingTop}px`}">
    <div class="holder">
      <FontIcon name="check" />
      <h4>{{ $t('_.title') }}</h4>
      <p>{{ $t(subTitle, { accountId: structure.model.Email }) }}</p>
      <template v-if="siteCompleteMap">
        <a @click="openWelcomePage" alt="WELCOME" name="Step10a_Registration_Image_Click" class="signup-banner-click">
          <img :src="siteCompleteMap.successImage" />
        </a>
        <div v-if="!isFromMobileApp && !isFromClientApp">
          <ColorButton :type="color" @click="download">
            {{ $t('signUp.success.download') }}
            <FontIcon :name="osIcons[osType]" class="os-type" />
          </ColorButton>
          <section class="stores">
            <img v-for="item in stores" :key="item" :src="item" :alt="item" />
          </section>
          <section class="psps">
            <img v-for="item in siteCompleteMap.psp" :key="item" :src="item" :alt="item" />
          </section>
        </div>
        <ProgressButton button-name="complete" v-if="isFromMobileApp || isFromClientApp" @click="complete" />
      </template>
    </div>
  </div>
</template>

<script>
import completeMap from '@/constants/base/signup/signUpComplete';
import { state } from '@shared/utils/storeUtils.mjs';
import { addResize, removeResize } from '@shared/utils/resizeUtil';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { completeRedirectUrl, isExcludeGgpass } from '@/utils/baseUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';
import { requestBrandWebDownloadInstaller } from '@/utils/webSiteUtil';

export default {
  name: 'SignUpComplete',
  lexicon: 'signUp.success',
  components: { FontIcon, ProgressButton, ColorButton },
  props: {
    structure: Specific,
  },
  data() {
    return {
      siteCompleteMap: null,
      stores: [`${cdnImgPath}/ms_store.png`, `${cdnImgPath}/app_store.png`, `${cdnImgPath}/play_store.png`],
      paddingTop: 0,
      osIcons: {
        Android: 'brands-android',
        iOS: 'brands-apple',
        MacOSX: 'display',
        windows: 'brands-windows'
      },
      isFromMobileApp: getCookie('isFromMobileApp') || this.$route.query.isFromMobileApp,
      isFromClientApp: getCookie('isFromClientApp') || this.$route.query.isFromClientApp,
    };
  },
  computed: {
    site: state('env', 'site'),
    theme: state('env', 'theme'),
    brandSignup: state('env', 'brandSignup'),
    defaultSite: state('env', 'defaultSite'),
    downloadUrl: state('env', `downloadUrl`),
    landingMode: state('query', 'landingMode'),
    isMobile: state('browser', 'mobile'),
    signedPw: state('user', 'signedPw'),
    signedEmail: state('user', 'signedEmail'),
    signedAccessToken: state('user', 'signedAccessToken'),
    osType() {
      if (this.isMobile) return window.navigator.userAgent.includes('Android') ? 'Android' : 'iOS';
      return window.navigator.userAgent.includes('Mac') ? 'MacOSX' : 'windows';
    },
    color() {
      return this.theme === 'GOLD' ? 'gold' : 'light-red';
    },
    subTitle() {
      return !isExcludeGgpass(this.site) ? '_.ggpassSubTitle' : '_.subTitle';
    }
  },
  methods: {
    download() {
      if (this.brandSignup) requestBrandWebDownloadInstaller(this);
      else window.open(this.downloadUrl, '_blank');
    },
    openWelcomePage() {
      window.open(this.siteCompleteMap.welcomeUrl);
    },
    updatePosition() {
      this.paddingTop = Math.max((window.innerHeight - (this.$el.querySelector('.holder').clientHeight + 240)), 0) / (this.landingMode ? 5 : 1.4);
    },
    async initialize() {
      const language = this.$services.cookie.getLanguage()?.toUpperCase() || 'EN';
      this.siteCompleteMap = await completeMap(this.site || this.defaultSite, language);
      window.addEventListener('resize', this.updatePosition);
      addResize(document.body.querySelector('.contents-holder'), this.updatePosition);
      this.updatePosition();
    },
    async complete() {
      if (!completeRedirectUrl()) {
        if (isClient()) autoLogin(this, { site: this.site, doLogin: true, email: this.signedEmail, password: this.signedPw, token: await this.$services.sign.getGcLoginToken() });
        else close(this);
      }
    },
  },
  async mounted() {
    await this.initialize();
  },
  beforeDestroy() {
    this.$services.cookie.removeIsFromMobileApp();
    this.$services.cookie.removeIsFromClientApp();

    window.removeEventListener('resize', this.updatePosition);
    removeResize(document.body.querySelector('.contents-holder'), this.updatePosition);
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[sign-up-complete] { .tc(); .rel(); .flex(); .items-center(); .justify-center();
  .holder { .w(100%);
    > * {.block();}
    [font-icon] { .fs(48); .mh-c(); .mb(8); .c(@c-mint); }
    h4 { .fs(20); .mb(8);
      & + p { .mb(30); .fs(16); .medium(); .c(@c-w06) }
    }
    > a { .max-w(504); .w(100%); .mh-c();
      > img { .w(100%); }
    }
    [color-button] { .mt(40); .wh(100%, 56); .br(28); .fs(18); .tr-t(.3s); .rel(); .max-w(472); .mh-c(); .block(); .medium();
      .os-type { .abs(); .rt(25, 50%); .t-yc(); .fs(20); .c(@c-white); }
    }
    .stores { .flex-wrap(); .mt(20); .justify-center();
      > img { .w(32%);
        &:not(:nth-of-type(1)) { .ml(2%); }
      }
    }
    .psps { .flex-wrap(); .mt(28); .justify-center(); .o(0.7);
      > img { .m(0, 8); object-fit: contain; .h(24); .mt(12);}
    }
  }

  @media (@mm-up) {
    .holder {
      .stores {
        > img { .w(100); }
      }
    }
  }
  @media (@ml-up) {
    .holder {
      .stores {
        > img { .w(125); }
      }
      .psps { .w(504); .mh-c();
        > img { .h(32);}
      }
    }
  }
}
</style>