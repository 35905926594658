<template>
  <div rg-display-exceed-limit-modal>
    <GpDescription :message="description" />
    <div class="body">
      <p class="sub-title">{{ subTitle }}</p>
      <div v-if="displayList" class="content">
        <div class="item-body" v-for="(item, idx) in displayList" :key="idx">
          <div class="label">{{ item.label }}</div>
          <div class="row-content">
            <div class="row">
              <div class="row-title">{{ $t('_.currentLimit') }}</div>
              <div class="amount">{{ item.currentValue }}</div>
            </div>
            <div class="row">
              <div class="row-title">{{ $t('_.requestLimit') }}</div>
              <div class="amount">{{ item.requestValue }}</div>
            </div>
            <div class="row">
              <div class="row-title">{{ $t('_.maximumLimit') }}</div>
              <div class="amount exceed-value">{{ item.exceedValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import Specific from '@shared/types/Specific';
import moment from 'moment/moment';

export default {
  name: 'DisplayExceedLimitModal',
  lexicon: 'responsibleGaming.modal.label',
  components: { GpDescription },
  props: {
    title: { type: String, default: null },
    subTitle: { type: String, default: null },
    calculateList: { type: Specific, default: null },
    descModel: { type: Specific, default: null },
  },
  data() {
    return {
      displayList: null,
      descType: null,
    };
  },
  computed: {
    description() {
      if (!this.descType) return '';
      return this.$t(this.descModel[this.descType]);
    }
  },
  methods: {
    initialize() {
      if (!this.calculateList) {
        this.displayList = [];
      }
      const calculateResults = this.calculateList.map(item => this.createDisplayItem(item));
      const list = calculateResults.filter(x => x !== null && x.exceedValue);

      this.descType = 'exceed';
      this.displayList = list;
    },

    createDisplayItem(item) {
      return {
        label: this.$t(this.getLabelKey(item)),
        currentValue: this.createDisplayValue(item.current, item.currencySymbol),
        requestValue: this.createDisplayValue(item.request, item.currencySymbol),
        exceedValue: this.getExceedValue(item.request, item.limit, item.currencySymbol)
      };
    },
    getExceedValue(request, limit, currencySymbol) {
      return (request > limit) ? this.createDisplayValue(limit, currencySymbol) : null;
    },
    createDisplayValue(value, currencySymbol = '') {
      if (!value) return this.$t('noLimit');
      if (value === 'None') return this.$t('noLimit');
      return `${currencySymbol} ${value}`;
    },
    getLabelKey(item) {
      const { rolling, type } = item;
      const prefix = 'onBoarding.setting';

      switch (type) {
        case 'daily':
          return `${prefix}.${rolling ? '24hours' : 'dailyLimit'}`;
        case 'weekly':
          return `${prefix}.${rolling ? '7Days' : 'weeklyLimit'}`;
        case 'monthly':
          return `${prefix}.${rolling ? '30Days' : 'monthlyLimit'}`;
        default :
          return '';
      }
    },
  },
  mounted() {
    this.initialize();
  }
};


</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-exceed-limit-modal] {overflow-y: auto;.h(100%);
  .sub-title {.c(#000); .fs(14); font-weight: 600; text-align: center; .p(0, 20, 20, 20);}
  .body {
    .content {.flex(); .flex-dc(); .gap(20); }
    .item-body {.flex(); .flex-dc(); .gap(5);
      .label {.fs(16); font-weight: 600; .c(#000);}
      .row-content {.bgc(#fff); .p(10, 20, 10, 20); .br(16); .flex(); .flex-dc(); .gap(10);
        .row {.flex(); .space-between(); font-weight: 400; .fs(14);
          .row-title {.c(#737373);}
          .amount {.c(#000); }
          .exceed-value { .c(#c64040); font-weight: 600;}
        }
      }
    }
  }
}
</style>