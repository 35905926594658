<template>
  <div caution-message>
    <div>
      <FontIcon class="icon" name="exclamation-triangle-regular" />
    </div>
    <div>
      <span v-html="message"></span>
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'CautionMessage',
  components: { FontIcon },
  props: {
    message: { type: String, default: null }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[caution-message] {.flex();.c(#737373);.fs(14);line-height: 20px;.pb(20); .gap(5); .pt(15); font-weight: 600;
}
</style>