import { siteIds } from '@/constants/base/siteMap';

export const baseList = {
  [siteIds.GGPUKE]: { name: 'GGPuke.com' },
  [siteIds.EVPUKE]: { name: 'GGPuke.com' },
  [siteIds.NATURAL8]: { name: 'Natural8.com'},
  [siteIds.N8IN]: { url: '//natural8in.com', name: 'Natural8In.com' },
  [siteIds.GGPOK]: { url: '//pokerok166.com', name: 'PokerOK.com' },
  [siteIds.TWOACE]: { url: '' },
  [siteIds.WSOPON]: { url: '//ggpoker.ca', name: 'GGPoker.ca' },
  [siteIds.GGPCOM]: { url: '//ggpoker.com', name: 'GGPoker.com' },
  [siteIds.GGPUK]: { url: '//ggpoker.co.uk', name: 'GGPoker.co.uk' },
  [siteIds.GGPEU]: { url: '//ggpoker.eu', name: 'GGPoker.eu' },
  [siteIds.GGPFI]: { url: '//fi.ggpoker.eu', name: 'fi.GGPoker.eu' },
  [siteIds.GGPPL]: { url: '//pl1.ggpoker.com', name: 'pl.GGPoker.eu' },
  [siteIds.GGPDE]: { url: '//ggpoker.de', name: 'GGPoker.de' },
  [siteIds.GGPBE]: { url: '//ggpoker.be', name: 'GGPoker.be' },
  [siteIds.GGPHU]: { url: '//hun.ggpoker.eu', name: 'hun.GGPoker.eu' },
  [siteIds.GGPNL]: { url: '//ggpoker.nl', name: 'GGPoker.nl' },
  [siteIds.GGPRO]: { url: '//playgg.ro', name: 'PlayGG.ro' },
  [siteIds.GGPBR]: { url: '//br.ggpoker.com', name: 'br.GGPoker.com' },
  [siteIds.GGPUA]: { url: '//ua.ggpoker.com', name: 'ua.ggpoker.com' },
  [siteIds.GGPCW]: { url: '//kw.ggpoker.com', name: 'GGPoker.com' },
  [siteIds.GGPCZ]: { url: '//ggpoker.cz', name: 'GGPoker.com' },
  [siteIds.GGPPH]: { url: '//ggpoker.ph', name: 'GGPoker.ph' },
  [siteIds.GGPDK]: { url: '//ggpoker.com', name: 'GGPoker.com' },
  [siteIds.GGPJP]: { url: '//ggpoker.com', name: 'GGPoker.com' },
  [siteIds.GGPNG]: { url: '//ggpoker.com', name: 'GGPoker.com' },
  [siteIds.OCNP]: { url: '//oceanbetpoker.com', name: 'oceanbetpoker.com' },
};
