<template>
  <span :class="[{'focus':!readonly && focusOn, disabled, 'error': error && error.length , center},{sm},theme]" text-input class="opener" @click="focus">
    <input ref="input" v-model="model" :class="{ password: type === 'password' }" :pattern="pattern" :inputmode="inputMode" class="text-field" @focus="focus" @blur="blur"
           :disabled="disabled" :placeholder="placeholder" :readonly="readonly" :tabindex="tabindex" :type="type" :max="max" :min="min"
           @input="input" @change="change" @keydown.enter="$emit('enter')" @keydown.delete="$emit('delete')"/>
     <button class="input-btn-x" v-if="value && clearable" @click="reset" tabindex="-1">
       <FontIcon name="close" />
     </button>
  </span>
</template>
<script>

import remove from 'lodash/remove';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'TextInput',
  components: { FontIcon },
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    number: { type: Boolean, default: false },
    digit: { type: Boolean, default: false },
    phone: { type: Boolean, default: false },
    telephone: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    sm: { type: Boolean, default: false },
    wordonly: { type: Boolean, default: false },
    noneSpecial: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: [Array, Object], default: null },
    maxLength: { type: Number, default: 999 },
    inputMode: { type: String, default: '' },
    pattern: { type: String, default: '' },

    /** @type string{'white' | 'black' | 'dark'} */
    theme: { type: String, default: 'white' },
    useReset: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    tabindex: { type: String, default: '0' },
    translate: { type: Boolean, default: false },
    isDecimalLimit: { type: Boolean, default: false, },
    decimalLimit: { type: Number, default: null },
    max: { type: String, default: null, },
    min: { type: String, default: null, },
    clearable: Boolean,

  },
  data() {
    return {
      t0: '',
      model: '',
      focusOn: false,
      keyDigit: false,
    };
  },
  computed: {},
  watch: {
    value: 'update',
    error() {
      console.log('error : textInput', this.error);
    }
  },
  methods: {
    focusIn() {
      if(this.disabled) return;
      this.$el.querySelector('input').focus();
    },
    input(e) {
      if (this.disabled) return;

      let { value } = e.target;

      if (this.maxLength > 0) {
        if (this.maxLength === 1 && (!this.digit || (this.digit && this.keyDigit))) {
          const split = value.split('');
          const r = remove(split, o => o === this.t0);
          value = !split.length ? r[0] : split[0];
        } else value = value.slice(0, this.maxLength);
      }

      this.t0 = (() => {
        switch (true) {
          case this.digit:
            return value?.replace(/[^\d]/gi, '');
          case this.number:
            return value?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          case this.phone:
            return value?.replace(/[^-0-9.\\-]/g, '');
          case this.telephone:
            return value?.replace(/[^0-9\s()-]/g, '');
          case this.wordonly:
            return value?.replace(/[0-9]/g, '');
          case this.noneSpecial :
            return value?.replace(/[^a-zA-Z\d]/g, '');
          case this.isDecimalLimit : {
            if (!this.decimalLimit) return value;
            const decimalInputLimit = this.decimalLimit;
            const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${decimalInputLimit}})?$`);
            const isValidDecimalInput = decimalRegex.test(value);
            if (!isValidDecimalInput) {
              return value.slice(0, -1);
            } else return value;
          }
          default:
            return value;
        }
      })();

      this.model = this.t0 || '';
      this.$emit('input', this.t0);
    },
    update() {
      this.t0 = this.value;
      this.model = this.t0;
    },
    change() {
      this.$emit('change', this.t0);
      this.update();
    },
    focus() {
      if(this.disabled) return;
      this.focusOn = true;
      this.$el.querySelector('input').focus();
      if (this.number) this.$emit('focus', parseInt(this.t0, 10));
      else this.$emit('focus', this.t0);
    },
    blur() {
      if(!this.focusOn || this.disabled) return;
      this.focusOn = false;
      if (this.number) this.$emit('blur', parseInt(this.t0, 10));
      else this.$emit('blur', this.t0);
    },
    reset(e) {
      e?.stopPropagation();
      this.model = '';
      this.t0 = '';
      this.$emit('input', this.t0);
    },
    checkDigit(e) {
      this.keyDigit = !/[^\d]/gi.test(e.key);
    },
  },
  mounted() {
    this.update();
    if (this.digit) window.addEventListener('keydown', this.checkDigit);
  },
  beforeDestroy() {
    if (this.digit) window.removeEventListener('keydown', this.checkDigit);
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

.error [text-input] {
  &:after { .bgc(@c-red); .t-s(1); .h(1); }
  &.dark { .-a(@c-red);}
}

[text-input] { .block; .-box; .c(@c-placeholder); .h(34); .br(4); .medium; .crop; .rel; .lh(0); .-a(@c-b01);
  &:after {.cnt; .abs; .wh(calc(100% - 6px), 1); .bgc(rgba(0, 0, 0, 0.1)); .ml(3); .t-s(0); .lb(0, 1); transition: transform .3s;}
  &.focus:after {.bgc(rgba(0, 0, 0, 0.9)); .t-s(1);}
  input, input[type='text'], input[type='password'], input[type='number'] {.fs(14, 34); .wh(100%); .min-h(34); .-a(); .bgc(transparent); .p(0, 40, 0, 12); .-box; .c(@c-title-black); .br(0);
    &::placeholder {.c(@c-placeholder); .medium;}
  }
  > * {.ib;}
  .msg {.fs(9); .c(@c-placeholder); .tr; .hide; .mt(2); .block;}
  .input-btn-x { .r(10);}

  &.disabled {
    input::placeholder {.c(@c-b05); }
  }
  &.sm { .wh(80, 30); .br(8);
    input {.fs(12, 30px); .tc;}
  }
  &.error {
    &:after { .bgc(@c-red); .t-s(1); .h(1); }
  }
  &.black, &.dark { .h(34); .c(#fff); .br(6); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset;
    &:after {.cnt; .abs; .wh(100%, 1); .t-s(0); .lb(0, 0); transition: transform .3s;}
    &.focus { .-a(@c-yellow, 2px);}
    input { .c(#fff); .br(0); .fs(14); .bgc(transparent); .pr(12); }
    input, input[type='text'], input[type='password'], input[type='number'] {.fs(18, 30); .medium(); .pl(12);
      &::placeholder {.c(@c-w03); .regular(); }
    }
    &.error {
      //&:after {.bgc(#e5bb30); .t-s(1);}
    }
    &.transparent { box-shadow: none; .-a();
      &:after { content: none; }
      input { .bgc(transparent); .-b(); }
    }
    .input-btn-x { .bgc(@c-w02);
      [font-icon] { .c(white); }
    }
  }
  &.center {
    input { .tc; }
  }
  &.sm { .wh(80, 30); .br(8);
    input {.fs(12, 30px);.tc;}
  }
  [font-icon] { .abs; .rt(8, 6); }
}
</style>
