<template>
  <div personal-info-editable-wrapper>
    <slot name="editable" :editable-model="editableModel" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { KYC_STATUS, kycMorePoiArray, STEP } from '@/constants/base/my-page';
import { isNil } from 'lodash';
import { siteIds } from '@/constants/base/siteMap';
import { state } from '@shared/utils/storeUtils.mjs';
export default {
  name: 'PersonalInfoEditableWrapper',
  props: {
    model: Specific,
    step: { type: String, default: null },
    kycStatus: { type: String, default: null }
  },
  data() {
    return {
      editableModel: {}
    };
  },
  computed: {
    site: state('env', 'site'),
    siteSpecificDisableFields() {
      return {
        // AdditionalAddress 는 코드상에는 적혀 있지 않지만, 실제 동작은 값이 없어도 비활성화 처리가 되고 있기 때문에 추가함.
        [siteIds.GGPDE]: ['MaidenName', 'PlaceOfBirth', 'StreetName', 'HouseNumber', 'Address', 'State', 'City', 'PostalCode', 'Gender', 'AdditionalAddress'],
        [siteIds.GGPCZ]: ['Gender', 'PlaceOfBirth', 'StreetName', 'StreetNumber', 'State', 'City', 'PostalCode'],
      };
    },
    disableFields() {
      const defaultList = ['FirstName', 'LastName', 'DateOfBirth'];
      if (this.siteSpecificDisableFields.hasOwnProperty(this.site)) {
        return [...defaultList, ...this.siteSpecificDisableFields[this.site]];
      }

      return defaultList;
    },

    // 값이 없을때 활성화 되는 필드 목록
    enableFields() {
      const defaultList = ['FirstName', 'LastName', 'DateOfBirth', 'Gender'];
      if (this.isGGPDE) {
        return [...defaultList, 'PlaceOfBirth'];
      }
      if (this.isGGPUK) {
        return [...defaultList, 'Building', 'Address', 'City', 'PostalCode'];
      }

      return defaultList;
    },

    disableDropdownFields() {
      const fields = ['Country', 'Nationality'];
      return fields.filter(x => this.model[x]);
    },

    isDefaultStep() {
      return this.step === STEP.DEFAULT;
    },
    isInputStep() {
      return this.step === STEP.INPUT;
    },
    isKycSignedUp() {
      return this.kycStatus === KYC_STATUS.SIGNED_UP;
    },
    isMorePoiStatusCheck() {
      return kycMorePoiArray.includes(this.kycStatus);
    },

    isN8IN() {
      return this.site === siteIds.N8IN;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGGPUK() {
      return this.site === siteIds.GGPUK;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    }
  },
  watch: {
    'step': 'updateStep'
  },
  methods: {
    updateStep() {
      if (this.isDefaultStep) this.allDisableField();
      if (this.isInputStep) this.setEditableFields();
    },
    initialize() {
      this.editableModel = Object.keys(this.model).reduce((acc, cur) => {
        acc[cur] = false;

        return acc;
      }, {});
    },
    hasValue(key) {
      return !isNil(this.model[key]) && this.model[key] !== '';
    },
    setEditableFields() {
      Object.keys(this.editableModel).forEach(key => {
        this.editableModel[key] = this.checkEditableField(key);
      });
    },
    checkDisableField(key) {
      return this.disableFields.includes(key);
    },
    // 편집 가능한 필드를 체크
    checkEditableField(key) {
      let isEditable = true;

      if (this.checkDisableField(key)) isEditable = false;
      // Gender에 대한 처리 ( N8IN은 제외 되어 있음 )
      if (!this.isN8IN && key === 'Gender') {
        if (this.hasValue(key)) isEditable = false;
      }

      if (this.enableFields.includes(key)) {
        if (!this.hasValue(key) || this.isKycSignedUp) isEditable = true;
      }

      // N8IN의 경우, Kyc Level 에 따라서 Editable 처리가 필요
      if (this.isN8IN) {
        if (this.isMorePoiStatusCheck) isEditable = false;
        else isEditable = true;
      }

      // 드롭다운 input 처리
      if (this.disableDropdownFields.includes(key)) return false;
      return isEditable;
    },

    // 모든 필드를 비활성화 함
    allDisableField() {
      Object.keys(this.editableModel).forEach(key => {
        this.editableModel[key] = false;
      });
      this.$emit('init');
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[personal-info-editable-wrapper] {}
</style>