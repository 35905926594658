<template>
  <SectionContainer referral-code>
    <template #contents>
      <div class="code-box">
        <h5>{{ $t('_.referralCode') }}</h5>
        <div class="code">
          <p id="celina-copy-code">{{ disabled ? '-' : info.invitationCode }}</p>
          <div class="message-container">
            <a @click="e => copyCode(e, code, 'showCodeCopied')" :disabled="disabled">
              <FontIcon name="content-copy" />
            </a>
            <span class="copied-message" v-show="showCodeCopied">{{ $t('_.codeCopiedToClipboard') }}</span>
          </div>
        </div>
      </div>
      <div class="link-box">
        <h5>{{ $t('_.shareReferralLink') }}</h5>
        <div class="share">
          <div class="link">
            <div class="code-container">
              <p>{{ disabled ? '-' : shareLink }}</p>
            </div>
            <div class="message-container">
              <a @click="e => copyCode(e, shareLink, 'showLinkCopied')" :disabled="disabled">{{ $t('promotion.copyLink') }}</a>
              <span class="copied-message" v-show="showLinkCopied">{{ $t('_.linkCopiedToClipboard') }}</span>
            </div>
          </div>
          <div class="sns">
            <a :href="snsUrl.facebook" class="facebook" target="_blank">
              <FontIcon name="facebook-f" />
            </a>
            <a :href="snsUrl.twitter" class="twitter" target="_blank">
              <FontIcon name="twitter" />
            </a>
          </div>
        </div>
      </div>
    </template>
  </SectionContainer>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import SectionContainer from '@/views/components/pages/promotion/SectionContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { copyClipboard, sleep } from '@shared/utils/commonUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import Specific from "@shared/types/Specific";

export default {
  name: 'ReferralCode',
  lexicon: 'promotion.referral',
  components: { SectionContainer, FontIcon },
  props: {
    info: /** @type{ReferralPromotionStatus} */ Specific,
  },
  data() {
    return {
      showCodeCopied: false,
      showLinkCopied: false,
    };
  },
  computed: {
    env: state('env', 'env'),
    brand: state('env', 'brand'),
    site: state('env', 'site'),
    token: state('user', 'token'),
    selectedLang: state('query', 'selectedLang'),
    baseLang: state('env', 'baseLang'),
    disabled() {
      return !this.info.isOnBoardingCompleted;
    },
    code() {
      return this.info?.invitationCode;
    },
    shareLink() {
      if (!this.info) return;
      const signUpUrl = process.env[`VUE_APP_SIGNUP_URL_${siteIds[this.site]}`];
      const queries = {
        qtag1: this.info?.invitationCode,
        b: '1',
        siteId: this.info?.siteId,
        lang: this.selectedLang || this.baseLang,
      };
      if (this.isMobile) queries.ViewType = 'mobileType';

      const queryString = Object.keys(queries).map(key => `${key}=${queries[key]}`).join('&');
      return `${signUpUrl}?${queryString}`;
    },
    snsUrl() {
      const title = this.$t('promotion.referral.invitationToJoin');
      return {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareLink)}&t=${encodeURIComponent(title)}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.shareLink)}&text=${encodeURIComponent(title)}`,
      };
    },
  },
  methods: {
    async showMessage(data) {
      if (data === 'showLinkCopied') this.showLinkCopied = true;
      else this.showCodeCopied = true;
      await sleep(1000);
      if (data === 'showLinkCopied') this.showLinkCopied = false;
      else this.showCodeCopied = false;
    },
    async copyCode(e, code, data) {
      try {
        copyClipboard(code);
        await this.showMessage(data);
      } catch (e) {
        alert(`copy error: ${e}`);
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
#copy-input {
  .wh(1); .crop; .ti(-1000px);
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
.RTL {
  [referral-code] {
    @media (@tp-up) {
      .link-box .share::before { .l(auto); .r(-20); .t-xy(50%, -50%); }
    }
  }
}
[referral-code] { .rel(); .p(0); .-t(@c-lightBlack, 2);
  h5 { .mb(16); .semi-bold(); .fs(16); .c(@c-blurWhite); }
  [class$='-box'] { .p(20); }
  .code-box {
    .code { .flex(); .items-center(); .gap(16); .h(50); .ph(24); .br(2em); .-a(@c-dimGrey, 2); .dashed(); .c(@c-whiteSmoke);
      p { flex: 1; .medium(); .fs(24); .tc(); .ellipsis(); }
      a { .flex(); .p(4); .bgc(); .c(inherit);
        [font-icon] { .fs(1.5em); }
      }
    }
  }
  .link-box { .-start(@c-lightBlack, 2); .pb(40);
    .share { .flex(); .flex-wrap(); .items-center(); .row-gap(30); .column-gap(12); .rel(); .wf();
    }
    .link { flex: 1; .flex(); .justify-center(); .items-center(); .gap(16); .wf(); .h(50); .p(10, 24); .br(2em); .bgc(@c-lightSmoke);
      .code-container { flex: 1; .rel();
        p { .abs(); .lt(0, 0); .wf(); .c(@c-nero); .ellipsis(); .t-yc(); }
      }
      a { .bgc(); .semi-bold(); .c(@c-blurRed); .nowrap(); .fs(14); }
    }
    .sns { .flex(); .justify-center(); .wf(); .column-gap(8); .fs(20); .c(@c-white); .tc();
      a { .block(); .w(50); .br(50%);
        [font-icon] { .lh(50); }
      }
      .facebook { .bgc(@c-blueberry); }
      .twitter { .bgc(@c-skyBlue); }
    }
  }
  button:disabled { .o(0.3); cursor: not-allowed; }
  .message-container { flex: 0 0; .rel();
    .copied-message { .abs(); .b(140%); .r(20); .p(4, 8); .br(4); .bgc(#777); .fs(14); .c(@c-blurWhite); .nowrap();
      &::before { .cnt(); .hide(); .z(-1); .abs(); .b(0); .l(50%); transform: translate(-50%, 50%) rotate(45deg); .wh(16); .bgc(inherit); }
    }
  }

  @media (@tp-up) { .flex(); .wf();
    .code-box { .w(calc(100% / 3)); }
    .link-box { flex: 1;
      .share { .rel();
        &::before { .cnt('or'); .abs(); .t(50%); .l(-20); .t-xyc(); .medium(); .fs(14); .c(@c-white); }
      }
      .sns { .w(); }
    }
    .message-container .copied-message { .l(50%); .r(auto); .t-xc(); }
  }
}
</style>
