<template>
  <div extra-address-ph v-if="modelInfo">
    <div class="fields-holder">
      <CheckboxItem theme="dark" v-model="modelInfo.IsSamePermanentAddress" @change="toggleSameAddress">{{ $t('_.extraAddress.samePermanentAddressNotice') }}</CheckboxItem>
      <ValidationComponent :label="$t('_.address')" name="_address" :component="TrimTextInput" :rules="`address:0,120,${this.modelInfo.CountryWritingSystem}`" v-model="extraAddressInfo.Address" :preset="addressPreset" :error-msg="addressErrorMsg" />
      <ValidationComponent :label="$t('_.street')" name="_street" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="extraAddressInfo.StreetName" :preset="streetNamePreset" :error-msg="streetErrorMsg" />
      <ValidationComponent :label="$t('_.barangay')" name="_barangay" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="extraAddressInfo.District" :preset="districtPreset" :error-msg="districtErrorMsg" />
      <ValidationComponent :label="$t('_.stateProvince')" name="_stateProvince" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="extraAddressInfo.State" :preset="statePreset" :error-msg="stateErrorMsg" />
      <ValidationComponent :label="$t('_.city')" name="_city" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="extraAddressInfo.City" :preset="cityPreset" :error-msg="cityErrorMsg" />
      <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalCodeRule" v-model="extraAddressInfo.PostalCode" :preset="postalPreset" :error-msg="postalCodeErrorMsg" />
    </div>
    <ProgressButton button-name="next" @click="confirm" :progress="isProgress" :name="buttonTag" :class="currentItem.buttonClass" :disabled="!!structure.limitTime || structure.error || invalid" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import Specific from '@shared/types/Specific';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'ExtraAddressPh',
  lexicon: 'personalDetails',
  components: { CheckboxItem, ValidationComponent, ProgressButton },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      originExtraAddressInfo: {
        Address: null,
        StreetName: null,
        District: null,
        // StateProvince: null,
        City: null,
        PostalCode: null,
        StreetNumber: '',
        HouseNumber: '',
        Building: '',
        AddressType: 'PermanentAddress',
      },
      extraAddressInfo: {
        Address: null,
        StreetName: null,
        District: null,
        // StateProvince: null,
        City: null,
        PostalCode: null,
        StreetNumber: '',
        HouseNumber: '',
        Building: '',
        AddressType: 'PermanentAddress',
      },
      isSame: true,
      isSameAddress: false,
      isNoneAddress: false,
      hasAddress: true,
      newAddress: null,
      isProgress: false,

      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,

      TrimTextInput,
    };
  },
  computed: {
    siteId: state('user', 'siteId'),
    addressPreset() {
      return { maxLength: 120, disable: this.isSameAddress };
    },
    streetNamePreset() {
      return { maxLength: 50, disable: this.modelInfo.IsSamePermanentAddress };
    },
    districtPreset() {
      return { maxLength: 120, disable: this.modelInfo.IsSamePermanentAddress };
    },
    statePreset() {
      return { maxLength: 50, disable: this.modelInfo.IsSamePermanentAddress };
    },
    cityPreset() {
      return { maxLength: 50, disable: this.modelInfo.IsSamePermanentAddress };
    },
    postalPreset() {
      return { maxLength: 4, disable: this.modelInfo.IsSamePermanentAddress, type: 'digit', digit: true, };
    },
    postalCodeRule() {
      return 'requiredNumber:4,4';
    },
    extraAddress() {
      return this.extraAddressInfo?.Address;
    },
    step() {
      return this.structure.step;
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if (!t) return '';
      return t?.[this.site] || t.default || t;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
  },
  // watch: {
  //   extraAddress() {
  //     this.newAddress = this.extraAddressInfo?.Address;
  //     this.toggleSameAddress();
  //   },
  // },
  methods: {
    /**
     * checkbox click 시 정보 갱신
     */
    toggleSameAddress() {
      this.isSameAddress = this.modelInfo.IsSamePermanentAddress;
      if (this.modelInfo.IsSamePermanentAddress) {
        this.extraAddressInfo.Address = this.modelInfo?.Address;
        this.isSameAddress = !!this.modelInfo?.Address;
        this.extraAddressInfo.StreetName = this.modelInfo?.StreetName;
        this.extraAddressInfo.District = this.modelInfo?.District;
        this.extraAddressInfo.State = this.modelInfo?.State;
        this.extraAddressInfo.City = this.modelInfo?.City;
        this.extraAddressInfo.PostalCode = this.modelInfo?.PostalCode;
      } else {
        this.extraAddressInfo.Address = this.originExtraAddressInfo?.Address;
        this.extraAddressInfo.StreetName = this.originExtraAddressInfo?.StreetName;
        this.extraAddressInfo.District = this.originExtraAddressInfo?.District;
        this.extraAddressInfo.State = this.originExtraAddressInfo?.State;
        this.extraAddressInfo.City = this.originExtraAddressInfo?.City;
        this.extraAddressInfo.PostalCode = this.originExtraAddressInfo?.PostalCode;

        this.$parent.reset(); // validator reset
      }
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      this.isProgress = true;
      const payload = { ExtraAddresses: [{ ...this.extraAddressInfo }] };
      await this.apiRequest(this.currentItem?.api, payload);
      this.isProgress = false;
      if (!this.invalid && !this.structure.error) {
        // 정상적으로 저장 되었을 경우, modelInfo 를 갱신함.
        this.modelInfo.ExtraAddresses = [{ ...this.extraAddressInfo }];
        console.log('this.modelInfo.ExtraAddresses', this.modelInfo.ExtraAddresses);
        this.modelInfo.step = 1;
      }
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if (!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); } catch (e) { return e; }
    },
    /**
     * api Error Handler
     * @param {object} r - error 정보
     * @returns {Promise<boolean>}
     */
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      /*
      * INVALID_STREET_NAME
        INVALID_STREET_NUMBER
        INVALID_HOUSE_NUMBER
        MISSING_EXTRA_ADDRESS
        INVALID_BARANGAY
        INVALID_ADDRESS
        INVALID_ADDRESS_LENGTH
        INVALID_ADDRESS_CHARACTER
        INVALID_STATE
        INVALID_BUILDING
        INVALID_CITY
        INVALID_CITY_LENGTH
        INVALID_CITY_CHARACTER
        INVALID_POSTAL_CODE
        INVALID_POSTAL_CODE_LENGTH
        INVALID_POSTAL_CODE_CHARACTER
      */
      switch (key) {
        case apiErrorCode.USER_INFO_ALREADY_EXIST:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.REJECT_FROM_BGC:
          this.replaceRouteName('VerificationFailed', { desc, errorTemplate }, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
          break;
        case apiErrorCode.INVALID_STREET_NAME:
        case apiErrorCode.INVALID_STREET_NUMBER:
          this.streetErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_BARANGAY:
          this.districtErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_HOUSE_NUMBER:
          this.houseNumberErrorMsg = this.$t(key, { fieldName: this.$t('houseNumber') });
          break;
        case apiErrorCode.INVALID_ADDRESS:
        case apiErrorCode.INVALID_ADDRESS_LENGTH:
        case apiErrorCode.INVALID_ADDRESS_CHARACTER:
          this.addressErrorMsg = this.$t(key, { fieldName: this.$t('address'), length: this.addressPreset.maxLength });
          break;
        case apiErrorCode.INVALID_STATE:
          this.stateErrorMsg = this.$t(key, { fieldName: this.$t('stateProvince') });
          break;
        case apiErrorCode.INVALID_CITY:
        case apiErrorCode.INVALID_CITY_LENGTH:
        case apiErrorCode.INVALID_CITY_CHARACTER:
          this.cityErrorMsg = this.$t(key, { fieldName: this.$t('city'), length: this.cityPreset.maxLength });
          break;
        case apiErrorCode.INVALID_POSTAL_CODE:
        case apiErrorCode.INVALID_POSTAL_CODE_LENGTH:
        case apiErrorCode.INVALID_POSTAL_CODE_CHARACTER:
          this.postalCodeErrorMsg = this.$t(key, { fieldName: this.$t('postalCode'), length: this.postalPreset.maxLength });
          break;
      }

      return true;
    },
  },
  async mounted() {
    const extraAddress = this.modelInfo.ExtraAddresses[0] || {};
    this.originExtraAddressInfo = { ...this.originExtraAddressInfo, ...extraAddress };
    this.extraAddressInfo = { ...this.extraAddressInfo, ...extraAddress, };

    this.extraAddressInfo.Building = '';
    this.extraAddressInfo.HouseNumber = '';
    this.extraAddressInfo.StreetNumber = '';
    if (this.modelInfo.IsSamePermanentAddress) {
      this.isSameAddress = !!this.modelInfo?.Address;
      this.isNoneAddress = !this.isSameAddress;
      this.extraAddressInfo.Address = this.modelInfo?.Address;
      this.extraAddressInfo.StreetName = this.modelInfo?.StreetName;
      this.extraAddressInfo.District = this.modelInfo?.District;
      this.extraAddressInfo.State = this.modelInfo?.State;
      this.extraAddressInfo.City = this.modelInfo?.City;
      this.extraAddressInfo.PostalCode = this.modelInfo?.PostalCode;
      await this.$nextTick();
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[extra-address-ph] {.rel();
  > div {
    [checkbox-item] { .mb(20);
      em { white-space: normal; }
     }
    > * {
      &:not(:nth-of-type(1)) { .mt(8); }
    }
  }
}
</style>
