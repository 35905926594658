import { isExcludeGgpass } from '@/utils/baseUtil';
import { getObjectIndexByProperty } from '@/utils/valueUtil';
import { npPasswordConfigs } from '@/utils/pwUtil';
import { siteIds } from '@/constants/base/siteMap';
import { routeNames } from '@/constants/base/signup/signUpSiteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';

export class SignServiceController {
  #services;
  #scope;
  #router;
  #store;
  #model;
  #isMBP;
  #isGGPBE;
  #site;
  #ggpass;
  #npOnly;
  #countryCode;

  #structure;
  #routeNames;

  setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#model = scope.$model;
    this.#countryCode = scope.$route.query.country || this.#store.state.user.countryCode;

    this.#site = this.#store.state.env.site;
    this.#ggpass = this.#store.state.env.ggpass;
    this.#npOnly = this.#store.state.env.npOnly;
    this.#routeNames = this.#store.state.env.routeNames;
    this.#isMBP = [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.#site);
    this.#isGGPBE = this.#site === siteIds.GGPBE;

    this.#scope = scope;
  }

  async initialize() {
    if (!this.#site && !this.#ggpass && !this.#npOnly) {
      await this.#scope.replaceRouteName('SignUpCountry', null, this.#scope.$route.query);
      return;
    }

    if (!this.#isMBP) await this.#services.captcha.reCaptchaInitialize();
    const pwConfig = !isExcludeGgpass(this.#site) ? await this.#services.ggpass.getConfig() : npPasswordConfigs(this.#site);
    const displayTnc = !isExcludeGgpass(this.#site) ? pwConfig?.displayTnc : (await this.#services.sign.getSiteConfig(this.#site))?.RequireTcAgreement;
    this.#store.commit('config/setInfo', pwConfig);
    this.#store.commit('config/setDisplayTnc', displayTnc);
  }

  async updateStructure(structure, requireTnc) {
    if(!requireTnc) {
      this.#structure = structure?.list.filter(s => s.route !== routeNames.SignUpTnc);
    }
  }

  async getModel() {
    const model = await this.#model.getModel(this.#scope, {application: 'signup', orderRouteNames: this.#routeNames, siteId: this.#site});
    if (model) model.model.CountryCode = this.#countryCode;
    return model;
  }

  async setRecaptcha(action) {
    return this.#isMBP ? await this.#services.captcha.setGeeCaptcha() : await this.#services.captcha.setRecaptcha(action);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$sign = new SignServiceController();
  }
};