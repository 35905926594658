import siteModel from '@/constants/model/forgot-password';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { setGTM } from '@/plugins/tracking';
import { siteIds } from '@/constants/base/siteMap';

class ForgotPasswordService {
  #services;
  #router;
  #store;
  #siteMap;
  #siteInfo;
  #site;
  #routeName;
  constructor(services, store, router) {
    this.#services = services;
    this.#router = router;
    this.#store = store;
    this.#siteInfo = store.state.env.siteInfo;
    this.#siteMap = siteModel();
    this.#site = store.state.env.site;
    this.updateSiteMap();
    this.#setRecaptcha();

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  #setRecaptcha() {
    if (![siteIds.GGPUKE, siteIds.EVPUKE].includes(this.#site)) this.#services.captcha.reCaptchaInitialize();
  }

  get currentPageModel() {
    return this.#siteMap[this.#routeName];
  }
  get name() {
    return this.#routeName;
  }
  get model() {
    return this.#siteMap.model;
  }
  get next() {
    return this.currentPageModel.next;
  }

  get root() {
    return this.#siteMap.root;
  }

  get currentStep() {
    return this.currentPageModel.step;
  }

  get useCaptcha() {
    const { use } = this.currentPageModel['captcha'];
    const { OP, NP } = use;
    if (!isExcludeGgpass(this.#site)) return OP;
    else return NP;
  }
  get captchaAction() {
    const { action } = this.currentPageModel['captcha'];
    const { OP, NP } = action;
    if (!isExcludeGgpass(this.#site)) return OP;
    else return NP;
  }
  get prevRoute() {
    const { OP, NP } = this.currentPageModel['prevRoute'];
    if (!isExcludeGgpass(this.#site)) return OP;
    else return NP;
  }
  get nextRoute() {
    const { OP, NP } = this.currentPageModel['nextRoute'];
    if (!isExcludeGgpass(this.#site)) return OP;
    else return NP;
  }
  get apiInfo() {
    const { OP, NP } = this.currentPageModel['action'];
    if (!isExcludeGgpass(this.#site)) return OP;
    else return NP;
  }

  get api() {
    return this.currentPageModel.api;
  }

  get stepList() {
    return this.currentPageModel.step || [];
  }
  get currentStepIndex() {
    if (this.stepList.length <= 0) return 0;
    return this.stepList.findIndex(step => this.name === step);
  }

  get currentFetchKey() {
    return this.currentPageModel.fetchKeys || null;
  }
  get isFirstStep() {
    return this.currentStepIndex === 0;
  }
  get isLastStep() {
    return this.currentStepIndex === this.stepList.length - 1;
  }

  get currentComponent() {
    return this.currentPageModel.component || null;
  }

  updateSiteMap() {
    this.#siteMap = siteModel();
  }

  changeRouteName(name) {
    this.#routeName = name;
    this.updateSiteMap();
  }

  loadCaptchaAction(type) {
    if (this.captchaAction.hasOwnProperty('default')) {
      return this.captchaAction['default']['action'];
    } else {
      return this.captchaAction[type]['action'];
    }
  }

  async createAction(payload, token) {
    const { service, api } = this.currentPageModel;
    if (token) payload = { ...payload, Method: 'TOKEN', UserName: payload.UserName, };
    return this.#services[service][api](payload, 'RESET_PASSWORD');
  }
}

export default {
  install(Vue, { services, store, router }) {
    Vue.prototype.$fp = new ForgotPasswordService(services, store, router);
  }
};