export default class PromotionService {
  #api;
  #optIn;
  constructor(services) {
    this.#api = services.promoApi;
  }

  history({ PlayerPromotionId, CurrentPage, PageSize }) {
    try {
      return this.#api.get('/v1/promotion/history', { PlayerPromotionId, CurrentPage, PageSize });
    } catch (e) {
      console.log('error : promotion history');
    }
  }

  /**
   * @id GetPromotionEnableByTypeRequest
   * @returns {Promise<PromotionAccessible>}
   * */
  async promotionInfo() {
    try{
      const r = await this.#api.get('/v1/promotion');
      return r;
    } catch (e) {
      return { error: true, path: 'AuthError' };
    }
  }

  /**
   * @id GetPromotionStatusRequest
   * @returns {Promise<PromotionStatus>}
   */
  depositPromotion() {
    try {
      return this.#api.get('/v1/promotion/status');
    } catch (e) {
      return { error: true, path: 'AuthError' };
    }
  }

  /**
   * @id GetSignUpPromotionStatusRequest
   * @returns {Promise<PromotionStatus>}
   */
  signUpPromotion() {
    try {
      return this.#api.get('/v1/sign-up/promotion');
    } catch (e) {
      return { error: true, path: 'AuthError' };
    }
  }

  /**
   * @id GetDailyPromotionStatusRequest
   * @param {string?} Language
   * @returns {Promise<DailyFreebie>}
   */
  dailyPromotion(Language) {
    try {
      return this.#api.get('/v1/daily/promotion', { Language });
    } catch (e) {
      return { error: true, path: 'AuthError' };
    }
  }

  /**
   * @id GetAffiliateDepositPromotionsRequest
   * @desc 기존 코드에서도 사용하지 않는 API
   * @returns {*}
   */
  affiliateDeposit() {
    return this.#api.get('/affiliate/promotion/deposit');
  }

  /**
   * @id ReferralPromotionStatusRequest
   * @returns {Promise<ReferralPromotionStatus>}
   */
  referralPromotion() {
    try {
      return this.#api.get('/v1/referral/promotion/status');
    } catch (e) {
      return { error: true, path: 'AuthError' };
    }
  }

  /**
   * @id OptInSignUpPromotionRequest
   * @returns {*}
   */
  async optInSignUp(reset) {
    try {
      if (!this.#optIn || reset) this.#optIn = await this.#api.post('/v1/sign-up/promotion/reward');
      return this.#optIn;
    } catch (e) {
      console.log('error : opt in sign up');
      return e;
    }
  }

  /**
   * @id ClaimDailyPromotionRequest
   * @param {number?} DayOfWeek
   * @returns {*}
   */
  async optInFreebie({ reset, DayOfWeek }) {
    try {
      if (!this.#optIn || reset) this.#optIn = await this.#api.post('/v1/daily/promotion/reward');
      return this.#optIn;
    } catch (e) {
      console.log('error : opt in daily freebie');
      return e;
    }
  }
}
