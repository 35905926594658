import * as Sentry from '@sentry/vue';

export default {
  install(Vue, { router, dsn }) {
    Sentry.init({
      Vue,
      dsn,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      ignoreErrors: [
        /Captcha/igm,
        /chunk/igm,
        /ResizeObserver/igm,
        'Unexpected token \'<\'',
        'Unexpected token \'=\'',
        /gtag/igm,
        /grecaptcha/igm,
        /\/recaptcha\//igm,
        /timeout/igm,
        /\(reading\s'style'\)/igm,
        /\(evaluating\s'[a-z]+.style'\)/igm,
        /googletagmanager\.com\/gtm\.js/i,
        /gstatic\.com\/recaptcha\/releases/i,
        /^chrome:\/\//i,
        /^moz-extension:\/\//i,
        /^about:/i,
        /^edge:\/\//i,
        /^analytics.min.js\/\//i,
      ],
    });
  }
};
