import { isExcludeGgpass, isLocal } from '@/utils/baseUtil';
import MyPageSiteMap, { getCurrencySymbol } from '@/constants/model/my-page';
import { setGTM } from '@/plugins/tracking';
import { STEP } from '@/constants/base/my-page';
import { apiErrorType } from '@/constants/base/apiErrorCode';

class MyInfoService {
  #services;
  #router;
  #store;
  #siteMap;
  #siteInfo;
  #step;
  #site;
  #brand;
  #structure;
  #routeName;
  constructor(services, store, router) {
    this.#services = services;
    this.#router = router;
    this.#store = store;
    this.#siteMap = MyPageSiteMap();
    this.#siteInfo = store.state.env.siteInfo;
    this.#step = STEP.DEFAULT;
    this.#site = store.state.env.site;
    this.#brand = store.state.env.brand;
    this.#structure = { action: null, config: null };

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  get title() {
    const item = this.currentPageModel.title;
    if (item.hasOwnProperty(this.#site)) {
      return `myInfo.title.${item[this.#site]}`;
    } else return `myInfo.title.${item.default}`;
  }
  get model() {
    return this.#siteMap.model;
  }
  get currentPageModel() {
    return this.#siteMap[this.#routeName];
  }
  get step() {
    return this.#step;
  }
  get root() {
    return this.#siteMap.root;
  }

  get stepList() {
    return this.currentPageModel.step || [];
  }
  get currentStepIndex() {
    if (this.stepList.length <= 0) return 0;
    return this.stepList.findIndex(step => this.#step === step);
  }

  get currentFetchModels() {
    return this.currentPageModel.fetchModels || null;
  }
  get currentFetchKey() {
    return this.currentPageModel.fetchKeys || null;
  }
  get isFirstStep() {
    return this.currentStepIndex === 0;
  }
  get isLastStep() {
    return this.currentStepIndex === this.stepList.length - 1;
  }

  get currentComponent() {
    return this.currentPageModel?.component || null;
  }

  async currencyInitialize() {
    let r;
    r = await this.#setLocalCurrency();
    return r;
  }

  async #setLocalCurrency() {
    const r = await this.#services.account.getBalance();
    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) { // TODO: [eric] 로직 확인 후 errorRouteReplace로 변경 확인
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return false;
      }
    }
    const localCurrency = r?.LocalCurrency;
    const currencySymbol = getCurrencySymbol(localCurrency, '');

    this.#store.commit('user/setCurrencySymbol', currencySymbol);
    return true;
  }

  async setCurrencyList() {
    const r = await this.#services.base.getCurrencyList();
    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) { // TODO: [eric] 로직 확인 후 errorRouteReplace로 변경 확인
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return false;
      }
    }

    this.#store.commit('config/setCurrencies', r.Currencies);
    return true;
  }

  async #setMyInfo() {
    if(isExcludeGgpass(this.#site)){
      const r = await this.#services.onboarding.getMyInfo(true);
      this.#store.commit('user/setUserInfo', r);
    }else {
      const result = await this.#services.onboarding.getMyInfo(true);
      this.#store.commit('user/setUserInfo', result);
      const r = await this.#services.ggpass.getInfo({ brandId: this.#brand, hasAvatar: true, hasMultiFactors: true, hasOauth: true, hasAccountDetail: true, hasMfas : true });
      this.#store.commit('user/setOnePassInfo', r);
    }
  }

  async setMyInfo(){
    // const r = await this.#services.onboarding.getMyInfo(true);
    // this.#store.commit('user/setUserInfo', r);
    await this.#setMyInfo();
  };

  async getSelfExclusion() {
    const r = await this.#services.player.getSelfExclusionType();
    return r;
  }

  changeStep(step) {
    this.#step = step;
  };
  movePrevStep() {
    if (this.isFirstStep && this.currentStepIndex <= 0) return;
    this.step = this.stepList[this.currentStepIndex - 1];
  };

  createErrorProxy(r) {
    return this.errorHandler(r);
  };

  #getApiService(serviceName) {
    if (!this.#services[serviceName])
      return null;
    return this.#services[serviceName];
  };
  getFetchFunction(serviceName, functionName, params = {}, hasKey = false, npOnly = false) {
    const service = this.#getApiService(serviceName);

    if (!service) return null;
    if (!service[functionName])
      return null;

    if (isExcludeGgpass(this.#site)) {
      if (!hasKey) {
        return service[functionName](params);
      } else {
        if (hasKey && npOnly) return service[functionName](params);
        else return null;
      }
    } else {
      if (!hasKey) {
        return service[functionName](params);
      } else {
        if (hasKey && npOnly) return null;
        else return service[functionName](params);
      }
    }
  };

  async createFetchFunction(payload) {
    if (!this.currentFetchModels) return;

    const fetchFunctions = this.currentFetchModels.map(fetchModel => {
      const fetch = this.getFetchFunction(fetchModel.service, fetchModel.fetch, { ...fetchModel.params, ...payload }, fetchModel.hasOwnProperty('npOnly'), fetchModel.npOnly);
      return fetch;
    }).filter(fetch => fetch !== null);

    if (fetchFunctions.length <= 0) return null;

    if (fetchFunctions.length > 1) {
      const results = await Promise.all(fetchFunctions).then((promises) => {
        const error = promises.find(x => x.error);
        if (error) {
          return error;
        }

        return promises.reduce((acc, cur, idx) => {
          acc[this.currentFetchKey[idx]] = cur;
          return acc;
        }, {});
      });
      return results;
    } else return fetchFunctions[0];
  };

  async fetch({payload , block = true}) {
    if(block) this.#services.modal.block();
    const r = await this.createFetchFunction(payload);
    if (r?.error) {
      if(block) this.#services.modal.unblock();
      const replace = this.errorHandler(r);
      if (replace) {
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return;
      }
      return r;
    }
    if(block) this.#services.modal.unblock();
    return r;
  };

  createAction() {
    const { service, api } = this.currentPageModel;

    if (!service && !api) {
      this.#structure.action = null;
      return;
    }
    this.#structure.action = (payload, ...args) => this.#services[service][api](payload, ...args);
  }

  async request(payload, type = null, reFetch = false) {
    if (!this.#structure.action) return;
    const r = await this.#structure.action(payload, type);
    if (r?.error) {
      if (!await this.#errorRouteReplace(r)) return r;
    }
    if (reFetch) await this.#setMyInfo();
    return r;
  }

  errorHandler(r) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    if (r.errorTemplate?.[apiErrorType.AUTH]) {
      return { path: 'AuthError', params: { ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined } };
    }
    return false;
  }

  async #errorRouteReplace(r) {
    const info = this.errorHandler(r);
    if (info) {
      await this.#router.replace({ name: info.path, params: info.params, query: info.query });
      return true;
    }

    return false;
  }

  updateRouteName(routeName) {
    this.#routeName = routeName;
  }

  redirectKycUrl({ redirectPath, additional, customStep, lang }) {
    this.#services.modal.block();
    try {
      const path = isLocal() ? '/myinfo' : '/app';
      const originUrl = `${location?.origin}${path}/${redirectPath || ''}`;
      const encodeOriginUrl = encodeURIComponent(originUrl);

      const token = this.#store.state.user.token;
      const kycUrl = process.env[`VUE_APP_ON_BOARDING_URL_${this.#site}`] || null;
      if (kycUrl === null) {
        this.#services.modal.unblock();
        return;
      }
      const queryString = {
        token: token,
        lang: lang,
        redirectUri: encodeOriginUrl,
        additional: additional,
        customStep: customStep
      };
      location.href = `${kycUrl}?${Object.keys(queryString).filter(x => queryString[x]).map(key => `${key}=${queryString[key]}`).join('&')}`;
      this.#services.modal.unblock();
      return true;
    } catch (err) {
      this.#services.modal.unblock();
      return false;
    }
  }
}

export default {
  install(Vue, { services, store, router }) {
    Vue.prototype.$myinfo = new MyInfoService(services, store, router);
  }
};