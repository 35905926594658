import { render, staticRenderFns } from "./VerifySumsub.vue?vue&type=template&id=3722edf1"
import script from "./VerifySumsub.vue?vue&type=script&lang=js"
export * from "./VerifySumsub.vue?vue&type=script&lang=js"
import style0 from "./VerifySumsub.vue?vue&type=style&index=0&id=3722edf1&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports